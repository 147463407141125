import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
var jwt = require("jwt-simple");
var secret = "HS256 ";
class TaxNavbar extends React.Component {
  state = {
    alert: null,
  };

  componentDidMount(){
    this.mounted = true;
    // this.updateInterval = setInterval(() => {
    //   // this.getListNotification();
    //   this.checkActive();
    // }, 10800000)
    // try{
    //   axios
    //     .post(
    //       config.API_URL + "/invoice/sync_admin_server_date",
    //       { user_id: 2},
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     )
    //     .then(
    //       (res) => {
    //         console.log(res)
    //         console.log("dadadmqwguwirhir")

    //         // this.deleteAllCookies();
    //         // localStorage.clear();
    //         // this.props.history.push("/auth/login");
    //       },
    //       (err) => {
    //         console.log(err)
    //         // if (err.response !== undefined) {
    //         //   this.failedAlert(err.response.data.message);
    //         //   this.setState({ isLoading: false });
    //         // }
    //       }
    //     );
    // } catch(e){
    //   console.log(e)
    //   // axiosApi.interceptors.response.use(
    //   //   function(response) {
    //   //     return response;
    //   //   },
    //   //   function(error) {
    //   //      if (error.status.code === 401 ) {
    //   //       store.dispatch(actions.logout());
    //   //     }
    
    //   //     return Promise.reject(error);
    //   //   }
    //   // );
    // }
    
  }
  componentWillUnmount(){
    this.mounted = true;
    
  }


  
  handlelogout = () => {
    try {
      var token_local = jwt.decode(localStorage.getItem("token"), secret);
      axios
        .post(
          config.API_URL + "/auth/logout",
          { user_id: token_local.user_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(
          (res) => {
            this.deleteAllCookies();
            localStorage.clear();
            this.props.history.push("/auth/tax");
          },
          (err) => {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
              this.setState({ isLoading: false });
            }
          }
        );
    } catch (e) {
      console.log(e);
    }
  };

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Logout"
          onConfirm={() => this.handlelogout()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, logout!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {data}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  // globalCek = () => {
  //   setInterval(
  //     this.cek()
  //   ,2000)
  //   // ,86400000)
  // };

  // cek =(e)=>{
  //   console.log(e)
  // }
  render() {
    const data_local = jwt.decode(localStorage.getItem("token"), secret);
    // this.globalCek()
    // setInterval(
    //  console.log("cek")
    // ,2000)
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            // { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="fixed-left" navbar>
                <h4 className="text-uppercase">E-Invoice</h4>
              </Nav>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "light" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line text-black" />
                      <i className="sidenav-toggler-line text-black" />
                      <i className="sidenav-toggler-line text-black" />
                    </div>
                  </div>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded-circle">
                        
                      </span> */}
                  <Media className="ml-2 d-none d-lg-block">
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        className="nav-link pr-0"
                        color=""
                        tag="a"
                      >
                        <span className="mb-0 text-sm font-weight-bold">
                          {data_local.username}{" "}
                        </span>
                        &emsp;
                        <i
                          className="fa fa-power-off"
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() => this.confirmAlert("Are you sure?")}
                        />
                      </DropdownToggle>
                      {/* <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.props.history.push('/admin/myprofile-admin')}
                    >
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      href="#exit"
                      onClick={() => this.confirmAlert("Are you sure?")}
                    >
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu> */}
                    </UncontrolledDropdown>
                  </Media>
                </Media>
              </Nav>
            </Collapse>
          </Container>
          {this.state.alert}
        </Navbar>
      </>
    );
  }
}
TaxNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
TaxNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default TaxNavbar;
