import React, { PureComponent } from "react";
// import { Prompt } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  Modal,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import StandardHeader from "components/Headers/HeaderVendor.js";
import { validateAddStandardInvoice } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { styleInputSelect } from "services/styles";
const today = new Date();
const inDate = today.getDate();
const pic_date = inDate % 2 === 0 ? 2 : 3;
// var exp = today.toISOString().split("T")[0].replace(/\-/g, "").slice(2, 8);
// var cek = "EINV-" + exp + "-3090990";
// console.log(cek);
// const nD = new Date().toLocaleString("en-US", {
//   timeZone: "Asia/Bangkok",
// });
// const dt = new Date().toISOString().slice(0, 19).replace('T', ' ');
// const ck = nD.format("YYYY-MM-DD HH:mm:ss");
// const dt= nD.getUTCFullYear()+"-"+('00' + (nD.getUTCMonth()+1)).slice(-2) + '-' +('00' + nD.getUTCDate()).slice(-2) + ' ' + ('00' + nD.getUTCHours()).slice(-2) + ':' + ('00' + nD.getUTCMinutes()).slice(-2) + ':' + ('00' + nD.getUTCSeconds()).slice(-2);
const nD = new Date();
const dt =
  nD.getFullYear() +
  "-" +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + nD.getDate()).slice(-2) +
  " " +
  ("0" + nD.getHours()).slice(-2) +
  ":" +
  ("0" + nD.getMinutes()).slice(-2) +
  ":" +
  nD.getSeconds();

const prefDate =
  new Date().getFullYear().toString().substr(-2) +
  "" +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  "" +
  ("0" + nD.getDate()).slice(-2);

class VendorCreateRegulerInvoicev2 extends PureComponent {
  state = {
    add: 0,
    thisShow: "",
    formSubmit: false,
    showSiuk: false,
    showAddSiuk: false,
    isButton: true,
    showAddOther: false,
    useInvoiceDisplay: "",
    national:[],
    dataSiuk: {
      siuk_name: "",
      siuk_fl: "",
    },
    dateSync: {
      dateDay: "0",
      date: "1900-01-01 00:00:00",
      dtt: "0",
      h: "0",
      m: "00",
      s: "00",
    },
    display: {
      dCurr: "",
      dCurrC: "",
      dCurrCC: "",
      dCurr: "",
      dCurr_id: "",
    },
    data: {
      to_pic: pic_date,
      user_id: config.USER_ID,
      company_id: config.COMPANY_ID,
      purchase_order: "",
      invoice_number: "",
      tax_invoice: "",
      invoice_amount: "",
      delivery_note: "",
      bast: "",
      good_receipt: "",
      siuk: "",
      curr: "",
      curr_id: "",
    },
    files: {
      invoice_file: "",
      siuk_file: "",
    },
    filesUse: {
      useSiuk: 0,
    },
    isLoading: false,
    alert: null,
    paramError: {},
    listCurrencies: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.handleTimeServer();
    this.handleHoliday();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getCurrencies = (inputValue) => {
    let searchTerm = inputValue;
    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }
    console.log(inputValue)
    const newRequest = axios.post(
      config.API_URL + "/vendor/get_currencies",
      { id: searchTerm },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    if (newRequest) {
      return newRequest.then((response) => {
        const compare = response.data.data.filter((i) =>
          i.code.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.code+" | "+data.country,
          value: data.id,
        }));
      });
    }
  };
  onSearchChangeCompanies = (selectedOptionCompanies) => {
    console.log(selectedOptionCompanies)
    if (selectedOptionCompanies) {
      this.setState(
        {
          selectedOptionCompanies: selectedOptionCompanies,
        },()=> this.selectCurrencies(selectedOptionCompanies.value)
      );
    } else {
      this.setState(
        {
          selectedOptionCompanies: null,
          display:{
            dCurr: "",
            dCurrC: "",
            dCurrCC: "",
            dCurr: "",
            dCurr_id: "",
          },
          data: {
            curr: "",
            curr_id: "",
          }
        }
      );
    }
  };
  selectCurrencies = (id) => {
    axios
      .post(
        config.API_URL + "/vendor/find_currencies",
        { id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              display:{
                ...this.state.display,
                dCurr: res.data.data[0].currency,
                dCurrC: res.data.data[0].symbol,
                dCurrCC: res.data.data[0].cc,
                dCurr: res.data.data[0].code,
                dCurr_id: res.data.data[0].id,
              },
              data: {
                ...this.state.data,
                curr: res.data.data[0].code,
                curr_id: res.data.data[0].id,
              }
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // selectGetCurrencies = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/vendor/get_currencies",
  //       { id: 54 },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState({
  //             display: {
  //               ...this.state.display,
  //               dCurr: res.data.data[0].currency,
  //               dCurrC: res.data.data[0].symbol,
  //               dCurrCC: res.data.data[0].cc,
  //             },
  //             data: {
  //               ...this.state.data,
  //               curr: res.data.data[0].code,
  //               curr_id: res.data.data[0].id,
  //             },
  //           });
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
 
  handleHoliday = () => {
    try {
      axios
        .get(
          "https://raw.githubusercontent.com/guangrei/APIHariLibur_V2/main/calendar.json",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (this.mounted) {
            this.setState({
              national: res.data,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  handleTimeServer = () => {
    axios
      .post(
        config.API_URL + "/vendor/sync_server",
        { id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState(
            {
              ...this.state,
              dateSync: {
                ...this.state.dateSync,
                dateDay: res.data.data.day,
                mt: res.data.data.mt,
                date: res.data.data.dt,
                dtt: res.data.data.dtt,
                h: res.data.data.h,
                m: res.data.data.m,
                s: res.data.data.s,
              },
            },
            () => this.handleTimeSync()
          );
        },
        (err) => {
          console.log(err.response);
        }
      );
  };
 
  handleTimeSync = () => {
    var dateISO = this.state.dateSync.date.substring(0, 10);
    var national = this.state.national;
    var found = Object.entries(national).find(
      ([key, value]) => key === dateISO && value.holiday === true
    );
    var simFound = found ? true : false;
    var dy = this.state.dateSync.dateDay;
    var dh = parseInt(this.state.dateSync.h);
    var setAStart = parseInt(9);
    var setAEnd = parseInt(15);


    if (this.state.dateSync.mt === 12) {
      if (dy > 0 && dy < 6 && simFound === false) {
        if (dh >= setAStart && dh < setAEnd) {
          this.getListCompany();
        } else {
          this.handleCancel();
        }
      } else {
        this.handleCancel();
      }
    } else {
    if (this.state.dateSync.dtt > 0 && this.state.dateSync.dtt < 26 && simFound === false) {
      if (dy > 0 && dy < 5) {
        if (dh >= setAStart && dh < setAEnd) {
          this.getListCompany();
        } else {
          this.handleCancel();
        }
      } else {
        this.handleCancel();
      }
    } else {
      this.handleCancel();
     }
     }
  };
  getListCompany = () => {
    axios
      .post(
        config.API_URL + "/vendor/list_vendor_settings",
        { company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                siuk: res.data.data[0].siuk,
              },
              files: {
                ...this.state.files,
                siuk_file: res.data.data[0].siuk_file,
              },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ thisShow: fileUploaded });
  };

  postSiuk = () => {
    this.setState(
      {
        isLoading: true,
        dataSiuk: {
          ...this.state.dataSiuk,
          company_id: config.COMPANY_ID,
          datetime: dt,
          prefDate: prefDate,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("datetime", this.state.dataSiuk.datetime);
        formData.append("prefDate", this.state.dataSiuk.prefDate);
        formData.append("company_id", this.state.dataSiuk.company_id);

        formData.append("siuk_name", this.state.dataSiuk.siuk_name);
        formData.append("siuk_fl", this.state.dataSiuk.siuk_fl);
        axios
          .post(
            config.API_URL + "/vendor/update_vendor_settings_siuk",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlertSIUJK(res.data.s);
                // cek this
                this.setState({
                  isLoading: false,
                  data: {
                    ...this.state.data,
                    siuk: res.data.s,
                  },
                  files: {
                    ...this.state.files,
                    siuk_file: res.data.sf,
                  },
                });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedRequirement(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      }
    );
  };

  handleSubmitInvoiceStandard = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
        },
        files: {
          ...this.state.files,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("pic", this.state.data.to_pic);
        formData.append("user_id", this.state.data.user_id);
        formData.append("company_id", this.state.data.company_id);
        formData.append("purchase_order", this.state.data.purchase_order);
        formData.append("invoice_number", this.state.data.invoice_number);
        formData.append("currency", this.state.data.curr);
        formData.append("currency_id", this.state.data.curr_id);
        formData.append("invoice_amount", this.state.data.invoice_amount);
        formData.append("tax_invoice", this.state.data.tax_invoice);
        formData.append("delivery_note", this.state.data.delivery_note);
        formData.append("bast", this.state.data.bast);
        formData.append("good_receipt", this.state.data.good_receipt);
        formData.append("invoice_file", this.state.files.invoice_file);

        if (this.state.filesUse.useSiuk === 1) {
          formData.append("siuk", this.state.data.siuk);
          formData.append("siuk_file", this.state.files.siuk_file);
        } else {
          formData.append("siuk", "");
          formData.append("siuk_file", "");
        }
        formData.append("created_on", dt);
        formData.append("pref_date", prefDate);

        //   for (let i = 0; i < fileUploads.length; i++) {
        //     formData.append(`images[${i}]`, this.state.data.fileUloads[i])
        // }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        const paramError = validateAddStandardInvoice(
          this.state.data,
          this.state.files
        );
        this.setState({ paramError });
        if (Object.keys(paramError).length === 0) {
          axios
            .post(config.API_URL + "/vendor/add_standard_invoice", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  handleChangeFile = (event) => {
    var myfile = event.target.files[0].name;
    var ext = myfile.split(".").pop();
    if (ext === "pdf") {
      this.setState({
        useInvoiceDisplay: myfile,
        files: {
          ...this.state.files,
          [event.target.name]: event.target.files[0],
        },
      });
    } else {
      this.setState(
        {
          useInvoiceDisplay: "",
          files: {
            ...this.state.files,
            invoice_file: "",
          },
        },
        () => this.failedAlert("The uploaded file must be in PDF format")
      );
    }
  };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleChangeNumber = (evt) => {
    const re = /^[0-9\.]+/;
    
    console.log( re.test(evt.target.value))
    if (evt.target.value === ""  || re.test(evt.target.value)) {
      const formatted = evt.target.value;
      // const formatted = evt.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      this.setState({
        data: {
          ...this.state.data,
          [evt.target.name]: formatted,
        },
      });
    }
  };
 
  handleCancel = () => {
    this.props.history.push("/vendor/active-reguler-invoice/");
  };
  onChangePreview = (data) => {
    const files = config.BUCKET_URL + data;

    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object width="100%" height="790" data={files} type="application/pdf">
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  resetSIUJK = () => {
    this.setState({
      ...this.state,
      showAddSiuk: false,
      alert: null,
      dataSiuk: {
        ...this.state.dataSiuk,
        datetime: "",
        prefDate: "",
        company_id: "",
        siuk_name: "",
        siuk_fl: "",
      },
    });
  };
  successAlertSIUJK = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.resetSIUJK()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={5000}
        >
          SIUJK&nbsp; <b>{`${data}`}</b>&nbsp;has been Successfully upload
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.props.history.push("/vendor/active-reguler-invoice/")
          }
          // onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={5000}
        >
          Invoice&nbsp; <b>{`${data.data}`}</b>&nbsp;added successfully
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={5000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  failedRequirement = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => window.location.reload(false)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={20000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  // handleSub = () => {
  //   if (this.state.add < 0) {
  //     this.setState({
  //       add: 0,
  //     });
  //   }
  //   this.setState({
  //     add: this.state.add - 1,
  //   });
  // };
  // handleAdd = () => {
  //   this.setState({
  //     add: this.state.add + 1,
  //   });
  //   if (this.state.add === 3) {
  //     this.setState({
  //       add: 3,
  //     });
  //   }
  // };
  handleChangeSiuk = (event) => {
    if (event.target.name === "siuk_name") {
      this.setState({
        dataSiuk: {
          ...this.state.dataSiuk,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      this.setState({
        dataSiuk: {
          ...this.state.dataSiuk,
          [event.target.name]: event.target.files[0],
        },
      });
    }
  };
  handleRemove = () => {
    this.setState({
      dataSiuk: {
        ...this.state.dataSiuk,
        siuk_name: "",
        siuk_fl: "",
      },
    });
  };
  handleRemoveDocument = () => {
    this.setState({ files: { invoice_file: "" } });
  };
  closeModal = () => {
    this.setState({
      showAddSiuk: false,
      dataSiuk: {
        ...this.state.dataSiuk,
        siuk_name: "",
        siuk_fl: "",
      },
    });
  };

  curr = (nm, ct, cd) => {
    const cur = Intl.NumberFormat(ct, {
      style: "currency",
      currency: cd,
      maximumFractionDigits: 2,
    }).format(nm);
    return cur;
  };

  render() {
    return (
      <>
        <StandardHeader
          name="Add invoice"
          parentName="Open"
          link="active-reguler-invoice"
        />
        <Modal
          centered
          size="lg"
          isOpen={this.state.showAddSiuk}
          // toggle={this.closeModal}
        >
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0" toggle={this.hideModal}>
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Add </h3>
                </Col>
                <Col xs="6" className="text-right">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => this.closeModal()}
                  >
                    <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                      &times;
                    </span>
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <label className="form-control-label">
                    Construction business license number
                    <i className="text-muted">(SIUJK)</i>
                  </label>
                  <Row>
                    {this.state.dataSiuk.siuk_fl ? (
                      <>
                        <Col
                          className="col-lg-1 col-md-1 col-sm-1 text-right"
                          xs="1"
                        >
                          <div className="mx-auto mt-2 text-right">
                            <FormGroup>
                              <Button
                                id="del"
                                onClick={() => this.handleRemove()}
                                size="sm"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                              >
                                <i
                                  className="fa fa-trash text-dark"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              <UncontrolledTooltip
                                flip
                                delay={0}
                                target={"del"}
                              >
                                Delete
                              </UncontrolledTooltip>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <FormGroup>
                            <Input
                              type="text"
                              onKeyPress={this.keyPressed}
                              name="siuk_name"
                              value={this.state.dataSiuk.siuk_name}
                              onChange={this.handleChangeSiuk}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup>
                            <Input
                              type="text"
                              onKeyPress={this.keyPressed}
                              name="siuk_name"
                              accept="application/pdf"
                              value={this.state.dataSiuk.siuk_name}
                              onChange={this.handleChangeSiuk}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                {this.state.dataSiuk.siuk_name ? (
                  <>
                    <Col>
                      <FormGroup>
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="customFileLang"
                            lang="en"
                            type="file"
                            name="siuk_fl"
                            // value={this.state.data.slip}
                            accept="application/pdf"
                            onChange={this.handleChangeSiuk}
                            required
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="customFileLang"
                          >
                            Select file
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>

              {this.state.dataSiuk.siuk_fl && this.state.dataSiuk.siuk_name ? (
                <>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-6 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="primary"
                        className="btn-icon"
                        type="button"
                        // disabled={this.state.isFilterLoading ? true : false}
                        onClick={() => this.postSiuk()}
                      >
                        {this.state.isFilterLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Upload
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Upload</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : null}
            </CardBody>
          </Card>
        </Modal>
        <Container className="mt--6 mb-auto">
          {/* <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row>
                <Col className="text-center">
                  <h2>
                  INVOICE
                  </h2>
                </Col>
              </Row>
            </CardHeader>
          </Card> */}
          <Row className="mt-0 mb-2">
            <Col className="text-center">
              <h2>
                INVOICE
              </h2>
            </Col>
          </Row>
          <hr className="mt-2" />
          <Row>
            <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
              <Card>
                <CardBody>
                  <FormGroup>
                    <label className="form-control-label">
                      Purchase order number
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="purchase_order"
                      value={this.state.data.purchase_order}
                      onChange={this.handleChangeString}
                      placeholder="Required"
                      required
                      className={
                        this.state.paramError.purchase_order ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.purchase_order}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Invoice number<span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="invoice_number"
                      value={this.state.data.invoice_number}
                      onChange={this.handleChangeString}
                      required
                      placeholder="Required"
                      className={
                        this.state.paramError.invoice_number ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.invoice_number}
                    </div>
                  </FormGroup>
                  {this.state.data.invoice_number? (<>
                  <FormGroup>
                    <label className="form-control-label" id="document_invoice">
                      Invoice document
                    </label>
                    <UncontrolledTooltip
                      flip
                      delay={0}
                      target={"document_invoice"}
                    >
                      Please make one PDF file containing invoice file, purchase
                      order file, tax invoice file and if there is a good
                      received file, delivery note file, bast file and upload it
                      in the document invoice
                    </UncontrolledTooltip>
                    <small> pdf</small>
                    <span className="text-danger">*</span>

                    <Form>
                      {this.state.files.invoice_file ? (
                        <>
                          <Row>
                            <Col xs="1">
                              <Button
                                id="del"
                                onClick={() =>
                                  this.setState({
                                    useInvoiceDisplay: "",
                                    data:{
                                      ...this.state.data,
                                      invoice_number:"",
                                    },
                                    files: {
                                      ...this.state.files,
                                      invoice_file: "",
                                    },
                                  })
                                }
                                size="sm"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                              >
                                <i
                                  className="fa fa-trash text-dark"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              <UncontrolledTooltip
                                flip
                                delay={0}
                                target={"del"}
                              >
                                Delete file
                              </UncontrolledTooltip>
                            </Col>
                            <Col>
                              <div
                                className="mt-1"
                                style={{ fontSize: "0.800rem" }}
                              >
                                {" " + this.state.useInvoiceDisplay}
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <div className="custom-file">
                            <Input
                              className="custom-file-input"
                              type="file"
                              name="invoice_file"
                              placeholder="Invoice number"
                              accept="application/pdf"
                              onChange={this.handleChangeFile}
                              required
                            />
                            <label className="custom-file-label"></label>
                          </div>
                          {this.state.paramError.invoice_file && (
                            <small className="text-warning">
                              {this.state.paramError.invoice_file}
                            </small>
                          )}
                        </>
                      )}
                    </Form>
                  </FormGroup>
                  </>): null}
                  <FormGroup>
                    <label className="form-control-label">
                      Invoice amount DPP<span className="text-danger">*</span>
                      &emsp;&emsp;
                      <span style={{ float: "right" }}>
                        {this.state.data.invoice_amount &&
                        this.state.data.curr ? (
                          <>
                            {this.curr(
                              this.state.data.invoice_amount,
                              this.state.display.dCurrCC,
                              this.state.display.dCurr
                            )}
                          </>
                        ) : null}
                      </span>
                    </label>
                    <InputGroup>
                      {this.state.data.curr ? (
                        <InputGroupText>
                          <b className="text-dark">
                            {this.state.display.dCurrC}
                          </b>
                        </InputGroupText>
                      ) : null}
                      <Input
                        id="inv_am"
                        type="text"
                        pattern="^[\d,]+$"
                        step="any"
                        placeholder="Required"
                        name="invoice_amount"
                        value={this.state.data.invoice_amount}
                        onChange={this.handleChangeNumber}
                        required
                        // onKeyPress={this.isNumber}
                        className={
                          this.state.paramError.invoice_amount
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.invoice_amount}
                      </div>
                    </InputGroup>
                  </FormGroup>
                  {this.state.data.invoice_amount ? (<>
                  <FormGroup classname="mt-3">
                    <label className="form-control-label" for="input-curr">
                      Currency<span className="text-danger">*</span>
                    </label>
                  <AsyncSelect
                        noOptionsMessage={() => "Not Found"}
                        cacheOptions
                        defaultOptions
                        isClearable
                        styles={styleInputSelect}
                        value={this.state.selectedOptionCompanies}
                        loadOptions={this.getCurrencies}
                        onChange={(e) => {
                          this.onSearchChangeCompanies(e)
                        }}
                      />   
                  </FormGroup>
                  </>):null}
                  <FormGroup className="mt-4">
                    <label className="form-control-label">
                      Tax invoice number
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="tax_invoice"
                      value={this.state.data.tax_invoice}
                      onChange={this.handleChangeString}
                      required
                      placeholder="Required"
                      className={
                        this.state.paramError.tax_invoice ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.tax_invoice}
                    </div>
                  </FormGroup>{" "}
                  <FormGroup className="mb--2">
                    <p className="font-italic ">
                      {" "}
                      <span className="text-danger">*</span>{" "}
                      <p2 className="text-default">Required field</p2>
                    </p>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
              <Card>
                <CardBody>
                  <FormGroup>
                    <label className="form-control-label">
                      Good receipt number
                    </label>
                    <Input
                      type="text"
                      name="good_receipt"
                      placeholder="Optional"
                      value={this.state.data.good_receipt}
                      onChange={this.handleChangeString}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Delivery note number
                    </label>
                    <Input
                      type="text"
                      name="delivery_note"
                      placeholder="Optional"
                      value={this.state.data.delivery_note}
                      onChange={this.handleChangeString}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Minutes of hand over number
                      <i className="text-muted">(BAST)</i>
                    </label>
                    <Input
                      type="text"
                      name="bast"
                      placeholder="Optional"
                      value={this.state.data.bast}
                      onChange={this.handleChangeString}
                      required
                    />
                  </FormGroup>
                  {this.state.files.siuk_file ? (
                    <>
                      {this.state.showSiuk ? (
                        <FormGroup>
                          <label className="form-control-label">
                            <a
                              className="btn btn-sm"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  showSiuk: false,
                                  filesUse: {
                                    ...this.state.filesUse,
                                    useSiuk: 0,
                                  },
                                })
                              }
                            >
                              <i className="ni ni-fat-delete" />
                              Added construction business license
                              <i className="text-muted">(SIUJK)</i>
                            </a>
                            {this.state.files.siuk_file ? null : (
                              <a
                                className="tex-right"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                  margin: "0px 4px 0px 0px",
                                }}
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    showSiuk: !this.state.showSiuk,
                                    filesUse: {
                                      ...this.state.filesUse,
                                      useSiuk: 1,
                                    },
                                  })
                                }
                              >
                                <i className="fa fa-times" aria-hidden="true" />
                              </a>
                            )}
                          </label>
                          {this.state.files.siuk_file ? (
                            <>
                              <div className="ml-auto mt-2">
                                <FormGroup>
                                  <Button
                                    id="pre"
                                    size="sm"
                                    type="button"
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                    onClick={() =>
                                      this.onChangePreview(
                                        this.state.files.siuk_file
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-file-pdf-o text-dark"
                                      aria-hidden="true"
                                    ></i>
                                    <span>{" " + this.state.data.siuk}</span>
                                  </Button>
                                  <UncontrolledTooltip
                                    flip
                                    delay={0}
                                    target={"pre"}
                                  >
                                    Preview
                                  </UncontrolledTooltip>
                                </FormGroup>
                              </div>
                            </>
                          ) : null}
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Button
                            // color="secondary"
                            size="sm"
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                showSiuk: !this.state.showSiuk,
                                filesUse: {
                                  ...this.state.filesUse,
                                  useSiuk: 1,
                                },
                              })
                            }
                          >
                            <i className="ni ni-fat-add" />
                            Add construction business license
                            <i className="text-muted">(SIUJK)</i>
                          </Button>
                        </FormGroup>
                      )}
                    </>
                  ) : (
                    <>
                      <FormGroup>
                        <a
                          className="btn btn-sm border"
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() => this.setState({ showAddSiuk: true })}
                        >
                          <i className="ni ni-settings-gear-65" />
                          Construction business license
                          <i className="text-muted">(SIUJK) Optional</i>
                        </a>
                        {/* </Button> */}
                      </FormGroup>
                    </>
                  )}
                </CardBody>
              </Card>
              {/* 
              <Card>
                <CardBody>
                  <FormGroup className="my-0">
                    <label className="form-control-label">Other document</label>
                    <i className="text-muted">
                      {"  "}
                      <small>Optional</small>
                    </i>
                  </FormGroup>
                 <hr className="mt-0 mb-3" />

                  {additional === 1 ? (
                    <>
                      <Row>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Additional Document 1
                            </label>
                            <Input
                              type="text"
                              placeholder="Additional"
                              onChange={this.handleChangeString}
                              value={this.state.data.add1}
                              name="add1"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <label className="form-control-label">
                            Additional Document 1 file
                          </label>
                          <Form>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="add_file1"
                                className="custom-file-input"
                                id="customFileLang"
                                onChange={this.handleChangeFile}
                                accept="application/pdf"
                              />
                              <label className="custom-file-label"></label>
                            </div>
                          </Form>
                        </Col>
                        <Col className="mt-2 col-lg-1 col-md-1 col-sm-1" xs="1">
                          <div
                            className="mt-4 ml--3 text-muted text-center"
                            style={{ cursor: "pointer", objectFit: "cover" }}
                            onClick={() => this.handleSub(1)}
                          >
                            <i
                              className="fa fa-times fa-2x"
                              aria-hidden="true"
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : additional === 2 ? (
                    <>
                      <Row>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Additional Document 1
                            </label>
                            <Input
                              type="text"
                              placeholder="Additional"
                              onChange={this.handleChangeString}
                              value={this.state.data.add1}
                              name="add1"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <label className="form-control-label">
                            Additional Document 1 file
                          </label>
                          <Form>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="add_file1"
                                className="custom-file-input"
                                id="customFileLang"
                                onChange={this.handleChangeFile}
                                accept="application/pdf"
                              />
                              <label className="custom-file-label"></label>
                            </div>
                          </Form>
                        </Col>
                        <Col className="mt-2 col-lg-1 col-md-1 col-sm-1" xs="1">
                          <div
                            className="mt-4 ml--3 text-muted text-center"
                            style={{ cursor: "pointer", objectFit: "cover" }}
                            onClick={() => this.handleSub(1)}
                          >
                            <i
                              className="fa fa-times fa-2x"
                              aria-hidden="true"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup className="mt-4">
                            <label className="form-control-label">
                              Additional Document 2
                            </label>
                            <Input
                              type="text"
                              placeholder="Additional"
                              onChange={this.handleChangeString}
                              value={this.state.data.add2}
                              name="add2"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <label className="form-control-label">
                            Additional Document 2 file
                          </label>
                          <Form>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="add_file2"
                                className="custom-file-input"
                                id="customFileLang"
                                onChange={this.handleChangeFile}
                                accept="application/pdf"
                              />
                              <label className="custom-file-label"></label>
                            </div>
                          </Form>
                        </Col>
                        <Col className="mt-2 col-lg-1 col-md-1 col-sm-1" xs="1">
                          <div
                            className="mt-4 ml--3 text-muted text-center"
                            style={{ cursor: "pointer", objectFit: "cover" }}
                            onClick={() => this.handleSub(2)}
                          >
                            <i
                              className="fa fa-times fa-2x"
                              aria-hidden="true"
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : additional === 3 ? (
                    <>
                      <Row>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Additional Document 1
                            </label>
                            <Input
                              type="text"
                              placeholder="Additional"
                              onChange={this.handleChangeString}
                              value={this.state.data.add1}
                              name="add1"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <label className="form-control-label">
                            Additional Document 1 file
                          </label>
                          <Form>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="add_file1"
                                className="custom-file-input"
                                id="customFileLang"
                                onChange={this.handleChangeFile}
                              />
                              <label className="custom-file-label"></label>
                            </div>
                          </Form>
                        </Col>
                        <Col className="mt-2 col-lg-1 col-md-1 col-sm-1" xs="1">
                          <div
                            className="mt-4 ml--3 text-muted text-center"
                            style={{ cursor: "pointer", objectFit: "cover" }}
                            onClick={() => this.handleSub(1)}
                          >
                            <i
                              className="fa fa-times fa-2x"
                              aria-hidden="true"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup className="mt-4">
                            <label className="form-control-label">
                              Additional Document 2
                            </label>
                            <Input
                              type="text"
                              placeholder="Additional"
                              onChange={this.handleChangeString}
                              value={this.state.data.add2}
                              name="add2"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <label className="form-control-label">
                            Additional Document 2 file
                          </label>
                          <Form>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="add_file2"
                                className="custom-file-input"
                                id="customFileLang"
                                onChange={this.handleChangeFile}
                                accept="application/pdf"
                              />
                              <label className="custom-file-label"></label>
                            </div>
                          </Form>
                        </Col>
                        <Col className="mt-2 col-lg-1 col-md-1 col-sm-1" xs="1">
                          <div
                            className="mt-4 ml--3 text-muted text-cente"
                            style={{ cursor: "pointer", objectFit: "cover" }}
                            onClick={() => this.handleSub(2)}
                          >
                            <i
                              className="fa fa-times fa-2x"
                              aria-hidden="true"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                          <FormGroup className="mt-4">
                            <label className="form-control-label">
                              Additional Document 3
                            </label>
                            <Input
                              type="text"
                              placeholder="Additional"
                              onChange={this.handleChangeString}
                              value={this.state.data.add3}
                              name="add3"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-11 col-md-11 col-sm-11" xs="11">
                          <label className="form-control-label">
                            Additional Document 3 file
                          </label>
                          <Form>
                            <div className="custom-file">
                              <input
                                type="file"
                                onChange={this.handleChangeFile}
                                name="add_file3"
                                className="custom-file-input"
                                id="customFileLang"
                                accept="application/pdf"
                              />
                              <label className="custom-file-label"></label>
                            </div>
                          </Form>
                        </Col>
                        <Col className="mt-2 col-lg-1 col-md-1 col-sm-1" xs="1">
                          <div
                            className="mt-4 ml--3 text-muted text-center"
                            style={{ cursor: "pointer", objectFit: "cover" }}
                            onClick={this.handleSub}
                          >
                            <i
                              className="fa fa-times fa-2x"
                              aria-hidden="true"
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <Button
                    id="add_other"
                    color="secondary"
                    size="sm"
                    onClick={this.handleAdd}
                    className="mt-4"
                  >
                    <i className="ni ni-fat-add" />
                    &nbsp;Add
                  </Button>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="col-6 col-md-3 col-sm-6" xs="12">
              <Button
                block
                outline
                color="default"
                className="btn-icon"
                type="button"
                onClick={() => this.handleCancel()}
              >
                <span className="btn-inner--text">Cancel</span>
              </Button>
            </Col>
            {this.state.files.invoice_file && this.state.data.invoice_amount && this.state.data.curr_id && this.state.data.tax_invoice ? (
              <>
                <Col className="col-6 col-md-3 col-sm-6" xs="12">
                  <Button
                    block
                    color="success"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isLoading ? true : false}
                    onClick={() => this.handleSubmitInvoiceStandard()}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Submit
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Submit</span>
                    )}
                  </Button>
                </Col>
              </>
            ) : null}
          </Row>
          <Row className="mt-2 mb-6">
            <Col>
              <h5>&emsp;Note</h5>
              <div className=" font-italic border border-danger">
                <ul>
                  <li>
                    <p className="mt-3">
                      Please fill in the &nbsp;
                      <a href="#inv_am" className="w3-bar-item w3-button">
                        invoice amount
                      </a>
                      &nbsp; column with the DPP invoice value only without
                      additional tax value
                    </p>
                  </li>
                  <li>
                    <p className="mt--3">
                      Please make one PDF file containing invoice file, purchase
                      order file, tax invoice file and if there is a good
                      received file, delivery note file, bast file and upload it
                      in the&nbsp;
                      <a
                        href="#document_invoice"
                        className="w3-bar-item w3-button"
                      >
                        document invoice
                      </a>
                    </p>
                  </li>
                  <li>
                    <p className="mt--3">
                      Column other document and the construction business
                      license forms are optional to be filled in if the
                      documents are outside of the notes above
                    </p>
                  </li>
                  <li>
                    <p className="mt--3">
                      Invoice input hours follow the WIB time zone
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default VendorCreateRegulerInvoicev2;
