import React, { PureComponent } from "react";
import ReactToPrint from "react-to-print";
import {
  Container,
  Row,
  Input,
  InputGroup,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import ListInvoice from "./list/ListInvoiceStandardAp";
var moment = require("moment");
// const nDate = new Date().toLocaleString("id-ID", {
//   timeZone: "Asia/Bangkok",
// });
// const pic_date = nDate % 2 === 0 ? 2 : 3;
class InvoiceStandardAp extends PureComponent {
  state = {
    listInvoiceAP: [],
    listStatus: [],
    files: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    listPeriod: [],
    paramError: {},
    actionFilter: {
      user_id: config.USER_ID,
      invoice: "",
    },
    activity: {
      id: "",
    },
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoiceAP();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListInvoiceAP = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/ap/list_invoice_standard_ap",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceAP: res.data.data,
                countTable1: res.data.data.length,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // getListOnholdInvoiceAP = () => {
  //   if (this.state.isResetLoading === false) {
  //     if (this.state.isFilterLoading === false) {
  //       this.setState({
  //         isFilterLoading: true,
  //       });
  //     }
  //   }
  //   axios
  //     .post(
  //       config.API_URL + "/ap/list_onhold_invoice_standard_ap",
  //       this.state.actionFilter,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               listOnholdInvoiceAP: res.data.data,
  //               countTable2: res.data.data.length
  //             },
  //             () => {
  //               if (this.state.isFilterLoading) {
  //                 this.setState({
  //                   isFilterLoading: false,
  //                   isFilter: true,
  //                 });
  //               }
  //               if (this.state.isResetLoading) {
  //                 this.setState({
  //                   isResetLoading: false,
  //                   isFilter: true,
  //                 });
  //               }
  //             }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  getDateInvoice = async () => {
    const response = await axios.post(
      config.API_URL + "/ap/list_early_date_invoice",
      this.state.actionFilter,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    this.setState({
      min_date: response.data.data[0].min_date,
      max_date: response.data.data[0].max_date,
    });
  };
  // handleChangePeriod = (e) => {
  //   var selectPeriod = e.target.value;
  //   if (selectPeriod === "1") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
  //         to_date: moment().format("YYYY-MM-DD"),
  //       },
  //       select_period: "1",
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "2") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: moment().subtract(2, "years").format("YYYY-MM-DD"),
  //         to_date: moment().format("YYYY-MM-DD"),
  //       },
  //       select_period: "2",
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "3") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: this.state.min_date,
  //         to_date: this.state.max_date,
  //       },
  //       select_period: "3",
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "4") {
  //     this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: "",
  //         to_date: this.state.max_date,
  //       },
  //       select_period: 4,
  //       show_custome: !this.state.show_custome,
  //     });
  //   }
  // };
  handleFilter = () => {
    if (this.state.switchTable === 1) {
      this.getListInvoiceAP();
    } else if (this.state.switchTable === 2) {
      this.getListOnholdInvoiceAP();
    } else {
      this.getListOnholdInvoiceAP();
    }
  };

  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceAP();
            }
          );
        }
      }
    );
  };

  handleDetails = (e) => {
    // let y = 1
    this.props.history.push("/ap/ap_verificationV2/" + e.id);
    // this.props.history.push("/ap/ap_verification/"+ y +"/"+ e.id);
  };
  handleReject = (e) => {
    this.props.history.push("/ap/ap_review_verification/" + e.id);
  };
  handleDetailsTaxReject = (e) => {
    // this.props.history.push("/ap/ap_verification/" + e.id);
  };
  handleConfirmation = (e) => {
    console.log(e);
    // this.props.history.push("/ap/Invoice-routing-slip/" + e.id);
  };

  handleDownload = (e) => {
    var id = e;
    this.setState(
      {
        activity: {
          id: id,
        },
      },
      () => {
        axios
          .post(
            config.API_URL + "/ap/vendor_document",
            this.state.activity,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (response) => {
              if (this.mounted) {
                this.setState(
                  {
                    files: [
                      response.data.data[0].invoice_file,
                      response.data.data[0].siuk_file,
                      response.data.data[0].add_file1,
                      response.data.data[0].add_file2,
                      response.data.data[0].add_file3,
                    ],
                  },
                  () => {
                    var filesAll = this.state.files;
                    const val = filesAll.filter(function (el) {
                      return el != null && el !== "";
                    });
                    const val1 = val.map((data) => config.BUCKET_URL + data);
                    for (var i = 0; i < val1.length; i++) {
                      window.open(val1[i], "_blank");
                    }
                  }
                );
              }
            },
            (err) => {
              console.log("error: ", err);
            }
          );
      }
    );
    // console.log(e)
    // this.props.history.push("/ap/print-routing-slip/" + e.id);
    // this.props.history.push("/ap/ap_verification/" + e.id);
    // console.log(e)
    //  <ReactToPrint
    //             trigger={() => (
    //               <Button
    //                 color="default"
    //                 className="buttons-copy buttons-html5"
    //                 id="print-tooltip"
    //               >
    //                 Print Barcode
    //               </Button>
    //             )}
    //             pageStyle={pageStyle}
    //             content={() => this.componentRef}
    //           />
    // const componentRef = useRef();
    //  ReactToPrint({
    //         content: () => componentRef.current,
    //       });
    // const componentRef = useRef();

    // return (
    //   <div>
    //     <ReactToPrint
    //       trigger={() => <button>Print this out!</button>}
    //       content={() => componentRef.current}
    //     />
    //     <MyDocument ref={componentRef} />
    //   </div>
    // );
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getListInvoiceAP();
      }
    );
  };
  handleChangeStatus = (e) => {
    var selectPeriod = e;
    // console.log(selectPeriod ===1 ? true : false)
    if (selectPeriod === 1) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            table: "1",
          },
        },
        () => this.getListInvoiceAP()
      );
    }
    if (selectPeriod === 2) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            table: "2",
          },
        },
        () => this.getListInvoiceAP()
      );
    }
    if (selectPeriod === 3) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            table: "3",
          },
        },
        () => this.getListInvoiceAP()
      );
    }
  };
  render() {
    // var tableGroup = this.state.listStatus.map((items, index) => {

    //   if (this.state.actionFilter.table===items.value){
    //     return (
    //       <>
    //       <Button
    //         className="mb-0"
    //         style={{backgroundColor: "#5bc0de", color: "white"}}
    //         onClick={()=>this.handleChangeStatus(index+1)}
    //         key={index}>
    //         {items.label}
    //       </Button>
    //           </>
    //     );
    //   } else {
    //     return (
    //       <>
    //       <Button
    //         className="mb-0"
    //         onClick={()=>this.handleChangeStatus(index+1)}
    //         key={index}>
    //         {items.label}
    //       </Button>
    //           </>
    //     );
    //   }
    // })
    return (
      <>
        <StandardHeader
          name="open"
          parentName="Invoice"
          link="reguler-invoice"
        />
        <Container className="mt--6" fluid>
          {/* <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Filter</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="col-lg-3 col-md-6 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Vendor</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="company"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.company}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-6 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Invoice</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="invoice"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.invoice}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-6 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">PO</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="po"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.po}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-6 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Period</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="period"
                      value={this.state.select_period}
                      onChange={this.handleChangePeriod}
                    >
                      {this.state.listPeriod.map((items, index) => {
                        return (
                          <option key={index} value={items.value}>
                            {items.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-end">
                {this.state.show_custome ? (
                  <>
                    <Col className="col-lg-3 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">From date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="from_date"
                          value={this.state.actionFilter.from_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.from_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.from_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">To date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="to_date"
                          value={this.state.actionFilter.to_date}
                          max={this.state.max_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.to_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.to_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row className="justify-content-md-center">
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <div className="text-center">
                    <Button
                      block
                      color="dark"
                      className="btn-icon"
                      type="button"
                      disabled={this.state.isResetLoading ? true : false}
                      onClick={() => this.handleReset()}
                    >
                      {this.state.isResetLoading ? (
                        <>
                          <span className="btn-inner--text">
                            <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                            Reset
                          </span>
                        </>
                      ) : (
                        <span className="btn-inner--text">Reset</span>
                      )}
                    </Button>
                  </div>
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <Button
                    block
                    color="primary"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isFilterLoading ? true : false}
                    onClick={() => this.handleFilter()}
                  >
                    {this.state.isFilterLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Filter
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Filter</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  info={
                    <>
                      <div className="d-flex align-items-center">
                        <div style={{ width: "500px" }}>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              id="search"
                              placeholder="Search"
                              result
                              onKeyPress={this.keyPressed}
                              name="invoice"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                              // style={{ visibility : this.state.isFilterLoading ? 'hidden' : 'visible'}}
                            />
                             <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            // style={{marginTop: "--100px"}}
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", }}
                            // aria-label="Close"
                            onClick={() => this.setState({actionFilter:{...this.state.actionFilter,invoice:""}},()=> this.getListInvoiceAP())}
                          >
                            
                            <i className="fa fa-times-circle text-default" />
                          </button>
                          </InputGroup>
                        </div>
                      </div>
                    </>
                  }
                  list={this.state.listInvoiceAP}
                  details={this.handleDetails}
                  reject={this.handleReject}
                  detailsTaxReject={this.handleDetailsTaxReject}
                  printDoc={this.handleDownload}
                  confirm={this.handleConfirmation}
                />
              </div>
            </div>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default InvoiceStandardAp;
