import React from "react";
import { Link } from "react-router-dom";
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
import config from "services/config";
import { Breadcrumb, BreadcrumbItem, Container, Table, Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Toast,
  ToastBody,ToastHeader,Modal,ModalBody
 } from "reactstrap";
 import axios from "axios";

class Regulation extends React.Component {
  state = {
    data: [],
    alert: null,
    view: false,
    viewData: null
  };
  componentDidMount() {
    axios
    .post(
      config.API_URL + "/public/regulation",
     {data :"cek"},
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: config.TOKEN,
        },
      }
    )
    .then(
      (res) => {
          this.setState(
            {
              data: res.data.data,
            },
            () => {
             console.log(this.state.data ? "true":"false")
            }
          );
      },
      (err) => {
        console.log("error: ", err);
      }
    );
  }
  // componentDidUpdate(e) {
  //   if (e.history.pathname !== e.location.pathname) {
  //     document.documentElement.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //     this.refs.mainContent.scrollTop = 0;
  //   }
  // }

  handlePreview =(data)=>{
    console.log(config.BUCKET_URL+"regulation/"+data)
        this.setState({...this.state, view:true,viewData: "regulation/"+data})
  }
  handleToggleClose =()=>{
    this.setState({...this.state, view: false ,viewData: null})
  }
  render() {
    const tableData = this.state.data.map((data,index) =>{ 
      return(
      <>
        <tr key={index}>
                <th scope="row" style={{ fontSize: "0.90em" }}>
                  {index + 1}
                </th>
                <td style={{ fontSize: "0.90em" }}>
                  <b style={{ objectFit: "cover", cursor: "pointer" }} onClick={()=>this.handlePreview(data.file_path_reg)}>{data.number_reg}</b>
                </td>
                <td style={{ fontSize: "0.90em" }}>
                  {data.description_reg}
                </td>
                <td style={{ fontSize: "0.90em" }}>
                  {data.date}
                </td>
                <td className="text-center">
                  <Button size="sm" color="success" outline onClick={()=>window.open(config.BUCKET_URL+"regulation/"+data.file_path_reg)}>Download</Button>
                </td>
              </tr>
      </>
    ) 
  }
)
    return (
      <>
        {/* <AuthNavbar /> */}
        <Navbar
          className="navbar-horizontal navbar-light bg-white border"
          expand="lg"
          id="navbar-main"
        >
          <Container>
         
            <NavbarBrand to="/" tag={Link}>
              <img
                alt="..."
                src={require("assets/img/brand/ssb_navbar.png")}
              />
            </NavbarBrand>
            {/* <button
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button> */}
            {/* <UncontrolledCollapse
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
            >
              <div className="navbar-collapse-header ">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/admin/dashboard">
                      <img
                        alt="..."
                        src={require("assets/img/brand/ssb_navbar.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-lg-auto" navbar>
              <NavItem className="ml-lg-4">
                  <NavLink href="https://microsite-einvoice.ptssb.co.id/auth/home" target="_blank">
                    <span className="nav-link-inner--text font-weight-bold">
                        Microsite E-Invoice
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse> */}
          </Container>
        </Navbar>
        <Container className="my-3">
          <Breadcrumb listTag="div">
            <BreadcrumbItem
              href="/"
              tag="a"
              style={{ fontSize: "1em" }}
            >
              Home
            </BreadcrumbItem>
            <BreadcrumbItem
              active
              tag="span"
              style={{ fontSize: "1em" }}
            >
              Regulation
            </BreadcrumbItem>
            {/* <BreadcrumbItem
              active
              tag="span"
              style={{ fontSize: "1em" }}
            >
             {config.API_URL}
            </BreadcrumbItem> */}
          </Breadcrumb>
        </Container>
        <Modal isOpen={this.state.view}  size={'lg'} toggle={()=>this.handleToggleClose()}>
        {/* <Modal isOpen={this.state.view} toggle={this.setState({...this.state, view: false ,viewData: null})} > */}
     
        <ModalBody>
        <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + this.state.viewData}
            type="application/pdf"
          >
            {" "}
          </object>
        </ModalBody>
      </Modal>
        <Container>
        <Table size="sm" bordered striped>
            <thead className="table-dark">
              <tr>
                <td style={{ fontSize: "0.90em" }}>
                  No
                </td>
                <td style={{ fontSize: "0.90em" }}>
                  Circular Letter Number
                </td>
                <td style={{ fontSize: "0.90em" }}>
                  Description
                </td>
                <td style={{ fontSize: "0.90em" ,textAlign:"center" }}>
                  Date
                </td>
                <td style={{ fontSize: "0.90em", textAlign: "center" }}>
                File
                </td>
              </tr>
            </thead>
            <tbody>
            
       {tableData ? tableData : "cek"}
            </tbody>
          </Table>
          {/* <Toast className=" bg-success p-3">
    <ToastHeader icon="primary" className="text-white">
      Reactstrap
    </ToastHeader>
    <ToastBody className="mt-2 text-white">
      This is a toast with a primary icon — check it out!
    </ToastBody>
  </Toast> */}
  {this.state.alert}
        </Container>
      </>
    );
  }
}

export default Regulation;
