/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListVendor = (props) => {
  const info = props.info;
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="user_id"
          columns={[
            {
              dataField: "company_id",
              text: "vendor id",
              sort: true,
              headerAlign: "center",
              align: "center"
            },
            {
              dataField: "company_name",
              text: "vendor",
              sort: true,
              formatter: (cell,row)=>{
                return (<>
                 <a
            style={{ cursor: "pointer" }}
            onClick={() => props.details(row)}
          >
          <b>{cell}</b>
          </a>
          </>)}
            },
            {
              dataField: "vendor_user",
              text: "user",
              sort: true,
              headerAlign: "center",
              align: "center"
            },
            {
              dataField: "company_phone",
              text: "contact",
              sort: true,
              headerAlign: "center",
              align: "center"
            },
            {
              dataField: "company_address",
              text: "Address",
              sort: true,
            },
            {
              dataField: "created_at",
              text: "date",
              sort: true,
              headerAlign: "center",
              align: "center"
            }
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                  {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListVendor;
