import React, { PureComponent } from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Modal,
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateCompany, validateUser } from "services/validate";
// import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

//services
import config from "services/config";

//packages
import axios from "axios";

// redux
import { connect } from "react-redux";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// import { getDataCompanies } from "./Redux/companyActions";
import ListUsers from "./ListVendorUser";
import { webdatetime } from "services/sysdate";

class CompanyDetails extends PureComponent {
  state = {
    data: {
      company_name: "",
      company_description: "",
      company_phone: "",
      company_address: "",
    },
    actionListCompanies: {
      page: 1,
      items_per_page: 10,
      order_by_field: "company_id",
      order_by_direction: "DESC",
      company_name: "",
    },
    dataUser: {
      username: "",
      password: "",
      email: "",
      role_id: 6,
      is_active: 1,
    },
    dataUpdateUser: {
      username: "",
      email: "",
      role_id: 6,
      is_active: 0,
      password:"",
      company_id:"",
    },
    showAdd: "",
    showEdit: "",
    passwordShow: false,
    showEditVendor: false,
    updateUser: false,
    listCompanies: [],
    listUsers: [],
    isLoading: false,
    alert: null,
    paramErrorCompany: {},
    paramErrorUser: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/companies/ap_view_company",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        data: {
          company_id: response.data.data[0].company_id,
          company_name: response.data.data[0].company_name,
          company_description: response.data.data[0].company_description,
          company_phone: response.data.data[0].company_phone,
          company_address: response.data.data[0].company_address,
        },
      });
      this.getListUsers();
    } catch (err) {
      console.log(err);
    }
    // this.togglePassword = this.togglePassword.bind(this);
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/users/ap_list_vendor_user",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  // getDataListCompanies = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/companies",
  //       this.state.actionListCompanies,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               listCompanies: res.data.data,
  //             },
  //             () => {
  //               this.props.dispatch(getDataCompanies(this.state.listCompanies));
  //             }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  handleAdd = () => {
    this.setState({
      showAdd: true,
    });
    // axios
    //   .post(
    //     config.API_URL + "/companies/delete_company",
    //     { company_id: id },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: config.TOKEN,
    //       },
    //     }
    //   )
    //   .then(
    //     () => {
    //       this.confirmedAlert();
    //       this.getDataListCompanies();
    //     },
    //     (err) => {
    //       console.log("error: ", err);
    //     }
    //   );
  };


  putDataToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorCompany = validateCompany(this.state.data);
    this.setState({ paramErrorCompany });
    if (Object.keys(paramErrorCompany).length === 0) {
      axios
        .post(config.API_URL + "/companies/ap_update_company", this.state.data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        })
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

  putDataUserToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorUser = validateUser(this.state.dataUpdateUser);
    this.setState({ paramErrorUser });
    if (Object.keys(paramErrorUser).length === 0) {
      axios
        .post(
          config.API_URL + "/users/ap_update_user",
          this.state.dataUpdateUser,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getListUsers();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.dataUpdateUser.username);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

 

  // handleChangeInt = (event) => {
  //   this.setState({
  //     data: {
  //       ...this.state.data,
  //       [event.target.name]: parseInt(event.target.value),
  //     },
  //   });
  // };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
        company_id: this.props.match.params.companyId,
      },
    });
  };
  handleChangeStringUser = (event) => {
    this.setState({
      dataUser: {
        ...this.state.dataUser,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),

      },
    });
  };

  handleChangeStringUpdateUser = (event) => {
    this.setState({
      dataUpdateUser: {
        ...this.state.dataUpdateUser,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
        role_id:6,
      },
    });
  };

  handleSubmit = () => {
    this.putDataToAPI();
  };

  handletoMainPage = () => {
    if (this.props.match.params.opt == 1) {
      this.props.history.push("/ap/vendors")
    }else {
      this.props.history.push("/ap/users")
    } 
      
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlertModal()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={2000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  // remove = (id) => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/delete_company",
  //       { company_id: id },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       () => {
  //         this.confirmedAlert();
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  // confirmAlert = (data) => {
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         warning
  //         style={{ display: "block" }}
  //         title="Are you sure?"
  //         onConfirm={() => this.remove(data.company_id)}
  //         onCancel={() => this.hideAlert()}
  //         showCancel
  //         confirmBtnBsStyle="danger"
  //         confirmBtnText="Yes, delete it!"
  //         cancelBtnBsStyle="secondary"
  //         cancelBtnText="Cancel"
  //         btnSize=""
  //       >
  //         Delete {`${data.company_name}`} vendor and all users, You won't be
  //         able to revert this!
  //       </SweetAlert>
  //     ),
  //   });
  // };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.handletoMainPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Vendor has been deleted.
        </SweetAlert>
      ),
    });
  };



  confirmAlertUser = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeUser(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.username}`} user, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlertUser = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          User has been deleted.
        </SweetAlert>
      ),
    });
  };

  removeUser = (data) => {
    axios
      .post(
        config.API_URL + "/users/ap_delete_user",
        { id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertUser();
          this.getListUsers();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleUpdateUser = (data) => {
    this.setState({
      dataUpdateUser: {
        user_id: data.user_id,
        username: data.username,
        email: data.email,
        company_id: data.company_id,
        role_id: data.role_id,
        is_active: data.is_active,
        is_verified: data.is_verified
      },
      showEdit: true,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  hideAlertModal = () => {
    this.setState({
      showAdd: false,
      showEdit: false,
      showEditVendor: false,
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleSubmitUpdateUser = () => {
    this.putDataUserToAPI();
  };

  handleSubmitUser = () => {
    this.setState(
      {
        isLoading: true,
        dataUser: {
          ...this.state.dataUser,
          company_id: this.props.match.params.companyId,
        },
      },
      () => {
        const paramErrorUser = validateUser(this.state.dataUser);
        this.setState({ paramErrorUser });
        if (Object.keys(paramErrorUser).length === 0) {
          // console.log(this.state.dataUser)
          axios
            .post(config.API_URL + "/users/ap_create_user", this.state.dataUser, {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataUser: {
                      ...this.state.dataUser,
                      username: "",
                      email: "",
                      password: "",
                      // role_id: "",
                    },
                  });
                  this.getListUsers();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataUser.username);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  hideModal = () => {
    this.setState({
      showAdd: false,
      showEdit: false,
      dataUser: {
        username: "",
        password: "",
        email: "",
        role_id: 6,
        is_active: 1,
      },
      dataUpdateUser: {
        username: "",
        email: "",
        role_id: 6,
        is_active: 0,
      },
    });
  };
  togglePassword = () => {
    this.setState({
      ...this.state,
      passwordShow: !this.state.passwordShow,
    });
  };
  render() {
    return (
      <>
        <StandardHeader name="details" parentName="Vendor" link="vendor" />
        <Container className="mt--6" fluid>
          <Modal
            centered
            fullscreen=""
            // size="lg"
            isOpen={this.state.showAdd}
            // toggle={this.handleDetails}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Add user
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.hideModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Username</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="username"
                      placeholder="Username"
                      value={this.state.dataUser.username}
                      required
                      className={
                        this.state.paramErrorUser.username ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="email"
                      placeholder="Email"
                      value={this.state.dataUser.email}
                      required
                      className={
                        this.state.paramErrorUser.email ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.email}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Password</label>
                    <InputGroup>
                      <Input
                        id="password"
                        type={this.state.passwordShow ? "text" : "password"}
                        onKeyPress={this.keyPressed}
                        name="password"
                        placeholder="Password"
                        value={this.state.dataUser.password}
                        required
                        className={
                          this.state.paramErrorUser.password ? "is-invalid" : ""
                        }
                        onChange={this.handleChangeStringUser}
                        disabled={this.state.updateUser ? true : false}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-outline-primary"
                          onClick={this.togglePassword}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {this.state.passwordShow ? (
                            <i className="fa fa-eye" />
                            ) : (
                              <i className="fa fa-eye-slash" />
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.paramErrorUser.password}
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Is Verified</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="is_active"
                      placeholder="Is Active"
                      value={
                        this.state.updateUser
                          ? this.state.dataUpdateUser.is_active
                          : this.state.dataUser.is_active
                      }
                      required
                      onChange={this.handleChangeStringUser}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.is_active}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                color="link"
                data-dismiss="modal"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="btn-icon"
                type="button"
                disabled={this.state.isLoading ? true : false}
                onClick={() => this.handleSubmitUser()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
          <Modal
            centered
            isOpen={this.state.showEdit}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Edit user
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.hideModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Username</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="username"
                      placeholder="Username"
                      value={this.state.dataUpdateUser.username}
                      required
                      className={
                        this.state.paramErrorUser.username ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUpdateUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="email"
                      placeholder="Email"
                      value={this.state.dataUpdateUser.email}
                      required
                      className={
                        this.state.paramErrorUser.email ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUpdateUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.email}
                    </div>
                  </FormGroup>
                </Col>
                {/* <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Password</label>
                    <InputGroup>
                      <Input
                        type={this.state.passwordShow ? "text" : "password"}
                        onKeyPress={this.keyPressed}
                        name="password"
                        placeholder="Password"
                        value={this.state.dataUpdateUser.password}
                        required
                        className={
                          this.state.paramErrorUser.password ? "is-invalid" : ""
                        }
                        onChange={this.handleChangeStringUpdateUser}
                        disabled={this.state.updateUser ? true : false}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-outline-primary"
                          onClick={this.togglePassword}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {this.state.passwordShow ? (
                            <i className="fa fa-eye" />
                            ) : (
                              <i className="fa fa-eye-slash" />
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.paramErrorUser.password}
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col> */}
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Is Verified</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="is_active"
                      placeholder="Is Active"
                      value={this.state.dataUpdateUser.is_verified}
                      required
                      onChange={this.handleChangeStringUpdateUser}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Input>
                    {/* <div className="invalid-feedback">
                      {this.state.paramErrorUser.is_active}
                    </div> */}
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                // className="ml-auto"
                color="link"
                data-dismiss="modal"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="btn-icon"
                type="button"
                disabled={this.state.isLoading ? true : false}
                onClick={() => this.handleSubmitUpdateUser()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
          <Card>
            <CardHeader>
              <Row>
                <Col
                  className="col-lg-11 col-md-11 col-sm-11 text-center"
                  xs="12"
                >
                  <h3 className="mb-0">{this.state.data.company_name}</h3>
                </Col>
                <Col className="col-lg-1 col-md-1 col-sm-1 text-right" xs="12">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => this.handletoMainPage()}
                  >
                    <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                      &times;
                    </span>
                  </button>
                  {/* <a  id="del" style={{cursor: "pointer",objectFit: "cover"}}onClick={() => this.confirmAlert(this.state.data)}><i className="fa fa-times-circle" aria-hidden="true"/></a> */}
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Row>
                  <Col xs={12} sm={12} md={3} lg={3}>
                    <Card className="shadow">
                      <CardHeader>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h3>Detail</h3>
                          </div>
                          <div className="ml-auto text-right">
                            {this.state.showEditVendor ? (
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={() => this.setState({...this.state,showEditVendor: !this.state.showEditVendor})}
                              >
                                <span
                                  aria-hidden="true"
                                  style={{ fontSize: "1 rem" }}
                                >
                                  &times;
                                </span>
                              </button>
                            ) : (
                              <Button
                                size="sm"
                                color="secondary"
                                type="button"
                                className="btn-icon"
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    showEditVendor: !this.state.showEditVendor,
                                  })
                                }
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="ni ni-building text-dark" />
                                </span>{" "}
                                Edit
                              </Button>
                            )}
                          </div>
                        </div>
                      </CardHeader>
                      {!this.state.showEditVendor ? (
                        <CardBody>
                          <FormGroup>
                            <label className="form-control-label">Vendor id</label>
                            <Input
                              style={{ border: "none" }}
                              value={this.state.data.company_id}
                              readonly
                            />
                          </FormGroup>
                            {/* <FormGroup>
                              <label className="form-control-label">Name</label>
                              <Input
                                style={{ border: "none" }}
                                value={this.state.data.company_name}
                                readonly
                              />
                            </FormGroup> */}
                          <FormGroup>
                            <label className="form-control-label">Contact</label>
                            <Input
                              style={{ border: "none" }}
                              value={this.state.data.company_phone}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label className="form-control-label">
                              Address
                            </label>
                            <Input
                            type="textarea"
                              style={{  
                                  border: "none",
                                  width: "100%",
                                  height: "150px"
                            
                            }}
                              value={this.state.data.company_address}
                              
                            />
                          </FormGroup>
                        </CardBody>
                      ) : (
                        <CardBody>
                          <FormGroup>
                            <label className="form-control-label">Name</label>
                            <Input
                              type="text"
                              onKeyPress={this.keyPressed}
                              name="company_name"
                              value={this.state.data.company_name}
                              onChange={this.handleChangeString}
                              required
                              className={
                                this.state.paramErrorCompany.company_name
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.paramErrorCompany.company_name}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <label className="form-control-label">Contact</label>
                            <Input
                              type="text"
                              // min="0"
                              name="company_phone"
                              onKeyPress={this.keyPressed}
                              value={this.state.data.company_phone}
                              onChange={this.handleChangeString}
                              required
                              className={
                                this.state.paramErrorCompany.company_phone
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.paramErrorCompany.company_phone}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <label className="form-control-label">
                              Address
                            </label>
                            <Input
                              type="textarea"
                              style={{  width: "100%",
                              height: "150px"}}
                              onKeyPress={this.keyPressed}
                              name="company_address"
                              value={this.state.data.company_address}
                              onChange={this.handleChangeString}
                              required
                              className={
                                this.state.paramErrorCompany.company_address
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.paramErrorCompany.company_address}
                            </div>
                          </FormGroup>
                          <div className="text-center">
                              <Button
                                color="success"
                                className="btn-icon"
                                type="button"
                                disabled={this.state.isLoading ? true : false}
                                onClick={() => this.handleSubmit()}
                              >
                                {this.state.isLoading ? (
                                  <>
                                    <span className="btn-inner--text">
                                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                      Update
                                    </span>
                                  </>
                                ) : (
                                  <span className="btn-inner--text">Edit</span>
                                  )}
                              </Button>
                                  </div>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                  <Col xs={12} sm={12} md={9} lg={9}>
                    <div className="col">
                      <div className="card-wrapper">
                        <ListUsers
                          list={this.state.listUsers}
                          info={
                            <Col className="text-right">
                              <Button
                                size="sm"
                                color="secondary"
                                type="button"
                                className="btn-icon"
                                onClick={() => this.handleAdd()}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fa fa-user-plus" />
                                </span>{" "}
                                Add
                              </Button>
                            </Col>
                          }
                          details={this.handleUpdateUser}
                          delete={this.confirmAlertUser}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCompanies: state.company.dataCompanies,
  };
};
export default connect(mapStateToProps)(CompanyDetails);
