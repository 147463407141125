import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

// reactstrap components
import {  Card, CardHeader, Alert, Row, Col, Button } from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
      </label>
    </div>
  ),
});

const ListSettingPPN = (props) => {

    const info = props.info;
    function dateFormatter(cell, row){
      if (!cell) {
            return "";
      }
      return moment(cell).format("DD-MM-YYYY");
    };
    const handleAction = (cell, row ) => {
      if (row.is_active ===1){
        return (
            <>
              {/* <a
                style={{ cursor: "pointer" }}
                onClick={() => props.edit(row)}
                className="table-action"
                >
                <i class="fa fa-upload"></i>
              </a>
              &emsp;
              <a
                style={{ cursor: "pointer" }}
                onClick={() => props.edit(row)}
                className="table-action"
                >
                <i class="fa fa-info-circle"></i>
              </a> */}
          <i className="fa fa-check-circle text-success" />
            </>
          )
      }  else {
        return (
          <>
          <i className="fa fa-times-circle" />
  </>

        )
        }
  };

  function getPic (cell,row, formatExtraData){
    // console.log(cell)
    console.log(formatExtraData)

    return (
      formatExtraData
      // <>
      // moment(formatExtraData).format("D-MM-YYYY  H:mm")[cell]
   
      // </>

    );
  }
  
  // const info = props.info;
  // const numtoString = (cell, row) => {
  //    return cell.toString()
  // };
  // const info = props.info;
  // const actionsIsActive = (cell, row) => {
  //   if (cell === 1) return "Yes";
  //   if (cell === 0) return "No";
  // };
  // const actionsAs = (cell, row) => {
  //   if (cell === 1) return "Verification";
  //   if (cell === 0) return "Not Verification";
  // };
  // const actionsFormatter = (cell, row) => {
  //   return (
  //     <>
  //       <a
  //         className="table-action"
  //         id={"tooltip12312"}
  //         style={{ cursor: "pointer" }}
  //         onClick={() => props.details(row)}
  //       >
  //         <i className="fa fa-chevron-right" />
  //       </a>
  //       <UncontrolledTooltip delay={0} target={"tooltip12312"}>
  //         Details
  //       </UncontrolledTooltip>
  //     </>
  //   );
  // };
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="company_id"
          columns={[
            {
              text: "Use",
              sort: true,
              headerAlign: 'center',
              formatter: handleAction,
              align: "center"
            },
            {
              dataField: "ppn",
              text: "tax",
              sort: true,
            },
            {
              dataField: "ppn_rate",
              text: "Rate %",
              sort: true,
            },
            // {
            //   dataField: "total po",
            //   text: "purchase order",
            //   sort: true
            // },
            // {
            //   dataField: "slip",
            //   text: "slip",
            //   sort: true,
            //   formatter: (cell,row ) => {
            //     console.log(row.slip)
            //     if (row.slip === null){
            //       return(
            //         <>
            //         <Button
            //         size="sm"
            //         >
            //          {/* <span className="fa-stack fa-1x">
            //                                     <span style={{color:"green"}}>
            //                                     <i className="fa fa-certificate fa-stack-2x"></i>
            //                                     </span>
            //                                     <i className="fa fa-check fa-stack-1x fa-inverse"></i>
            //                                   </span> */}
            //                                   files
            //         </Button>
            //                                   </>
            //       )
            //     } else {
            //       return
            //     }
            //   }
            // },
            {
              dataField: "created_at",
              text: "Created date",
              sort: true,
              formatter: dateFormatter
            }
          ]}
        >
          {(props) => (
            <div>
             <CardHeader>
                <Row>
                  <Col className="text-left" sm={6}xs={12}>
                    <h3 className="mb-0">List PPN</h3>
                  </Col>
                  {info}
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No Data</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListSettingPPN;
