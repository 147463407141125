import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
import ListInvoice from "./ListInvoiceTax";
// import ListInvoiceAssign from "./ListInvoiceTaxAssign";
var moment = require("moment");
class InvoiceTax extends PureComponent {
  state = {
    listInvoiceTax: [],
    // listInvoiceTaxAssign: [],
    listTable: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    paramError: {},
    actionFilter: {
      user_id: config.USER_ID,
      search: "",
      invoices:""
    },
    actionFilterAssign: {
      user_id: config.USER_ID,
      search: "",
    },
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoiceTax();
    // this.getListInvoiceTaxAssign();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListInvoiceTax = () => {
    // if (this.state.isResetLoading === false) {
    //   if (this.state.isFilterLoading === false) {
    //     this.setState({
    //       isFilterLoading: true,
    //     });
    //   }
    // }
    axios
      .post(
        config.API_URL + "/tax/list_invoice_standard_tax",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceTax: res.data.data,
                actionFilter: {
                  ...this.state.actionFilter,
                  invoices: res.data.invoices,
                  // from_date: res.data.min_data,
                  // to_date: res.data.max_data,
                }
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // getListInvoiceTaxAssign = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/tax/list_invoice_standard_taxAssign",
  //       this.state.actionFilterAssign,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               listInvoiceTaxAssign: res.data.data,
  //               actionFilter: {
  //                 ...this.state.actionFilter,
  //                 invoices: res.data.invoices,
  //                 // from_date: res.data.min_data,
  //                 // to_date: res.data.max_data,
  //               }
  //             },
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  handleFilter = () => {
    this.getListInvoiceTax();
  };

  handleReset = () => {
    this.setState(
      {
        ... this.state,
        show_custome: false,
        actionFilter: {
          ...this.state.actionFilter,
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceTax();
            }
          );
        }
      }
    );
  };

  handleVerification = (e) => {
    let id = config.USER_ID
    if(e.pic_id === ""){
      //free book
      this.props.history.push("/tax/tax_verification/" + e.id+"/"+1);
    } 
    if(e.pic_id === id){
      //you book
      this.props.history.push("/tax/tax_verification/" + e.id+"/"+2);
    }
  };
  // handleDetails = (e) => {
  //     this.props.history.push("/tax/detail-invoice-tax/" + e.id +"/"+1)
  // };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState({
      actionFilter: {
        ...this.state.actionFilter,
        [event.target.name]: event.target.value,
      },
    },()=> this.getListInvoiceTax());
  };
  handleChangeStringAssign = (event) => {
    this.setState({
      actionFilterAssign: {
        ...this.state.actionFilterAssign,
        [event.target.name]: event.target.value,
      },
    },()=> this.getListInvoiceTaxAssign());
  };
 
  handleChangeStatus = (e) => {
    var selectPeriod = e
    if (selectPeriod === 1) {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          status: "1"
        },
      },()=> this.getListInvoiceTax());
    }
    if (selectPeriod === 2) {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          status: "2"
        },
       },()=> this.getListInvoiceTax());
    }
    // if (selectPeriod === 3) {
    //   return this.setState({
    //     actionFilter: {
    //       ...this.state.actionFilter,
    //       status: "3"
    //     },
    //    },()=> this.getListInvoiceTax());
    // }
  };

  handleDetails = (e) => {
    this.props.history.push(
      "/tax/detail-invoice-tax/" + e.id + "/" + 0
    );
  };
  
  handleShow = (data) => {
  var dt = data.invoice_file
   this.setState({
    alert: (
      <SweetAlert
        title=""
        style={{ display: "block", width: "60%" }}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="link"
        confirmBtnText="Close"
        btnSize=""
      >
        <object
          width="100%"
          height="790"
          data={config.BUCKET_URL + dt}
          type="application/pdf"
        >
          {" "}
        </object>
      </SweetAlert>
    ),
  });
  };
  
  render() {
    // var tableGroup = this.state.listTable.map((items, index) => {
    //   if (this.state.actionFilter.status === items.value) {
    //     return (
    //       <>
    //         <Button
    //           className="mb-0"
    //           style={{ backgroundColor: "#5bc0de", color: "white" }}
    //           onClick={() => this.handleChangeStatus(index + 1)}
    //           key={index}
    //         >
    //           {items.label}
    //         </Button>
    //       </>
    //     );
    //   } else {
    //     return (
    //       <>
    //         <Button
    //           className="mb-0"
    //           onClick={() => this.handleChangeStatus(index + 1)}
    //           key={index}
    //         >
    //           {items.label}
    //         </Button>
    //       </>
    //     );
    //   }
    // });
    return (
      <>
        <StandardHeader
          name="Open"
          parentName="Invoice"
          link="active-invoice-tax"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  list={this.state.listInvoiceTax}
                  verification={this.handleVerification}
                  details={this.handleDetails}
                  show={this.handleShow}
                  uid={config.USER_ID}
                  info={
                  <>
                  <Col md={6} lg={4} sm={12} >
                     <div className="text-right">
                          <InputGroup>
                            <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopRightRadius:"0px",borderBottomRightRadius:"0px", }}
                            >
                            {this.state.actionFilter.invoices ? this.state.actionFilter.invoices:0}
                          </button>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              id="search"
                              placeholder="Search"
                              result
                              onKeyPress={this.keyPressed}
                              name="search"
                              value={this.state.actionFilter.search}
                              onChange={this.handleChangeString}
                            />
                             <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", }}
                            onClick={() => this.setState({actionFilter:{...this.state.actionFilter,search:""}},()=> this.getListInvoiceTax())}
                          >
                            <i className="fa fa-times-circle text-default" />
                          </button>
                          </InputGroup>
                      </div>
                  </Col>
                  </>
                  }
                />
              </div>
            </div>
          </Row>
          {this.state.alert}
          
        </Container>
      </>
    );
  }
}

export default InvoiceTax;
