/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import { Card, CardHeader, Alert} from "reactstrap";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
              <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
      </label>
    </div>
  ),
});
const VendorListNotification = (props) => {
  const info = props.info;
  function dateFormatter(cell) {
    if (!cell) {
      return "";
    }
    return (
      moment(cell).format("DD-MM-Y")
      )
  }
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "updated_at",
              text: "Approval date",
              headerAlign:"center",
              align: "center",
              sort: true,
              formatter: dateFormatter,
            },
            {
              dataField: "invoice",
              text: "Invoice",
              sort: true,
              formatter: (cell,row) =>{
                if (row.invoice_info===12){
                  return (
                    <>
                    <div>
                      <a
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                        onClick={() => props.views(row)}
                        >
                        <i className="fa fa-times-circle text-danger" />
                      {" "}{cell}
                      </a>
                        </div>
                    </>
                  );
                } else {
                  return (
                    <>
                    <div>
                      <a
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                        onClick={() => props.views(row)}
                        >
                        <i className="fa fa-check-circle text-success" />
                      {" "}{cell}
                      </a>
                        </div>
                    </>
                  );
                }
              }
            },
            {
              dataField: "tax_invoice",
              text: "Tax invoice",
              sort: true,
            },
            {
              dataField: "purchase_order",
              text: "PO",
              sort: true,
            },
            {
              dataField: "good_receipt",
              text: "GR",
              sort: true,
            },
            {
              dataField: "bast",
              text: "BAST",
              sort: true,
            },
            {
              dataField: "delivery_note",
              text: "Delivery Note",
              sort: true,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data record</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default VendorListNotification;
