import React from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  InputGroup,
  Input,
  Modal,
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateCompany, validateUser } from "services/validate";
import AsyncSelect from "react-select/async";
// import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

import { styleInputSelect } from "services/styles";
//services
import config from "services/config";

//packages
import axios from "axios";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// import { getDataCompanies } from "./Redux/companyActions";
import ListUsers from "./Table/ListVendorUserAdmin";
import { webdatetime } from "services/sysdate";

class VendorDetail extends React.Component {
  state = {
    data: {
      company_id: "",
      company_name: "",
      company_phone: "",
      company_address: "",
    },
    showTransfer: false,
    dataConfirm: {
      transferConfirm: 0,
    },
    dataTransfer: {
      company_id: "",
      company_name: "",
      from_company_id: "",
      user_id: "",
      email: "",
      invoice_id: "",
    },
    cek: false,
    selectedOptionCompanies: [],
    actionListCompanies: {
      page: 1,
      items_per_page: 10,
      order_by_field: "company_id",
      order_by_direction: "DESC",
      company_name: "",
    },
    dataUser: {
      username: "",
      password: "",
      email: "",
      role_id: 6,
      is_active: 1,
    },
    dataUpdateUser: {
      username: "",
      email: "",
      role_id: 6,
      is_active: 0,
      password: "",
      company_id: "",
    },
    showAdd: "",
    showEdit: "",
    passwordShow: false,
    showEditVendor: false,
    updateUser: false,
    listCompanies: [],
    listUsers: [],
    isLoading: false,
    alert: null,
    paramErrorCompany: {},
    paramErrorUser: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/companies/admin_view_company",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        data: {
          company_id: response.data.data[0].company_id,
          company_name: response.data.data[0].company_name,
          company_description: response.data.data[0].company_description,
          company_phone: response.data.data[0].company_phone,
          company_address: response.data.data[0].company_address,
        },
      });
      this.getListUsers();
    } catch (err) {
      console.log(err);
    }
    // this.togglePassword = this.togglePassword.bind(this);
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  fetchDataCompanies = (inputValue) => {
    let searchTerm = inputValue;
    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }
    this.setState({
      actionListCompanies: {
        ...this.state.actionListCompanies,
        company_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/companies/companies_public",
      { id: this.state.actionListCompanies },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        this.setState({
          company_id: response.data.data[0].company_id,
          company_name: response.data.data[0].company_name,
        });
        const compare = response.data.data.filter((i) =>
          i.company_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        if (compare.length == 0) {
          this.setState({ pushAdmin: true, cek: false });
        }
        return compare.map((data) => ({
          label: data.company_name,
          value: data.company_id,
        }));
      });
    }
  };
  onSearchChangeCompanies = (selectedOptionCompanies) => {
    if (selectedOptionCompanies) {
      this.setState({
        selectedOptionCompanies: selectedOptionCompanies,
        dataTransfer: {
          ...this.state.dataTransfer,
          company_id: selectedOptionCompanies.value,
          company_name: selectedOptionCompanies.label,
        },
        cek: true,
      });
    } else {
      this.setState({
        selectedOptionCompanies: null,
        dataTransfer: {
          ...this.state.dataTransfer,
          company_id: "",
          company_name: "",
        },
        cek: false,
      });
    }
  };
  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/users/admin_list_vendor_user",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  // getDataListCompanies = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/companies",
  //       this.state.actionListCompanies,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               listCompanies: res.data.data,
  //             },
  //             () => {
  //               this.props.dispatch(getDataCompanies(this.state.listCompanies));
  //             }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  handleAdd = () => {
    this.setState({
      showAdd: true,
    });
    // axios
    //   .post(
    //     config.API_URL + "/companies/delete_company",
    //     { company_id: id },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: config.TOKEN,
    //       },
    //     }
    //   )
    //   .then(
    //     () => {
    //       this.confirmedAlert();
    //       this.getDataListCompanies();
    //     },
    //     (err) => {
    //       console.log("error: ", err);
    //     }
    //   );
  };
  // remove = (id) => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/delete_company",
  //       { company_id: id },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       () => {
  //         this.confirmedAlert();
  //         // this.getDataListCompanies();
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };

  putDataToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorCompany = validateCompany(this.state.data);
    this.setState({ paramErrorCompany });
    if (Object.keys(paramErrorCompany).length === 0) {
      axios
        .post(
          config.API_URL + "/companies/admin_update_company",
          this.state.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  // user crud
  // create
  handleSubmitUser = () => {
    this.setState(
      {
        isLoading: true,
        dataUser: {
          ...this.state.dataUser,
          company_id: this.props.match.params.companyId,
        },
      },
      () => {
        const paramErrorUser = validateUser(this.state.dataUser);
        this.setState({ paramErrorUser });
        if (Object.keys(paramErrorUser).length === 0) {
          axios
            .post(
              config.API_URL + "/users/admin_create_user",
              this.state.dataUser,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataUser: {
                      ...this.state.dataUser,
                      username: "",
                      email: "",
                      password: "",
                      // role_id: "",
                    },
                  });
                  this.getListUsers();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataUser.username);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  //transfer
  handleSubmitTransferUser = () => {
    this.setState({ isLoading: true });
    if (
      this.state.dataConfirm.transferConfirm === 1 &&
      this.state.dataTransfer.from_company_id !=
        this.state.dataTransfer.company_id
    ) {
      axios
        .post(
          config.API_URL + "/admin/transfer_user_vendor",
          this.state.dataTransfer,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.setState(
                {
                  isLoading: false,
                  showTransfer: false,
                  dataConfirm: {
                    ...this.state.dataConfirm,
                    confirmTransfer: 0,
                  },
                  dataTransfer: {
                    ...this.state.dataTransfer,
                    company_id: "",
                    company_name: "",
                    from_company_id: "",
                    user_id: "",
                    email: "",
                    invoice_id: "",
                  },
                },
                () => this.getListUsers(),
                this.successAlert(res.data.message)
              );
            }
          },
          (err) => {
            if (this.mounted) {
              this.setState({ isLoading: false });
              this.failedAlert(this.state.dataTransfer.email);
            }
          }
        );
    } else {
      this.failedAlert(
        "Validation Errors ,The target company cannot be the same as the current company"
      );
      this.setState({
        isLoading: false,
      });
    }
  };
  handleToggle(e) {
    let isChecked = e.target.checked;
    if (isChecked === true) {
      this.setState({
        dataConfirm: {
          ...this.state.dataConfirm,
          [e.target.name]: 1,
        },
      });
    } else {
      this.setState({
        dataConfirm: {
          ...this.state.dataConfirm,
          [e.target.name]: 0,
        },
      });
    }
  }
  // update
  putDataUserToAPI = () => {
    this.setState({ isLoading: true });
    console.log(this.state.dataUpdateUser);
    const paramErrorUser = validateUser(this.state.dataUpdateUser);
    this.setState({ paramErrorUser });
    if (Object.keys(paramErrorUser).length === 0) {
      axios
        .post(
          config.API_URL + "/users/admin_update_user",
          this.state.dataUpdateUser,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getListUsers();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.dataUpdateUser.username);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  // delete
  removeUser = (data) => {
    axios
      .post(
        config.API_URL + "/users/admin_delete_user",
        { user_id: data.user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertUser();
          this.getListUsers();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
        company_id: this.props.match.params.companyId,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleChangeStringUser = (event) => {
    this.setState({
      dataUser: {
        ...this.state.dataUser,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
      },
    });
  };

  handleChangeStringUpdateUser = (event) => {
    this.setState({
      dataUpdateUser: {
        ...this.state.dataUpdateUser,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
        role_id: 6,
      },
    });
  };

  handleSubmit = () => {
    this.putDataToAPI();
  };

  handletoMainPage = () => {
    this.props.history.push("/admin/vendor");
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlertModal()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={9000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  // confirmAlert = (data) => {
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         warning
  //         style={{ display: "block" }}
  //         title="Are you sure?"
  //         onConfirm={() => this.remove(data.company_id)}
  //         onCancel={() => this.hideAlert()}
  //         showCancel
  //         confirmBtnBsStyle="danger"
  //         confirmBtnText="Yes, delete it!"
  //         cancelBtnBsStyle="secondary"
  //         cancelBtnText="Cancel"
  //         btnSize=""
  //       >
  //         Delete {`${data.company_name}`} vendor and all users, You won't be
  //         able to revert this!
  //       </SweetAlert>
  //     ),
  //   });
  // };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.handletoMainPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Vendor has been deleted.
        </SweetAlert>
      ),
    });
  };

  confirmAlertUser = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeUser(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.username}`} user, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };
  confirmTransfer = (data) => {
    this.setState({
      showTransfer: true,
      dataTransfer: {
        from_company_id: data.company_id,
        user_id: data.user_id,
        email: data.email,
        invoice_id: data.ct_inv,
      },
    });
    //     this.setState({
    //       alert: (
    //         <SweetAlert
    //           warning
    //           style={{ display: "block" }}
    //           title="Are you sure?"
    //           onConfirm={() => this.removeUser(data)}
    //           onCancel={() => this.hideAlert()}
    //           showCancel
    //           confirmBtnBsStyle="warning"
    //           confirmBtnText="Yes, delete it!"
    //           cancelBtnBsStyle="secondary"
    //           cancelBtnText="Cancel"
    //           btnSize=""
    //         >
    //           <Card>
    //             <CardBody className="bg-secondary">
    //             <h3>            This email{" "}will be transfer!
    //             <br/>
    //             <b>{`${data.email}`}</b>
    //             </h3>
    //           <AsyncSelect
    //                     noOptionsMessage={() => "Not register"}
    //                     cacheOptions
    //                     defaultOptions
    //                     isClearable
    //                     styles={styleInputSelect}
    //                     value={this.state.selectedOptionCompanies}
    //                     loadOptions={this.fetchDataCompanies}
    //                     onChange={(e) => {
    //                       this.onSearchChangeCompanies(e);
    //                     }}
    //                   />
    //                   {this.state.cek ? (<>
    //           <div className="text-center">
    //          <table className="text-center border border-primary" style={{tableLayout:"fixed",width:"100%"}}>
    // <tr>
    //   <td className="text-center border border-primary"><h5>From</h5></td>
    //   <td className="text-center border border-primary" rowspan="3"><i className="fa fa-chevron-right fa-lg" aria-hidden="true"/></td>
    //   <td className="text-center border border-primary"><h5>To</h5></td>
    // </tr>
    //           <tr>
    //             <td className="text-center border border-primary"><h5>{`${data.company_name}`}</h5></td>

    //             <td className="text-center border border-primary"><h5>{`${data.company_name}`}</h5></td>
    //           </tr>
    //           <tr>
    //             <td className="text-center border border-primary"><h5>{`${data.company_id}`}</h5></td>

    //             <td className="text-center border border-primary"><h5>{`${data.company_id}`}</h5></td>
    //           </tr>
    //          </table>
    //           </div>
    //                   </>):null
    //                 }

    //             </CardBody>
    //           </Card>
    //         </SweetAlert>
    //       ),
    //     });
  };

  confirmedAlertUser = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={3000}
        >
          User has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleUpdateUser = (data) => {
    this.setState({
      dataUpdateUser: {
        user_id: data.user_id,
        username: data.username,
        email: data.email,
        company_id: data.company_id,
        role_id: data.role_id,
        is_active: data.is_active,
        is_verified: data.is_verified,
      },
      showEdit: true,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  hideAlertModal = () => {
    this.setState({
      showAdd: false,
      showEdit: false,
      showEditVendor: false,
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleSubmitUpdateUser = () => {
    this.putDataUserToAPI();
  };

  hideModal = () => {
    this.setState({
      showAdd: false,
      showEdit: false,
      dataUser: {
        username: "",
        password: "",
        email: "",
        role_id: 6,
        is_active: 1,
      },
      dataUpdateUser: {
        username: "",
        email: "",
        role_id: 6,
        is_active: 0,
      },
    });
  };
  togglePassword = () => {
    this.setState({
      ...this.state,
      passwordShow: !this.state.passwordShow,
    });
  };
  render() {
    return (
      <>
        <StandardHeader name="details" parentName="Vendor" link="vendor" />
        <Container className="mt--6" fluid>
          <Modal centered isOpen={this.state.showTransfer}>
            <Card style={{ width: "fit-content", blockSize: "fit-content" }}>
              <CardHeader>
                <h3 className="mt--2 mb--1">{this.state.dataTransfer.email}</h3>
                <small>Transfer user</small>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    this.setState({
                      showTransfer: false,
                      dataConfirm: {
                        ...this.state.dataConfirm,
                        confirmTransfer: 0,
                      },
                      dataTransfer: {
                        ...this.state.dataTransfer,
                        company_id: "",
                        company_name: "",
                        from_company_id: "",
                        user_id: "",
                        email: "",
                        invoice_id: "",
                      },
                    })
                  }
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody>
                <Row>
                  {this.state.dataTransfer &&
                  this.state.data.company_name &&
                  this.state.data.company_id ? (
                    <>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          {this.state.dataTransfer.invoice_id > 0 ? (
                            <>
                              <h5 className="mt--3 p-2">{`User has added ${this.state.dataTransfer.invoice_id} invoice`}</h5>
                            </>
                          ) : (
                            <>
                              <h5 className="mt--3 mb-3 p-2">
                                User has not yet input invoice
                              </h5>
                            </>
                          )}
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <table
                            className="text-center border border-primary"
                            style={{ tableLayout: "fixed", width: "100%" }}
                          >
                            <tr>
                              <td
                                className="text-center border border-primary"
                                style={{ width: "45%" }}
                              >
                                <h5>From</h5>
                              </td>
                              <td
                                className="text-center border border-primary"
                                style={{ width: "10%" }}
                                rowspan="3"
                              >
                                <i
                                  className="fa fa-chevron-right fa-lg"
                                  aria-hidden="true"
                                />
                              </td>
                              <td
                                className="text-center border border-primary"
                                style={{ width: "45%" }}
                              >
                                <h5>To</h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center border border-primary w-100">
                                <h5>{`${this.state.data.company_name}`}</h5>
                              </td>
                              <td className="text-center border border-primary w-100">
                                {!this.state.dataTransfer
                                  .company_name ? null : (
                                  <h5>{`${this.state.dataTransfer.company_name}`}</h5>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center border border-primary">
                                <h5>{`${this.state.dataTransfer.from_company_id}`}</h5>
                              </td>
                              <td className="text-center border border-primary">
                                {!this.state.dataTransfer.company_id ? null : (
                                  <h5>{`${this.state.dataTransfer.company_id}`}</h5>
                                )}
                              </td>
                            </tr>
                          </table>
                        </FormGroup>
                      </Col>
                    </>
                  ) : null}
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <AsyncSelect
                        noOptionsMessage={() => "Not register"}
                        cacheOptions
                        defaultOptions
                        isClearable
                        styles={styleInputSelect}
                        value={this.state.selectedOptionCompanies}
                        loadOptions={this.fetchDataCompanies}
                        onChange={(e) => {
                          this.onSearchChangeCompanies(e);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <div className="d-flex justify-content-between">
                        <div>
                          <label className="mt-2 form-control-label">
                            Confirm
                          </label>
                        </div>
                        <div>
                          <label className="custom-toggle custom-toggle-primary mt-2">
                            <Input
                              type="checkbox"
                              name="transferConfirm"
                              value={this.state.dataConfirm.transferConfirm}
                              onChange={(e) => this.handleToggle(e)}
                              // className={
                              //   this.state.paramError.transferConfirm ? "is-invalid" : ""
                              // }
                              checked={
                                this.state.dataConfirm.transferConfirm[
                                  this.state.dataConfirm
                                ]
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="NO"
                              data-label-on="YES"
                            />
                          </label>
                        </div>
                      </div>
                      {/* <div className="invalid-feedback">
                          {this.state.paramErrorUser.is_active}
                        </div> */}
                    </FormGroup>
                  </Col>
                </Row>
                {this.state.dataConfirm.transferConfirm == 1 ? (
                  <>
                    {!this.state.dataTransfer.company_id ? null : (
                      <>
                        <Button
                          color="success"
                          block
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmitTransferUser()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Submit
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Submit</span>
                          )}
                        </Button>
                      </>
                    )}
                  </>
                ) : null}
              </CardBody>
            </Card>
          </Modal>
          <Modal
            centered
            fullscreen=""
            // size="lg"
            isOpen={this.state.showAdd}
            // toggle={this.handleDetails}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Add user
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.hideModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Username</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="username"
                      placeholder="Username"
                      value={this.state.dataUser.username}
                      required
                      className={
                        this.state.paramErrorUser.username ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="email"
                      placeholder="Email"
                      value={this.state.dataUser.email}
                      required
                      className={
                        this.state.paramErrorUser.email ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.email}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Password</label>
                    <InputGroup>
                      <Input
                        id="password"
                        type={this.state.passwordShow ? "text" : "password"}
                        onKeyPress={this.keyPressed}
                        name="password"
                        placeholder="Password"
                        value={this.state.dataUser.password}
                        required
                        className={
                          this.state.paramErrorUser.password ? "is-invalid" : ""
                        }
                        onChange={this.handleChangeStringUser}
                        disabled={this.state.updateUser ? true : false}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-outline-primary"
                          onClick={this.togglePassword}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {this.state.passwordShow ? (
                            <i className="fa fa-eye" />
                          ) : (
                            <i className="fa fa-eye-slash" />
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.paramErrorUser.password}
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Is Verified</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="is_active"
                      placeholder="Is Active"
                      value={
                        this.state.updateUser
                          ? this.state.dataUpdateUser.is_active
                          : this.state.dataUser.is_active
                      }
                      required
                      onChange={this.handleChangeStringUser}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.is_active}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                color="link"
                data-dismiss="modal"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="btn-icon"
                type="button"
                disabled={this.state.isLoading ? true : false}
                onClick={() => this.handleSubmitUser()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
          <Modal centered isOpen={this.state.showEdit}>
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Edit user
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.hideModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Vendor id</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="company_id"
                      placeholder="Vendor id"
                      value={this.state.dataUpdateUser.company_id}
                      required
                      // className={
                      //   this.state.paramErrorUser.username ? "is-invalid" : ""
                      // }
                      onChange={this.handleChangeStringUpdateUser}
                    />
                    {/* <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div> */}
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Username</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="username"
                      placeholder="Username"
                      value={this.state.dataUpdateUser.username}
                      required
                      className={
                        this.state.paramErrorUser.username ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUpdateUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="email"
                      placeholder="Email"
                      value={this.state.dataUpdateUser.email}
                      required
                      className={
                        this.state.paramErrorUser.email ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUpdateUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.email}
                    </div>
                  </FormGroup>
                </Col>
                {/* <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Password</label>
                    <InputGroup>
                      <Input
                        type={this.state.passwordShow ? "text" : "password"}
                        onKeyPress={this.keyPressed}
                        name="password"
                        placeholder="Password"
                        value={this.state.dataUpdateUser.password}
                        required
                        className={
                          this.state.paramErrorUser.password ? "is-invalid" : ""
                        }
                        onChange={this.handleChangeStringUpdateUser}
                        disabled={this.state.updateUser ? true : false}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-outline-primary"
                          onClick={this.togglePassword}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {this.state.passwordShow ? (
                            <i className="fa fa-eye" />
                            ) : (
                              <i className="fa fa-eye-slash" />
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.paramErrorUser.password}
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col> */}
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Is Verified</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="is_active"
                      placeholder="Is Active"
                      value={this.state.dataUpdateUser.is_verified}
                      required
                      onChange={this.handleChangeStringUpdateUser}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Input>
                    {/* <div className="invalid-feedback">
                      {this.state.paramErrorUser.is_active}
                    </div> */}
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                // className="ml-auto"
                color="link"
                data-dismiss="modal"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="btn-icon"
                type="button"
                disabled={this.state.isLoading ? true : false}
                onClick={() => this.handleSubmitUpdateUser()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
          <Card>
            <CardHeader>
              <Row>
                <Col
                  className="col-lg-11 col-md-11 col-sm-11 text-center"
                  xs="12"
                >
                  <h3 className="mb-0">{this.state.data.company_name}</h3>
                </Col>
                <Col className="col-lg-1 col-md-1 col-sm-1 text-right" xs="12">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => this.handletoMainPage()}
                  >
                    <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                      &times;
                    </span>
                  </button>
                  {/* <a  id="del" style={{cursor: "pointer",objectFit: "cover"}}onClick={() => this.confirmAlert(this.state.data)}><i className="fa fa-times-circle" aria-hidden="true"/></a> */}
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Row>
                  <Col xs={12} sm={12} md={3} lg={3}>
                    {this.state.showTransfer ? (
                      <>
                        <Card className="shadow">
                          <CardHeader>
                            <div className="d-flex justify-content-between">
                              <div>
                                <h3>Detail</h3>
                              </div>
                              <div className="ml-auto text-right">
                                <button
                                  type="button"
                                  className="close"
                                  aria-label="Close"
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      showEditVendor:
                                        !this.state.showEditVendor,
                                    })
                                  }
                                >
                                  <span
                                    aria-hidden="true"
                                    style={{ fontSize: "1 rem" }}
                                  >
                                    &times;
                                  </span>
                                </button>
                              </div>
                            </div>
                          </CardHeader>
                          {!this.state.showEditVendor ? (
                            <CardBody>
                              <FormGroup>
                                <label className="form-control-label">
                                  Vendor id
                                </label>
                                <Input
                                  style={{ border: "none" }}
                                  value={this.state.data.company_id}
                                  readonly
                                />
                              </FormGroup>
                              {/* <FormGroup>
                              <label className="form-control-label">Name</label>
                              <Input
                                style={{ border: "none" }}
                                value={this.state.data.company_name}
                                readonly
                              />
                            </FormGroup> */}
                              <FormGroup>
                                <label className="form-control-label">
                                  Contact
                                </label>
                                <Input
                                  style={{ border: "none" }}
                                  value={this.state.data.company_phone}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label">
                                  Address
                                </label>
                                <Input
                                  type="textarea"
                                  style={{
                                    border: "none",
                                    width: "100%",
                                    height: "150px",
                                  }}
                                  value={this.state.data.company_address}
                                />
                              </FormGroup>
                            </CardBody>
                          ) : (
                            <CardBody>
                              <FormGroup>
                                <label className="form-control-label">
                                  Name
                                </label>
                                <Input
                                  type="text"
                                  onKeyPress={this.keyPressed}
                                  name="company_name"
                                  value={this.state.data.company_name}
                                  onChange={this.handleChangeString}
                                  required
                                  className={
                                    this.state.paramErrorCompany.company_name
                                      ? "is-invalid"
                                      : ""
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.paramErrorCompany.company_name}
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label">
                                  Contact
                                </label>
                                <Input
                                  type="text"
                                  // min="0"
                                  name="company_phone"
                                  onKeyPress={this.keyPressed}
                                  value={this.state.data.company_phone}
                                  onChange={this.handleChangeString}
                                  required
                                  className={
                                    this.state.paramErrorCompany.company_phone
                                      ? "is-invalid"
                                      : ""
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.paramErrorCompany.company_phone}
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label">
                                  Address
                                </label>
                                <Input
                                  type="textarea"
                                  style={{ width: "100%", height: "150px" }}
                                  onKeyPress={this.keyPressed}
                                  name="company_address"
                                  value={this.state.data.company_address}
                                  onChange={this.handleChangeString}
                                  required
                                  className={
                                    this.state.paramErrorCompany.company_address
                                      ? "is-invalid"
                                      : ""
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.paramErrorCompany.company_address}
                                </div>
                              </FormGroup>
                              <div className="text-center">
                                <Button
                                
                                  color="success"
                                  className="btn-icon"
                                  type="button"
                                  disabled={this.state.isLoading ? true : false}
                                  onClick={() => this.handleSubmit()}
                                >
                                  {this.state.isLoading ? (
                                    <>
                                      <span className="btn-inner--text">
                                        <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                        Update
                                      </span>
                                    </>
                                  ) : (
                                    <span className="btn-inner--text">
                                      Edit
                                    </span>
                                  )}
                                </Button>
                              </div>
                            </CardBody>
                          )}
                        </Card>
                      </>
                    ) : (
                      <>
                        <Card className="shadow">
                          <CardHeader>
                            <div className="d-flex justify-content-between">
                              <div>
                                <h3>Detail</h3>
                              </div>
                              <div className="ml-auto text-right">
                                {this.state.showEditVendor ? (
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() =>
                                      this.setState({
                                        ...this.state,
                                        showEditVendor:
                                          !this.state.showEditVendor,
                                      })
                                    }
                                  >
                                    <span
                                      aria-hidden="true"
                                      style={{ fontSize: "1 rem" }}
                                    >
                                      &times;
                                    </span>
                                  </button>
                                ) : (
                                  <Button
                                    size="sm"
                                    color="secondary"
                                    type="button"
                                    className="btn-icon"
                                    onClick={() =>
                                      this.setState({
                                        ...this.state,
                                        showEditVendor:
                                          !this.state.showEditVendor,
                                      })
                                    }
                                  >
                                    <span className="btn-inner--icon mr-1">
                                      <i className="ni ni-building text-dark" />
                                    </span>{" "}
                                    Edit
                                  </Button>
                                )}
                              </div>
                            </div>
                          </CardHeader>
                          {!this.state.showEditVendor ? (
                            <CardBody>
                              <FormGroup>
                                <label className="form-control-label">
                                  Vendor id
                                </label>
                                <Input
                                  style={{ border: "none" }}
                                  value={this.state.data.company_id}
                                  readonly
                                />
                              </FormGroup>
                              {/* <FormGroup>
                              <label className="form-control-label">Name</label>
                              <Input
                                style={{ border: "none" }}
                                value={this.state.data.company_name}
                                readonly
                              />
                            </FormGroup> */}
                              <FormGroup>
                                <label className="form-control-label">
                                  Contact
                                </label>
                                <Input
                                  style={{ border: "none" }}
                                  value={this.state.data.company_phone}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label">
                                  Address
                                </label>
                                <Input
                                  type="textarea"
                                  style={{
                                    border: "none",
                                    width: "100%",
                                    height: "150px",
                                  }}
                                  value={this.state.data.company_address}
                                />
                              </FormGroup>
                            </CardBody>
                          ) : (
                            <CardBody>
                              <FormGroup>
                                <label className="form-control-label">
                                  Name
                                </label>
                                <Input
                                  type="text"
                                  onKeyPress={this.keyPressed}
                                  name="company_name"
                                  value={this.state.data.company_name}
                                  onChange={this.handleChangeString}
                                  required
                                  className={
                                    this.state.paramErrorCompany.company_name
                                      ? "is-invalid"
                                      : ""
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.paramErrorCompany.company_name}
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label">
                                  Contact
                                </label>
                                <Input
                                  type="text"
                                  // min="0"
                                  name="company_phone"
                                  onKeyPress={this.keyPressed}
                                  value={this.state.data.company_phone}
                                  onChange={this.handleChangeString}
                                  required
                                  className={
                                    this.state.paramErrorCompany.company_phone
                                      ? "is-invalid"
                                      : ""
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.paramErrorCompany.company_phone}
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label">
                                  Address
                                </label>
                                <Input
                                  type="textarea"
                                  style={{ width: "100%", height: "150px" }}
                                  onKeyPress={this.keyPressed}
                                  name="company_address"
                                  value={this.state.data.company_address}
                                  onChange={this.handleChangeString}
                                  required
                                  className={
                                    this.state.paramErrorCompany.company_address
                                      ? "is-invalid"
                                      : ""
                                  }
                                />
                                <div className="invalid-feedback">
                                  {this.state.paramErrorCompany.company_address}
                                </div>
                              </FormGroup>
                              <div className="text-center">
                                <Button
                                block
                                  color="success"
                                  className="btn-icon"
                                  type="button"
                                  disabled={this.state.isLoading ? true : false}
                                  onClick={() => this.handleSubmit()}
                                >
                                  {this.state.isLoading ? (
                                    <>
                                      <span className="btn-inner--text">
                                        <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                        Update
                                      </span>
                                    </>
                                  ) : (
                                    <span className="btn-inner--text">
                                      Edit
                                    </span>
                                  )}
                                </Button>
                              </div>
                            </CardBody>
                          )}
                        </Card>
                      </>
                    )}
                  </Col>
                  <Col xs={12} sm={12} md={9} lg={9}>
                    <div className="col">
                      <div className="card-wrapper">
                        <ListUsers
                          list={this.state.listUsers}
                          info={
                            <Col className="text-right">
                              <Button
                                size="sm"
                                color="secondary"
                                type="button"
                                className="btn-icon"
                                onClick={() => this.handleAdd()}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fa fa-user-plus" />
                                </span>{" "}
                                Add
                              </Button>
                            </Col>
                          }
                          details={this.handleUpdateUser}
                          delete={this.confirmAlertUser}
                          trans={this.confirmTransfer}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default VendorDetail;
