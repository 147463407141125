import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  ListGroupItem,
  ListGroup,
  Media,
  Progress,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import ListInvoice from "../TabInvoiceOutstanding";
import SweetAlert from "react-bootstrap-sweetalert";
import { webdatetime } from "services/sysdate";
var moment = require("moment");
class ListTask extends PureComponent {
  state = {
    listPic: [],
    listStatus: [],
    files: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    listPeriod: [],
    paramError: {},
    mini_1: {
      tax_verified: 0,
      ap_verified: 0,
      verified_fikri: 0,
      verified_farhan: 0,
      verified_sari: 0,
      verified_sellina: 0,
    },
    mini_2: {
  aging_invoice:0,
  aging_ver_tax:0,
  aging_ver_ap:0
    },
    actionFilter: {
      user_id: config.USER_ID,
      table: "",
    },
    id: "",
    preview: "",
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListPic();
    this.getListTaskPic();
    this.getListInvoiceAging();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListPic = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/list_invoice_pic",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
              listPic: res.data.data
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListTaskPic = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/admin/mini_pic_task", this.state.actionFilter, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini_1: {
                  ...this.state.mini_1,
                  tax_verified: res.data.data[0].tax_verified,
                  ap_verified: res.data.data[0].ap_verified,
                  verified_fikri: res.data.data[0].tax_fikri,
                  verified_farhan: res.data.data[0].tax_farhan,
                  task_ap: res.data.data[0].task_ap,
                  verified_sari: res.data.data[0].ap_sari,
                  verified_sellina: res.data.data[0].ap_sellina
                }
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListInvoiceAging = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/admin/mini_invoice_aging", this.state.actionFilter, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini_2: {
                  ...this.state.mini_2,
                  aging_invoice: res.data.data[0].aging_invoice,
                  aging_invoice_ap: res.data.data[0].aging_invoice_match,
                  aging_ver_tax: res.data.data[0].aging_ver_tax,
                  aging_ver_ap: res.data.data[0].aging_ver_tax_to_ap,
                }
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // getListInvoiceAdmin = () => {
  //   if (this.state.isResetLoading === false) {
  //     if (this.state.isFilterLoading === false) {
  //       this.setState({
  //         isFilterLoading: true,
  //       });
  //     }
  //   }
  //   axios
  //     .post(
  //       config.API_URL + "/admin/list_invoice_document_adm",
  //       this.state.actionFilter,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               listInvoiceAdm: res.data.data,
  //             },
  //             () => {
  //               if (this.state.isFilterLoading) {
  //                 this.setState({
  //                   isFilterLoading: false,
  //                   isFilter: true,
  //                 });
  //               }
  //               if (this.state.isResetLoading) {
  //                 this.setState({
  //                   isResetLoading: false,
  //                   isFilter: true,
  //                 });
  //               }
  //             }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceAdmin();
            }
          );
        }
      }
    );
  };

  handleConfirmation = (data) => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/confirmation_document_adm",
        { id: data, user: config.USER_ID, local_datetime: webdatetime() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.getListInvoiceAdmin();
            this.successAlert(res.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  refresh = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.getListInvoiceAdmin();
      }
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => this.refresh()}
          confirmBtnBsStyle="success"
          disabled={true}
          timeout={3000}
        >
          Documents has been confirmed received
        </SweetAlert>
      ),
    });
  };

  handlePreview = (e) => {
    this.onChangePreview(e.invoice_file);
  };
  onChangePreview = (data) => {
    this.setState({
      preview: data,
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  confirmAlertUser = (data) => {
    console.log(data.id);
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Document confirmation"
          onConfirm={() => this.handleConfirmation(data.id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="primary"
          confirmBtnText="Confirm"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {`${data.company_name}`} <br />
          {`${data.invoice}`}
          <br />
          Document has been received?
        </SweetAlert>
      ),
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => this.getListInvoiceAdmin()
    );
  };
  render() {
    // var total_tax = this.state.mini_1.task_tax*100/this.state.mini_1.entries_invoice
                            
    // var to_fikri  = parseFloat(this.state.mini_1.verified_fikri*100/this.state.mini_1.tax_verified).toFixed(2)
    // var to_farhan  = parseFloat(this.state.mini_1.verified_farhan*100/this.state.mini_1.tax_verified).toFixed(2)
    var to_sari  = parseFloat(this.state.mini_1.verified_sari*100/this.state.mini_1.ap_verified).toFixed(2)
    var to_sellina  = parseFloat(this.state.mini_1.verified_sellina*100/this.state.mini_1.ap_verified).toFixed(2)
    var a_0= parseFloat(this.state.mini_2.aging_invoice_ap).toFixed(1)
    // var a_1= parseFloat(this.state.mini_2.aging_invoice).toFixed(1)
    // var a_2 = parseFloat(this.state.mini_2.aging_ver_tax).toFixed(1)
    // var a_3 = parseFloat(this.state.mini_2.aging_ver_ap).toFixed(1)
    var listPic = this.state.listPic.map((pic, index) => {
      if (pic.is_on == 1) {
        if(pic.id ==2){
          return (
            <>
              <ListGroupItem className="px-3" key={index}>
                <Row className="align-items-center">
                  <Col className="col ml--2">
                    <h4 className="mb-0">
                        {pic.username}
                    </h4>
                    <span className="text-success mr-1">●</span>
                    <small>Online</small>
                  </Col>
                  <Col className="col-auto">
                      {pic.description}
                  </Col>
                </Row>
                <Row className="mt-2">
                {/* <Card className=" mx-2">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 "
                            >
                              ap verified
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 ">
                              {this.state.mini_1.ap_verified}
                            </span>
                          </div>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <a
                            className="text-nowrap text-muted font-weight-600"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                          </a>
                        </p>
                      </CardBody>
                    </Card>
                <Card className=" border-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 "
                            >
                             SARI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 ">
                              {this.state.mini_1.verified_sari}
                            </span>
                            <Progress
                              className="progress-xs mt-3 mb-0"
                              max="100"
                              value={to_sari}
                              color="success"
                            />
                          </div>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <a
                            className="text-nowrap text-muted font-weight-600"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            0%
                          </a>
                        </p>
                      </CardBody>
                    </Card> */}
                </Row>
              </ListGroupItem>
            </>
          );
        } else {
          return (
            <>
              <ListGroupItem className="px-3" key={index}>
                <Row className="align-items-center">
                  <Col className="col ml--2">
                    <h4 className="mb-0">
                        {pic.username}
                    </h4>
                    <span className="text-success mr-1">●</span>
                    <small>Online</small>
                  </Col>
                  <Col className="col-auto">
                      {pic.description}
                  </Col>
                </Row>
                {/* <Row className="mt-2">
                <Card className=" border-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 "
                            >
                              ap verified
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 ">
                              {this.state.mini_1.ap_verified}
                            </span>
                          </div>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <a
                            className="text-nowrap text-muted font-weight-600"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            0%
                          </a>
                        </p>
                      </CardBody>
                    </Card>
                <Card className=" border-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 "
                            >
                             SARI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 ">
                              {this.state.mini_1.verified_sari}
                            </span>
                            <Progress
                              className="progress-xs mt-3 mb-0"
                              max="100"
                              value={to_sari}
                              color="success"
                            />
                          </div>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <a
                            className="text-nowrap text-muted font-weight-600"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            {to_sari}%
                          </a>
                        </p>
                      </CardBody>
                    </Card>
                </Row> */}
              </ListGroupItem>
            </>
          );
        }
      } else {
        return (
          <>
            <ListGroupItem className="px-3" key={index}>
              <Row className="align-items-center">
                <div className="col ml--2">
                  <h4 className="mb-0">
                      {pic.username}
                  </h4>
                  <span className="text-danger mr-1">●</span>
                  <small>Offline</small>
                </div>
                <Col className="col-auto">
                 
                    {pic.description}
                </Col>
              </Row>
            </ListGroupItem>
          </>
        );
      }
    });
    return (
      <>
        <StandardHeader
          name="PIC"
          parentName="Invoice"
          link="invoice-pic"
        />
        <Container className="mt--6" fluid>
          {/* <div className="card-deck flex-column flex-xl-row"></div> */}
          <Row>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aging invoice
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                           {a_0+" D"}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            {/* <i className="ni ni-active-40" /> */}
                            <i className="fa fa-hourglass" aria-hidden="true"></i>

                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aging invoice
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                           {a_1+" D"}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                            <i className="fa fa-hourglass" aria-hidden="true"></i>

                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aging Tax 
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                         {a_2+" D"}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-warning  text-white rounded-circle shadow">
                          <i className="fa fa-hourglass-start" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aging AP
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{a_3+" D"}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                          <i className="fa fa-hourglass-end" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
          <Row>
            <Col md="6" xl="6">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Team Members</h5>
                </CardHeader>
                <CardBody>
                  <ListGroup className="list my--3" flush>
                    {listPic}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default ListTask;
