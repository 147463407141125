/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { currencyIDR } from "services/currency";

// reactstrap components
import { Card, CardHeader, Alert} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
      </label>
    </div>
  ),
});

const TabInvoiceVendor = (props) => {
  const info = props.info;
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          striped
          hover
          condensed
          columns={[
            {
              dataField: "company_name",
              text: "Vendor",
              sort: true,
            },
            {
              dataField: "email",
              text: "user name",
              sort: true,
            },
            {
              dataField: "inv",
              text: "count invoice",
              sort: true,
              headerAlign:"center",
              align:"center",
            },
            {
              dataField: "entrie",
              text: "new entries",
              sort: true,
              headerAlign:"center",
              align:"center",
            },
            {
              dataField: "user_id",
              text: "outstanding ap",
              sort: true,
              headerAlign:"center",
              align:"center", 
              formatter: (cell,row) => {
                var oap = row.tax_approve+row.tax_reject
                return(oap)
              },
            },
            {
              text: "Verified reject",
              sort: true,
              headerAlign:"center",
              align:"center", 
              formatter: (cell,row) => {
                return <>{row.reject}</>;
              },
            },
            {
              text: "Verified approve",
              sort: true,
              headerAlign:"center",
              align:"center", 
              formatter: (cell,row) => {
                return <>{row.approve}</>;
              },
            },
            {
              dataField: "sap_number",
              text: "Routing slip",
              sort: true,
              headerAlign:"center",
              align:"center", 
            },
            {
              dataField: "received_doc",
              text: "received document",
              sort: true,
              headerAlign:"center",
              align:"center", 
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                  {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default TabInvoiceVendor;
