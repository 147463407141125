import React, { PureComponent } from "react";
import {
  Container,
  Row,
  InputGroup,
  Col,
  Input,
  ButtonGroup,
  Button,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import ListInvoice from "./ListAllInvoiceTax";
class InvoiceTaxAll extends PureComponent {
  state = {
    listInvoiceTax: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    listTable: [],
    listPeriod: [],
    paramError: {},
    min_date: "",
    max_date: "",
    actionFilter: {
      status: "1",
      user_id: config.USER_ID,
      search: "",
    },
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoiceTax();
    if (this.props.match.params.tb) {
      this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: this.props.match.params.tb,
          },
        },
        () => {
          this.getListInvoiceTax();
        }
      );
    }
    if (this.props.match.params.tb === ":tb") {
      this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "1",
          },
        },
        () => {
          this.getListInvoiceTax();
        }
      );
    }
    this.setState({
      listTable: [
        {
          value: "1",
          label: "All",
        },
        {
          value: "2",
          label: "Approve",
        },
        {
          value: "3",
          label: "Reject",
        },
      ],
      // listPeriod: [
      //   {
      //     value: "1",
      //     label: "Annual",
      //   },
      //   {
      //     value: "2",
      //     label: "Monthly",
      //   },
      //   {
      //     value: "3",
      //     label: "All",
      //   },
      //   {
      //     value: "4",
      //     label: "Set",
      //   },
      // ],
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListInvoiceTax = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/tax/list_all_invoice_standard_tax",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceTax: res.data.data,
                min_date: res.data.min_date,
                max_date: res.data.max_date,
                // }
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // handleChangePeriod = (e) => {
  //   var selectPeriod = e.target.value;
  //   if (selectPeriod === "1") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
  //         to_date: moment().format("YYYY-MM-DD"),
  //       },
  //       select_period: 1,
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "2") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: moment().subtract(1, "month").format("YYYY-MM-DD"),
  //         to_date: moment().format("YYYY-MM-DD"),
  //       },
  //       select_period: 2,
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "3") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: this.state.min_date,
  //         to_date: this.state.max_date,
  //       },
  //       select_period: 3,
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "4") {
  //     this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: this.state.min_date,
  //         to_date: this.state.max_date,
  //       },
  //       select_period: 4,
  //       show_custome: !this.state.show_custome,
  //     });
  //   }
  // };
  // handleFilter = () => {
  //   this.getListInvoiceTax();
  // };

  // handleReset = () => {
  //   this.setState(
  //     {
  //       actionFilter: {
  //         user_id: config.USER_ID,
  //         invoice: "",
  //         po: "",
  //         company: "",
  //         from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
  //         to_date: moment().add(1, "days").format("YYYY-MM-DD"),
  //       },
  //     },
  //     () => {
  //       if (this.state.isResetLoading === false) {
  //         this.setState(
  //           {
  //             isResetLoading: true,
  //           },
  //           () => {
  //             this.getListInvoiceTax();
  //           }
  //         );
  //       }
  //     }
  //   );
  // };

  handleDetails = (e) => {
    this.props.history.push(
      "/tax/detail-invoice-tax/" + e.id + "/" + this.state.actionFilter.status
    );
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangeStatus = (e) => {
    var selectPeriod = e;
    if (selectPeriod === 1) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "1",
          },
        },
        () => this.getListInvoiceTax()
      );
    }
    if (selectPeriod === 2) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "2",
          },
        },
        () => this.getListInvoiceTax()
      );
    }
    if (selectPeriod === 3) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "3",
          },
        },
        () => this.getListInvoiceTax()
      );
    }
  };
  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => this.getListInvoiceTax()
    );
  };

  render() {
    var tableGroup = this.state.listTable.map((items, index) => {
      if (this.state.actionFilter.status === items.value) {
        return (
          <>
            <Button
              className="mb-0"
              style={{ backgroundColor: "#5bc0de", color: "white" }}
              onClick={() => this.handleChangeStatus(index + 1)}
              key={index}
            >
              {items.label}
            </Button>
          </>
        );
      } else {
        return (
          <>
            <Button
              className="mb-0"
              onClick={() => this.handleChangeStatus(index + 1)}
              key={index}
            >
              {items.label}
            </Button>
          </>
        );
      }
    });
    return (
      <>
        <StandardHeader name="Close" parentName="Invoice" link="Invoice-tax" />
        <Container className="mt--6" fluid>
          {/* <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Filter</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Vendor</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="company"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.company}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Invoice</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="invoice"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.invoice}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">PO</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="po"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.po}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Period</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="period"
                      value={this.state.select_period}
                      onChange={this.handleChangePeriod}
                    >
                      {this.state.listPeriod.map((items, index) => {
                        return (
                          <option key={index} value={items.value}>
                            {items.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-end">
                {this.state.show_custome ? (
                  <>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">From date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="from_date"
                          min={this.state.min_date}
                          value={this.state.actionFilter.from_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.from_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.from_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">To date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="to_date"
                          value={this.state.actionFilter.to_date}
                          max={this.state.max_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.to_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.to_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row className="justify-content-md-center">
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <div className="text-center">
                    <Button
                      block
                      color="dark"
                      className="btn-icon"
                      type="button"
                      disabled={this.state.isResetLoading ? true : false}
                      onClick={() => this.handleReset()}
                    >
                      {this.state.isResetLoading ? (
                        <>
                          <span className="btn-inner--text">
                            <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                            Reset
                          </span>
                        </>
                      ) : (
                        <span className="btn-inner--text">Reset</span>
                      )}
                    </Button>
                  </div>
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <Button
                    block
                    color="primary"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isFilterLoading ? true : false}
                    onClick={() => this.handleFilter()}
                  >
                    {this.state.isFilterLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Filter
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Filter</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  list={this.state.listInvoiceTax}
                  details={this.handleDetails}
                  filter={
                    <>
                      <Row>
                        <Col xl={4} md={4} sm={12}>
                          <div className="text-right">
                            <InputGroup>
                              <Input
                                bsSize="sm"
                                type="search"
                                className="search"
                                id="search"
                                placeholder="Search"
                                result
                                onKeyPress={this.keyPressed}
                                name="search"
                                value={this.state.actionFilter.search}
                                onChange={this.handleChangeString}
                              />
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm"
                                style={{
                                  fontSize: "0.9em",
                                  height: "31px",
                                  border: "1px solid #D6D9DE",
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      actionFilter: {
                                        ...this.state.actionFilter,
                                        search: "",
                                      },
                                    },
                                    () => this.getListInvoiceTax()
                                  )
                                }
                              >
                                <i className="fa fa-times-circle text-default" />
                              </button>
                            </InputGroup>
                          </div>
                        </Col>
                        <Col xl={8} md={8} sm={12} className="text-right">
                          <ButtonGroup>{tableGroup}</ButtonGroup>
                        </Col>
                      </Row>
                    </>
                  }
                  pic={config.USER_ID}
                />
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default InvoiceTaxAll;
