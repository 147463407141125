/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListVendorUser = (props) => {
  const info = props.info;
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="user_id"
          columns={[
            {
              dataField: "username",
              text: "username",
              sort: true,
              formatter: (cell, row) => {
                if (row.is_on == 1) {
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.details(row)}
                        >
                          <span
                            className="text-success"
                            style={{ fontSize: "1.50em" }}
                          >
                            ●
                          </span>{" "}
                          <b>{cell}</b>
                        </a>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.details(row)}
                        >
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.50em" }}
                          >
                            ●
                          </span>{" "}
                          <b>{cell}</b>
                        </a>
                      </div>
                    </>
                  );
                }
              },
            },
            {
              dataField: "ct_inv",
              text: "create invoice (count)",
              sort: true,
              headerAlign:"center",
              align:"center",
              formatter: (cell) => {return <b>{cell}</b>}
            },
            {
              dataField: "email",
              text: "email",
              sort: true,
              formatter: (cell, row) => {
                if (row.is_verified == 1 && row.is_active == 1) {
                  if(row.ct_inv > 0 ){
                    return (
                      <>
                        <div className="col ml--2 text-muted">
                            <i className="fa fa-check-circle" /> <b>{cell}</b>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className="col ml--2">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => props.delete(row)}
                          >
                            <i className="fa fa-check-circle" /> <b>{cell}</b>
                          </a>
                        </div>
                      </>
                    );
                  }
                } else if (row.is_verified == 1 && row.is_active == 0) {
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.delete(row)}
                        >
                          <i className="fa fa-minus-circle" /> <b>{cell}</b>
                        </a>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.delete(row)}
                        >
                          <i className="fa fa-times-circle" /> <b>{cell}</b>
                        </a>
                      </div>
                    </>
                  );
                }
              },
            },
            {
              dataField: "is_verified",
              text: "Verified",
              sort: true,
              headerAlign: "center",
              align: "center",
              formatter:(cell,row)=>{
                if (cell =='1'){
                  return 'Yes';
                } else{
                  return 'No';
                }
              }
            },
            {
              dataField: "updated_at",
              text: "last update",
              sort: true,
              headerAlign: "center",
              align: "center",
            },
            {
              dataField: "user_id",
              text: "transfer",
              sort: true,
              headerAlign: "center",
              align: "center",
              formatter:(cell,row)=>{
                return (
                  <>
               <div className="col ml--2">
                        <a
                          style={{ cursor: "pointer",objectFit: "cover" }}
                          onClick={() => props.trans(row)}
                          >
                          <i className="fa fa-share-square" />
                        </a>
                      </div>
               </>
                          )
            }
          }
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col>
                    <h3 className="mb-0">Users</h3>
                  </Col>
                  <Col>{info}</Col>
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListVendorUser;
