import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Label,
  Card,
  CardHeader,
  InputGroup,
  InputGroupText,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  ButtonGroup,
  CardBody,
  FormGroup,
  Input,
  // ButtonGroup,
  Button,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import ListSettingPPN from "./list/ListSettingPPN";
import ListSettingPPH from "./list/ListSettingPPH";
import ListInvoice from "./list/ListSettingTypeInvoice";
import ListReject from "./list/ListReject";
var moment = require("moment");

class SettingTax extends PureComponent {
  state = {
    listPPH: [],
    listPPN: [],
    listSettings: [],
    listInvoiceType: [],
    listInvoiceReject: [],
    listPeriod: [],
    isFilter: false,
    isUpload: true,
    isLoading: false,
    isFilterLoading: false,
    isResetLoading: false,
    alert: null,
    showModal: false,
    showAddPPN:false,
    showAddPPH:false,
    action: {
      select_id: "",
    },
    data: {
      request: config.USER_ID,
      id: "",
      pph: "",
      pph_rate: "",
    },
    actionFilter: {
      table: 1,
      user_id: config.USER_ID,
      settings: "",
      pph_id: "pph_type",
      company: "",
      from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
      to_date: moment().add(1, "days").format("YYYY-MM-DD"),
    },
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getPPH();
    this.getPPN();
    this.getInvoiceType();
    this.getTaxReject();
    this.setState({
      listSettings: [
        {
          value: "1",
          label: "All",
        },
        {
          value: "2",
          label: "Slip tax",
        },
        {
          value: "3",
          label: "Type PPH",
        },
      ],
      listPeriod: [
        {
          value: "1",
          label: "Last Year",
        },
        {
          value: "2",
          label: "2 Years",
        },
        {
          value: "3",
          label: "All",
        },
        {
          value: "4",
          label: "Custome",
        },
      ],
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

 
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

 
  hideModal = () => {
    this.setState({
      showModal: false,
      showAddPPN: false,
      showAddPPH: false,
      data: {
        id: "",
        pph: "",
        pph_rate: "",
        ppn:"",
        ppn_rate:""
      },
    });
  };
  handleEdit = (event) => {
    this.setState(
      {
        ...this.state,
        showModal: true,
        data: {
          id: event.pph_type_id,
          pph: event.pph_type,
          pph_rate: event.pph_rate,
        },
      }
    );
  };
  handleDelete = (event) => {
    let id_pph = event.pph_type_id;
    this.setState({
      ...this.state,
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Are you sure ?"
          onConfirm={() => this.handleActionDelete(id_pph)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes. delete!"
          cancelBtnBsStyle="link"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete <b>{`${event.pph_type}`}</b>, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };
  handleActionDelete = (dt) => {
    axios
      .post(config.API_URL + "/tax/delete_pph", {id:dt,request: config.USER_ID}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.successAlert(res.data.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
    };
 
  successAlert = (data) => {
    // console.log(data)
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.refresh()
          }
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={4000}
        >
          {data+" "}has been successfully deleted
        </SweetAlert>
      ),
    });
  };

  refresh = () => {
    this.setState({
      ...this.state,
      showModal: null,
      alert: null,
    },()=> {this.getPPH()});
  };
  thisAddPPH = () => {
    if (this.state.isLoading === false) {
      this.setState({
        isLoading: true,
        data:{
          ...this.state.data,
          request: config.USER_ID,
        }
      },()=> 
    axios
      .post(config.API_URL + "/tax/add_pph", this.state.data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.successAlertUpdate(res.data);
          }
        },
        (err) => {
          this.failedAlert(err.response.data.message);
          this.setState({ isLoading: false });
        }
      )
    )
    }
  };
  thisUpdatePPH = () => {
    console.log(this.state.data)
    if (this.state.isLoading === false) {
      this.setState({
        isLoading: true,
        data:{
          ...this.state.data,
          request: config.USER_ID,
        }
      },()=> 
    axios
      .post(config.API_URL + "/tax/update_pph", this.state.data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.successAlertUpdate(res.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      )
    )
    }
  };
  successAlertUpdate = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.refresh()
          }
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={2000}
        >
          {data.message}
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  getInvoiceType = () => {
    axios
      .post(config.API_URL + "/tax/invoice_type", {id:1}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceType: res.data.data,
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getTaxReject = () => {
    axios
      .post(config.API_URL + "/tax/list_tax_reject", {id:1}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceReject: res.data.data,
              }
            )
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getPPN = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/tax/type_ppn", this.state.actionFilter, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listPPN: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getPPH = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/tax/type_pph", this.state.actionFilter, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listPPH: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleChangePeriod = (e) => {
    var selectPeriod = e.target.value;
    if (selectPeriod === "1") {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
        },
        select_period: "1",
        show_custome: false,
      });
    }
    if (selectPeriod === "2") {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: moment().subtract(2, "years").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
        },
        select_period: "2",
        show_custome: false,
      });
    }
    if (selectPeriod === "3") {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: this.state.min_date,
          to_date: this.state.max_date,
        },
        select_period: "3",
        show_custome: false,
      });
    }
    if (selectPeriod === "4") {
      this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: "",
          to_date: this.state.max_date,
        },
        select_period: 4,
        show_custome: !this.state.show_custome,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      isUpload: !this.state.isUpload,
    });
  };
  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getPPH();
            }
          );
        }
      }
    );
  };
  handleFilter = () => {
    this.getPPH();
  };
  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          invoice: "",
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getPPH();
            }
          );
        }
      }
    );
  };
  handleDetails = (e) => {
    this.props.history.push("/tax/tax_verification/" + e.id);
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  // handleChangeString = (event) => {
  //   this.setState({
  //     data: {
  //       ...this.state.data,
  //       [event.target.name]: event.target.value,
  //     },
  //   });
  // };

  setRSelected = (s) => {
    console.log(s);
  };
  render() {
    // var listType = this.state.listInvoiceType.map((item,k) => 
    // {
    //   return (
    //     <>
    //     <tr key={k} className="border-left-0">
    //     <td>
    //     {item.invoice_type}
    //     </td>
    //     <td>
    //     {item.ct}
    //     </td>
    //     </tr>
    //     </>
    //     )
    //   }
    // )
    return (
      <>
        <StandardHeader name="Tax" parentName="Settings" link="settings-tax" />
        <Container className="mt--6" fluid>
          <Modal
            centered
            size="sm"
            isOpen={this.state.showModal}
            // toggle={this.handleDetail}
          >
            {this.state.showAddPPH ? (<>
            <ModalHeader toggle={this.hideModal}>Add PPH</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <FormGroup>
                  <label htmlFor="pphRate">PPH</label>
  <InputGroup>
                  <Input
                    id="pphRate"
                    type="text"
                    name="pph_rate"
                    value={this.state.data.pph_rate}
                    onChange={this.handleChangeString}
                    />
                    <InputGroupText className="bg-secondary">%
                   </InputGroupText>
  </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                  <label htmlFor="tax">Description</label>
                  <Input
                    id="tax"
                    type="textarea"
                    name="pph"
                    value={this.state.data.pph}
                    onChange={this.handleChangeString}
                    />
                    </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter >
              <Button color="link" onClick={this.hideModal}>
                Cancel
              </Button>{" "}
              <Button color="success" onClick={this.thisAddPPH}>
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <>
                    <span className="btn-inner--text">Submit</span>
                  </>
                )}
              </Button>
            </ModalFooter>
            </>):(<>
            <ModalHeader toggle={this.hideModal}>Edit PPH</ModalHeader>
            <ModalBody>
            <Row>
                <Col md={12}>
                  <FormGroup>
                  <label htmlFor="pphRate">PPH</label>
  <InputGroup>
                  <Input
                    id="pphRate"
                    type="text"
                    name="pph_rate"
                    value={this.state.data.pph_rate}
                    onChange={this.handleChangeString}
                    />
                    <InputGroupText className="bg-secondary">%
                   </InputGroupText>
  </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                  <label htmlFor="tax">Description</label>
                  <Input
                    id="tax"
                    type="textarea"
                    name="pph"
                    value={this.state.data.pph}
                    onChange={this.handleChangeString}
                    />
                    </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={this.hideModal}>
                Cancel
              </Button>{" "}
              <Button color="success" onClick={this.thisUpdatePPH}>
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <>
                    <span className="btn-inner--text">Submit</span>
                  </>
                )}
              </Button>
            </ModalFooter>
            </>)}
          </Modal>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListSettingPPN
                  info={
                    <Col className="text-right">
                      {/* <Button
                      color="success"
                      type="button"
                      className="btn-icon"
                      size="sm"
                      onClick={() => this.handletoAdd()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      Add
                    </Button> */}
                    </Col>
                  }
                  list={this.state.listPPN}
                  // details={this.handleDetails}
                  edit={this.handleEdit}
                  delete={this.handleDelete}
                />
              </div>
            </div>
            <div className="col">
              <div className="card-wrapper">
                <ListSettingPPH
                  info={
                    <Col className="text-right">
                      <Button
                        color="success"
                        type="button"
                        className="btn-icon"
                        size="sm"
                        onClick={() => this.setState({  showModal: true,showAddPPH: true})}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-fat-add" />
                        </span>
                        Add
                      </Button>
                    </Col>
                  }
                  list={this.state.listPPH}
                  // details={this.handleDetails}
                  edit={this.handleEdit}
                  delete={this.handleDelete}
                />
              </div>
            </div>
            {this.state.alert}
          </Row>
          <Row>
            <Col className="col-xl-6 col-lg-6 col-sm-6 col-12">
            <div className="card-wrapper">
                <ListInvoice
                  list={this.state.listInvoiceType}
                />
              </div>
              
              </Col>
          </Row>
          <Row>
            <Col >
            <div className="card-wrapper">
                <ListReject
                  list={this.state.listInvoiceReject}
                />
              </div>
              
              </Col>

          </Row>
        </Container>
      </>
    );
  }
}

export default SettingTax;
