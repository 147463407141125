import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  ListGroupItem,
  ListGroup,
  Media,
  Progress,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import config from "services/config";
import axios from "axios";
// import ListInvoice from "../TabInvoiceOutstanding";
import SweetAlert from "react-bootstrap-sweetalert";
import { webdatetime } from "services/sysdate";
import { currencyIDR } from "services/currency";
var moment = require("moment");
class DashboardAPAdmin extends PureComponent {
  state = {
    listPic: [],
    listStatus: [],
    files: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    listPeriod: [],
    paramError: {},
    mini_invoice:{
      mi_entries:0,
      mi_verified_tax:0,
      mi_verified_ap:0,
      mi_routing_slip:0,
      mi_received:0,
    },
    mini: {
      open: 0,
      close: 0,
      all: 0,
      task_tax: 0,
      task_ap: 0,
      process: 0,
      approve: 0,
      reject: 0,
      routing_slip: 0,
      received: 0,
    },
    mini_2: {
  aging_invoice:0,
  aging_ver_tax:0,
  aging_ver_ap:0
    },
    mini_amount: {
      am_all: 0,
      am_process: 0,
      am_approve: 0,
      am_reject: 0,
    },
    actionFilter: {
      user_id: config.USER_ID,
      table: "",
      search: "",
      limit:1
    },
    id: "",
    preview: "",
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getMiniInvoiceToday();
    this.getMiniInvoice();
    this.getListInvoiceAging();
    this.getMiniAmount();
    this.getListTop();

  };

  componentWillUnmount() {
    this.mounted = false;
  }
  getMiniInvoiceToday = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/count_invoice_today",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini_invoice: {
                  ...this.state.mini_invoice,
                  mi_entries: res.data.data[0].entries_invoice,
                  mi_verified_tax: res.data.data[0].verified_tax,
                  mi_verified_ap: res.data.data[0].verified_ap,
                  mi_routing_slip: res.data.data[0].routing_slip,
                  mi_received: res.data.data[0].received_document,
                },
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getMiniInvoice = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/mini_invoice_status",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini: {
                  ...this.state.mini,
                  all: res.data.data[0].all_invoice,
                  open: res.data.data[0].invoice_open,
                  close: res.data.data[0].invoice_close,
                  task_tax: res.data.data[0].task_tax,
                  task_ap: res.data.data[0].task_ap,
                  approve: res.data.data[0].approve,
                  reject: res.data.data[0].reject,
                  routing_slip: res.data.data[0].routing_slip,
                  received: res.data.data[0].received_document,
                },
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListTop = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/list_amount_invoice",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
              listPic: res.data.data
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getMiniAmount = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/amount_invoice_gt",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini_amount: {
                  ...this.state.mini_amount,
                  am_all: res.data.data[0].gt_all,
                  am_approve: res.data.data[0].gt_approve,
                  am_process: res.data.data[0].gt_process,
                  am_reject: res.data.data[0].gt_reject,
                },
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListInvoiceAging = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/admin/mini_invoice_aging", this.state.actionFilter, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini_2: {
                  ...this.state.mini_2,
                  aging_invoice: res.data.data[0].aging_invoice,
                  aging_ver_tax: res.data.data[0].aging_ver_tax,
                  aging_ver_ap: res.data.data[0].aging_ver_tax_to_ap,
                }
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // getListInvoiceAdmin = () => {
  //   if (this.state.isResetLoading === false) {
  //     if (this.state.isFilterLoading === false) {
  //       this.setState({
  //         isFilterLoading: true,
  //       });
  //     }
  //   }
  //   axios
  //     .post(
  //       config.API_URL + "/admin/list_invoice_document_adm",
  //       this.state.actionFilter,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               listInvoiceAdm: res.data.data,
  //             },
  //             () => {
  //               if (this.state.isFilterLoading) {
  //                 this.setState({
  //                   isFilterLoading: false,
  //                   isFilter: true,
  //                 });
  //               }
  //               if (this.state.isResetLoading) {
  //                 this.setState({
  //                   isResetLoading: false,
  //                   isFilter: true,
  //                 });
  //               }
  //             }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceAdmin();
            }
          );
        }
      }
    );
  };

  handleConfirmation = (data) => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/confirmation_document_adm",
        { id: data, user: config.USER_ID, local_datetime: webdatetime() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.getListInvoiceAdmin();
            this.successAlert(res.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  refresh = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.getListInvoiceAdmin();
      }
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => this.refresh()}
          confirmBtnBsStyle="success"
          disabled={true}
          timeout={3000}
        >
          Documents has been confirmed received
        </SweetAlert>
      ),
    });
  };

  handlePreview = (e) => {
    this.onChangePreview(e.invoice_file);
  };
  onChangePreview = (data) => {
    this.setState({
      preview: data,
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  confirmAlertUser = (data) => {
    console.log(data.id);
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Document confirmation"
          onConfirm={() => this.handleConfirmation(data.id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="primary"
          confirmBtnText="Confirm"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {`${data.company_name}`} <br />
          {`${data.invoice}`}
          <br />
          Document has been received?
        </SweetAlert>
      ),
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => this.getListInvoiceAdmin()
      );
    };
    render() {
    console.log(this.state.listPic)
    var merge_ap =
    this.state.mini.approve +
    this.state.mini.received +
    this.state.mini.routing_slip;

  var to_open = parseFloat(
    (this.state.mini.open * 100) / this.state.mini.all
  ).toFixed(2);
  var to_tax = parseFloat(
    (this.state.mini.task_tax * 100) / this.state.mini.open
  ).toFixed(2);
  var to_ap = parseFloat(
    (this.state.mini.task_ap * 100) / this.state.mini.open
  ).toFixed(2);
  var to_close = parseFloat(
    (this.state.mini.close * 100) / this.state.mini.all
  ).toFixed(2);
  var to_approve = parseFloat(
    (merge_ap * 100) / this.state.mini.close
  ).toFixed(2);
  var to_reject = parseFloat(
    (this.state.mini.reject * 100) / this.state.mini.close
  ).toFixed(2);
                            

    var a_1= parseFloat(this.state.mini_2.aging_invoice).toFixed(0)
    var a_2 = parseFloat(this.state.mini_2.aging_ver_tax).toFixed(0)
    var a_3 = parseFloat(this.state.mini_2.aging_ver_ap).toFixed(0)
    
    var merge_invoice_today = this.state.mini_invoice.mi_entries+this.state.mini_invoice.mi_verified_tax+this.state.mini_invoice.mi_verified_ap+this.state.mini_invoice.mi_routing_slip+this.state.mini_invoice.mi_received
    var today_tax  = parseFloat(this.state.mini_invoice.mi_verified_tax*100/merge_invoice_today).toFixed(2)
    var today_ap  = parseFloat(this.state.mini_invoice.mi_verified_ap*100/merge_invoice_today).toFixed(2)


    var listPic = this.state.listPic.map((i, index) => {
        return (
          <>
            <ListGroupItem className="px-2 mx-1" key={index}>
              <Row className="align-items-center">
                <div className="col ml--2">
                  <h4 className="mb-0">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {i.company_name}
                    </a>
                  </h4>
                </div>
                <Col className="col-auto">
                {currencyIDR(i.invoice_amount).replace("IDR", "RP")}
                </Col>
              </Row>
            </ListGroupItem>
          </>
        )
    });
    return (
      <>
        <Container className="mt-3" fluid>
          {/* <div className="card-deck flex-column flex-xl-row"></div> */}
          <div className="header bg-primary">
          <Row className="mt-4 mb-1 pl-4 pt-4 pr-4 pb-2">
            <Col md="3" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                    <div className="col text-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                      Today Invoice
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini_invoice.mi_entries}
                      </span>
                    </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        tax
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini_invoice.mi_verified_tax}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {today_tax + "%"}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        AP
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini_invoice.mi_verified_ap}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {today_ap + "%"}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                    <div className="col text-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        today total invoice
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                     {merge_invoice_today} 
                      </span>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4 mb-1 p-4">
            <Col md="5" xl="5">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        tax
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.task_tax}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_tax + "%"}
                      </div>
                    </Col>
                    <div  className="col border-left dashed border-default">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        AP
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.task_ap}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_ap + "%"}
                      </div>
                    </Col>
                    <div className="col border-left dashed border-primary">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        On Process
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.open}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_open + "%"}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="5" xl="5">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Reject
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.reject}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_reject + "%"}
                      </div>
                    </Col>
                    <div  className="col border-left dashed border-default">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Approve
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {merge_ap}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_approve + "%"}
                      </div>
                    </Col>
                    <div  className="col border-left dashed border-success">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Completed
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.close}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_close + "%"}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="2" xl="2">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col text-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Total invoice
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.all}
                      </span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </div>
          <Row>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aging invoice
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                           {a_1+" D"}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                            <i className="fa fa-hourglass" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aging Tax 
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                         {a_2+" D"}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-warning  text-white rounded-circle shadow">
                          <i className="fa fa-hourglass-start" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aging AP
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{a_3+" D"}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                          <i className="fa fa-hourglass-end" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Approve invoice Amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {currencyIDR(
                                           this.state.mini_amount.am_approve
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i class="fa fa-check-circle" aria-hidden="true" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Process Invoice Amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {currencyIDR(
                                             this.state.mini_amount.am_process
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                    
                        <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>

                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Reject Invoice amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">  
                        {currencyIDR(
                                             this.state.mini_amount.am_reject
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        All Invoice Amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                      {currencyIDR(
                                            this.state.mini_amount.am_all
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                      <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6" xl="4">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Top Ten Invoice Amount</h5>
                </CardHeader>
                <CardBody>
                  <ListGroup className="list my--3 " flush>
                    {listPic}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default DashboardAPAdmin;
