/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import styled from "styled-components";
// import { useReactToPrint } from "react-to-print";
// import slipRoute from "./dummy";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const color = styled.div`
  aa {
    color: "#28a745";
  }
  bb {
    color: "#c1c1c1";
  }
  cc {
    color: "#6c757d";
  }
`;

const ListAllInvoiceStandardAp = (props) => {
  var info = props.info;
  const handleDownload = (cell, row) => {
    if (row.invoice_info == 12) {
      return (
        <>
          <span className="fa-stack">
            <i className="fa fa-print" aria-hidden="true"></i>
          </span>

          <span class="fa-stack  ">
            <i class="fa fa-file fa-stack-1x"></i>
            <i
              class="fa fa-ban fa-stack-1x fa-inverse text-light"
              style={{
                marginLeft: "-0.3px",
                marginTop: "1px",
                fontSize: "8px",
              }}
            ></i>
          </span>
        </>
      );
    }
    if (row.invoice_info == 8) {
      return (
        <>
          <Button
            color="primary"
            size="sm"
            type="button"
            style={{
              cursor: "pointer",
              objectFit: "cover",
            }}
            onClick={() => props.printSlip(row.id)}
          >
            <i className="fa fa-print" aria-hidden="true"></i>
          </Button>
          <Button
            color="primary"
            size="sm"
            style={{
              cursor: "pointer",
              padding: "0px",
            }}
            onClick={() => props.confirmFiles(row)}
          >
            <span class="fa-stack ">
              <i class="fa fa-file fa-stack-1x"></i>
              <i
                class="fa fa-chevron-circle-right fa-stack-1x fa-inverse text-primary"
                style={{
                  marginLeft: "-0.3px",
                  marginTop: "1px",
                  fontSize: "8px",
                }}
              ></i>
            </span>
          </Button>
        </>
      );
    }
  
    if (row.invoice_info == 9) {
        if (row.input_at !== "0000-00-00 00:00:00" && row.is_retrieved == 1  ) {
          return (
            <>
              <Button
                color="default"
                size="sm"
                type="button"
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => props.printSlip(row.id)}
              >
                <i className="fa fa-print" aria-hidden="true"></i>
              </Button>
              <Button
                color="default"
                size="sm"
                id="checked"
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                  padding: "0px",
                }}
              >
                <span class="fa-stack ">
                  <i class="fa fa-file fa-stack-1x"></i>
                  <i
                    class="fa fa-check fa-stack-1x fa-inverse text-default"
                    style={{
                      marginLeft: "-0.3px",
                      marginTop: "1px",
                      fontSize: "8px",
                    }}
                  ></i>
                </span>
              </Button>
              <UncontrolledTooltip placement="right" target="checked">
                {row.input_at}
              </UncontrolledTooltip>
            </>
          );
        } else {
          return (
            <>
              <Button
                color="default"
                size="sm"
                type="button"
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => props.printSlip(row.id)}
              >
                <i className="fa fa-print" aria-hidden="true"></i>
              </Button>
              <Button
                color="primary"
                size="sm"
                style={{
                  // pointerEvents: "none",
                  cursor: "pointer",
                  objectFit: "cover",
                  padding: "1px 0px 1px 0px",
                }}
                onClick={() => props.confirmFiles(row)}
              >
                <span class="fa-stack">
                  <i class="fa fa-file fa-stack-1x"></i>
                  <i
                    class="fa fa-chevron-circle-right fa-stack-1x fa-inverse text-primary"
                    style={{
                      marginLeft: "-0.3px",
                      marginTop: "1px",
                      fontSize: "8px",
                    }}
                  ></i>
                </span>
              </Button>
            </>
          );
        }
    }
    if (row.invoice_info == 10) { 
      if (row.sap_number !=="" && row.sap_input_at !== "0000-00-00 00:00:00") {
        return (
          <>
            <Button
              color="default"
              size="sm"
              type="button"
              style={{
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() => props.printSlip(row.id)}
            >
              <i className="fa fa-print" aria-hidden="true"></i>
            </Button>
            <Button
              color="default"
              size="sm"
              id="checked"
              style={{
                cursor: "pointer",
                objectFit: "cover",
                padding: "0px",
              }}
            >
              <span class="fa-stack ">
                <i class="fa fa-file fa-stack-1x"></i>
                <i
                  class="fa fa-check fa-stack-1x fa-inverse text-default"
                  style={{
                    marginLeft: "-0.3px",
                    marginTop: "1px",
                    fontSize: "8px",
                  }}
                ></i>
              </span>
            </Button>
            <UncontrolledTooltip placement="right" target="checked">
              {row.input_at}
            </UncontrolledTooltip>
          </>
        );
      } else {
        return (
          <>
          <Button
            color="primary"
            size="sm"
            type="button"
            style={{
              cursor: "pointer",
              objectFit: "cover",
            }}
            onClick={() => props.printSlip(row.id)}
          >
            <i className="fa fa-print" aria-hidden="true"></i>
          </Button>
          <Button
            color="default"
            size="sm"
            id="checked"
            style={{
              cursor: "pointer",
              padding: "0px",
            }}
          >
            <span class="fa-stack ">
              <i class="fa fa-file fa-stack-1x"></i>
              <i
                class="fa fa-check fa-stack-1x fa-inverse text-default"
                style={{
                  marginLeft: "-0.3px",
                  marginTop: "1px",
                  fontSize: "8px",
                }}
              ></i>
            </span>
          </Button>
          <UncontrolledTooltip placement="right" target="checked">
            {row.input_at}
          </UncontrolledTooltip>
          </>
        );
      }
    }
  };
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "invoice_info",
              sort: true,
              headerAlign: "center",
              align: "center",
              text: "File",
              formatter: handleDownload,
            },
            {
              dataField: "company_name",
              text: "Vendor",
              sort: true,
              formatter: (cell, row) => {
                if (row.invoice_info == 12) {
                  return (
                    <>
                      <a
                        className="table-action text-black"
                        style={{ cursor: "pointer" }}
                        onClick={() => props.details(row)}
                      >
                        <i className="fa fa-times-circle text-danger" />{" "}
                        <strong>{cell}</strong>
                      </a>
                    </>
                  );
                } else {
                  return (
                    <>
                      <a
                        className="table-action text-black"
                        style={{ cursor: "pointer" }}
                        onClick={() => props.details(row)}
                      >
                        <i className="fa fa-check-circle text-success" />{" "}
                        <strong>{cell}</strong>
                      </a>
                    </>
                  );
                }
              },
            },
            {
              dataField: "invoice",
              text: "Invoice",
              headerAlign: "center",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    <Button
                      size="sm"
                      type="button"
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => props.printDoc(row.id)}
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>
                    </Button>
                    <Button
                      id={"pr"}
                      size="sm"
                      type="button"
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => props.viewDoc(row.invoice_file)}
                    >
                      <i className="fa fa-file-pdf-o" /> {cell}
                    </Button>
                  </>
                );
              },
            },
            {
              dataField: "purchase_order",
              text: "Purchase order",
              sort: true,
              headerAlign: "center",
              align: "center",
            },
            {
              dataField: "good_receipt",
              text: "Good receipt",
              sort: true,
            },
            {
              dataField: "sap_number",
              text: "sap number",
              sort: true,
              formatter: (cell, row) => {
                if (cell !== "" || row.sap_input_at !== "0000-00-00 00:00:00") {
                  return <>{cell}</>;
                } else {
                  return "";
                }
              },
            },
            {
              dataField: "created_at",
              text: "created date",
              sort: true,
            },
            {
              dataField: "updated_at",
              text: "approval date",
              sort: true,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>{info}</CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data record</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListAllInvoiceStandardAp;
