import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import ListInvoice from "./TabInvoiceVendor";
import SweetAlert from "react-bootstrap-sweetalert";
import { webdatetime } from "services/sysdate";
var moment = require("moment");
class MetricInvoiceVendor extends PureComponent {
  state = {
    listInvoiceVendor: [],
    listStatus: [],
    files: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    listPeriod: [],
    paramError: {},
    actionFilter: {
      user_id: config.USER_ID,
      search: "",
    },
    mini: {
      open: 0,
      close: 0,
      all: 0,
      task_tax: 0,
      task_ap: 0,
      process: 0,
      approve: 0,
      reject: 0,
      routing_slip: 0,
      received: 0,
    },
    id: "",
    preview: "",
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getMiniInvoice();
    this.getListInvoiceAdmin();
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  getMiniInvoice = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/mini_invoice_status",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini: {
                  ...this.state.mini,
                  all: res.data.data[0].all_invoice,
                  open: res.data.data[0].invoice_open,
                  close: res.data.data[0].invoice_close,
                  task_tax: res.data.data[0].task_tax,
                  task_ap: res.data.data[0].task_ap,
                  approve: res.data.data[0].approve,
                  reject: res.data.data[0].reject,
                  routing_slip: res.data.data[0].routing_slip,
                  received: res.data.data[0].received_document,
                },
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListInvoiceAdmin = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/list_invoice_vendor_tracking",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceVendor: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceAdmin();
            }
          );
        }
      }
    );
  };

  handleConfirmation = (data) => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/confirmation_document_adm",
        { id: data, user: config.USER_ID, local_datetime: webdatetime() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.getListInvoiceAdmin();
            this.successAlert(res.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  refresh = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.getListInvoiceAdmin();
      }
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => this.refresh()}
          confirmBtnBsStyle="success"
          disabled={true}
          timeout={3000}
        >
          Documents has been confirmed received
        </SweetAlert>
      ),
    });
  };

  handlePreview = (e) => {
    this.onChangePreview(e.invoice_file);
  };
  onChangePreview = (data) => {
    this.setState({
      preview: data,
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  confirmAlertUser = (data) => {
    console.log(data.id);
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Document confirmation"
          onConfirm={() => this.handleConfirmation(data.id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="primary"
          confirmBtnText="Confirm"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {`${data.company_name}`} <br />
          {`${data.invoice}`}
          <br />
          Document has been received?
        </SweetAlert>
      ),
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => this.getListInvoiceAdmin()
    );
  };
  render() {
    var merge_ap =
      this.state.mini.approve +
      this.state.mini.received +
      this.state.mini.routing_slip;

    var to_open = parseFloat(
      (this.state.mini.open * 100) / this.state.mini.all
    ).toFixed(2);
    var to_tax = parseFloat(
      (this.state.mini.task_tax * 100) / this.state.mini.open
    ).toFixed(2);
    var to_ap = parseFloat(
      (this.state.mini.task_ap * 100) / this.state.mini.open
    ).toFixed(2);
    var to_close = parseFloat(
      (this.state.mini.close * 100) / this.state.mini.all
    ).toFixed(2);
    var to_approve = parseFloat(
      (merge_ap * 100) / this.state.mini.close
    ).toFixed(2);
    var to_reject = parseFloat(
      (this.state.mini.reject * 100) / this.state.mini.close
    ).toFixed(2);
    return (
      <>
        <StandardHeader
          name="Invoice Amount"
          parentName="Invoice Amount"
          link="invoice-amount"
        />
        <Container className="mt--6" fluid>
          <Row className="my-2">
            <Col md="5" xl="5">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        tax
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.task_tax}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_tax + "%"}
                      </div>
                    </Col>
                    <div  className="col border-left dashed border-default">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        AP
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.task_ap}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_ap + "%"}
                      </div>
                    </Col>
                    <div className="col border-left dashed border-primary">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        On Process
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.open}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_open + "%"}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="5" xl="5">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Reject
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.reject}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_reject + "%"}
                      </div>
                    </Col>
                    <div  className="col border-left dashed border-default">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Approve
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {merge_ap}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_approve + "%"}
                      </div>
                    </Col>
                    <div  className="col border-left dashed border-success">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Completed
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.close}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow">
                        {to_close + "%"}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="2" xl="2">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        All
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {this.state.mini.all}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle shadow"></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="card-wrapper">
                <ListInvoice
                  info={
                    <>
                      <Row>
                        <Col xl={5} md={5} sm={12}>
                          <div>
                            <InputGroup>
                              <Input
                                bsSize="sm"
                                type="search"
                                className="search"
                                id="search"
                                placeholder="Search"
                                result
                                onKeyPress={this.keyPressed}
                                name="search"
                                value={this.state.actionFilter.search}
                                onChange={this.handleChangeString}
                                // style={{ visibility : this.state.isFilterLoading ? 'hidden' : 'visible'}}
                              />
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm"
                                // style={{marginTop: "--100px"}}
                                style={{
                                  fontSize: "0.9em",
                                  height: "31px",
                                  border: "1px solid #D6D9DE",
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                  boxShadow: "none",
                                }}
                                // aria-label="Close"
                                onClick={() =>
                                  this.setState(
                                    {
                                      actionFilter: {
                                        ...this.state.actionFilter,
                                        search: "",
                                      },
                                    },
                                    () => this.getListInvoiceAdmin()
                                  )
                                }
                              >
                                <i className="fa fa-times-circle text-default" />
                              </button>
                            </InputGroup>
                          </div>
                        </Col>
                      </Row>
                    </>
                  }
                  list={this.state.listInvoiceVendor}
                  printDoc={this.handlePreview}
                  confirm={this.confirmAlertUser}
                />
              </div>
            </Col>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default MetricInvoiceVendor;
