/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import config from "services/config";
import axios from "axios";
import AuthHeader from "components/Headers/AuthHeader";
import SweetAlert from "react-bootstrap-sweetalert";
import { styleInputSelect } from "services/styles";
import AsyncSelect from "react-select/async";
import { validateCreateNewAccount } from "services/validate";
import { webdatetime } from "services/sysdate";

class Registration extends PureComponent {
  state = {
    cek: false,
    pushAdmin: false,
    data: {
      company_id: "",
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      datetime: webdatetime()
    },
    actionListCompanies: {
      company_name: "",
    },
    
    selectedOptionCompanies: [],
    authError: false,
    isLoading: false,
    paramError: {},
    token: null,
    alert: null,
  };
  fetchDataCompanies = (inputValue) => {
    let searchTerm = inputValue;
    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }
    this.setState({
      actionListCompanies: {
        ...this.state.actionListCompanies,
        company_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/companies/companies_public",
      { id: this.state.actionListCompanies },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        this.setState({
          company_id: response.data.data[0].company_id,
          company_name: response.data.data[0].company_name,
        });
        const compare = response.data.data.filter((i) =>
          i.company_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        if (compare.length == 0) {
          this.setState({ pushAdmin: true, cek: false });
        }
        return compare.map((data) => ({
          label: data.company_name,
          value: data.company_id,
        }));
      });
    }
  };
  onSearchChangeCompanies = (selectedOptionCompanies) => {
    if (selectedOptionCompanies){
      this.setState({
        selectedOptionCompanies: selectedOptionCompanies,
        data: {
          ...this.state.data,
          company_id: selectedOptionCompanies.value,
        },
        cek: true
      });
    }else{
      this.setState({
        selectedOptionCompanies: null,
        data: {
          ...this.state.data,
          company_id: "",
        },
        cek: false
      });
    }
  };

  componentDidMount = async () => {
    console.log(webdatetime())
    //  this.fetchData()
  };
  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    const paramError = validateCreateNewAccount(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(
          config.API_URL + "/users/create_user_public",
          this.state.data,
          headers
        )
        .then(
          (res) => {
            this.successAlert(res.data.message);
            this.setState({
              isLoading: false,
              cek: false,
              data: {
                company_id: "",
                username: "",
                email: "",
                password: "",
                confirm_password: "",
              },
            });
          },
          (err) => {
            this.setState({ authError: true, isLoading: false });
            this.failedAlert("Invalid Credentials");
          }
        );
    } else {
      this.setState({ isLoading: false });
    }
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={this.props.history.push("/aunt/login")}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Login"
          btnSize=""
          // timeout={7000}
        >
          Your account has been created please check email to verification
          account
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyUp = (event) => {
    event.preventDefault();
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      this.handleSubmit();
    }
  };
  handleToLogin = () => {
    this.props.history.push("/auth/login");
  };
  render() {
    const paramError = this.state.paramError;
    if (this.state.data.company_id !== "") {
      this.setState({ pushAdmin: false });
    }
    return (
      <div style={{ height: `1000px` }}>
        <AuthHeader title="Welcome" lead="Microsite E-Invoice" />
        <Container className="my-auto">
          <Row className="justify-content-center">
            <Col lg="4" md="7">
              <Form role="form">
                <FormGroup>
                  <label className="form-control-label text-white">
                    Company
                  </label>
                  <a
                    style={{
                      float: "right",
                      objectFit: "cover",
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() =>
                      this.setState({
                        cek: false,
                        pushAdmin: false,
                        selectedOptionCompanies: [],
                        data: {
                          company_id: "",
                          username: "",
                          email: "",
                          password: "",
                          confirm_password: "",
                        },
                      })
                    }
                  >
                    <i className="fa fa-undo" aria-hidden="true"/>

                    {" "}
                    reset
                  </a>
                  <AsyncSelect
                    noOptionsMessage={() => "Not register"}
                    cacheOptions
                    defaultOptions
                    isClearable
                    styles={styleInputSelect}
                    value={this.state.selectedOptionCompanies}
                    loadOptions={this.fetchDataCompanies}
                    onChange={(e) => {
                      this.onSearchChangeCompanies(e);
                    }}
                  />
                </FormGroup>
                {this.state.cek === true ? (
                  <>
                    <FormGroup>
                      <label className="form-control-label text-white">
                        Username
                      </label>
                      <Input
                        type="text"
                        onKeyDown={this.handleEnter}
                        name="username"
                        placeholder="Username"
                        value={this.state.data.username}
                        onChange={this.handleChange}
                        required
                        className={
                          this.state.paramError.username ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.username}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label text-white">
                        Email
                      </label>
                      <Input
                        type="email"
                        onKeyDown={this.handleEnter}
                        name="email"
                        placeholder="Email"
                        value={this.state.data.email}
                        onChange={this.handleChange}
                        required
                        className={
                          this.state.paramError.email ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.email}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label text-white">
                        Password
                      </label>
                      <Input
                        type="password"
                        onKeyDown={this.handleEnter}
                        name="password"
                        placeholder="Password"
                        value={this.state.data.password}
                        onChange={this.handleChange}
                        required
                        className={
                          this.state.paramError.password ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.password}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label text-white">
                        Confirm Password
                      </label>
                      <Input
                        type="password"
                        onKeyDown={this.handleEnter}
                        name="confirm_password"
                        placeholder="Password"
                        value={this.state.data.confirm_password}
                        onChange={this.handleChange}
                        required
                        className={
                          this.state.paramError.confirm_password
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.confirm_password}
                      </div>
                    </FormGroup>
                    <div style={{ marginTop: 40 }} className="text-center">
                      <Button
                        block
                        className="text-uppercase my-4"
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={this.state.isLoading ? true : false}
                      >
                        {this.state.isLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Create user
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Create</span>
                        )}
                      </Button>
                    </div>
                    <Row>
                      <Col>
                        <h5 className="text-white" style={{
                           animation: 'breathing 1s ease-out infinite normal',
                          //  fontSize: '24px',
                          //  background: '#5885cb',
                          //  color: '#E23558',
                           webkitFontSmoothing: 'antialiased'
                        }}>
                            <span className="text-danger">
                              *{" "}
                              </span>
                              <b>Make sure you have chosen your company correctly</b> <br />
                              <span className="text-danger">
                              *{" "}
                              </span>
                              <b>Please contact our team to register if your company is not registered</b>

                        </h5>
                          
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs="6">
                        <a
                          className="text-white"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleToLogin()}
                        >
                          <small>
                            <u>Back to login</u>
                          </small>
                        </a>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {this.state.pushAdmin ? (
                  <>
                    <FormGroup className="mt-6">
                      <Alert color="primary">
                      <b>
                        Please contact our team, if your company is not registered
                        </b>
                      </Alert>
                    </FormGroup>
                  </>
                ) :null}
              </Form>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </div>
    );
  }
}

export default Registration;
