import { 
    GET_DATA_USER
 } from "./userActions";

 const initialState = {
    dataUser: [],
  };

  export default function userReducer(state = initialState, action){
      switch(action.type){
          case GET_DATA_USER:
              return {
                  ...state,
                  dataUser: action.payload.user
              };
        
        default:
            return state;
      }
  }