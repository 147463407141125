import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import config from "services/config";
import ListInvoiceApprove from "./VendorListNotification";
import ListInvoiceReject from "./VendorListNotificationReject";
import TaxSlipTable from "../Slip/TaxSlipVendor";
import StandardHeader from "components/Headers/HeaderVendor";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
// var moment = require("moment");

class VendorNotification extends PureComponent {
  state = {
    listInvoiceApprove: [],
    listInvoiceReject: [],
    listTaxSlip: [],
    listInvoiceStatusActivity: [],
    a1: false,
    a2: false,
    a3: false,
    t1: "",
    t2: "",
    t3: "",
    activity: {
      company_id: config.COMPANY_ID,
      id: "",
    },
    data: {
      company_id: config.COMPANY_ID,
      id: "",
      ids: "",
    },
    status: "",
    thisShow: "",
    alert: null,
    showActivity: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.onChangePreview = this.onChangePreview.bind(this);
    this.getListInvoiceApprove();
    this.getListInvoiceReject();
    this.getTaxSlip();
    // if (this.props.match.params.tb) {
    //   this.setState({
    //     actionFilter: {
    //       ...this.state.actionFilter,
    //       status: this.props.match.params.tb,
    //     },
    //   });
    // }
    // if (this.props.match.params.tb === ":tb") {
    //   this.setState({
    //     actionFilter: {
    //       ...this.state.actionFilter,
    //       status: "1",
    //     },
    //   });
    // }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleRead = (e) => {
    var id = parseInt(e)
    this.setState(
      {
        data: {
          ...this.state.data,
          id: id,
          ids: 'ALL',
        },
      },
      this.getRead()
    );
  };

  getRead = () => {
    this.setState(
      {
        data: {
          ...this.state.data,
        },
      },
      () => {
        axios
          .post(config.API_URL + "/vendor/notification_read", this.state.data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.setState({
                  data: {
                    company_id: config.COMPANY_ID,
                    id: "",
                    ids: "",
                  },
                },
                this.getListInvoiceApprove(),
                this.getListInvoiceReject(),
                this.getTaxSlip()
                );
              }
            },
            (err) => {
              console.log("error: ", err);
            }
          );
      }
    );
  };
  getTaxSlip = () => {
    axios
      .post(
        config.API_URL + "/vendor/notification_vendor_slip_list",
        { company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listTaxSlip: res.data.data,
              t3: res.data.data.length,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListInvoiceApprove = () => {
    axios
      .post(
        config.API_URL + "/vendor/notification_vendor_invoice_list",
        { company_id: config.COMPANY_ID, status: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listInvoiceApprove: res.data.data,
              t1: res.data.data.length,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListInvoiceReject = () => {
    axios
      .post(
        config.API_URL + "/vendor/notification_vendor_invoice_list",
        { company_id: config.COMPANY_ID, status: 2 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listInvoiceReject: res.data.data,
              t2: res.data.data.length,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // handleDetails = (e) => {
  //   var  id = e
  //   // console.log(id )
  //   this.setState({
  //     ...this.state,
  //     activity: {
  //       ...this.state.activity,
  //       id: id
  //     }
  //   }, () => {
  //     this.getListActivity()
  //   }
  //   )
  // };
  handleViews = (e) => {
    // console.log(e)
    var id = e.id
    var cat = e.invoice_info
    var apr
    if (cat ==12) { apr = 2 } else { apr = 1}
    this.setState(
      {
        data: {
          ...this.state.data,
          id: apr,
          ids: id,
        },
      },
      this.getRead(),
      );
      // this.props.history.push("/vendor/review-reguler-invoice/" + 2 + "/" + id)
      // this.onChangePreview(file)
  };
  hideModal = () => {
    this.setState({
      showActivity: false,
      activity: {
        select_id: "",
      },
      data: {
        company_id: config.COMPANY_ID,
        id: "",
        ids: "",
      },
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ thisShow: fileUploaded });
  };
  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ width: "900px" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="700"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  handlePreview = (e) => {
    var file = e.files;
    var id = e.id;
    // var cek = e.notif;
    // if (cek==0){

    // } else {

    // }
    this.setState(
      {
        data: {
          ...this.state.data,
          id: 3,
          ids: id,
        },
      },
      this.getRead(),
      this.onChangePreview(file)
    );
  };
  handleDownload = (e) => {
    var file = e.files;
    window.open(config.BUCKET_URL + file, "_blank");
  };
  hideAlert = () => {
    this.setState({
      ...this.state,
      alert: null,
      thisShow: "",
    });
  };

  handleBack = () => {
    this.props.history.push("/vendor/home")
  }

  render() {
    var ct = this.state.t1+this.state.t2+this.state.t3
    // console.log(ct)
    var back
    if (ct ===0 ) {
      //   back =  <button
      //   type="button"
      //   className="close my-auto"
      //   aria-label="Close"
      //   onClick={() => this.handleBack()}
      // >
      //   <span
      //     aria-hidden="true"
      //     style={{ fontSize: "1 rem" }}
      //   >
      //     &times;
      //   </span>
      // </button>
      this.handleBack()
    } else {
    
    }

    // if (this.state.t1)
    return (
      <>
        <StandardHeader
          name="Notification"
          parentName="Dasboard"
          link="new-notification-vendor"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            {this.state.t1 ==0 ? null:
              (
                <>
              <div className="card-wrapper">
                <ListInvoiceApprove
                  info={
                    <>
                      <Row>
                        <Col className="text-left">
                          <h3>Approve</h3>
                        </Col>
                        <Col className="text-right">
                        <button
      type="button"
      className="close my-auto"
      aria-label="Close"
      onClick={() => this.handleRead(1)}
    >
      <span
        aria-hidden="true"
        style={{ fontSize: "1 rem" }}
      >
        &times;
      </span>
      </button>
                        </Col>
                      </Row>
                    </>
                  }
                  list={this.state.listInvoiceApprove}
                  views={this.handleViews}
                  // printDoc={this.handleDownload}
                  // details={this.handleDetails}
                />
              </div>
              </>)}
              {this.state.t2 ==0 ? null:
              (
                <>
              <div className="card-wrapper">
                <ListInvoiceReject
                  info={
                    <>
                      <Row>
                        <Col className="text-left">
                          <h3>Reject</h3>
                        </Col>
                        <Col className="text-right">
                          <button
                            type="button"
                            className="close my-auto"
                            aria-label="Close"
                            onClick={() => this.handleRead(2)}
                          >
                            <span
                              aria-hidden="true"
                              style={{ fontSize: "1 rem" }}
                            >
                              &times;
                            </span>
                          </button>
                        </Col>
                      </Row>
                    </>
                  }
                  list={this.state.listInvoiceReject}
                  views={this.handleViews}
                  // printDoc={this.handleDownload}
                  // details={this.handleDetails}
                />
              </div>
              </>)}
              {this.state.t3 ==0 ? null:
              (
                <>
              <div className="card-wrapper w-50%">
                <TaxSlipTable
                  list={this.state.listTaxSlip}
                  info={
                    <>
                      <Row>
                        <Col className="text-left">
                          <h3 className="mb-0">Slip</h3>
                        </Col>
                        <Col className="text-right">
                          <button
                            type="button"
                            className="close my-auto"
                            aria-label="Close"
                            onClick={() => this.handleRead(3)}
                          >
                            <span
                              aria-hidden="true"
                              style={{ fontSize: "1 rem" }}
                            >
                              &times;
                            </span>
                          </button>
                        </Col>
                      </Row>
                    </>
                  }
                  view={this.handlePreview}
                  download={this.handleDownload}
                  className="w-50%"
                />
              </div>
              </>
              )
              }
            </div>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}
export default VendorNotification;
