/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  Button,
} from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListInvoiceStandardAp = (props) => {
  {
    /* <i
                          className="fa fa-times-circle"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>{" "} */
  }
  {
    /* <i
                        className="fa fa-times-circle"
                        style={{ color: "#8965e0" }}
                        aria-hidden="true"
                      ></i>{" "} */
  }
  {
    /* <i
                        className="fa fa-times-circle"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>{" "} */
  }
  {
    /* <i
                       className="fa fa-circle"
                       style={{ color: "#8965e0" }}
                       aria-hidden="true"
                     ></i>{" "} */
  }
  {
    /* <i
                       className="fa fa-circle"
                       style={{ color: "secondary" }}
                       aria-hidden="true"
                     ></i>{" "} */
  }
  var btnPurple = {
    backgroundColor: "#8965e0",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  };
  // var btnDarkPurple = {
  //   backgroundColor: "danger",
  //   color: "#fff",
  //   border: "none",
  //   cursor: "pointer"
  // };
  var btnDetail = {
    backgroundColor: "#077E8C",
    // backgroundColor: "#6C757D",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  };
  var btnPending = {
    backgroundColor: "#F29339",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  };
  const info = props.info;
  function dateFormatter(cell) {
    if (!cell) {
      return "";
    }
    return moment(cell).format("DD-MM-YYYY H:mm");
  }
 
  const handleDownload = (cell, row) => {
    return (
      <>
        <Button
          size="sm"
          type="button"
          style={{
            cursor: "pointer",
            objectFit: "cover",
          }}
          onClick={() => props.printDoc(cell)}
        >
          <i className="fa fa-download" aria-hidden="true"></i>
        </Button>
      </>
    );
  };

  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "created_at",
              text: "Created date",
              sort: true,
              formatter: dateFormatter,
            },
            {
              dataField: "company_name",
              text: "Vendor",
              sort: true,
              formatter: (cell, row) => {
  
                if (row.invoice_info === 2) {
                  return (
                    <>
                      <a
                        className="table-action text-white btn btn-sm"
                        style={btnPurple}
                        onClick={() => props.details(row)}
                      >
                        <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                        {" "+cell}
                      </a>
                    </>
                  )
                }
                if (row.invoice_info === 7) {
                  return (
                    <>
                      <a
                        className="table-action text-white btn btn-sm bg-danger"
                        onClick={() => props.details(row)}
                      >
                        <i className="fa fa-arrow-circle-right" aria-hidden="true" />
                        {" "+cell}
                      </a>
                    </>
                  )
                }
                if (row.invoice_info === 5) {
                  return (
                    <>
                      <a
                        className="table-action text-white btn btn-sm"
                        style={btnPurple}
                        onClick={() => props.details(row)}
                      >
                        <i className="fa fa-arrow-circle-right" aria-hidden="true" />
                        {" "+cell}
                      </a>
                    </>
                  )
                }
              }
            },
            {
              dataField: "invoice",
              text: "Invoice",
              sort: true,
            },
            {
              dataField: "purchase_order",
              text: "Purchase order",
              sort: true,
              headerAlign: "center",
              align: "center",
            },
            {
              dataField: "good_receipt",
              text: "Good receipt",
              sort: true,
            },
            {
              dataField: "tax_invoice",
              text: "Tax invoice",
              sort: true,
            },
            {
              dataField: "delivery_note",
              text: "Delivery Note",
              sort: true,
            },
            // {
            //   dataField: "id",
            //   text: "File",
            //   headerAlign: "center",
            //   align: "center",
            //   sort: true,
            //   formatter: handleDownload,
            // },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <div class="d-flex">
                  <div class="mr-auto pl-2">
                    <h3>List active invoice</h3>
                  </div>
                  {info}
                </div>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListInvoiceStandardAp;
