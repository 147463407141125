/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

// reactstrap components
import { Card, CardHeader, Alert, Row, Col } from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListAllInvoiceTax = (props) => {
  const filter = props.filter;
  const pic = props.pic;

  function dateFormatter(cell) {
    if (!cell) {
      return "";
    }
    return moment(cell).format("DD-MM-YYYY HH:mm");
  }
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "created_at",
              text: "created invoice",
              sort: true,
              formatter: (cell,row)=>{
                if (!cell) {
                  return "";
                }
                return moment(cell).format("DD-MM-YYYY HH:mm");
              },
            },
            {
              dataField: "company_name",
              text: "Vendor",
              sort: true,
                formatter: (cell, row) => {
                  if (row.is_reject == 2) {
                    return (
                      <>
                      <a
                      style={{ display: "block", cursor: "pointer" }}
                      onClick={() => props.details(row)}
                    >
                    <i className="fa fa-times-circle text-danger" />{" "}
                      <strong>{cell}</strong>
                    </a>
                      </>
                    );
                  } 
                   if (row.is_reject == 3) {
                    return (
                      <>
                       <a
                      style={{ display: "block", cursor: "pointer" }}
                      onClick={() => props.details(row)}
                    >
                      <i className="fa fa-check-circle text-success" />{" "}
                      <strong>{cell}</strong>
                    </a>
                      </>
                    );
                  }
                },
              },
              {
                dataField: "invoice",
                text: "Invoice",
                sort: true,
              },
            {
              dataField: "purchase_order",
              text: "Purchase order",
              sort: true,
            },
            {
              dataField: "pic_id",
              text: "Verified",
              sort: true,
              formatter: (cell,row)=>{
                if (cell === pic) {
                  return "you"
                } else {
                  return row.pic 
                }
                  
              }
            },
            {
              dataField: "ver_date",
              text: "Verified date",
              sort: true,
              formatter: dateFormatter,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                  {filter}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Invoice empty</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListAllInvoiceTax;
