/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { Pie } from "react-chartjs-2";
import config from "services/config";
import axios from "axios";
import moment from "moment";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

import StandardHeader from "components/Headers/StandardHeader.js";
import decode from "jwt-decode";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#009fe3",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};
var calendar;
class DashboardTAX extends PureComponent {
  state = {
    chartPie: [],
    badge: {
      actives: 0,
      active_today: 0,
      approve_today: 0,
      reject_today: 0,
      approves: 0,
      rejects: 0,
      alls: 0,
    },
  };
  componentDidMount() {
    this.getInvoiceBudge();
    calendar = new Calendar(this.refs.widgetCalendar, {
      plugins: [dayGridPlugin],
      defaultView: "dayGridMonth",
      selectable: true,
      selectHelper: true,
      editable: true,
    });
    calendar.render();
  }

  getInvoiceBudge = async () => {
    const response = await axios.post(
      config.API_URL + "/tax/invoice_badge_tax",
      { id: config.USER_ID },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    this.setState({
      badge: {
        ...this.state.badge,
        actives: response.data.data[0].actives,
        active_today: response.data.data[0].active_today,
        approve_today: response.data.data[0].approve_today,
        reject_today: response.data.data[0].reject_today,
        approves: response.data.data[0].approve,
        rejects: response.data.data[0].reject,
        alls: response.data.data[0].alls,
      },
    });
  };
  const;
  render() {
    // var p1 = (this.state.badge.actives * 100) / this.state.badge.alls;
    // var p2 = (this.state.badge.approves * 100) / this.state.badge.alls;
    // var p3 = (this.state.badge.rejects * 100) / this.state.badge.alls;
    // var d1 = p1.toFixed(2);
    // var d2 = p2.toFixed(2);
    // var d3 = p3.toFixed(2);
    // const token_local = localStorage.getItem("token");
    // var token = decode(token_local);
    // var token_fills = decode(token.token);
    // console.log(token_fills);
    // const chartExample = {
    //   data: {
    //     labels: ["Active", "Approve", "Reject"],
    //     datasets: [
    //       {
    //         data: [
    //           d1,
    //           d2,
    //           d3,
    //         ],
    //         backgroundColor: [
    //           colors.theme["primary"],
    //           colors.theme["success"],
    //           colors.theme["warning"],
    //         ],
    //         label: ["Dataset 1"],
    //       },
    //     ],
    //   },
    //   options: {
    //     responsive: true,
    //     legend: {
    //       position: "bottom",
    //     },
    //     animation: {
    //       animateScale: true,
    //       animateRotate: true,
    //     },
    //   },
    // };
    // var pieChart;
    // if (this.state.badge.actives || this.state.badge.approves || this.state.badge.rejects !== 0 )  {
    //  pieChart =  <Col xl="6">
    //           <Card>
    //             <CardHeader>
    //               <Row>
    //                 <Col>
    //                   <div>
    //                     <h5 className="h5 text-muted mb-1">Invoice</h5>
    //                     <h3 className="h3 mb-0">Percent %</h3>
    //                   </div>
    //                 </Col>
    //                 {/* <Col>
    //                   <div className="col text-right">
    //                     <Button
    //                       color="primary"
    //                       style={{
    //                         cursor: "pointer",
    //                         objectFit: "cover",
    //                       }}
    //                       onClick={() => this.props.history.push("/vendor/close-reguler-invoice/"+1)}
    //                       size="sm"
    //                     >
    //                       See all
    //                     </Button>
    //                   </div>
    //                 </Col> */}
    //               </Row>
    //             </CardHeader>
    //             <CardBody className="mt-3 mb-2">
    //               <div className="chart">
    //                 <Pie
    //                   data={chartExample.data}
    //                   options={chartExample.options}
    //                   className="chart-canvas"
    //                   id="chart-pie"
    //                 />
    //               </div>
    //             </CardBody>
    //           </Card>
    //         </Col>
    // }
    return (
      <>
        <StandardHeader name="Home" parentName="Dashboard" link="dashboard-TAX" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="3">
              <Card className="bg-gradient-primary"
               style={{
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() => this.props.history.push("/tax/active-invoice-tax")}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0 text-white">
                        Active
                      </CardTitle>
                      <span className="h2 font-weight-bold mt-3 text-white">
                        {this.state.badge.actives}
                      </span>
                  <span className="h5 font-weight-bold mt-3 text-white">
                      &nbsp;/ {this.state.badge.active_today}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fa fa-arrow-circle-right" />
                      </div>
                    </Col>
                  </Row>
                  <p className="text-nowrap text-white font-weight-600 mt-3 mb-0 text-sm">
                    {/* <a
                      className="text-nowrap text-white font-weight-600"
                    >
                    </a> */}
                      See details
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card className="bg-gradient-success"
                     style={{
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    onClick={() => this.props.history.push("/tax/all-invoice-tax/"+2)}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0 text-white">
                        Approve
                      </CardTitle>
                      <span className="h2 font-weight-bold mt-3 text-white">
                        {this.state.badge.approves}
                      </span>
                      <span className="h5 font-weight-bold mt-3 text-white">
                      &nbsp;/ {this.state.badge.approve_today}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fa fa-check-circle" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <a
                      className="text-nowrap text-white font-weight-600"
                    >
                      See details
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card className="bg-gradient-danger"
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => this.props.history.push("/tax/all-invoice-tax/"+3)}
                      >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0 text-white">
                        Reject
                      </CardTitle>
                      <span className="h2 font-weight-bold mt-3 text-white">
                        {this.state.badge.rejects}
                      </span>
                      <span className="h5 font-weight-bold mt-3 text-white">
                      &nbsp;/ {this.state.badge.reject_today}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fa fa-times-circle" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <a
                      className="text-nowrap text-white font-weight-600"
                    >
                      See details
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card className="bg-gradient-default"
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => this.props.history.push("/tax/all-invoice-tax/"+1)}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0 text-white">
                        All
                      </CardTitle>
                      <span className="h2 font-weight-bold mt-3 text-white">
                        {this.state.badge.alls}
                      </span>
                      <span className="h5 font-weight-bold mt-3 text-white">
                      &nbsp;/ {this.state.badge.reject_today + this.state.badge.approve_today}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="fa fa-filter" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <a
                      className="text-nowrap text-white font-weight-600"
                    >
                      See details
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
          {/* {pieChart} */}
            {/* <Col md="6" xl="3">
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                  <h5 className="h5 text-muted mb-1 ">Notifications</h5>
                  <h5 className="h3 mb-0">Latest updated</h5>
                    </Col>
                    <Col className="">
                  <i className="ni ni-bell-55" />
                    </Col>
                  </Row>
                  
                </CardHeader>
                <CardBody>
                  <div
                    className="timeline timeline-one-side"
                    data-timeline-axis-style="dashed"
                    data-timeline-content="axis"
                  >
                    <div className="timeline-block">
                      <span className="timeline-step badge-success">
                        <i className="fa fa-check-circle" />
                      </span>
                      <div className="timeline-content">
                        <div className="d-flex justify-content-between pt-1">
                          <div>
                            <span className="text-muted text-sm font-weight-bold">
                             New Approve Invoice{"  "}<Badge color="warning" className="text-dark">12</Badge>
                            </span>
                          </div>
                          <div className="text-right">
                            <small className="text-muted">
                              <i className="fas fa-clock mr-1" />2 hrs ago
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block">
                      <span className="timeline-step badge-danger">
                        <i className="fa fa-times-circle" />
                      </span>
                      <div className="timeline-content">
                        <div className="d-flex justify-content-between pt-1">
                          <div>
                            <span className="text-muted text-sm font-weight-bold">
                              New Reject Invoice{"  "}<Badge color="warning" className="text-dark">1</Badge>
                            </span>
                          </div>
                          <div className="text-right">
                            <small className="text-muted">
                              <i className="fas fa-clock mr-1" />3 hrs ago
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block">
                      <span className="timeline-step badge-info">
                        <i className="fa fa-paperclip" aria-hidden="true" />
                      </span>
                      <div className="timeline-content">
                        <div className="d-flex justify-content-between pt-1">
                          <div>
                            <span className="text-muted text-sm font-weight-bold">
                              New Slip {"  "}<Badge color="warning" className="text-dark">1</Badge>
                            </span>
                          </div>
                          <div className="text-right">
                            <small className="text-muted">
                              <i className="fas fa-clock mr-1" />5 hrs ago
                            </small>
                          </div>
                        </div>
                        <h6 className="text-sm mt-1 mb-0">
                          Your posts have been liked a lot.
                        </h6>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="6" >
              <Card className="widget-calendar">
                <CardHeader>
                  <div className="h5 text-muted mb-1 widget-calendar-year">
                    {moment().format("YYYY")}
                  </div>
                  <div className="h3 mb-0 widget-calendar-day">
                    {moment().format("dddd, MMM D")}
                  </div>
                </CardHeader>
                <CardBody>
                  <div ref="widgetCalendar" />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </>
    );
  }
}

export default DashboardTAX;
