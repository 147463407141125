import React from "react";
// import ReactToPrint from "react-to-print";
// import PropTypes from "prop-types";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import  MyDocument  from './MyDocument';
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  ButtonGroup,
  Button,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";

import ListInvoice from "./list/ListAllInvoiceStandardAp";
import SweetAlert from "react-bootstrap-sweetalert";
import { webdatetime } from "services/sysdate";
var moment = require("moment");
// const nDate = new Date().toLocaleString("id-ID", {
//   timeZone: "Asia/Bangkok",
// });
// const pic_date = (nDate % 2  === 0) ? 2 : 3 ;
class AllInvoiceStandardAp extends React.Component {
  state = {
    listInvoiceAP: [],
    listStatus: [],
    listStatus2: [],
    listStatus3: [],
    switchTable: 1,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    isLoadingConfirmation: false,
    alert: null,
    show_custome: false,
    // listPeriod: [],
    paramError: {},
    actionFilter: {
      user_id: config.USER_ID,
      status: "1",
      invoice: "",
      // po: "",
      // company: "",
      // from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
      // to_date: moment().add(1, "days").format("YYYY-MM-DD"),
    },
    idChecked: "",
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoiceAP();
    if (this.props.match.params.tb) {
      this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          status: this.props.match.params.tb,
        },
      });
    }
    if (this.props.match.params.tb === ":tb") {
      this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          status: "1",
        },
      });
    }
    this.setState({
      // listPeriod: [
      //   {
      //     value: "1",
      //     label: "Last Year",
      //   },
      //   {
      //     value: "2",
      //     label: "Last Month",
      //   },
      //   {
      //     value: "3",
      //     label: "All",
      //   },
      //   {
      //     value: "4",
      //     label: "Set",
      //   },
      // ],
      listStatus: [
        {
          value: "1",
          label: "All",
        },
        {
          value: "2",
          label: "Approve",
        },
        {
          value: "3",
          label: "Reject",
        },
      ],
      listStatus2: [
        {
          value: "4",
          label: "Input SAP no",
        },
        {
          value: "5",
          label: "Routing slip",
        },
      ],
      listStatus3: [
        {
          value: "6",
          label: "Not Received doc",
        },
        {
          value: "7",
          label: "Received doc",
        },
      ],
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListInvoiceAP = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/ap/list_all_invoice_standard_ap",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceAP: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getDateInvoice = async () => {
    const response = await axios.post(
      config.API_URL + "/ap/list_ap_early_date_invoice",
      { id: 3 },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    this.setState({
      min_date: response.data.data[0].min_date,
      max_date: response.data.data[0].max_date,
    });
  };
  // handleChangePeriod = (e) => {
  //   var selectPeriod = e.target.value;
  //   if (selectPeriod === "1") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
  //         to_date: moment().format("YYYY-MM-DD"),
  //       },
  //       select_period: "1",
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "2") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: moment().subtract(1, "months").format("YYYY-MM-DD"),
  //         to_date: moment().format("YYYY-MM-DD"),
  //       },
  //       select_period: "2",
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "3") {
  //     return this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: this.state.min_date,
  //         to_date: this.state.max_date,
  //       },
  //       select_period: "3",
  //       show_custome: false,
  //     });
  //   }
  //   if (selectPeriod === "4") {
  //     this.setState({
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         from_date: "",
  //         to_date: this.state.max_date,
  //       },
  //       select_period: 4,
  //       show_custome: !this.state.show_custome,
  //     });
  //   }
  // };
  // handleFilter = () => {
  //   this.getListInvoiceAP();
  // };

  // handleReset = () => {
  //   this.setState(
  //     {
  //       ...this.state,
  //       select_period: "1",
  //       actionFilter: {
  //         ...this.state.actionFilter,
  //         invoice: "",
  //         status: "1",
  //         po: "",
  //         company: "",
  //         from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
  //         to_date: moment().add(1, "days").format("YYYY-MM-DD"),
  //       },
  //     },
  //     () => {
  //       if (this.state.isResetLoading === false) {
  //         this.setState(
  //           {
  //             isResetLoading: true,
  //           },
  //           () => {
  //             this.getListInvoiceAP();
  //           }
  //         );
  //       }
  //     }
  //   );
  // };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={3000}
        >
          Invoice {data.data + " " + data.message}
        </SweetAlert>
      ),
    });
  };
  // handleChecked = (idChecked) => {
  //   axios
  //     .post(
  //       config.API_URL + "/ap/checked_document",
  //       { idCheck: idChecked, user_id: config.USER_ID },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         this.successAlert(res.data);
  //         this.getListInvoiceAP();
  //       },
  //       (err) => {
  //         if (this.mounted) {
  //           if (err.response !== undefined) {
  //             this.failedAlert(err.response.data.message);
  //             this.setState({ isLoadingConfirmation: false });
  //           }
  //         }
  //       }
  //     );
  // };
  handleSap = (e) => {
    this.props.history.push("/ap/invoice-routing-slip/" + e.id);
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data.data}`}
        </SweetAlert>
      ),
    });
  };
  handleDetails = (e) => {
    this.props.history.push(
      "/ap/ap_review_invoice/" + e.id + "/" + this.state.actionFilter.status
    );
  };

  handlePrint = (e) => {
    this.props.history.push("/ap/invoice-routing-slip/" + e);
  };
  hideAlert = () => {
    this.setState({
      alert: null,

    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState({
      actionFilter: {
        ...this.state.actionFilter,
        [event.target.name]: event.target.value,
      },
    },()=>  this.getListInvoiceAP());
  };
  handleChangeStatus = (e) => {
    var selectPeriod = e;
    if (selectPeriod === 1) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "1",
          },
        },
        () => this.getListInvoiceAP()
      );
    }
    if (selectPeriod === 2) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "2",
          },
        },
        () => this.getListInvoiceAP()
      );
    }
    if (selectPeriod === 3) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "3",
          },
        },
        () => this.getListInvoiceAP()
      );
    }
    if (selectPeriod === 4) {
      return this.setState(
        {
          actionFilter: {
            ...this.state.actionFilter,
            status: "4",
          },
        },
        () => this.getListInvoiceAP()
      );
    }
  };

  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ thisShow: fileUploaded });
  };
  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="link"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
            <button type="button" class="close" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>
          </object>
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  handleDownload = (e) => {
    var id = e;
    this.setState(
      {
        activity: {
          id: id,
        },
      },
      () => {
        axios
          .post(
            config.API_URL + "/ap/vendor_document",
            this.state.activity,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (response) => {
              if (this.mounted) {
                this.setState(
                  {
                    files: [
                      response.data.data[0].invoice_file,
                      response.data.data[0].siuk_file,
                      response.data.data[0].add_file1,
                      response.data.data[0].add_file2,
                      response.data.data[0].add_file3,
                    ],
                  },
                  () => {
                    var filesAll = this.state.files;
                    const val = filesAll.filter(function (el) {
                      return el != null && el !== "";
                    });
                    const val1 = val.map((data) => config.BUCKET_URL + data);
                    for (var i = 0; i < val1.length; i++) {
                      window.open(val1[i], "_blank");
                    }
                  }
                );
              }
            },
            (err) => {
              console.log("error: ", err);
            }
          );
      }
    );
  };
  confirmAlertUser = (data) => {
    console.log(data.id)
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Document confirmation"
          onConfirm={() => this.handleConfirmation(data.id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="primary"
          confirmBtnText="Confirm"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {`${data.company_name}`} <br />
          {`${data.invoice}`}<br/>
          Document has been received?
        </SweetAlert>
      ),
    });
  };
  // checked_document
  handleConfirmation = (data) => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/ap/confirmation_document_ap",
        { id: data,user: config.USER_ID ,local_datetime: webdatetime()},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.getListInvoiceAP()
            this.successAlert(res.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  render() {
    var tableGroup = this.state.listStatus.map((items, index) => {
      if (this.state.actionFilter.status === items.value) {
        return (
          <>
            <Button
              className="mb-0"
              style={{ backgroundColor: "#5bc0de", color: "white" }}
              onClick={() => this.handleChangeStatus(index + 1)}
              key={index}
            >
              {items.label}
            </Button>
          </>
        );
      } else {
        return (
          <>
            <Button
              className="mb-0"
              onClick={() => this.handleChangeStatus(index + 1)}
              key={index}
            >
              {items.label}
            </Button>
          </>
        );
      }
    });
    // var tableGroup2 = this.state.listStatus2.map((items, index) => {
    //   if (this.state.actionFilter.status === items.value) {
    //     return (
    //       <>
    //         <Button
    //           className="mb-0"
    //           style={{ backgroundColor: "#000000", color: "white" }}
    //           onClick={() => this.handleChangeStatus(index + 1)}
    //           key={index}
    //         >
    //           {items.label}
    //         </Button>
    //       </>
    //     );
    //   } else {
    //     return (
    //       <>
    //         <Button
    //           className="mb-0"
    //           onClick={() => this.handleChangeStatus(index + 1)}
    //           key={index}
    //         >
    //           {items.label}
    //         </Button>
    //       </>
    //     );
    //   }
    // });
    // var tableGroup3 = this.state.listStatus3.map((items, index) => {
    //   if (this.state.actionFilter.status === items.value) {
    //     return (
    //       <>
    //         <Button
    //           className="mb-0"
    //           style={{ backgroundColor: "#000000", color: "white" }}
    //           onClick={() => this.handleChangeStatus(index + 1)}
    //           key={index}
    //         >
    //           {items.label}
    //         </Button>
    //       </>
    //     );
    //   } else {
    //     return (
    //       <>
    //         <Button
    //           className="mb-0"
    //           onClick={() => this.handleChangeStatus(index + 1)}
    //           key={index}
    //         >
    //           {items.label}
    //         </Button>
    //       </>
    //     );
    //   }
    // });

    return (
      <>
        <StandardHeader
          name="close"
          parentName="Invoice"
          link="all-reguler-invoice/:tb "
        />
        <Container className="mt--6" fluid>
          {/* <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Filter</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Invoice</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="invoice"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.invoice}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">PO</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="po"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.po}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Vendor</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="company"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.company}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Period</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="period"
                      value={this.state.select_period}
                      onChange={this.handleChangePeriod}
                    >
                      {this.state.listPeriod.map((items, index) => {
                        return (
                          <option key={index} value={items.value}>
                            {items.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {this.state.show_custome ? (
                  <>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">From date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="from_date"
                          value={this.state.actionFilter.from_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.from_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.from_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">To date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="to_date"
                          value={this.state.actionFilter.to_date}
                          max={this.state.max_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.to_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.to_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row className="justify-content-md-center">
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <div className="text-center">
                    <Button
                      block
                      color="dark"
                      className="btn-icon"
                      type="button"
                      disabled={this.state.isResetLoading ? true : false}
                      onClick={() => this.handleReset()}
                    >
                      {this.state.isResetLoading ? (
                        <>
                          <span className="btn-inner--text">
                            <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                            Reset
                          </span>
                        </>
                      ) : (
                        <span className="btn-inner--text">Reset</span>
                      )}
                    </Button>
                  </div>
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <Button
                    block
                    color="primary"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isFilterLoading ? true : false}
                    onClick={() => this.handleFilter()}
                  >
                    {this.state.isFilterLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Filter
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Filter</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card> */}
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  info={
                    <>
                      <Row>
                        <Col className="col-12 col-sm-8 col-md-4">
                        <div style={{ width: "500px" }}>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              id="search"
                              placeholder="Search"
                              result
                              onKeyPress={this.keyPressed}
                              name="invoice"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                              // style={{ visibility : this.state.isFilterLoading ? 'hidden' : 'visible'}}
                            />
                               <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            // style={{marginTop: "--100px"}}
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", }}
                            // aria-label="Close"
                            onClick={() => this.setState({actionFilter:{...this.state.actionFilter,invoice:""}},()=> this.getListInvoiceAP())}
                          >
                            
                            <i className="fa fa-times-circle text-default" />
                          </button>
                          </InputGroup>
                        </div>
                        </Col>
                        {/* <Col className="col-12 col-sm col-md-1">
                  <FormGroup>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="period"
                      value={this.state.select_period}
                      onChange={this.handleChangePeriod}
                    >
                      {this.state.listPeriod.map((items, index) => {
                        return (
                          <option key={index} value={items.value}>
                            {items.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                {this.state.show_custome ? (
                  <>
                    <Col className="col-12 col-sm col-md-2">
                      <FormGroup>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="from_date"
                          value={this.state.actionFilter.from_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.from_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.from_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-sm col-md-2">
                      <FormGroup>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="to_date"
                          value={this.state.actionFilter.to_date}
                          max={this.state.max_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.to_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.to_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null} */}
                        <Col className="text-right">
                          <ButtonGroup>{tableGroup}</ButtonGroup>
                          {/* <ButtonGroup>{tableGroup2}</ButtonGroup>
                          <ButtonGroup>{tableGroup3}</ButtonGroup> */}
                        </Col>
                      </Row>
                    </>
                  }
                  list={this.state.listInvoiceAP}
                  printSlip={this.handlePrint}
                  inputSap={this.handleSap}
                  confirmFiles={this.confirmAlertUser}
                  details={this.handleDetails}
                  printDoc={this.handleDownload}
                  viewDoc={this.onChangePreview}
                />
              </div>
            </div>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default AllInvoiceStandardAp;
