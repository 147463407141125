/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Modal,
  InputGroup
} from "reactstrap";
//services
import config from "services/config";
import { webdatetime } from "services/sysdate";

//packages
import axios from "axios";

//redux
import { connect } from "react-redux";
import { getDataCompanies } from "./Redux/companyActions";
import { validateCompany } from "services/validate";
import ListVendor from "./ListVendor";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import StandardHeader from "components/Headers/StandardHeader";
class Companies extends PureComponent {
  state = {
    actionListCompanies: {
      company_name: "",
    },
    add: {
      local_datetime: webdatetime(),
      company_id: "",
      company_name: "",
      company_phone: "",
      company_address: "",
    },
    data: {
      file_vendor: "",
    },
    alert: null,
    listCompanies: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    showModal: false,
    addModal: false,
    isLoading: false,
    paramError: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.getDataListCompanies();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChangeStringFilter = (event) => {
    this.setState(
      {
        actionListCompanies: {
          ...this.state.actionListCompanies,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getDataListCompanies();
      }
    );
  };

  getDataListCompanies = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/companies/ap_list_company",
        this.state.actionListCompanies,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listCompanies: res.data.data,
                total_items: res.data.total_items,
              },
              () => {
                this.props.dispatch(getDataCompanies(this.state.listCompanies));
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleDetails = (data) => {
    this.props.history.push("/ap/vendor_details/" + data.company_id+"/1");
  };

  handlePagination = (numPage) => {
    this.setState(
      {
        actionListCompanies: {
          ...this.state.actionListCompanies,
          page: numPage,
        },
      },
      () => {
        this.getDataListCompanies();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListCompanies: {
          ...this.state.actionListCompanies,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListCompanies();
      }
    );
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleFilter = () => {
    this.setState(
      {
        actionListCompanies: {
          ...this.state.actionListCompanies,
          page: 1,
        },
      },
      () => {
        this.getDataListCompanies();
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        actionListCompanies: {
          page: 1,
          items_per_page: 10,
          order_by_field: "company_id",
          order_by_direction: "DESC",
          company_name: "",
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getDataListCompanies();
            }
          );
        }
      }
    );
  };
  // handleDelete = (data) => {
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         warning
  //         style={{ display: "block" }}
  //         title="Are you sure?"
  //         onConfirm={() => this.remove(data)}
  //         onCancel={() => this.hideAlert()}
  //         showCancel
  //         confirmBtnBsStyle="danger"
  //         confirmBtnText="Yes, delete it!"
  //         cancelBtnBsStyle="secondary"
  //         cancelBtnText="Cancel"
  //         btnSize=""
  //       >
  //         Delete {`${data.company_name}`} vendor, You won't be able to revert
  //         this!
  //       </SweetAlert>
  //     ),
  //   });
  // };

  // remove = (data) => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/delete_company",
  //       { company_id: data.company_id },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       () => {
  //         this.confirmedAlert(data.company_name);
  //         this.handleFilter();
  //       },
  //       (err) => {
  //         console.log(err);
  //         if (this.mounted) {
  //           if (err.response !== undefined) {
  //             this.failedAlert(err.response.data.message);
  //           }
  //         }
  //       }
  //     );
  // };
  confirmedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Vendor {`${data}`} has been deleted.
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  handleSubmit = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
        },
      },
      // () => console.log(this.state.data),
      () => {
        let formData = new FormData();
        // formData.append("user_id", config.USER_ID);
        formData.append("file_vendor", this.state.data.file_vendor);
        formData.append("local_datetime", webdatetime());
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        axios
          .post(config.API_URL + "/companies/ap_create_bulk_vendor", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.setState({
                  isLoading: false,
                });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      }
    );
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.setState({
              alert: false,
              showModal: false,
              addModal: false,
              data: { file_vendor: "" },
            })
          }
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  onFileChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.files[0],
      },
    });
  };
  onFileDelete = () => {
    this.setState({ data: { ...this.state.data, file_vendor: null } });
  };
  addVendor = () => {
    this.props.history.push("/admin/add-vendor");
  };
  handleAdd = () => {
    this.setState({ isLoading: true });
    const paramError = validateCompany(this.state.add);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      axios
        .post(config.API_URL + "/companies/ap_create_company", this.state.add, {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        })
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({
                isLoading: false,
                add: {
                  company_id: "",
                  company_name: "",
                  company_phone: "",
                  company_address: "",
                },
              });
            }
          },
          (err) => {
            if (this.mounted) {
              if (err.response !== undefined) {
                this.failedAlert(err.response.data.message);
                this.setState({ isLoading: false });
              }
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  handleChangeString = (event) => {
    this.setState({
      add: {
        ...this.state.add,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
      },
    });
  };
  render() {
    return (
      <>
        <StandardHeader name="Vendor" parentName="Vendor" link="vendor" />
        <Container className="mt--6" fluid>
          <Row>
            <Modal
              centered
              fullscreen=""
              // size="lg"
              isOpen={this.state.addModal}
              // toggle={this.handleDetails}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Add Vendor
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.setState({ addModal: false })}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Vendor id</label>
                      <Input
                        type="text"
                        onKeyPress={this.keyPressed}
                        name="company_id"
                        value={this.state.add.company_id}
                        onChange={this.handleChangeString}
                        required
                        className={
                          this.state.paramError.company_id ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.company_id}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Vendor name</label>
                      <Input
                        type="text"
                        onKeyPress={this.keyPressed}
                        name="company_name"
                        value={this.state.add.company_name}
                        onChange={this.handleChangeString}
                        required
                        className={
                          this.state.paramError.company_name ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.company_name}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <label className="form-control-label">
                        Vendor contact
                      </label>
                      <Input
                        type="text"
                        onKeyPress={this.keyPressed}
                        name="company_phone"
                        value={this.state.add.company_phone}
                        onChange={this.handleChangeString}
                        required
                        className={
                          this.state.paramError.company_phone
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.company_phone}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <label className="form-control-label">
                        Vendor Address
                      </label>
                      <Input
                        type="textarea"
                        onKeyPress={this.keyPressed}
                        name="company_address"
                        value={this.state.add.company_address}
                        onChange={this.handleChangeString}
                        required
                        className={
                          this.state.paramError.company_address
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramError.company_address}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                
              </div>
              <div className="modal-footer">
                <Button
                      // block
                      color="success"
                      className="btn-icon"
                      type="button"
                      disabled={this.state.isLoading ? true : false}
                      onClick={() => this.handleAdd()}
                    >
                      {this.state.isLoading ? (
                        <>
                          <span className="btn-inner--text">
                            <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                            Submit
                          </span>
                        </>
                      ) : (
                        <span className="btn-inner--text">Submit</span>
                      )}
                    </Button>
              </div>
            </Modal>
            <Modal
              centered
              fullscreen=""
              // size="lg"
              isOpen={this.state.showModal}
              // toggle={this.handleDetails}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Import list vendor
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.setState({ showModal: false })}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <label className="form-control-label mr-auto">File</label>

                  {this.state.data.file_vendor ? (
                    <>
                      <div className="d-flex my-auto">
                        <div className="mt-2 mr-auto">
                          {this.state.data.file_vendor.name}
                        </div>
                        &nbsp;
                        <div className="mt-2">
                          <Button
                            id="del"
                            onClick={() => this.onFileDelete()}
                            // color="dark"
                            size="sm"
                            type="button"
                            style={{
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                          >
                            <i
                              className="fa fa-trash text-dark"
                              aria-hidden="true"
                            ></i>
                          </Button>
                          <UncontrolledTooltip flip delay={0} target={"del"}>
                            Delete
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="custom-file d-flex">
                        <Input
                          className="custom-file-input mr-auto"
                          id="customFileLang"
                          lang="en"
                          type="file"
                          name="file_vendor"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={(e) => this.onFileChange(e)}
                        />
                      </div>
                    </>
                  )}
                </FormGroup>
              </div>
              <div className="modal-footer">
                <Button
                  type="button"
                  color="link"
                  data-dismiss="modal"
                  onClick={() => this.setState({ showModal: false })}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  className="btn-icon"
                  type="button"
                  disabled={
                    this.state.isLoading || !this.state.data.file_vendor
                      ? true
                      : false
                  }
                  onClick={() => this.handleSubmit()}
                >
                  {this.state.isLoading ? (
                    <>
                      <span className="btn-inner--text">
                        <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                        Submit
                      </span>
                    </>
                  ) : (
                    <span className="btn-inner--text">Submit</span>
                  )}
                </Button>
              </div>
            </Modal>
            <div className="col">
              <div className="card-wrapper">
                <ListVendor
                  list={this.state.listCompanies}
                  info={
                    <Row className="d-flex">
                      <Col className="col-md-7  col-sm-12 col-12">
                        <h3>List Vendor</h3>
                      </Col>
                      <Col
                        className="col-md-5  col-sm-12 col-12 float-right"
                        style={{ width: "500px" }}
                      >
                           <InputGroup >
                        <Input
                           className="mx-2"
                          size="sm"
                          id="example-date-input"
                          type="text"
                          onKeyPress={this.keyPressed}
                          result
                          name="company_name"
                          placeholder="Search"
                          value={this.state.actionListCompanies.company_name}
                          onChange={this.handleChangeStringFilter}
                        />
                        <Button
                          size="sm"
                          color="success"
                          type="button"
                          className="btn-icon mx-2"
                          onClick={() => this.setState({ addModal: true })}
                        >
                          <i className="fa fa-plus" /> Add
                        </Button>
                        <Button
                          size="sm"
                          color="default"
                          type="button"
                          className="btn-icon mx-2"
                          onClick={() => this.setState({ showModal: true })}
                          >
                          <i className="fa fa-file-excel-o" /> Import
                        </Button>
                          </InputGroup>
                      </Col>
                    </Row>
                  }
                  details={this.handleDetails}
                  // delete={this.handleDelete}
                />
              </div>
            </div>
          </Row>
        </Container>

        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCompanies: state.company.dataCompanies,
  };
};

export default connect(mapStateToProps)(Companies);
