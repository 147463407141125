import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import ListInvoice from "./TabInvoiceOutstanding";
import SweetAlert from "react-bootstrap-sweetalert";
import { webdatetime } from "services/sysdate";
// import ReactToPrint from "react-to-print";
var moment = require("moment");
class ListInvoiceOutstanding extends PureComponent {
  state = {
    listInvoiceAdm: [],
    listStatus: [],
    files: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    listPeriod: [],
    paramError: {},
    actionFilter: {
      user_id: config.USER_ID,
      table: 1,
      invoice: "",
    },
    id: "",
    preview: "",
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoiceAdmin();
  };

  componentWillUnmount() {
    this.mounted = false;
  }
 
  getListInvoiceAdmin = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/list_invoice_document_adm",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceAdm: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceAdmin();
            }
          );
        }
      }
    );
  };

  handleConfirmation = (data) => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/confirmation_document_adm",
        { id: data,user: config.USER_ID ,local_datetime: webdatetime()},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.getListInvoiceAdmin()
            this.successAlert(res.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  

  refresh = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.getListInvoiceAdmin();
      }
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => this.refresh()}
          confirmBtnBsStyle="success"
          disabled={true}
          timeout={3000}
        >
          Documents has been confirmed received
        </SweetAlert>
      ),
    });
  };

  handlePreview = (e) => {
   this.onChangePreview(e.invoice_file)
  };
  onChangePreview = (data) => {
    this.setState({
      preview: data,
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  confirmAlertUser = (data) => {
    console.log(data.id)
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Document confirmation"
          onConfirm={() => this.handleConfirmation(data.id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="primary"
          confirmBtnText="Confirm"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {`${data.company_name}`} <br />
          {`${data.invoice}`}<br/>
          Document has been received?
        </SweetAlert>
      ),
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleDetail=(data)=>{
    this.props.history.push("/admin/vendor_invoice_detail/" + data);
  }
  handleChangeString = (event) => {
    this.setState({
      actionFilter: {
        ...this.state.actionFilter,
        [event.target.name]: event.target.value,
      },
    },()=> this.getListInvoiceAdmin());
  };
  routingSlip =(id)=>{
      this.props.history.push(
        "/admin/invoice-routing-slip-admin/"+id
      );
  }
  render() {
    return (
      <>
        <StandardHeader
          name="outstanding"
          parentName="Invoice Document"
          link="outstanding-document"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  info={<Col md={6} sm={6}>
                     <div className="float-right">
                        <div style={{ width: "500px" }}>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              id="search"
                              placeholder="Search"
                              result
                              onKeyPress={this.keyPressed}
                              name="invoice"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                              // style={{ visibility : this.state.isFilterLoading ? 'hidden' : 'visible'}}
                            />
                             <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            // style={{marginTop: "--100px"}}
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", }}
                            // aria-label="Close"
                            onClick={() => this.setState({actionFilter:{...this.state.actionFilter,invoice:""}},()=> this.getListInvoiceAdmin())}
                          >
                            
                            <i className="fa fa-times-circle text-default" />
                          </button>
                          </InputGroup>
                        </div>
                      </div>
                  </Col>}
                  list={this.state.listInvoiceAdm}
                  printDoc={this.handlePreview}
                  // confirm={this.confirmAlertUser}
                  sap={this.routingSlip}
                  detail={this.handleDetail}
                />
              </div>
            </div>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default ListInvoiceOutstanding;
