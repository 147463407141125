/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
// import PDFMerger from 'pdf-merger-js/browser';
import { PDFDocument } from "pdf-lib";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Button,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateApInvoice } from "services/validate";
import { currencyIDR } from "services/currency";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
class ApInvoiceReview extends PureComponent {
  state = {
    rSelected: 2,
    setActive: false,
    preview: undefined,
    listTrackInvoice: [],
    thisShow: "",
    checked: false,
    add: false,
    checkedAll: false,
    files: [],
    showDetail: false,
    display:{
      dCurrC : "",
      dCurrCC: "",
     },
    data: {
      note: "",
      // decline: 0,
      cSelected: 1,
    },
    file: {
      invoice_number_file: "",
      purchase_order_file: "",
      good_receipt_file: "",
      bast_file: "",
      tax_invoice_file: "",
      delivery_note_file: "",
      siuk_file: "",
    },
    verification: {
      all: 0,
      c_po: 0,
      c_inv: 0,
      c_tax: 0,
      c_gr: 0,
      c_bast: 0,
      c_dn: 0,
      c_siuk: 0,
    },
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.getListInvoice();
    this.getListTrackInvoice();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getListInvoice = async () => {
    const response = await axios.post(
      config.API_URL + "/ap/invoice_review_ap",
      { id: this.props.match.params.id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    this.setState({
      data: {
        revision: response.data.data[0].revision,
        invoice_id: response.data.data[0].id,
        company_name: response.data.data[0].company_name,
        created_on: response.data.data[0].created_at,

        invoice_number: response.data.data[0].invoice,
        purchase_order: response.data.data[0].purchase_order,
        tax_invoice: response.data.data[0].tax_invoice,
        good_receipt: response.data.data[0].good_receipt,
        delivery_note: response.data.data[0].delivery_note,
        bast: response.data.data[0].bast,

        siuk: response.data.data[0].siuk,

        invoice_type: response.data.data[0].invoice_type,
        // invoice_info: 12,
        invoice_info: response.data.data[0].invoice_info,
        invoice_status: response.data.data[0].invoice_status,
        invoice_amount: response.data.data[0].invoice_amount,
  
        is_ppn: response.data.data[0].ppn,
        ppn: response.data.data[0].tax_ppn,
        ppn_value: response.data.data[0].ppn_rate,
        is_pph: response.data.data[0].pph,
        type_pph: response.data.data[0].tax_pph,
        tax_pph_dpp: response.data.data[0].tax_pph_dpp,
        pph_value: response.data.data[0].tax_rate,
        total_invoice: response.data.data[0].total_invoice,
        
        tax_verified: response.data.data[0].tax_verified,
        updated_at_a: response.data.data[0].tax_date,
        note_a: response.data.data[0].tax_note,
  
        pic_tax_id: response.data.data[0].tax_id,
        pic_tax: response.data.data[0].pic_tax,
        pic_ap_id: response.data.data[0].ap_id,
        pic_ap: response.data.data[0].pic_ap,
        verification: response.data.data[0].tax_id === response.data.data[0].ap_id ? 1 : 2,
        ap_verified: response.data.data[0].ap_verified,
        updated_at_b: response.data.data[0].ap_date,
        note_b: response.data.data[0].ap_note,
        sap_number: response.data.data[0].ap_sap,
        sap_date: response.data.data[0].sap_date,
        is_receive: response.data.data[0].doc_receive,
        is_receive_date: response.data.data[0].doc_receive_date,

      },
      file: {
        invoice_number_file: response.data.data[0].invoice_file,
        siuk_file: response.data.data[0].siuk_file,
      },
      display:{
        ...this.state.display,
        dCurrC: response.data.data[0].currency ? response.data.data[0].currency : "IDR",
        dCurrID: response.data.data[0].curr,
      }
    });
  };
  getListTrackInvoice = async () => {
    await axios
      .post(
        config.API_URL + "/ap/invoice_review_ap2",
        { id: this.props.match.params.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState({
            ...this.state,
            listTrackInvoice: res.data.data,
          });
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
 
  
  handleCancel = () => {
    this.props.history.push(
      "/ap/all-reguler-invoice/" + this.props.match.params.tb
    );
  };
  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ thisShow: fileUploaded });
  };
  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="link"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
            <button type="button" class="close" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>
          </object>
        </SweetAlert>
      ),
    });
  };
  handleClose = () => {
    this.setState({ thisShow: "" });
  };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.props.history.push("/ap/reguler-invoice")}
          // onConfirm={() => this.hideAlert()}
          // onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={1000}
        >
          <b>
            Invoice <b>{`${data.data}`}</b> has been verified
          </b>
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  mergeDocuments = async () => {
    // const cek = Array.from(filesAll, item => typeof item === null ? 'jdasjdadausdu' : item);
    // const merger = new PDFDocument()
    var filesAll = this.state.files;
    const val = filesAll.filter(function (el) {
      return el != null && el !== "";
    });
    // var conS3 = config.BUCKET_URL

    const val1 = val.map((data) => config.BUCKET_URL + data);
    var files = [];
    const doc = await PDFDocument.create();
    for (var i = 0; i < val1.length; i++) {
      var coverBytes = await fetch(val1[i]).then((res) => res.arrayBuffer());
      // console.log(coverBytes);
      // files[i]      = await PDFDocument.load(coverBytes)
      // const contents = await doc.copyPages(files[i], files[i].getPageIndices());
      // for (const page of contents) {
      // doc.addPage(page);
      // }
    }
    // doc.save

    // console.log(files)
    // for (var i = 0; i < val1.length; i++) {
    //   window.open(
    //       val1[i],
    //       "_blank"
    //     )
    // }
  };
  onShow = (event) => {
    this.setState({
      ...this.state,
      add: event,
    });
    // if (event.key === "Enter") {
    //   event.preventDefault();
    // }
  };
  handleSap = (e) => {
    // console.log(e)
    this.props.history.push("/ap/invoice-routing-slip/" + e);
  };
  render() {
    // var list = this.state.listTrackInvoice.map((ids, index) => {
    //   if (index === 0) {
    //     if (ids.status_id === 3 ){
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-check-circle text-success"/>&nbsp;<b className="text-success">You Approved</b></div>
    //               <div><small className="text-success"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3"></span>
    //             </div>
    //         </>
    //       );
    //     } 
    //     if (ids.status_id === 9 ){
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-check-circle text-dark"/>&nbsp;<b className="text-dark">You submit SAP number</b></div>
    //               <div><small className="text-dark"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3"></span>
    //             </div>
    //         </>
    //       );
    //     } 
    //      if (ids.status_id === 10 ){
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-check-circle text-dark"/>&nbsp;<b className="text-dark">You confirm received document</b></div>
    //               <div><small className="text-dark"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3"></span>
    //             </div>
    //         </>
    //       );
    //     } 
    //     if (ids.status_id === 12 ) {
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-times-circle text-danger"/>&nbsp;<b className="text-danger">Reject by You</b></div>
    //               <div><small className="text-danger"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-default d-flex ml-2">
    //              <span className="text-muted ml-3 my-3">{this.state.data.ap_note}</span>
    //             </div>
    //         </>
    //       );
    //     }
    //   // 2
    //   } else {
    //     if (ids.status_id === 9 ){
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-check-circle text-muted"/>&nbsp;<b className="text-muted">You submit SAP number</b></div>
    //               <div><small className="text-muted"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3"></span>
    //             </div>
    //         </>
    //       );
    //     }  
    //     if (ids.status_id === 10 ){
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-check-circle text-muted"/>&nbsp;<b className="text-muted">You confirm received document</b></div>
    //               <div><small className="text-muted"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3"></span>
    //             </div>
    //         </>
    //       );
    //     } 
    //     if (ids.status_id === 12 ) {
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-times-circle text-muted"/>&nbsp;<b className="text-muted">Reject by You</b></div>
    //               <div><small className="text-muted"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3">{this.state.data.ap_note}</span>
    //             </div>
    //         </>
    //       );
    //     }
    //     if (ids.status_id === 3 ){
    //       return (
    //         <>
    //             <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-check-circle text-muted"/>&nbsp;<b className="text-muted">You Approved</b></div>
    //               <div><small className="text-muted"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3">{this.state.data.ap_note}</span>
    //             </div>
    //         </>
    //       );
    //     } 
        
    //     if (ids.status_id === 6 ){
    //       return (
    //         <>
    //           <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-dot-circle-o  text-muted"/>&nbsp;<b className="text-muted">Approve by{" "+this.state.data.pic}</b> </div>
    //               <div><small className="text-muted"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3">{this.state.data.pic_note}</span>
    //             </div>
    //         </>
    //       );
    //   } 
    //    if (ids.status_id === 7) {
    //       return (
    //         <>
    //           <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-dot-circle-o text-muted"/>&nbsp;<b className="text-muted">Reject by{" "+this.state.data.pic}</b> </div>
    //               <div><small className="text-muted"><b>{ids.created_at}</b></small></div>
    //             </div>
    //             <div className="border-left dashed border-muted d-flex ml-2">
    //              <span className="text-muted ml-3 my-3">{this.state.data.pic_note}</span>
    //             </div>
    //         </>
    //       );
    //   } else {
    //     return (
    //       <>
    //     <div className="d-flex" key={index}>
    //               <div className="mr-auto"> <i className="fa fa-dot-circle-o text-muted"/>&nbsp;<b className="text-muted">Create invoice</b> </div>
    //               <div><small className="text-muted"><b>{ids.created_at}</b></small></div>
    //             </div>
    //       </>
    //     )
    //     }
    //   }
    //   return(null)
    // });
    return (
      <>
        <StandardHeader
          name="Invoice verification"
          parentName="Invoice verification"
          link="reguler-invoice"
        />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <div className="d-flex">
                <div className="mr-auto">
                  <h2 className="mb--2w">{this.state.data.company_name}</h2>
                  {/* <span style={{ fontSize: "0.75em" }} className="text-muted">
                    {this.state.data.created_on ? (
                      <>
                        Created at{" "}
                        {" " +
                          moment(this.state.data.created_on).format(
                            "HH:mm DD-MM-YYYY"
                          )}
                      </>
                    ) : null}
                  </span> */}
                </div>
                <div className="align-self-center mr-3">
                          {this.state.data.revision > 0 ? (
                        <>
                          <div className="">
                            <h2>
                              <span className="text-primary">
                                {" R" + this.state.data.revision}
                              </span>
                            </h2>
                          </div>
                        </>
                      ) : null}
                  <Button
                    id="dl"
                    size="sm"
                    type="button"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        config.BUCKET_URL + this.state.file.invoice_number_file,
                        "_blank"
                      )
                    }
                  >
                    <i className="fa fa-download"></i>
                  </Button>
                  <UncontrolledTooltip flip delay={0} target={"dl"}>
                    Donwload File
                  </UncontrolledTooltip>
                  <UncontrolledTooltip flip delay={0} target={"pr"}>
                    Preview File
                  </UncontrolledTooltip>
                  &emsp;
                  <Button
                    size="sm"
                    type="button"
                    id="pr"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.onChangePreview(this.state.file.invoice_number_file)
                    }
                  >
                    <i className="fa fa-file-pdf-o"></i>
                  </Button>{" "}
                  &emsp;
                    <span
                      className="btn btn-sm btn-default"
                      style={{
                        fontSize: "1.1em",
                        pointerEvents: "none",
                        borderRadius: "45px",
                      }}
                    >
                      {/* <i className="fa fa-certificate" /> REJECT */}
                      {/* <i className="fa fa-check-circle" />  */}
                      
                        <span
                        className="fa-stack fa-lg mt--1"
                        style={{ fontSize: "0.5em" }}
                      >
                        <i className="fa fa-certificate fa-stack-2x"></i>
                        {this.state.data.invoice_info === 12 ? (
                          <i className="fa fa-times fa-stack-1x fa-inverse  text-default mx-auto"></i>
                        ):(
                        <i className="fa fa-check fa-stack-1x fa-inverse text-default mx-auto"></i>
                        )}
                      </span>{" "}
                      CLOSED
                    </span>
                  <button
                    type="button"
                    className="close mt--4 mr--5"
                    // style={{marginTop: "--100px"}}
                    style={{ fontSize: "1.2em"}}
                    aria-label="Close"
                    onClick={() => this.handleCancel()}
                  >
                  
                    <i className="fa fa-times-circle text-default" />

                  </button>
                </div>
              </div>
            </CardHeader>
          </Card>
          <Row>
            <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
              <Card className="bg-secondary shadow">
                <CardBody className="bg-white px-4 pt-4">
                  <FormGroup>
                    <Row>
                      <Col className="col-md-4 col-sm-12 col-12 my-auto">
                        Invoice number
                      </Col>
                      <Col className="col-md-7 col-sm-12 col-12 my-auto">
                        :&emsp;<b>{this.state.data.invoice_number}</b>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col className="col-md-4 col-sm-12 col-12 my-auto">
                        Purchase order (PO)
                      </Col>
                      <Col className="col-md-7 col-sm-12 col-12 my-auto">
                        :&emsp;<b>{this.state.data.purchase_order}</b>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col className="col-md-4 col-sm-12 col-12 my-auto">
                        Tax Invoice
                        <i className="text-muted"> Faktur pajak</i>
                      </Col>
                      <Col className="col-md-7 col-sm-12 col-12 my-auto">
                        :&emsp;<b>{this.state.data.tax_invoice}</b>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col className="col-md-4 col-sm-12 col-12 my-auto">
                        Good receipt (GR)
                      </Col>
                      <Col className="col-md-7 col-sm-12 col-12 my-auto">
                        :&emsp;<b>{this.state.data.good_receipt}</b>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col className="col-md-4 col-sm-12 col-12 my-auto">
                        Delivery note
                      </Col>
                      <Col className="col-md-7 col-sm-12 col-12 my-auto">
                        :&emsp;<b>{this.state.data.delivery_note}</b>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col className="col-md-4 col-sm-12 col-12 my-auto">
                        <label className="form-control-label">
                          Minutes of handover
                          <i className="text-muted"> BAST</i>
                        </label>
                      </Col>
                      <Col
                        xs="12"
                        className="col-md-7 col-sm-12 col-12 my-auto"
                      >
                        :&emsp;<b>{this.state.data.bast}</b>
                      </Col>
                    </Row>
                  </FormGroup>
                  {this.state.invoice_info ==12 ? (<>
                  <FormGroup>
                    <Row>
                      <Col className="col-md-4 col-sm-12 col-12 my-auto">
                        SAP number
                      </Col>
                      {this.state.data.sap_number ? (
                              <>
                                <Col className="col-md-7 col-sm-12 col-12 my-auto">
                                  :&emsp;
                                  <Button
                                    size="sm"
                                    type="button"
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                    onClick={() => this.handleSap(this.state.data.invoice_id)}
                                  >
                                    <b id="sap_time">
                                      <i className="fa fa-file-pdf-o" />{" "}
                                      {this.state.data.sap_number}
                                    </b>
                                  </Button>

                                </Col>
                                <UncontrolledTooltip placement="bottom" target="sap_time">
                                  {this.state.data.sap_date}
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              <>
                                <Col className="col-md-7 col-sm-12 col-12 my-auto">
                                  :&emsp;
                                  <Button
                                    size="sm"
                                    type="button"
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                    onClick={() => this.handleSap(this.state.data.invoice_id)}
                                  >
                                    <b id="sap_time">
                                      <i className="fa fa-pencil-square-o" />{" "}
                                    </b>
                                  </Button>
                                </Col>
                              </>
                            )}
                    </Row>
                  </FormGroup>
                  </>):null}
                  {this.state.file.siuk_file ? (
                    <>
                      {this.state.add ? (
                        <>
                          <div className="text-right mt--3 mb-3">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => this.onShow(false)}
                            >
                              <i className="fa fa-chevron-circle-up" />
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-right mt--3 mb-3">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => this.onShow(true)}
                            >
                              <i
                                className="fa fa-chevron-circle-down"
                                style={{ cursor: "pointer" }}
                              />
                            </a>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </CardBody>
              </Card>
              {this.state.file.siuk_file ? (
                <>
                  {this.state.add ? (
                    <>
                      <Card>
                        <CardBody>
                          {this.state.file.siuk_file !== "" ? (
                            <>
                              <Row>
                                <Col md="12" className="my-auto">
                                  Construction business license
                                  <br />
                                  <small>
                                    <i className="text-muted">
                                      Surat izin usaha kontruksi (SIUK)
                                    </i>
                                  </small>
                                </Col>
                              </Row>
                              <FormGroup>
                                <Row>
                                  <Col md="7" xs="12" className="my-auto">
                                    &emsp;{this.state.data.siuk}
                                  </Col>
                                  <Col
                                    md="5"
                                    xs="12"
                                    className="my-auto text-right"
                                  >
                                    <Button
                                      size="sm"
                                      type="button"
                                      id="dl"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        window.open(
                                          config.BUCKET_URL +
                                            this.state.file.siuk_file,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <i className="fa fa-download"></i>
                                    </Button>
                                    <UncontrolledTooltip
                                      flip
                                      delay={0}
                                      target={"dl"}
                                    >
                                      Donwload File
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      flip
                                      delay={0}
                                      target={"pr"}
                                    >
                                      Preview File
                                    </UncontrolledTooltip>
                                    &emsp;
                                    <Button
                                      size="sm"
                                      type="button"
                                      id="pr"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.onChangePreview(
                                          this.state.file.siuk_file
                                        )
                                      }
                                    >
                                      <i className="fa fa-file-pdf-o"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          ) : null}
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                </>
              ) : null}
            </Col>
            <Col
              className="col-lg-6 col-md-6
             col-sm-12"
              xs="12"
            >
                  <Card>
                    <CardBody className=" mx-4">
                    {this.state.data.invoice_type ?
                    (<>
                      <div className="text-center">
                          <span
                              className="btn btn-sm btn-secondary"
                              style={{
                                fontSize: "0.90em",
                                pointerEvents: "none",
                                borderRadius: "45px",
                              }}
                              >
                              <i className="fa fa-tags" />&nbsp;
                              {this.state.data.invoice_type}
                            </span>
                      </div>
                    </>)
                  : null}
                    
                          <FormGroup>
                            {/* {this.state.data.invoice_info == 7 ? (
                              <>
                                <Row>
                                  <Col md={12}>
                                    <FormGroup>
                                      <Input
                                        type="textarea"
                                        value={this.state.data.pic_note}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            ) : null} */}
                            {/* <Row>
                                
                                  <Col className="text-right">
                                    
                                  </Col>
                                </Row> */}
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md={4}>
                                <label
                                  className="form-control-label"
                                  style={{ fontSize: "1.30em" }}
                                >
                                  <strong>
                                  {this.state.data.invoice_info ===12 ?
                                (<>
                                  Total
                                </>
                                  )
                                  :
                                (<>
                                  Amount
                                </>
                                  )
                                }
                                    </strong>
                                </label>
                              </Col>
                              <Col md={8} className="text-right">
                                <div style={{ fontSize: "1.30em" }}>
                                  <strong>{currencyIDR(
                                    this.state.data.invoice_amount
                                  ).replace("IDR", "")}</strong>
                                  {/* {this.state.data.invoice_amount.toUpperCase()} */}
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                          {this.state.data.is_ppn || this.state.data.is_pph ? (<><hr /></>) : null}
                          {this.state.data.is_ppn ? (<>
                          <FormGroup className="ml-4">
                            <Row>
                              <Col md={6}>
                                <Label className="form-control-label my-auto">
                                  PPN
                                </Label>
                              </Col>
                              <Col md={6} className="text-right">
                                {this.state.data.is_ppn ? (
                                  <>
                                  <strong>
                                    {currencyIDR(
                                      this.state.data.ppn_value
                                      ).replace("IDR", "")}
                                      </strong>
                                  </>
                                ) : (
                                  0
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                          </>) : null}
                          {this.state.data.is_pph ? (<>
                          <FormGroup className="ml-4">
                            <Row>
                              <Col md={6}>
                                <Label className="form-control-label my-auto text-muted">
                                  PPH DPP
                                </Label>
                              </Col>
                              <Col md={6} className="text-right">
                                {this.state.data.is_pph ? (
                                  <>
                                  <strong>
                                    {currencyIDR(
                                      this.state.data.tax_pph_dpp
                                      ).replace("IDR", "")}
                                      </strong>
                                  </>
                                ) : (
                                  0
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="ml-4">
                            <Row>
                              <Col md={6}>
                                <Label className="form-control-label my-auto text-muted">
                                  PPH{" "}
                                  {this.state.data.is_pph
                                    ? this.state.data.type_pph + "%"
                                    : null}
                                </Label>
                              </Col>
                              <Col md={6} className="text-right">
                                {this.state.data.is_pph ? (
                                  <>
                                  <strong>
                                    {currencyIDR(
                                      this.state.data.pph_value
                                      ).replace("IDR", "- ")}
                                      </strong>
                                  </>
                                ) : (
                                  0
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                          </>) : null}
                          {this.state.data.is_ppn || this.state.data.is_pph ? (<><hr /></>) : null}
                          {this.state.data.invoice_info ===12 ? null :
                                (<>
                          <FormGroup>
                            <Row>
                              <Col md={6}>
                                <label
                                  className="form-control-label my-auto"
                                  style={{ fontSize: "1.30em" }}
                                >
                                 <strong> Total</strong>
                                </label>
                              </Col>
                              <Col md={6} className="text-right">
                                <div style={{ fontSize: "1.30em" }}>
                                  <strong>
                                    {currencyIDR(
                                      this.state.data.total_invoice
                                    ).replace("IDR", "")}
                                    {/* {currencyIDR(
                                            this.state.data.total_invoice
                                          ).replace(/\.00$/, "")} */}
                                  </strong>
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                          </>)}
                          <FormGroup>
                       <Row>
                              <Col sm={6}>
                                <Label
                                  className="form-control-label my-auto text-muted text-left"
                                  style={{ fontSize: "0.94em" }}
                                >
                                  Currency
                                </Label>
                              </Col>
                              <Col sm={6}>
                                <Label className="my-auto float-right text-muted">
                                  <div
                                    style={{
                                      fontSize: "0.94em",
                                    }}
                                  >
                                    <strong>
                                      { this.state.display.dCurrC}
                                    </strong>
                                  </div>
                                </Label>
                              </Col>
                            </Row>
                      </FormGroup>

                      {
                        // this.state.rSelected === 2 ? (
                        //   <>
                        //     <FormGroup>
                        //       <label className="form-control-label">
                        //         Reason
                        //       </label>
                        //       <Input
                        //         type="select"
                        //         onKeyPress={this.keyPressed}
                        //         name="decline"
                        //         value={this.state.data.decline}
                        //         onChange={this.handleChangeString}
                        //       >
                        //         <option value={"0"}>Select</option>
                        //         {declineOption}
                        //       </Input>
                        //     </FormGroup>
                        //     <FormGroup>
                        //       <Row>
                        //         <Col>
                        //           <Label className="form-control-label">
                        //             Note
                        //           </Label>
                        //         </Col>
                        //       </Row>
                        //       <Row>
                        //         <Col>
                        //           <Input
                        //             type="textarea"
                        //             name="note"
                        //             value={this.state.data.note}
                        //             onChange={this.handleChangeString}
                        //             placeholder="note"
                        //             className={
                        //               this.state.paramError.note
                        //                 ? "is-invalid"
                        //                 : ""
                        //             }
                        //           />
                        //           <div className="invalid-feedback">
                        //             {this.state.paramError.note}
                        //           </div>
                        //         </Col>
                        //       </Row>
                        //     </FormGroup>
                        //   </>
                        // ) :
                      }
                    </CardBody>
                  </Card>
                  {/* <Card className=" shadow">
                    <CardBody className="mt-2">
                      <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                        <div className="step completed">
                          <div className="step-icon-wrap">
                            <div className="step-icon">
                              <i
                                className="text-default fa fa-plus"
                                style={{
                                  display: "inline-block",
                                  position: "relative",
                                }}
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <h3 className="step-title mt-1">
                            <b>Add</b>
                          </h3>
                          <small className="text-muted mt--1">
                            {this.state.data.created_on}
                          </small>
                        </div>
                        {this.state.data.updated_at_a !==
                        this.state.data.created_on ? (
                          <div className="step completed">
                            <div className="step-icon-wrap">
                              <div className="step-icon">
                                <span
                                  className="fa-stack fa-lg mt--1"
                                  style={{ fontSize: "0.9em" }}
                                >
                                  <i className="fa fa-check text-default" />
                                </span>
                              </div>
                            </div>
                            <h3 className="step-title mt-1">
                              <b>Verified</b>
                            </h3>
                            <small className=" text-muted mt--1">
                              {this.state.data.updated_at_a}
                            </small>
                          </div>
                        ) : (
                          <div className="step">
                            <div className="step-icon-wrap">
                              <div className="step-icon">
                                <i className="text-default fa fa-clock-o text-muted"></i>
                              </div>
                            </div>
                            <h3 className="step-title mt-1">
                              <b className="text-muted">Verification </b>
                            </h3>
                          </div>
                        )}
                        {this.state.data.updated_at_b !==
                        this.state.data.created_on ? (
                          <>
                            {this.state.data.invoice_status == 2 ? (
                              <>
                                {this.state.data.invoice_info == 12 ? (
                                  <>
                                    <div className="step completed">
                                      <div className="step-icon-wrap">
                                        <div className="step-icon">
                                          <span
                                            className="fa-stack fa-lg mt--1"
                                            style={{ fontSize: "0.6em" }}
                                          >
                                            <i className="fa fa-certificate fa-stack-2x text-default "></i>
                                            <i className="fa fa-times fa-stack-1x fa-inverse mx-auto"></i>
                                          </span>
                                        </div>
                                      </div>
                                      <h3 className="step-title mt-1">
                                        <b>Reject</b>
                                      </h3>
                                      <small className=" text-muted mt--1">
                                        {this.state.data.updated_at_a}
                                      </small>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="step completed">
                                      <div className="step-icon-wrap">
                                        <div className="step-icon">
                                          <span
                                            className="fa-stack fa-lg mt--1"
                                            style={{ fontSize: "0.6em" }}
                                          >
                                            <i className="fa fa-certificate fa-stack-2x text-default "></i>
                                            <i className="fa fa-check fa-stack-1x fa-inverse mx-auto"></i>
                                          </span>
                                        </div>
                                      </div>
                                      <h3 className="step-title mt-1">
                                        <b>Approve</b>
                                      </h3>
                                      <small className=" text-muted mt--1">
                                        {this.state.data.updated_at_a}
                                      </small>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="step">
                                  <div className="step-icon-wrap">
                                    <div className="step-icon">
                                      <i className="text-default fa fa-spinner text-muted" />
                                    </div>
                                  </div>
                                  <h4 className="step-title">
                                    <b className="text-muted">Approval</b>
                                  </h4>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <div className="step">
                            <div className="step-icon-wrap">
                              <div className="step-icon">
                                <i className="text-default fa fa-spinner text-muted" />
                              </div>
                            </div>
                            <h4 className="step-title">
                              <b className="text-muted">Approval</b>
                            </h4>
                          </div>
                        )}
                      </div>
                      {this.state.data.note_b ? (
                        <>
                          <hr className="mx-6 my-2 mt--3 mb-4" />
                          <FormGroup className="mx-6 mb-3 ">
                            <Row
                              className="form-control-label"
                              style={{ fontSize: "0.80em" }}
                            >
                              <Col sm={12}>
                                <div style={{ color: "#9DB2BF" }}>
                                  {this.state.data.note_a}
                                </div>
                                <div style={{ color: "#9DB2BF" }}>
                                  {this.state.data.note_b}
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </>
                      ) : null}
                    </CardBody>
                  </Card> */}
                  <Card>
                    <CardBody>
                      <div className="mx-4">
                      <div className="p-3">
                        {this.state.data.ap_verified === 1 ? (
                          <>
                            {this.state.data.invoice_info == 12 ? (
                              <>
                                <div className="d-flex">
                                  <div >
                                    {" "}
                                    <i className="fa fa-times-circle text-danger" />
                                    &nbsp;<b>Reject</b>{" "}
                                  </div>
                                  {/* <div className="ml-1">
                                      <b> {this.state.data.pic_ap}</b>
                                  </div> */}
                                  <div className="ml-auto">
                                    <small>
                                      <b>{this.state.data.updated_at_b}</b>
                                    </small>
                                  </div>
                                </div>
                                { this.state.data.verification ==1 ? 
                                (
                                  <>
                                  <div className="border-left dashed border-muted d-flex ml-2">
                                  <div className="mt-4 mb-2 ml-4 mr-2">
                                  
                                     {this.state.data.note_a ? 
                                    (
                                    <b>{this.state.data.note_a}</b>
                                      ):null}
                                       <p className="text-muted">
                                    {this.state.data.pic_ap_id === config.USER_ID ? "You" : this.state.data.pic_ap}
                                  </p>
                                  </div>
                                </div>
                                </>  
                                ):(
                                  <>
                                <div className="border-left dashed border-muted d-flex ml-2">
                                <div className="mt-4 mb-2 ml-4 mr-2">
                                     {this.state.data.note_b ? 
                                    (
                                    <b>{this.state.data.note_b}</b>
                                      ):null}
                                       <p className="text-muted">
                                    {this.state.data.pic_ap_id === config.USER_ID ? "You": this.state.data.pic_ap}
                                  </p>
                                  </div>
                                </div>
                                </>
                                )
                                }
                              </>
                            ) : (
                              <>
                                {this.state.data.is_receive ==1 && this.state.data.is_receive_date !=="0000-00-00 00:00:00" ? (
                                  <>
                                    <div className="d-flex">
                                      <div className="mr-auto">
                                        {" "}
                                        <i className="fa fa-file-archive-o text-dark" />
                                        &nbsp;
                                        <b className="text-dark">Document received</b>{" "}
                                      </div>
                                      <div>
                                        <small className="text-dark">
                                          <b>{this.state.data.is_receive_date}</b>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="border-left dashed border-muted d-flex ml-2">
                                      <span className="text-muted ml-3 my-3">
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      <div >
                                        {" "}
                                        <i className="fa fa-check-circle text-muted" />
                                        &nbsp;
                                        <b className="text-muted">Approved</b>{" "}
                                      </div>
                                      <div className="ml-1">
                                      {this.state.data.pic_ap}
                                      </div>
                                      <div>
                                        <small className="text-muted ml-auto">
                                          <b>{this.state.data.updated_at_b}</b>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="border-left dashed border-muted d-flex ml-2">
                                      <span className="text-muted ml-3 my-3">
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <i className="fa fa-check-circle text-success" />
                                        &nbsp;
                                        <b>Approved</b>{" "}
                                      </div>
                                      <div className="ml-1">
                                        <b>{this.state.data.pic_ap}</b>
                                      </div>
                                      <div className="ml-auto">
                                        <small>
                                          <b>{this.state.data.updated_at_b}</b>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="border-left dashed border-muted d-flex ml-2">
                                      <span className="text-muted ml-3 my-3">
                                      </span>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.data.tax_verified === 1 ? (
                              <>
                            <div className="d-flex">
                              <div className="mr-auto">
                                {" "}
                                <i className="fa fa-dot-circle-o text-dark" />
                                &nbsp;<b className="text-dark">Approval</b>
                              </div>
                              <div>
                                <small className="text-dark">
                                  <b>On Progress</b>
                                </small>
                              </div>
                            </div>
                            <div className="border-left dashed border-muted d-flex ml-2">
                              <span className="text-muted ml-3 my-3"></span>
                            </div>
                            </>):null}
                          </>
                        )}
                        {this.state.data.tax_verified === 1 ? (
                          <>
                          {this.state.data.verification == 1 ? null :(<>
                            <div className="d-flex">
                              <div>
                                {" "}
                                <i className="fa fa-dot-circle-o text-muted" />
                                &nbsp;<b className="text-muted">
                                  Verified 
                                </b>{" "}
                              </div>
                              {/* <div className="ml-1 text-muted">
                                <b>{this.state.data.pic_tax}</b>
                              </div> */}
                              <div className="ml-auto">
                                <small className="text-muted">
                                  <b>{this.state.data.updated_at_a}</b>
                                </small>
                              </div>
                            </div>
                            {this.state.data.note_a === this.state.data.note_b ? (<>
                              <div className="border-left dashed border-muted d-flex ml-2">
                              <span className="text-muted ml-3 my-3"></span>
                            </div>
                            </>) :(<>
                            <div className="border-left dashed border-muted d-flex ml-2">
                            <div className="mt-4 mb-2 ml-4 mr-2">
                                    <b>
                                     {this.state.data.note_a ? 
                                    (
                                    <b>{this.state.data.note_a}</b>
                                      ):null}
                                    </b>
                                     <p className="text-muted">
                                <b>{this.state.data.pic_tax}</b>
                              </p>
                                  </div>
                                </div>
                            </>)}
                          </>)}  
                          </>
                        ) : (
                          <>
                            <div className="d-flex">
                              <div className="mr-auto">
                                {" "}
                                <i className="fa fa-dot-circle-o text-dark" />
                                &nbsp;<b className="text-dark">Verification</b>
                              </div>
                              <div>
                                <small className="text-dark">
                                  <b>On Progress</b>
                                </small>
                              </div>
                            </div>
                            <div className="border-left dashed border-muted d-flex ml-2">
                              <span className="text-muted ml-3 my-3"></span>
                            </div>
                          </>
                        )}
                         {this.state.data.revision > 0 ? (<div className="d-flex">
                          <div className="mr-auto">
                            {" "}
                            <i className="fa fa-dot-circle-o text-muted" />
                            &nbsp;<b className="text-muted">
                           Revision
                            </b>{" "}
                          </div>
                          <div>
                            <small className="text-muted">
                              <b>{this.state.data.created_on}</b>
                            </small>
                          </div>
                        </div>
                        ) :
                        (<div className="d-flex">
                          <div className="mr-auto">
                            {" "}
                            <i className="fa fa-dot-circle-o text-muted" />
                            &nbsp;<b className="text-muted">
                           Submit
                            </b>{" "}
                          </div>
                          <div>
                            <small className="text-muted">
                              <b>{this.state.data.created_on}</b>
                            </small>
                          </div>
                        </div>
                        )}
                        
                      </div>
                      </div>
                    </CardBody>
                  </Card>
            </Col>
          </Row>

          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default ApInvoiceReview;
