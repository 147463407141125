import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Button,
  InputGroup,
  ButtonGroup,
  FormGroup,
  // UncontrolledTooltip,
  Alert,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import { validateInvoiceRegulerTax } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
import { currencyIDR } from "services/currency";
// import { uuidv5 } from "services/uuidv5";
import { webdatetime } from "services/sysdate";
// var uuid = uuidv5(8);

// const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
// var otp;
// for (let i = 0; i < 8; i++) {
//   const index = Math.floor(Math.random() * characters.length);
//   otp += characters[index];
// }
class ApInvoiceValidationV2 extends PureComponent {
  state = {
    ad: "",
    listCurrencies: [],
    listTypePph: [],
    listTypeInvoice: [],
    listReject: [],
    setPPN: "",
    valPPN: "",
    match_pph: [],
    pros_pph: "",
    custome_pph: "",
    preview: "",
    checked: false,
    checkedPph: false,
    cr: 0,
    checkedReject: false,
    pphRate: "",
    book: 0,
    display: {
      dCurrC: "",
      dCurrCC: "",
    },
    data: {
      pic_id: "",
      id: "",
      rv: "",
      invoice_number: "",
      purchase_order: "",
      good_receipt: "",
      bast: "",
      tax_invoice: "",
      delivery_note: "",
      siuk: "",
      invoice_number_file: "",
      // purchase_order_file: "",
      // good_receipt_file: "",
      // bast_file: "",
      // tax_invoice_file: "",
      // delivery_note_file: "",
      siuk_file: "",
    },
    submit: {
      // user_id: config.USER_ID,
      datetime: webdatetime(),
      type_invoice: "",
      reject: 0,
      note: "",
      ppn: 0,
      ppn_rate: "",
      tax_ppn: "",
      pph: 0,
      type_pph: "",
      pph_dpp: "",
      custome_type_pph: 1,
      tax: "",
      tax_rate: "",
      invoice_total: "",
      curr: "54",
    },
    verification: {
      c_inv: 0,
    },
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoice();

    this.getTypePph();
    this.getTypePpn();
    this.getTypeInvoice();
    this.handleChange = this.handleChange.bind(this);
    // this.handleReject = this.handleReject.bind(this);
    // this.onChangePreview = this.onChangePreview.bind(this);
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListInvoice = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/ap/invoice_verification_standard_ap",
        { id: this.props.match.params.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState(
        {
          data: {
            id: response.data.data[0].id,
            rv: response.data.data[0].rev,
            user_id: config.USER_ID,
            company_name: response.data.data[0].company_name,
            invoice_number: response.data.data[0].invoice,
            invoice_number_file: response.data.data[0].invoice_file,
            purchase_order: response.data.data[0].purchase_order,
            good_receipt: response.data.data[0].good_receipt,
            bast: response.data.data[0].bast,
            tax_invoice: response.data.data[0].tax_invoice,
            delivery_note: response.data.data[0].delivery_note,
            siuk: response.data.data[0].siuk,
            siuk_file: response.data.data[0].siuk_file,
            invoice_amount: response.data.data[0].invoice_amount,
            created_at: response.data.data[0].created_on,
            currency: response.data.data[0].currency,
            curr: response.data.data[0].curr,
            recentNote: response.data.data[0].recent_note,
            status: response.data.data[0].invoice_info,
            pic_tax: response.data.data[0].pic_id,
          },
          submit: {
            ...this.state.submit,
            invoice_total: response.data.data[0].invoice_total,
            type_invoice: response.data.data[0].tp_invoice,
            ppn: response.data.data[0].ppn,
            ppn_rate: response.data.data[0].ppn_rate,
            tax_ppn: response.data.data[0].tax_ppn,
            pph: response.data.data[0].pph,
            type_pph: response.data.data[0].type_pph,
            pph_dpp: response.data.data[0].tax_pph_dpp,
            custome_type_pph: response.data.data[0].set_pph,
            tax: response.data.data[0].tax_pph,
            tax_rate: response.data.data[0].tax_rate,
            // invoice_amount: response.data.data[0].invoice_amount,
          },
          display: {
            ...this.state.display,
            dCurrC: response.data.data[0].currency,
            dCurrCC: response.data.data[0].curr,
          },
        },
        // console.log(this.state.data.curr)
        () =>
          this.state.data.user_id ? this.state.data.user_id : config.USER_ID,
        () => this.getCurrencies(this.state.data.curr)
      );
      // } else {
      //   this.props.history.push("/admin/active-invoice-tax");
      // }
    } catch (error) {
      console.log(error);
      // this.props.history.push("/admin/active-invoice-tax");
    }
  };
  getCurrencies = (data) => {
    axios
      .post(
        config.API_URL + "/ap/ap_currencies",
        { id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              display: {
                ...this.state.display,
                dCurrC: res.data.data[0].code,
                dCurrCC: res.data.data[0].cc,
              },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  curr = (nm, ct, cd) => {
    const cur = Intl.NumberFormat(ct, {
      style: "currency",
      currency: cd,
      maximumFractionDigits: 2,
    }).format(nm);
    return cur;
  };
  getReject = () => {
    axios
      .post(
        config.API_URL + "/ap/list_ap_reject",
        { limit: 0 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listReject: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // getCurrencies = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/ap/currencies",
  //       { id: "id" },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState({
  //             listCurrencies: res.data.data,
  //           });
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  getTypePph = () => {
    axios
      .post(
        config.API_URL + "/ap/ap_type_pph",
        { pph_id: "pph_type_id" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listTypePph: res.data.data,
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };
  getTypePpn = () => {
    axios
      .post(
        config.API_URL + "/ap/ap_type_ppn",
        { pph_id: "pph_type_id" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              setPPN: res.data.data[0].ppn_rate,
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };
  getTypeInvoice = () => {
    axios
      .post(
        config.API_URL + "/ap/ap_list_invoice_type",
        { pph_id: "pph_type_id" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listTypeInvoice: res.data.data,
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };

  handleReject(e) {
    let isCheckedReject = e;
    if (isCheckedReject === 1) {
      this.setState(
        {
          pphRate: "",
          valPPN: "",
          checked: false,
          checkedPph: false,
          cr: 1,
          submit: {
            ...this.state.submit,
            type_pph: "",
            tax: "",
            reject: 1,
            ppn: 0,
            pph: 0,
            tax_ppn: "",
            ppn_rate: "",
            invoice_total: this.state.data.invoice_amount,
          },
          checkedReject: 1,
        },
        () => this.getReject()
      );
    } else {
      this.setState({
        cr: 0,
        submit: {
          ...this.state.submit,
          reject: 0,
          note: "",
        },
        checkedReject: 0,
      });
    }
  }

  handleSubmit = () => {
    this.setState(
      {
        // isLoading: true,
        submit: {
          ...this.state.submit,
        },
        data: {
          id: this.state.data.id,
          invoice_number: this.state.data.invoice_number,
          invoice_amount: this.state.data.invoice_amount,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("id", this.state.data.id);
        formData.append(
          "user_id",
          this.state.data.user_id ? this.state.data.user_id : config.USER_ID
        );
        formData.append("datetime", this.state.submit.datetime);
        // formData.append(
        //   "type_invoice",
        //   this.state.submit.type_invoice.toUpperCase()
        // );
        formData.append("reject", this.state.submit.reject);

        formData.append("tax_ppn", this.state.submit.tax_ppn);
        formData.append("ppn_rate", this.state.submit.ppn_rate);

        var invoice_total = parseInt(this.state.data.invoice_amount);
        if (this.state.submit.reject == 1) {
          formData.append("note", this.state.submit.note);
          formData.append("invoice_total", invoice_total);
        } else {
          formData.append("ppn", this.state.submit.ppn);
          formData.append("pph", 0);
          // formData.append("pph", this.state.submit.pph);
          // formData.append("type_pph", this.state.submit.type_pph);
          // formData.append("custome_type_pph", 1);
          // formData.append("pph_dpp", this.state.submit.pph_dpp);
          // formData.append("tax", this.state.submit.tax);
          // formData.append("tax_rate", this.state.submit.tax_rate);
          formData.append("invoice_total", this.state.submit.invoice_total);
        }
        const paramError = validateInvoiceRegulerTax(
          this.state.submit,
          this.state.verification
        );
        this.setState({ paramError });
        if (Object.keys(paramError).length === 0) {
          for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
          }
          axios
            .post(config.API_URL + "/ap/verification_reguler_ap", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  onChangePreview = (data) => {
    //  var dt =  config.BUCKET_URL + data
    // localStorage.setItem('preview',dt)
    // var userData = localStorage.getItem('preview')

    this.setState({
      preview: data,
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  handleCancel = () => {
    this.props.history.push("/ap/reguler-invoice/");
  };
  handleClose = () => {
    this.setState({ preview: "" });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.handleCancel()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={4000}
        >
          Invoice&nbsp; <b>{`${data.data}`}</b>&nbsp;{`${data.message}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={5000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    localStorage.removeItem("preview");
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangepphdpp = (e) => {
    this.setState({
      ...this.state,
      submit: {
        ...this.state.submit,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleChangeString = (event) => {
    if (this.state.submit.type_pph === "custome_pph") {
      if (event.target.value !== "") {
        var targetValue = Number(event.target.value);
        var taxSub;
        if (targetValue >= 100) {
          taxSub = Number(99);
        } else if (targetValue <= 0) {
          taxSub = Number(0.1);
        } else {
          taxSub = targetValue;
        }
        var invoice = parseInt(this.state.data.invoice_amount);
        var taxRate = (invoice * taxSub) / 100;
        var totalPph = this.state.data.invoice_amount - taxRate;

        // include ppn
        var paramTotal;
        if (this.state.checked === true) {
          let pad = this.state.setPPN;
          const add_ppn = ("." + pad + "").padStart(4, "0");
          var inPPN = this.state.data.invoice_amount * add_ppn;
          paramTotal = this.state.data.invoice_amount + inPPN - taxRate;
        } else {
          paramTotal = totalPph;
        }

        this.setState({
          ...this.state,
          pphRate: taxRate,
          submit: {
            ...this.state.submit,
            [event.target.name]: taxSub,
            tax: taxSub,
            tax_rate: taxRate,
            invoice_total: paramTotal,
          },
        });
      } else {
        if (this.state.checked === true) {
          let pad = this.state.setPPN;
          const add_ppn = ("." + pad + "").padStart(4, "0");
          var addPPN = this.state.data.invoice_amount * add_ppn;
          var total = this.state.data.invoice_amount + addPPN;
          this.setState({
            ...this.state,
            pphRate: "",
            submit: {
              ...this.state.submit,
              custome_type_pph: "",
              tax: "",
              invoice_total: total,
            },
          });
        } else {
          this.setState({
            ...this.state,
            pphRate: "",
            submit: {
              ...this.state.submit,
              custome_type_pph: "",
              tax: "",
              invoice_total: this.state.data.invoice_amount,
            },
          });
        }
      }
    } else if (this.state.submit.type_pph === "custome_pph_dpp") {
      if (event.target.value !== "") {
        var targetValue_a = Number(event.target.value);
        var taxSub_a;
        if (targetValue_a >= 100) {
          taxSub_a = Number(99);
        } else if (targetValue_a <= 0) {
          taxSub_a = Number(0.1);
        } else {
          taxSub_a = targetValue_a;
        }
        var inv = parseInt(this.state.submit.pph_dpp);
        var taxRate_a = (inv * taxSub_a) / 100;
        var totalPph_a = this.state.data.invoice_amount - taxRate_a;
        var paramTotal_a;
        if (this.state.checked === true) {
          let pad = this.state.setPPN;
          const add_ppn = ("." + pad + "").padStart(4, "0");
          var inPPN_a = this.state.data.invoice_amount * add_ppn;
          paramTotal_a = this.state.data.invoice_amount + inPPN_a - taxRate_a;
        } else {
          paramTotal_a = totalPph_a;
        }
        this.setState({
          ...this.state,
          pphRate: taxRate_a,
          submit: {
            ...this.state.submit,
            [event.target.name]: taxSub_a,
            tax: taxSub_a,
            tax_rate: taxRate_a,
            invoice_total: paramTotal_a,
          },
        });
      } else {
        if (this.state.checked === true) {
          let pad = this.state.setPPN;
          const add_ppn = ("." + pad + "").padStart(4, "0");
          var addPPN_a = this.state.data.invoice_amount * add_ppn;
          var total_a = this.state.data.invoice_amount + addPPN_a;
          this.setState({
            ...this.state,
            pphRate: "",
            submit: {
              ...this.state.submit,
              custome_type_pph: "",
              tax: "",
              invoice_total: total_a,
            },
          });
        } else {
          this.setState({
            ...this.state,
            pphRate: "",
            submit: {
              ...this.state.submit,
              custome_type_pph: "",
              tax: "",
              invoice_total: this.state.data.invoice_amount,
            },
          });
        }
      }
    } else {
      this.setState({
        submit: {
          ...this.state.submit,
          [event.target.name]: event.target.value,
        },
      });
    }
  };
  //CHECKED PPN
  handleChangePpn(e) {
    let isChecked = e.target.checked;
    // let addPPN = this.state.data.invoice_amount * this.state.setPPN
    // let inc_ppn = this.state.data.invoice_amount + addPPN
    // const padded = ("." + this.state.setPPN + "").padStart(4, "0");
    // let addPPN = this.state.data.invoice_amount * this.state.setPPN
    var pad = this.state.setPPN;
    const add_ppn = ("." + pad + "").padStart(4, "0");
    var addPPN = this.state.data.invoice_amount * add_ppn;
    if (isChecked === true) {
      this.setState({
        submit: {
          ...this.state.submit,
          ppn: 1,
          invoice_total:
            this.state.submit.pph === 1
              ? this.state.data.invoice_amount +
                addPPN -
                this.state.submit.tax_rate
              : this.state.data.invoice_amount + addPPN,
          tax_ppn: pad,
          ppn_rate: addPPN,
        },
        valPPN: addPPN,
        checked: isChecked,
      });
    } else {
      this.setState({
        submit: {
          ...this.state.submit,
          ppn: 0,
          tax_ppn: "",
          ppn_rate: "",
          invoice_total:
            this.state.submit.pph === 1
              ? this.state.data.invoice_amount - this.state.submit.tax_rate
              : this.state.data.invoice_amount,
        },
        valPPN: "",
        checked: isChecked,
      });
    }
  }
  //CHECKED PPH
  handleChangePph = (event) => {
    // console.log(event.target.name + " | " + event.target.value);

    //add feature manual pph with set invoice base pph, pph percentage, pph rate and invoice total
    if (event.target.value === "custome_pph") {
      if (this.state.checked === true) {
        this.setState({
          submit: {
            ...this.state.submit,
            [event.target.name]: event.target.value,
            tax: "",
            tax_rate: "",
            invoice_total: this.state.data.invoice_amount + this.state.valPPN,
          },
        });
      } else {
        this.setState({
          pphRate: "",
          submit: {
            ...this.state.submit,
            [event.target.name]: event.target.value,
            tax: "",
            invoice_total: "",
          },
        });
      }
    } else if (event.target.value !== "") {
      var ratePph;
      var key = Number(event.target.value);
      this.state.listTypePph
        .filter((poItems) => poItems.pph_type_id === key)
        .map((items) => (ratePph = items.pph_rate));
      var invoice = parseInt(this.state.data.invoice_amount);
      var taxSub = Number(ratePph);
      var taxRate = (invoice * taxSub) / 100;
      var paramTotal;
      if (this.state.checked === true) {
        let addPPN = this.state.data.invoice_amount + this.state.valPPN;
        paramTotal = addPPN - taxRate;
      } else {
        paramTotal = this.state.data.invoice_amount - taxRate;
      }

      this.setState({
        ...this.state,
        pphRate: taxRate,
        submit: {
          ...this.state.submit,
          custome_type_pph: "",
          [event.target.name]: event.target.value,
          tax: taxSub,
          tax_rate: taxRate,
          invoice_total: paramTotal,
        },
      });
    } else {
      if (this.state.checked === true) {
        this.setState({
          ...this.state,
          pphRate: "",
          submit: {
            ...this.state.submit,
            type_pph: "",
            tax: "",
            invoice_total: this.state.data.invoice_amount + this.state.valPPN,
          },
        });
      } else {
        this.setState({
          ...this.state,
          pphRate: "",
          submit: {
            ...this.state.submit,
            type_pph: "",
            tax: "",
            invoice_total: this.state.data.invoice_amount,
          },
        });
      }
    }
  };
  handleChange(e) {
    let isCheckedPph = e.target.checked;
    if (isCheckedPph === true) {
      this.setState({
        submit: {
          ...this.state.submit,
          pph: 1,
          pph_dpp: "",
        },
        checkedPph: isCheckedPph,
      });
    } else {
      // var invoice = parseInt( this.state.data.invoice_amount)
      var del_pph = this.state.data.invoice_amount + this.state.valPPN;
      this.setState({
        submit: {
          ...this.state.submit,
          pph: 0,
          type_pph: "",
          pph_dpp: "",
          custome_type_pph: "",
          invoice_total: del_pph,
        },
        checkedPph: isCheckedPph,
      });
    }
  }
  formatInput() {
    var num = this.state.number;
    this.setState({
      number: parseFloat(num).toFixed(2),
    });
  }

  floas = (value) => {
    var int = parseInt(value);
    const pad = ("." + int + "").padStart(4, "0");
    return parseFloat(pad);
  };
  handleToggle(e) {
    let isChecked = e.target.checked;
    if (isChecked === true) {
      this.setState({
        verification: {
          ...this.state.verification,
          [e.target.name]: 1,
        },
      });
    } else {
      this.setState({
        verification: {
          ...this.state.verification,
          [e.target.name]: 0,
        },
      });
    }
  }

  render() {
    return (
      <>
        <StandardHeader
          name="verification"
          parentName="Invoice"
          link="active-invoice-tax"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="col-lg-12 col-md-12" sm="12" xs="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-4 solid ">
                  <div className="d-flex  align-items-center">
                    <div className="mr-auto">
                      <h3 className="my-0">{this.state.data.company_name}</h3>
                      <p className="my-0">
                        <small>
                          <i>
                            {" " + this.state.data.created_at
                              ? this.state.data.created_at
                              : ""}
                          </i>
                        </small>
                      </p>
                    </div>
                    <div className="text-right d-flex">
                      {this.state.data.rv > 0 ? (
                        <>
                          <div className="mr-4">
                            <h2>
                              <span className="text-primary pull-right">
                                {" R" + this.state.data.rv}
                              </span>
                            </h2>
                          </div>
                        </>
                      ) : null}
                      <div className="mr-4">
                        <Button
                          id="dl"
                          size="sm"
                          type="button"
                          style={{
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(
                              config.BUCKET_URL +
                                this.state.data.invoice_number_file,
                              "_blank"
                            )
                          }
                        >
                          <i className="fa fa-download"></i>
                        </Button>
                      </div>
                      <div>
                        <Button
                          size="sm"
                          type="button"
                          id="pr"
                          style={{
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.onChangePreview(
                              this.state.data.invoice_number_file
                            )
                          }
                        >
                          <i className="fa fa-file-pdf-o"></i>
                        </Button>
                      </div>
                      <div className="ml-auto mt--5 mr--4">
                        <button
                          type="button"
                          className="close"
                          // style={{marginTop: "--100px"}}
                          style={{ fontSize: "1.1em" }}
                          aria-label="Close"
                          onClick={() => this.handleCancel()}
                        >
                          <i className="fa fa-times-circle text-default" />
                        </button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              {/* <fieldset
                style={{
                  border: "1px solid gray",
                  padding: "1.30em",
                  backgroundColor: " #FFFFFF",
                }}
              >
                <legend className="w-auto mb-0"
                   style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                  }}
                >Details</legend> */}
              {
            this.state.data.recentNote ? (<>
          
              {this.state.data.rv == 0 && this.state.data.recentNote && (this.state.data.status === 5 || this.state.data.status === 2 || this.state.data.status === 7)? null : (
                <>
                  {this.state.data.rv > 0 ? (
                    <>
                      <Card>
                        <CardBody className="py--2">
                          <div>
                            <h4 className="mb--2">
                              {this.state.data.recentNote}
                            </h4>
                            <small className="text-muted pt--1">
                              Recent note
                            </small>
                          </div>
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    <>
                      <>
                        <Card>
                          <CardBody className="py--2">
                            <div>
                              <h4 className="mb--1">
                                {this.state.data.recentNote}
                              </h4>
                              <small className="text-muted pt--1">
                                {this.state.data.pic_tax}
                              </small>
                            </div>
                          </CardBody>
                        </Card>
                      </>
                    </>
                  )}
                </>
              )}
              </>) :null
              }
              <Card>
                <CardBody>
                  <FormGroup className="mb-2">
                    <div
                      className="text-muted mx-auto"
                      style={{
                        fontSize: "0.675rem",
                        fontWeight: "510",
                      }}
                    >
                      Invoice number {"  "}
                      {/* <i
                        className="fa fa-download "
                        onClick={() =>
                          window.open(
                            config.BUCKET_URL +
                              this.state.data.invoice_number_file,
                            "_blank"
                          )
                        }
                        style={{
                          fontSize: "0.800rem",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      ></i> */}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-1">
                    <div
                      style={{
                        fontSize: "0.924rem",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.data.invoice_number
                        ? this.state.data.invoice_number
                        : " - "}
                    </div>
                  </FormGroup>
                  <hr className="my-2" />
                  <FormGroup className="mb-2">
                    <div
                      className="text-muted mx-auto"
                      style={{
                        fontSize: "0.675rem",
                        fontWeight: "510",
                      }}
                    >
                      Tax Invoice
                      <i className="text-muted"> Faktur pajak</i>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb--1">
                    <div
                      style={{
                        fontSize: "0.924rem",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.data.tax_invoice
                        ? this.state.data.tax_invoice
                        : " - "}
                    </div>
                  </FormGroup>
                  <hr className="my-2" />
                  <FormGroup className="mb-2">
                    <div
                      className="text-muted mx-auto"
                      style={{
                        fontSize: "0.675rem",
                        fontWeight: "510",
                      }}
                    >
                      Purchase order (PO)
                    </div>
                  </FormGroup>
                  <FormGroup className="mb--1">
                    <div
                      style={{
                        fontSize: "0.924rem",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.data.purchase_order
                        ? this.state.data.purchase_order
                        : " - "}
                    </div>
                  </FormGroup>
                  <hr className="my-2" />
                  <FormGroup className="mb-2">
                    <div
                      className="text-muted mx-auto"
                      style={{
                        fontSize: "0.675rem",
                        fontWeight: "510",
                      }}
                    >
                      Good receipt (GR)
                    </div>
                  </FormGroup>
                  <FormGroup className="mb--1">
                    <div
                      style={{
                        fontSize: "0.924rem",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.data.good_receipt
                        ? this.state.data.good_receipt
                        : " - "}
                    </div>
                  </FormGroup>
                  <hr className="my-2" />
                  <FormGroup className="mb-2">
                    <div
                      className="text-muted mx-auto"
                      style={{
                        fontSize: "0.675rem",
                        fontWeight: "510",
                      }}
                    >
                      Delivery note (DN)
                    </div>
                  </FormGroup>
                  <FormGroup className="mb--1">
                    <div
                      style={{
                        fontSize: "0.924rem",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.data.delivery_note
                        ? this.state.data.delivery_note
                        : " - "}
                    </div>
                  </FormGroup>
                  <hr className="my-2" />
                  <FormGroup className="mb-2">
                    <div
                      className="text-muted mx-auto"
                      style={{
                        fontSize: "0.675rem",
                        fontWeight: "510",
                      }}
                    >
                      Minutes of hand over
                      <i className="text-muted">(BAST)</i>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb--1">
                    <div
                      style={{
                        fontSize: "0.924rem",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.data.bast ? this.state.data.bast : " - "}
                    </div>
                  </FormGroup>

                  <hr className="my-2" />
                  {this.state.data.siuk_file !== "" &&
                  this.state.data.siuk !== "" ? (
                    <>
                      <FormGroup className="mb-2">
                        <div
                          className="text-muted mr-auto"
                          style={{
                            fontSize: "0.675rem",
                            fontWeight: "510",
                          }}
                        >
                          Construction business license{" "}
                          <i
                            className="fa fa-download "
                            onClick={() =>
                              window.open(
                                config.BUCKET_URL + this.state.data.siuk_file,
                                "_blank"
                              )
                            }
                            style={{
                              fontSize: "0.800rem",
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                          ></i>
                        </div>
                      </FormGroup>
                      <FormGroup className="my-1">
                        <Button
                          size="sm"
                          type="button"
                          id="pr"
                          onClick={() =>
                            this.onChangePreview(this.state.data.siuk_file)
                          }
                        >
                          <div
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {"  " + this.state.data.siuk}
                          </div>
                        </Button>
                      </FormGroup>
                      <hr className="my-2" />
                    </>
                  ) : null}
                  {/* </fieldset> */}
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Card>
                <CardBody>
                  {/* ppn */}
                  <FormGroup>
                    <FormGroup>
                      <Row>
                        {/* <Col sm={7}>
                          <FormGroup className="mt--2">
                            {this.state.verification.c_inv ? (
                              <div
                                className="mt-3"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                                onClick={() => {
                                  this.setState({
                                    submit: {
                                      ...this.state.submit,
                                      type_invoice: "",
                                    },
                                  });
                                }}
                              >
                                <span
                                  className="btn btn-sm "
                                  style={{
                                    backgroundColor: "#252B48",
                                    color: "#fff",
                                    border: "none",
                                    pointerEvents: "none",
                                    borderRadius: "45px",
                                    fontSize: "1em",
                                  }}
                                >
                                  <i className="fa fa-tags" />{" "}
                                  {this.state.submit.type_invoice}
                                </span>
                              </div>
                            ) : (
                              <>
                                <InputGroup>
                                  <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            style={{ fontSize: "0.9em", height: "46px",border:"1px solid #D6D9DE",borderTopRightRadius:"0px",borderBottomRightRadius:"0px",marginTop:"1em" }}
                            onClick={() => this.setState({submit:{...this.state.submit,type_invoice:""}})}
                            >
                            <i className="fa fa-times-circle text-default" />
                          </button>
                                  <Input
                                    name="type_invoice"
                                    type="text"
                                    value={this.state.submit.type_invoice}
                                    onChange={this.handleChangeString}
                                    list="data"
                                    placeholder="Invoice type"
                                    style={{
                                      textTransform: "uppercase",
                                      marginTop: "1em",
                                    }}
                                    className={
                                      this.state.paramError.type_invoice
                                        ? "is-invalid"
                                        : ""
                                    }
                                  />
                                  <datalist id="data">
                                    {this.state.listTypeInvoice.map(
                                      (item, key) => (
                                        <option
                                          key={key}
                                          value={item.invoice_type}
                                        />
                                      )
                                    )}
                                  </datalist>
                                  <div className="invalid-feedback">
                                    {this.state.paramError.type_invoice}
                                  </div>
                                </InputGroup>
                              </>
                            )}
                          </FormGroup>
                        </Col> */}

                        <Col sm={12} className="text-center">
                          <ButtonGroup className="mt-2">
                            <Button
                              color="success"
                              style={{
                                fontSize: "0.90em",
                                // pointerEvents: "none",
                                borderTopLeftRadius: "45px",
                                borderBottomLeftRadius: "45px",
                              }}
                              outline
                              size="sm"
                              onClick={() => this.handleReject(0)}
                              active={this.state.cr === 0 ? true : false}
                            >
                              <i className="fa fa-check-circle" /> APPROVE
                            </Button>
                            <Button
                              color="danger"
                              style={{
                                fontSize: "0.90em",
                                // pointerEvents: "none",
                                borderTopRightRadius: "45px",
                                borderBottomRightRadius: "45px",
                              }}
                              outline
                              size="sm"
                              onClick={() => this.handleReject(1)}
                              active={this.state.cr === 1 ? true : false}
                            >
                              <i className="fa fa-times-circle" /> REJECT
                            </Button>
                          </ButtonGroup>
                        </Col>
                        <Col sm={12} className="mt--2">
                          <hr className="mb-0" />
                        </Col>

                        {this.state.checked ||
                        this.state.submit.ppn > 0 ||
                        this.state.submit.pph > 0 ? (
                          <>
                            <Col sm={6}>
                              <Label
                                className="form-control-label my-auto text-left "
                                style={{ fontSize: "1.20em" }}
                              >
                                Amount
                              </Label>
                            </Col>
                            <Col sm={6}>
                              <Label
                                className="my-auto float-right"
                                style={{ fontSize: "1.10em" }}
                              >
                                <div className="mr-3">
                                  <strong>
                                    {currencyIDR(
                                          this.state.data.invoice_amount
                                        ).replace("IDR", "")}
                                  </strong>
                                </div>
                              </Label>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </FormGroup>
                    {this.state.checked ? (
                      <FormGroup className="ml-2">
                        <Row>
                          <Col sm={6}>
                            <Label
                              className="form-control-label my-auto text-left "
                              style={{ fontSize: "0.924rem" }}
                            >
                              PPN{" "}
                              {this.state.submit.ppn
                                ? this.state.submit.tax_ppn + "%"
                                : null}
                            </Label>
                          </Col>
                          <Col sm={6}>
                            <Label className="my-auto float-right">
                              <div className="mr-3">
                                {currencyIDR(this.state.valPPN).replace(
                                      "IDR",
                                      ""
                                    )}
                              </div>
                            </Label>
                          </Col>
                        </Row>
                      </FormGroup>
                    ) : null}
                  </FormGroup>
                  {/* pph*/}
                  {this.state.submit.type_pph ? (
                    <>
                      {/* <FormGroup>
                                        <Row>
                                          <Col sm={6}>
                                            <Label className="form-control-label my-auto text-left  text-muted">
                                              PPH rate
                                            </Label>
                                          </Col>
                                          <Col sm={6}>
                                            {this.state.submit.type_pph !== "" ||
                                            this.state.submit.custome_type_pph !==
                                              "" ? (
                                              <>
                                                <Label className="my-auto float-right text-muted">
                                                  <div className="mr-3">
                                                    <strong>
                                                      {this.state.submit.tax}
                                                      &nbsp;%
                                                    </strong>
                                                  </div>
                                                </Label>
                                              </>
                                            ) : null}
                                          </Col>
                                        </Row>
                                      </FormGroup> */}
                      {this.state.submit.type_pph === "custome_pph_dpp" ? (
                        <FormGroup>
                          <Row>
                            <Col sm={6}>
                              <Label
                                className="form-control-label my-auto text-left ml-2 text-muted"
                                style={{ fontSize: "0.924rem" }}
                              >
                                PPH BASE DPP
                              </Label>
                            </Col>
                            <Col sm={6}>
                              <Label className="my-auto float-right text-muted">
                                <div className="mr-3">
                                  <strong>
                                    {currencyIDR(
                                          this.state.submit.pph_dpp
                                        ).replace("IDR", "")}
                                  </strong>
                                </div>
                              </Label>
                            </Col>
                          </Row>
                        </FormGroup>
                      ) : null}
                      <FormGroup>
                        <Row>
                          <Col sm={6}>
                            <Label
                              className="form-control-label my-auto text-left ml-2 text-muted"
                              style={{ fontSize: "0.924rem" }}
                            >
                              PPH{" "}
                              {this.state.submit.tax
                                ? this.state.submit.type_pph ==
                                  "custome_pph_dpp"
                                  ? this.state.submit.custome_type_pph + "%"
                                  : this.state.submit.tax + "%"
                                : null}
                              {/* {this.state.submit.custome_type_pph + "%"} */}
                            </Label>
                          </Col>
                          <Col sm={6}>
                            <Label className="my-auto float-right text-muted">
                              {this.state.pphRate ? (
                                <>
                                  <div className="mr-3">
                                    <strong>
                                      {currencyIDR(
                                            this.state.pphRate
                                          ).replace("IDR", "")}
                                    </strong>
                                  </div>
                                </>
                              ) : (
                                <div className="mr-3">
                                  <strong>0</strong>
                                </div>
                              )}
                            </Label>
                          </Col>
                        </Row>
                      </FormGroup>
                    </>
                  ) : null}
                  {this.state.checkedReject ||
                  this.state.checked ||
                  this.state.checkedPph ? (
                    <FormGroup>
                      <Row>
                        <Col sm={6}>
                          <Label
                            className="form-control-label my-auto text-left"
                            style={{ fontSize: "1.20em" }}
                          >
                            Total
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Label className="my-auto float-right">
                            <div
                              className="mr-3"
                              style={{
                                fontSize: "1.20em",
                              }}
                            >
                              <strong>
                                {" "}
                                {currencyIDR(
                                      this.state.submit.invoice_total
                                    ).replace("IDR", "")}
                              </strong>
                            </div>
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <Row>
                        <Col sm={6}>
                          <Label
                            className="form-control-label my-auto text-left"
                            style={{ fontSize: "1.20em" }}
                          >
                            Amount
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Label className="my-auto float-right">
                            <div
                              className="mr-3"
                              style={{
                                fontSize: "1.20em",
                              }}
                            >
                              <strong>
                                {" "}
                                {currencyIDR(
                                      this.state.data.invoice_amount
                                    ).replace("IDR", "")}
                              </strong>
                            </div>
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Row className=" mt-2 mb--1">
                      <Col sm={6}>
                        <Label
                          className="form-control-label my-auto text-muted text-left"
                          style={{ fontSize: "0.94em" }}
                        >
                          Currency
                        </Label>
                      </Col>
                      <Col sm={6}>
                        <Label className="my-auto float-right text-muted">
                          <div
                            className="mr-3"
                            style={{
                              fontSize: "0.94em",
                            }}
                          >
                            <strong>
                              {this.state.display.dCurrC
                                ? this.state.display.dCurrC
                                : "IDR"}
                            </strong>
                          </div>
                        </Label>
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>

                  {this.state.submit.reject === 0 ? (
                    <>
                      <FormGroup className="mb-1 mx-4">
                        <Row>
                          <Col>
                            <Label className="form-control-label">PPN</Label>
                          </Col>
                          <Col
                            md={{
                              offset: 6,
                              size: "auto",
                            }}
                          >
                            <label className="custom-toggle custom-toggle-success mr-1">
                              <Input
                                type="checkbox"
                                name="ppn"
                                value={this.state.submit.ppn}
                                onChange={(e) => this.handleChangePpn(e)}
                                checked={this.state.checked}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                            <div className="invalid-feedback">
                              {this.state.paramError.ppn}
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>
                      {/* <FormGroup className="mb-1 mx-4">
                        <Row>
                          <Col>
                            <Label className="form-control-label">PPH</Label>
                          </Col>
                          <Col
                            md={{
                              offset: 6,
                              size: "auto",
                            }}
                          >
                            <label className="custom-toggle custom-toggle-success mr-1">
                              <Input
                                type="checkbox"
                                name="ppn"
                                value={this.state.submit.pph}
                                onChange={this.handleChange}
                                checked={this.state.checkedPph}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                            <div className="invalid-feedback">
                              {this.state.paramError.pph}
                            </div>
                          </Col>
                        </Row>
                      </FormGroup> */}
                      {/* custome  */}
                      {this.state.checkedPph ? (
                        <>
                          <FormGroup className="mb-1 mx-4">
                            <Input
                              type="select"
                              onKeyPress={this.keyPressed}
                              name="type_pph"
                              value={this.state.submit.type_pph}
                              onChange={this.handleChangePph}
                              className={
                                this.state.paramError.type_pph
                                  ? "is-invalid"
                                  : ""
                              }
                            >
                              <option value="">select PPH type</option>
                              {this.state.listTypePph.map((element, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={element.pph_type_id}
                                  >
                                    {element.pph_type}
                                  </option>
                                );
                              })}
                              <option value="custome_pph">Set PPH</option>
                              <option value="custome_pph_dpp">
                                Set PPH DPP
                              </option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.type_pph}
                            </div>
                          </FormGroup>
                          {this.state.submit.type_pph === "custome_pph" ? (
                            <>
                              {" "}
                              <FormGroup className="mb-1 mx-4">
                                <Row>
                                  <Col sm="12">
                                    <Label className="form-control-label">
                                      PPH %
                                    </Label>
                                    <Input
                                      type="number"
                                      onKeyPress={this.keyPressed}
                                      name="custome_type_pph"
                                      onChange={this.handleChangeString}
                                      className={
                                        this.state.paramError.custome_type_pph
                                          ? "is-invalid"
                                          : ""
                                      }
                                      value={this.state.submit.custome_type_pph}
                                      // onBlur={ this.formatInput }
                                    />
                                    <div className="invalid-feedback">
                                      {this.state.paramError.custome_type_pph}
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          ) : null}
                          {this.state.submit.type_pph === "custome_pph_dpp" ? (
                            <>
                              <FormGroup className="mb-1 mx-4">
                                <Row>
                                  <Col sm="12">
                                    <Label className="form-control-label">
                                      PPH DPP
                                    </Label>
                                    <Input
                                      type="text"
                                      onKeyPress={this.keyPressed}
                                      name="pph_dpp"
                                      onChange={this.handleChangepphdpp}
                                      // className={
                                      //   this.state.paramError
                                      //     .custome_type_pph
                                      //     ? "is-invalid"
                                      //     : ""
                                      // }
                                      value={this.state.submit.pph_dpp}
                                      // onBlur={ this.formatInput }
                                    />
                                    {/* <div className="invalid-feedback">
                                            {
                                              this.state.paramError
                                                .custome_type_pph
                                            }
                                          </div> */}
                                  </Col>
                                </Row>
                              </FormGroup>
                              {this.state.submit.pph_dpp ? (
                                <>
                                  <FormGroup className="mb-1 mx-4">
                                    <Row>
                                      <Col sm="12">
                                        <Label className="form-control-label">
                                          PPH %
                                        </Label>
                                        <Input
                                          type="number"
                                          onKeyPress={this.keyPressed}
                                          name="custome_type_pph"
                                          onChange={this.handleChangeString}
                                          className={
                                            this.state.paramError
                                              .custome_type_pph
                                              ? "is-invalid"
                                              : ""
                                          }
                                          value={
                                            this.state.submit.custome_type_pph
                                          }
                                          // onBlur={ this.formatInput }
                                        />
                                        <div className="invalid-feedback">
                                          {
                                            this.state.paramError
                                              .custome_type_pph
                                          }
                                        </div>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <FormGroup className="p-2">
                            <div
                              style={{
                                fontSize: "0.724rem",
                                fontWeight: "600",
                              }}
                            >
                              {this.state.submit.note}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={12}>
                                <Input
                                  // pattern="[0-9]+"
                                  id="idNote"
                                  name="note"
                                  value={this.state.submit.note}
                                  onChange={this.handleChangeString}
                                  type="text"
                                  list="reason"
                                  placeholder="Note"
                                  className={
                                    this.state.paramError.note
                                      ? "is-invalid"
                                      : ""
                                  }
                                />
                                <datalist id="reason">
                                  {this.state.listReject.map((item, key) => (
                                    <option key={key} value={item.reason} />
                                  ))}
                                </datalist>
                                <div className="invalid-feedback">
                                  {this.state.paramError.note}
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </>
                  )}
                  <FormGroup className="mt-1 mx-4">
                    <Row>
                      <Col>
                        <Label className="form-control-label">Verified</Label>
                      </Col>
                      <Col className="text-right">
                        <div className="mr-1">
                          <label className="custom-toggle custom-toggle-success mt-2">
                            <Input
                              type="checkbox"
                              name="c_inv"
                              value={this.state.verification.c_inv}
                              onChange={(e) => this.handleToggle(e)}
                              className={
                                this.state.paramError.c_inv ? "is-invalid" : ""
                              }
                              checked={
                                this.state.verification.c_inv[
                                  this.state.verification
                                ]
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                            <div className="invalid-feedback mt-4">
                              <span
                                style={{
                                  display: "block",
                                  maxWidth: "300px",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {this.state.paramError.c_inv}
                              </span>
                            </div>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                  {this.state.verification.c_inv ==1 ? 
                (
                  <>
                      <Row className="justify-content-md-center">
                        <Col
                          style={{ marginTop: 15, marginBottom: 15 }}
                          className="col-6 col-md-6 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            color="default"
                            className="btn-icon"
                            type="button"
                            onClick={() => this.handleCancel()}
                          >
                            <span className="btn-inner--text">Cancel</span>
                          </Button>
                        </Col>
                        <Col
                          style={{ marginTop: 15, marginBottom: 15 }}
                          className="col-6 col-md-6 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            onClick={() => this.handleSubmit()}
                          >
                            {this.state.isLoading ? (
                              <>
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Submit
                                </span>
                              </>
                            ) : (
                              <span className="btn-inner--text">Submit</span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                  </>
                ) : null 
                }
              <br />
            </Col>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}
export default ApInvoiceValidationV2;
