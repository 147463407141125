import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Container,
  Card,
  CardHeader,
  UncontrolledTooltip,
  CardBody,
  Alert,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
// import { validateSettingVendor } from "services/validate";
import { webdatetime } from "services/sysdate";
// import TaxSlipTable from "../TaxSlipVendor";
import axios from "axios";
const nD = new Date();
const prefDate =
  new Date().getFullYear().toString().substr(-2) +
  "" +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  "" +
  ("0" + nD.getDate()).slice(-2);
class Settings extends PureComponent {
  state = {
    thisShow: "",
    view_profile: false,
    listTaxSlip: [],
    editCompany: false,
    editFiles: false,
    data: {
      company_name: "",
      company_phone: "",
      company_address: "",
      company_siuk: "",
      company_siuk_file: "",
      company_update: "",
    },
    file: {
      fileSiuk: 0,
    },
    isLoading: false,
    isLoadingFile: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.onChangePreview = this.onChangePreview.bind(this);
    this.getListCompany();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleChangeFile = (event) => {
    console.log(event.target.name)
    // console.log(event.target.files[0])
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.files[0],
      },
      file: {
        ...this.state.file,
        fileSiuk: 1,
      },
    });
  };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleSubmit = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("datetime", webdatetime());
        formData.append("company_id", config.COMPANY_ID);
        formData.append("param", this.state.editCompany ? 1 : 0);
        if (this.state.editCompany) {
          formData.append("company_phone", this.state.data.company_phone);
          formData.append("company_address", this.state.data.company_address);
        }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        axios
          .post(config.API_URL + "/vendor/update_vendor_settings", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.getListCompany();
                this.setState({
                  isLoading: false,
                  editCompany: false,
                });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      }
    );
  };

  handleSubmitFiles = () => {
    this.setState(
      {
        isLoadingFile: true,
        file: {
          ...this.state.file,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("datetime", webdatetime());
        formData.append("prefDate", prefDate);
        formData.append("company_id", config.COMPANY_ID);
        formData.append("param", this.state.editFiles ? 2 : 0);
        if (this.state.editFiles) {
          formData.append("siuk_fl", this.state.data.company_siuk_file);
          formData.append("siuk_name", this.state.data.company_siuk);
        }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        
        // for (let i = 0; i < this.state.data.company_siuk_file.length; i++) {
        //       formData.append(`images[${i}]`, this.state.data.company_siuk_file[i])
        //   }
        axios
          .post(config.API_URL + "/vendor/update_vendor_settings_siuk", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.getListCompany();
                this.setState({
                  isLoadingFile: false,
                  editFiles: false,
                });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoadingFile: false });
                }
              }
            }
          );
      }
    );
  };
  getListCompany = () => {
    axios
      .post(
        config.API_URL + "/vendor/list_vendor_settings",
        { company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              data: {
                company_name: res.data.data[0].company_name,
                company_phone: res.data.data[0].company_phone,
                company_address: res.data.data[0].company_address,
                company_siuk: res.data.data[0].siuk,
                company_siuk_file: res.data.data[0].siuk_file,
                company_update: res.data.data[0].updated_at,
              },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ thisShow: fileUploaded });
  };
  handleClose = () => {
    this.setState({ thisShow: "" });
  };

  handleCancel = () => {
    this.setState({
      view_profile: !this.state.view_profile,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleEdit() {
    this.props.history.push("/vendor/settings-edit");
  }
  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRemove = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        company_siuk: "",
        company_siuk_file: "",
      },
      file: {
        ...this.state.file,
        fileSiuk: 1,
      },
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={5000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={8000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  render() {
    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    var ct = this.state.data.company_update;
    var showFileSiuk;

    if (
      this.state.data.company_siuk != "" &&
      this.state.data.company_siuk_file != "" &&
      this.state.editFiles === false
    ) {
      showFileSiuk = (
        <>
          <div className="my-1">
            <UncontrolledTooltip flip delay={0} target={"pr"}>
              Preview File
            </UncontrolledTooltip>
            &emsp;
            <Button
              id="pr"
              size="sm"
              type="button"
              style={{
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() =>
                this.onChangePreview(this.state.data.company_siuk_file)
              }
            >
              <i className="fas fa-file-image "></i>
            </Button>
            <a
              style={{
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() => this.setState({ ...this.state, editFiles: true })}
            >
              <b className="ml-1">{this.state.data.company_siuk}</b>
            </a>
          </div>
        </>
      );
    } else {
      showFileSiuk = (
        <>
          <div className="mx-4 my-1">
            <a
              style={{
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() => this.setState({ ...this.state, editFiles: true })}
            >
              -
            </a>
          </div>
        </>
      );
    }
    return (
      <>
        <Container className="mt-5" style={{ width: "800px" }}>
          {/* <Row className="justify-content-center"> */}
          <Row>
            <Col
              className="p-3 col-xl-12 col-md-12 col-sm-12 col-12"
              // style={{
              //   cursor: "pointer",
              //   objectFit: "cover",
              // }}
              // onClick={() => this.handleEdit()}
            >
              <fieldset
                style={{
                  border: "1px solid grey",
                  padding: "1.30em",
                  backgroundColor: "#FFFFFF",
                  borderRadius: '19px',
                }}
              >
                <legend
                  className="w-auto mb-0"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                  }}
                >
                  <a style={{
  // backgroundColor: '#ffe54c',
  borderRadius: '4px',
  color: '#353130',
  display: 'inline-block',
  padding: '6px 14px',
  textAlign: 'center'
}} >{this.state.data.company_name}</a>
                </legend>
                <FormGroup>
                  <label className="form-control-label text-muted">Phone</label>
                  {this.state.editCompany ? (
                    <>
                      <div className="mx-4">
                        <Input
                          name="company_phone"
                          value={this.state.data.company_phone}
                          type="text"
                          onChange={this.handleChangeString}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mx-4">
                        <a
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() =>
                            this.setState({ ...this.state, editCompany: true })
                          }
                        >
                          {this.state.data.company_phone
                            ? (<><b>{this.state.data.company_phone}</b></>)
                            : "-"}
                        </a>
                      </div>
                    </>
                  )}
                </FormGroup>

                <FormGroup>
                  <label className="form-control-label text-muted">
                    Address
                  </label>
                  {this.state.editCompany ? (
                    <>
                      <div className="mx-4">
                        <Input
                          name="company_address"
                          value={this.state.data.company_address}
                          type="textarea"
                          onChange={this.handleChangeString}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mx-4">
                        <a
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() =>
                            this.setState({ ...this.state, editCompany: true })
                          }
                        >
                          {this.state.data.company_address
                            ? (<><b>{this.state.data.company_address}</b></>)
                            : "-"}
                        </a>
                      </div>
                    </>
                  )}
                </FormGroup>
                {this.state.editCompany ? (
                  <>
                    <FormGroup className="text-center">
                      <Button
                        size="sm"
                        color="default"
                        outline
                        className="text-uppercase"
                        onClick={() =>
                          this.setState({ ...this.state, editCompany: false })
                        }
                        md={6}
                      >
                        <span className="btn-inner--text">Cancel</span>
                      </Button>
                      <Button
                        size="sm"
                        color="success"
                        className="text-uppercase"
                        onClick={() => this.handleSubmit()}
                        md={6}
                        disabled={this.state.isLoading ? true : false}
                      >
                        {this.state.isLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Update
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Update</span>
                        )}
                      </Button>
                    </FormGroup>
                  </>
                ) : null}
              </fieldset>
            </Col>

            <Col className="p-3 col-xl-12 col-md-12 col-sm-12 col-12">
              <fieldset
                style={{
                  border: "1px solid grey",
                  padding: "1.30em",
                  backgroundColor: "#FFFFFF",
                  borderRadius: '19px',
                }}
              >
                <legend
                  className="w-auto mb-0"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",

                  }}
                >
                  <a style={{
  // backgroundColor: '#ffe54c',
  borderRadius: '4px',
  color: '#353130',
  display: 'inline-block',
  padding: '6px 14px',
  textAlign: 'center'
}}>Files</a>
                </legend>
                <Row>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label text-muted">
                        Construction business license<i>(SIUJK)</i>
                      </label>
                      <br />
                      {this.state.editFiles ? (
                        <>
                          {this.state.data.company_siuk &&
                          this.state.data.company_siuk_file ? (
                            <>
                              <Col
                                className="col-lg-9 col-md-9 col-sm-9"
                                xs="6"
                              >
                                <FormGroup>
                                  <Button
                                    id="del"
                                    onClick={() => this.handleRemove()}
                                    // color="dark"
                                    size="sm"
                                    type="button"
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash text-dark"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    flip
                                    delay={0}
                                    target={"del"}
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                  &emsp;
                                  <a>{this.state.data.company_siuk}</a>
                                </FormGroup>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Row>
                                <Col
                                  className="col-lg-12 col-md-12 col-sm-12"
                                  xs="12"
                                >
                                  {/* <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                  >
                                    Files
                                  </label> */}
                              <FormGroup>
                              <Input
                                          type="text"
                                          onKeyPress={this.keyPressed}
                                          name="company_siuk"
                                          placeholder="SIUJK number"
                                          value={this.state.data.company_siuk}
                                          onChange={this.handleChangeString}
                                          required
                                        />
                                        </FormGroup>
                                        <FormGroup>
                                        {this.state.data.company_siuk ? (<> 
                                          <div className="custom-file">
                                          <input
                                            type="file"
                                            name="company_siuk_file"
                                            className="custom-file-input"
                                            onChange={this.handleChangeFile}
                                            id="customFileLang"
                                            required
                                            //  invalid
                                          />
                                          <label className="custom-file-label"></label>
                                        </div>
                                          </>):null  }
                              </FormGroup>
                              </Col>
                                  {this.state.data.company_siuk_file ? (
                                    <>
                                      <FormGroup>
                                        <Button
                                          id="del"
                                          onClick={() => this.handleRemove()}
                                          // color="dark"
                                          size="sm"
                                          type="button"
                                          style={{
                                            cursor: "pointer",
                                            objectFit: "cover",
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash text-dark"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                      </FormGroup>
                                       <FormGroup>
                                        <div className="custom-file">
                                          <input
                                            type="file"
                                            name="company_siuk_file"
                                            className="custom-file-input"
                                            onChange={this.handleChangeFile}
                                            id="customFileLang"
                                            required
                                            //  invalid
                                          />
                                          <label className="custom-file-label"></label>
                                        </div>
                                      </FormGroup>
                                    </>
                                  ):null }
                              
                              </Row>
                            </>
                          )}
                        </>
                      ) : (
                        <>{showFileSiuk}</>
                      )}
                      {this.state.editFiles ? (
                        <>
                        {this.state.data.company_siuk !== "" && this.state.data.company_siuk_file !==""? 
                        (<>
                        
                          <FormGroup className="text-center mt-3">
                            <Button
                              color="default"
                              size="sm"
                              outline
                              className="text-uppercase"
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  editFiles: false,
                                })
                              }
                              md={6}
                            >
                              <span className="btn-inner--text">Cancel</span>
                            </Button>
                            <Button
                              size="sm"
                              color="success"
                              className="text-uppercase"
                              onClick={() => this.handleSubmitFiles()}
                              md={6}
                              disabled={this.state.isLoadingFile ? true : false}
                            >
                              {this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                    Update
                                  </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Update</span>
                              )}
                            </Button>
                          </FormGroup>
                        </>) : ""
                        }
                        </>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>
          <span className="p-3">
            <small className="text-muted">
              <i>Last update : {ct}</i>
            </small>
          </span>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default Settings;
