/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardHeader, Alert, UncontrolledTooltip, Row, Col } from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListUserAdmin = (props) => {
  const info = props.info;
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="user_id"
          columns={[
            {
              dataField: "company_name",
              text: "vendor",
              sort: true,
              formatter:(cell,row)=>{
                return (
                  <a
                  style={{ cursor: "pointer" }}
                  onClick={() => props.vendor(row)}
                >
                  {/* <span
                    className="text-success"
                    style={{ fontSize: "1.50em" }}
                  >
                    ●
                  </span>{" "} */}
                  <b>{cell}</b>
                </a>
                )
              }
            },
            {
              dataField: "username",
              text: "username",
              sort: true,
              formatter: (cell, row) => {
                if (row.is_on == 1) {
                  return (
                    <>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.details(row)}
                        >
                          {/* <span
                            className="text-success"
                            style={{ fontSize: "1.50em" }}
                          >
                            ●
                          </span>{" "} */}
                          <b>{cell}</b>
                        </a>
                    </>
                  );
                } else {
                  return (
                    <>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.details(row)}
                        >
                          {/* <span
                            className="text-danger"
                            style={{ fontSize: "1.50em" }}
                          >
                            ●
                          </span>{" "} */}
                          <b>{cell}</b>
                        </a>
                    </>
                  );
                }
              },
            },
            // {
            //   dataField: "ct_inv",
            //   text: "count invoice",
            //   sort: true,
            // },
            {
              dataField: "email",
              text: "email",
              sort: true,
              formatter: (cell, row) => {
                if (row.is_verified == 1 && row.is_active == 1) {
                  if(row.ct_inv > 0 ){
                    return (
                      <>
                        <div className="col ml--2 text-muted">
                            <i className="fa fa-check-circle" /> <b>{cell}</b>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className="col ml--2">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => props.delete(row)}
                          >
                            <i className="fa fa-check-circle" /> <b>{cell}</b>
                          </a>
                        </div>
                      </>
                    );
                  }
                } else if (row.is_verified == 1 && row.is_active == 0) {
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.delete(row)}
                        >
                          <i className="fa fa-minus-circle" /> {cell}
                        </a>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => props.delete(row)}
                        >
                          <i className="fa fa-times-circle" /> {cell}
                        </a>
                      </div>
                    </>
                  );
                }
              },
            },{
              dataField: "is_verified",
              text: "Verified",
              sort: true,
              headerAlign: "center",
              align: "center",
              formatter:(cell,row)=>{
                if (cell =='1'){
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          //    style={{ cursor: "pointer" }}
                          // onClick={() => props.reset(row)}
                        >
                          <i className="fa fa-check-circle" />  <b>Yes</b>
                        </a>
                      </div>
                    </>
                  ); 
                } else{
                  return (
                    <>
                      <div className="col ml--2">
                        <a
                          //    style={{ cursor: "pointer" }}
                          // onClick={() => props.reset(row)}
                        >
                          <i className="fa fa-minus-circle" /> <b>No</b>
                        </a>
                      </div>
                    </>
                  );
                }
              }
            },
            {
              dataField: "is_reset",
              text: "reset",
              sort: true,
              headerAlign: "center",
              align: "center",
              formatter:(cell,row)=>{
                return (
                  <div className="col ml--2">
                        <a
                             style={{ cursor: "pointer" }}
                          onClick={() => props.reset(row)}
                        >
                            <b>{cell}</b>
                        </a>
                      </div>
                )
              }
            },
            {
              dataField: "updated_at",
              text: "last update",
              sort: true,
              headerAlign: "center",
              align: "center",
            },
            {
              dataField: "created_at",
              text: "created date",
              sort: true,
              headerAlign: "center",
              align: "center",
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                  {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListUserAdmin;
