import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  FormFeedback,
} from "reactstrap";
import config from "services/config";
import { webdatetime } from "services/sysdate";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
// import { validateSettingVendor } from "services/validate";
// import sd from "services/sysdate"
// const dateToTime = date => date.toLocaleString('en-US', {
//   hour: 'numeric',
//   minute: 'numeric'
// });

// const dateString = "2019-05-05T10:39:00Z";
// const userOffset = new Date().getTimezoneOffset()*60*1000;
// const localDate = new Date(dateString);
// const utcDate = new Date(localDate.getTime() + userOffset);

// console.log(`${dateToTime(utcDate)} (${dateToTime(localDate)} Your Time)`);

// const tz = Intl.DateTimeFormat().resolvedOptions()
// const nD = new Date().toLocaleString("en-US", {
//   timeZone: "Asia/Bangkok",
// });

// console.log(nD)
// console.log(tz)
class SettingsUpdate extends PureComponent {
  state = {
    thisShow: "",
    data: {
      date_time: webdatetime(),
      company_id: "",
      company_phone: "",
      company_address: "",
      company_siuk: "",
      company_siuk_file: "",
    },
    file: {
      file1: 0,
    },
    alert: null,
    paramError: {},
    isLoading: false,
  };
  componentDidMount() {
    this.mounted = true;
    this.onChangePreview = this.onChangePreview.bind(this);
    this.getListCompany();
  }
  getListCompany = () => {
    axios
      .post(
        config.API_URL + "/vendor/list_vendor_settings",
        { company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                data: {
                  ...this.state.data,
                  company_name: res.data.data[0].company_name,
                  company_phone: res.data.data[0].company_phone,
                  company_address: res.data.data[0].company_address,
                  company_siuk: res.data.data[0].siuk,
                  company_siuk_file: res.data.data[0].siuk_file,
                  
                }
                // ,
                // file:{
                //   ...this.state.file,
                //   file1: res.data.data[0].siuk_file ? 1 : 0
                // }
              }
              // ,
              // () =>
              //   this.state.data.company_siuk_file
              //     ? this.setState({ file: { file1: true } })
              //     : this.setState({ file: { file1: false } })
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  componentWillUnmount() {
    this.mounted = false;
  }
  onChangePreview = (a) => {
    const fileUploaded = a;
    this.setState({ thisShow: fileUploaded });
  };
  handleClose = () => {
    this.setState({ thisShow: "" });
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangeFile = (event) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [event.target.name]: event.target.files[0],
        },
        file: {
          ...this.state.file,
          file1: 1,
        },
      }
    );
  };

  handleSubmit = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
        },
        file: {
          ...this.state.file,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("datetime", webdatetime());
        formData.append("company_id", config.COMPANY_ID);
        formData.append("company_phone", this.state.data.company_phone);
        formData.append("company_address", this.state.data.company_address);
        formData.append("company_file1", this.state.file.file1);
        formData.append("company_siuk", this.state.data.company_siuk);
        if (this.state.data.company_siuk_file != null) {
          formData.append("company_siuk_file",this.state.data.company_siuk_file);
        } else {
          formData.append("company_siuk_file", null);
        }

        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        // const paramError = validateSettingVendor(
        //   this.state.data,
        //   this.state.file
        // );
        // console.log('paramError', paramError )
        // this.setState({ paramError });
        // if (Object.keys(paramError).length === 0) {
        axios
          .post(config.API_URL + "/vendor/update_vendor_settings", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.setState({
                  isLoading: false,
                });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          )
        // }  else {
        //   this.failedAlert("Validation Errors");
        //   this.setState({
        //     isLoading: false,
        //   });
        // }
      }
    );
  };
  handleCancel() {
    this.props.history.push("/vendor/settings");
  }
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.props.history.push("/vendor/settings")}
          // onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={3000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >

            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  handleRemove = () => {
    this.setState({
      file: {
        file1: 1,
      },
      data: {
        ...this.state.data,
        company_siuk: "",
        company_siuk_file: "",
      },
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  render() {
    return (
      <>
        <Container className="mt-6" fluid>
          <Row className="justify-content-center">
            <Card>
              <CardHeader>
                <h3 className="mb-0 text-center">
                  {this.state.data.company_name}
                </h3>
              </CardHeader>
              <CardBody className="bg-secondary">
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Phone</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="company_phone"
                            value={this.state.data.company_phone}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.phone ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.phone}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Address</label>
                          <Input
                            type="textarea"
                            onKeyPress={this.keyPressed}
                            rows="6"
                            name="company_address"
                            value={this.state.data.company_address}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.address ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.address}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <h4 className="text-muted"> Supporting Document</h4>
                        </FormGroup>
                      </Col>
                      {this.state.file.file1 === 0 ? 
                       (
                        <>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Construction business license{" "}
                            <i className="text-muted">
                              Surat izin usaha kontruksi (SIUJK)
                            </i>
                          </label>
                        </FormGroup>
                      </Col>
                      </>
                      ):
                      (
                        <>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Construction business license{" "}
                            <i className="text-muted">
                              Surat izin usaha kontruksi (SIUJK)
                            </i>
                          </label>
                          {this.state.data.company_siuk_file !=="" && this.state.data.company_siuk !=="" ? null:(<>
                          
                          <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="company_siuk"
                          value={this.state.data.company_siuk}
                            onChange={this.handleChangeString}
                            required
                            // disabled={this.state.data.company_siuk_file ? false : true}
                            className={
                              this.state.paramError.company_siuk
                              ? "is-invalid"
                              : ""
                            }
                            />
                            <div className="invalid-feedback">
                            {this.state.paramError.company_siuk}
                            </div>
                            </>)}
                            </FormGroup>
                            </Col>
                      </>
                      )
                    }

                      {this.state.data.company_siuk_file ? (
                        <>
                         <Col className="col-lg-9 col-md-9 col-sm-9" xs="6">
                            <FormGroup>
                              <Button
                                id="del"
                                onClick={() => this.handleRemove()}
                                // color="dark"
                                size="sm"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                              >
                                <i
                                  className="fa fa-trash text-dark"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              <UncontrolledTooltip
                                flip
                                delay={0}
                                target={"del"}
                              >
                                Delete
                              </UncontrolledTooltip>
                              
                              &emsp; {this.state.data.company_siuk}
                            </FormGroup>
                          </Col>
                          <Col className="col-lg-3 col-md-3 col-sm-3" xs="6">
                            <FormGroup  className="text-right">
                              <Button
                                id="dl"
                                size="sm"
                                type="button"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open(
                                    config.BUCKET_URL +
                                      this.state.data.company_siuk_file,
                                    "_blank"
                                  )
                                }
                              >
                                <i className="fa fa-download"></i>
                              </Button>
                              <UncontrolledTooltip flip delay={0} target={"dl"}>
                                Donwload File
                              </UncontrolledTooltip>
                              <UncontrolledTooltip flip delay={0} target={"pr"}>
                                Preview File
                              </UncontrolledTooltip>
                              &emsp;
                              <Button
                                id="pr"
                                size="sm"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                                onClick={() =>
                                  this.onChangePreview(
                                    this.state.data.company_siuk_file
                                  )
                                }
                              >
                                <i className="fas fa-file-pdf-o"></i>
                              </Button>
                            </FormGroup>
                          </Col>
                         
                        </>
                      ) : (
                        <>
                          <Col
                            className="col-lg-12 col-md-12 col-sm-12"
                            xs="12"
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Files
                            </label>
                            <Form>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  name="company_siuk_file"
                                  className="custom-file-input"
                                  onChange={this.handleChangeFile}
                                  id="customFileLang"
                                  required
                                //  invalid
                                />
                                  <label className="custom-file-label"></label>
                              </div>
                              {this.state.paramError.company_siuk_file && (
                        <small className="text-warning">
                          {this.state.paramError.company_siuk_file}
                        </small>
                      )}
                                   {/* <div className="invalid-feedback">
                        {this.state.paramError.siuk_file}
                      </div> */}
                            </Form>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-3 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          size="sm"
                          block
                          color="link"
                          className="text-uppercase"
                          onClick={() => this.handleCancel()}
                        >
                          <span className="btn-inner--text">Cancel</span>
                        </Button>
                      </Col>
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-3 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          size="sm"
                          className="text-uppercase"
                          onClick={() => this.handleSubmit()}
                          md={6}
                          disabled={this.state.isLoading ? true : false}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Update
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Update</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default SettingsUpdate;
