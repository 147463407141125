/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

// reactstrap components
import {  Card, CardHeader, Alert, Row, Col, Button } from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
      </label>
    </div>
  ),
});

const ListSlipSummary = (props) => {
    const info = props.info;
    function dateFormatter(cell, row){
      if (!cell) {
            return "";
      }
      return moment(cell).format("DD-MM-YYYY  HH:mm");
    };
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="company_id"
          columns={[
            {
              dataField: "que",
              text: "List file",
              headerAlign:"center",
              align:"center",
              sort: true,
            },
            {
              dataField: "files",
              text: "slip",
              sort: true,
              formatter:(cell,row) =>{
                if (cell > 0) {
                  return (
                      <>
                        <a
                          style={{ objectFit:"cover",cursor: "pointer" }}
                          onClick={() => props.detail(row)}
                          className="table-action"
                          >
                        <b>{cell}</b>
                        </a>
                      </>
                    )
                  }  else {
                  return (
                      <>
                        <a
                          // style={{ cursor: "pointer" }}
                          // onClick={() => props.detail(row)}
                          className="table-action text-muted"
                          >
                          {cell}
                        </a>
                      </>
                    )
                }
              }
            },
            {
              dataField: "created_at",
              text: "Recent input date",
              headerAlign: 'center',
              align: "center",
              sort: true,
              formatter: dateFormatter
            },
            {
              dataField: "id",
              text: "Action",
              headerAlign: 'center',
              align: "center",
              sort: true,
              formatter: (cell,row)=>{
                return (<>
                 <Button
                              id="pr"
                              size="sm"
                              type="button"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                 props.view(row)  
                              }
                            >
                              <i className="fa fa-download text-primary" />
                            </Button>
                            &emsp;
                            <Button
                              id="pr"
                              size="sm"
                              type="button"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                props.delete(row)  
                                             }
                            >
                              <i className="fa fa-trash text-danger" />
                            </Button>
                </>)
              }
            },
          ]}
        >
          {(props) => (
            <div>
             <CardHeader>
                  {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No Data</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListSlipSummary;
