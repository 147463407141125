import decode from "jwt-decode";

export const roles = (search) => {
    const token_local = localStorage.getItem("token");
    if(!token_local){
        return false;
    }

    try {
        var token = decode(token_local);
        var token_fills = decode(token.token)
        if(token_fills.role_id === search){
            return true
        }
        
        return false;
    } catch (e){
        return false
    }
}