import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import ListInvoice from "./TabInvoiceAmount";
import SweetAlert from "react-bootstrap-sweetalert";
import { webdatetime } from "services/sysdate";
import { currencyIDR } from "services/currency";
var moment = require("moment");
class MetricInvoiceAmount extends PureComponent {
  state = {
    listInvoiceVendor: [],
    listStatus: [],
    files: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    isLoading: false,
    alert: null,
    show_custome: false,
    listPeriod: [],
    paramError: {},
    actionFilter: {
      user_id: config.USER_ID,
      table: "",
      search: "",
    },
    mini: {
      all: 0,
      process: 0,
      approve: 0,
      reject: 0,
    },
    id: "",
    preview: "",
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getMiniInvoice();
    this.getListInvoiceAdmin();
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  getMiniInvoice = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/amount_invoice_gt",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                mini: {
                  ...this.state.mini,
                  all: res.data.data[0].gt_all,
                  approve: res.data.data[0].gt_approve,
                  process: res.data.data[0].gt_process,
                  reject: res.data.data[0].gt_reject,
                },
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListInvoiceAdmin = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/list_amount_invoice",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceVendor: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          user_id: config.USER_ID,
          invoice: "",
          po: "",
          company: "",
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceAdmin();
            }
          );
        }
      }
    );
  };

  handleConfirmation = (data) => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/admin/confirmation_document_adm",
        { id: data, user: config.USER_ID, local_datetime: webdatetime() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({ isLoading: false });
            this.getListInvoiceAdmin();
            this.successAlert(res.data);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  refresh = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.getListInvoiceAdmin();
      }
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => this.refresh()}
          confirmBtnBsStyle="success"
          disabled={true}
          timeout={3000}
        >
          Documents has been confirmed received
        </SweetAlert>
      ),
    });
  };

  handlePreview = (e) => {
    this.onChangePreview(e.invoice_file);
  };
  onChangePreview = (data) => {
    this.setState({
      preview: data,
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  confirmAlertUser = (data) => {
    console.log(data.id);
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Document confirmation"
          onConfirm={() => this.handleConfirmation(data.id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="primary"
          confirmBtnText="Confirm"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {`${data.company_name}`} <br />
          {`${data.invoice}`}
          <br />
          Document has been received?
        </SweetAlert>
      ),
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => this.getListInvoiceAdmin()
    );
  };
  render() {
    return (
      <>
        <StandardHeader
          name="Invoice Amount"
          parentName="Invoice Amount"
          link="invoice-amount"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Approve invoice Amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {currencyIDR(
                                           this.state.mini.approve
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i class="fa fa-check-circle" aria-hidden="true" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Process Invoice Amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {currencyIDR(
                                             this.state.mini.process
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                    
                        <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>

                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Reject Invoice amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">  
                        {currencyIDR(
                                            this.state.mini.reject
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-stats mb-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        All Invoice Amount
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                      {currencyIDR(
                                              this.state.mini.all
                                            ).replace("IDR", "RP")}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                      <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xl="6" lg="6" md="6">
              <div className="card-wrapper">
                <ListInvoice
                  info={
                    <>
                    <Row>
                      <Col>
                      </Col>
                      <Col>
                        <div>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              id="search"
                              placeholder="Search"
                              result
                              onKeyPress={this.keyPressed}
                              name="search"
                              value={this.state.actionFilter.search}
                              onChange={this.handleChangeString}
                              // style={{ visibility : this.state.isFilterLoading ? 'hidden' : 'visible'}}
                            />
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              // style={{marginTop: "--100px"}}
                              style={{
                                fontSize: "0.9em",
                                height: "31px",
                                border: "1px solid #D6D9DE",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                                boxShadow: "none"
                              }}
                              onClick={() =>
                                this.setState(
                                  {
                                    actionFilter: {
                                      ...this.state.actionFilter,
                                      search: "",
                                    },
                                  },
                                  () => this.getListInvoiceAdmin()
                                )
                              }
                            >
                              <i className="fa fa-times-circle text-default" />
                            </button>
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>
                    </>
                  }
                  list={this.state.listInvoiceVendor}
                  printDoc={this.handlePreview}
                  confirm={this.confirmAlertUser}
                />
              </div>
            </Col>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default MetricInvoiceAmount;
