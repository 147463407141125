import React from "react";
import PropTypes from "prop-types"
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
class HeaderVendor extends React.Component {
  render() {
    return (
      <>
        <div className="header pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                  <h6 className="h2 d-inline-block mb-0 ">
                    {this.props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links"
                  >
                    <BreadcrumbItem>
                      <Link to={"/vendor/" + this.props.link}>
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={"/vendor/" + this.props.link}>
                        {this.props.parentName}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      aria-current="page"
                      className="active"
                    >
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

HeaderVendor.propTypes = {
    name: PropTypes.string,
    parentName: PropTypes.string,
    link: PropTypes.string,
  };
  
export default HeaderVendor;
