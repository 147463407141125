/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  Row,
  Col,
  Button,
} from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const TabInvoiceReceived = (props) => {
  const info = props.info;
  function dateFormatter(cell) {
    if (!cell) {
      return "";
    }
    return moment(cell).format("DD-MM-YYYY");
  }
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          striped
          hover
          condensed
          columns={[
            {
              dataField: "company_name",
              text: "Vendor",
              sort: true,
              formatter: (cell,row) => {
                return (
                  <>
                  
                    <a
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                        onClick={() => props.confirm(row.id)}
                        >
                  <b><span className="fa-lg  text-default">
                            <i className="fa fa-arrow-circle-right" />
                          </span>{" "}{cell}</b>
                        </a>
                  </>
                );
              },
            },
            {
              dataField: "sap_number",
              text: "Sap number",
              sort: true,
              formatter: (cell,row) => {
                return (
                  <>
                  
                    <a
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                        onClick={() => props.confirm(row.id)}
                        >
                  <b><span className="fa-lg  text-default">
                            <i className="fa fa-arrow-circle-right" />
                          </span>{" "}{cell}</b>
                        </a>
                  </>
                );
              },
            },
            {
              dataField: "invoice",
              text: "invoice",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                    <Button
                      id={"pr"}
                      size="sm"
                      type="button"
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => props.printDoc(row)}
                    >
                      <i className="fa fa-file-pdf-o"/>{" "}{cell}

                    </Button>
                  </>
                );
              }
            },
              {
                dataField: "purchase_order",
                text: "Purchase order",
                sort: true,
              },
            {
              dataField: "tax_invoice",
              text: "Tax invoice",
              sort: true,
            },
            {
              dataField: "good_receipt",
              text: "Good receipt",
              sort: true,
            },
            {
              dataField: "updated_at",
              text: "Verified date",
              headerAlign:"center",
              align: 'center',
              sort: true,
              formatter: dateFormatter,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row className="mx-0">
                  <Col md={6} sm={6}>
                    <h3>List Received Document</h3>
                  </Col>
                  {info}
                  {/* <p className="text-sm mb-0"> </p> */}
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default TabInvoiceReceived;
