import React, { PureComponent } from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Button,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import { validateSapNumber } from "services/validate";
import config from "services/config";
import axios from "axios";
import { currencyIDR } from "services/currency";
import SweetAlert from "react-bootstrap-sweetalert";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import { MyDocument } from "services/PDF/quotation/document";
import { webdatetime } from "services/sysdate";
const icon_cl = {
  color: "#16a085",
};
class InvoiceRoutingSlip extends PureComponent {
  state = {
    preview: undefined,
    isLoading: false,
    paramError: {},
    data: [],
    doc: {
      pic_id: config.USER_ID,
      invoice_id: "",
      sap_number: "",
    },
    ready: false,
  };
  componentDidMount() {
    this.mounted = true;
    this.getDocument();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getDocument = async () => {
    const response = await axios.post(
      config.API_URL + "/ap/routing_slip_print",
      { invoice: this.props.match.params.conId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    this.setState(
      {
        data: {
          id: response.data.data[0].id,
          invoice_type: response.data.data[0].invoice_type,
          company_name: response.data.data[0].company_name,
          invoice: response.data.data[0].invoice,
          invoice_file: response.data.data[0].invoice_file,
          purchase_order: response.data.data[0].purchase_order,
          good_receipt: response.data.data[0].good_receipt,
          tax_invoice: response.data.data[0].tax_invoice,
          bast: response.data.data[0].bast,
          delivery_note: response.data.data[0].delivery_note,
          // sap:222,
          sap: response.data.data[0].sap_number,

          // purchase_order_file: response.data.data[0].purchase_order_file,
          // good_receipt_file: response.data.data[0].good_receipt_file,
          // bast_file: response.data.data[0].bast_file,
          // tax_invoice_file: response.data.data[0].tax_invoice_file,
          // delivery_note_file: response.data.data[0].delivery_note_file,
          // siuk_file: response.data.data[0].siuk_file,
          // add_file1: response.data.data[0].add_file1,
          // add_file2: response.data.data[0].add_file2,
          // add_file3: response.data.data[0].add_file3,

          // invoice_ver: response.data.data[0].inv_ver,
          // purchase_order_ver: response.data.data[0].po_ver,
          // good_receipt: response.data.data[0].good_receipt,
          // bast: response.data.data[0].bast,
          // tax_invoice: response.data.data[0].tax_invoice,
          // delivery_note: response.data.data[0].delivery_note,
          // siuk: response.data.data[0].siuk,
          ppn: response.data.data[0].ppn,
          pph: response.data.data[0].pph,
          tax_ppn: response.data.data[0].tax_ppn,
          ppn_value: response.data.data[0].ppn_rate,
          tax_pph: response.data.data[0].tax_pph,
          pph_value: response.data.data[0].tax_rate,
          invoice_amount: response.data.data[0].invoice_amount,
          total_invoice: response.data.data[0].total_invoice,
        },
      }
      // ,()=> ppn !=="0" || pph!=="0" || tax !==
    );
  };

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        // THIS IS THE HACK
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  handleChangeString = (event) => {
    this.setState({
      doc: {
        ...this.state.doc,
        [event.target.name]: event.target.value,
        invoice_id: this.state.data.id,
      },
    });
  };
  handleSubmit = () => {
    this.setState(
      {
        isLoading: true,
        doc: {
          ...this.state.doc,
          local_datetime: webdatetime()
        },
      },

      () => {
        const paramError = validateSapNumber(this.state.doc);
        this.setState({ paramError });
        if (Object.keys(paramError).length === 0) {
          axios
            .post(
              config.API_URL + "/ap/post_routing_slip",
              this.state.doc,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert(
            "Validation errors ! SAP document number is required "
          );
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.setState({ alert: null }, () => this.getDocument())
          }
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          cancelBtnBsStyle="danger"
          cancelBtnText="Cancel"
          btnSize=""
          timeout={3000}
        >
         {`${this.state.data.company_name}`} <br />
          {`${this.state.data.invoice}`}<br/>
          <b>SAP number has been successfully added</b>
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={10000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  confirmCancel = () => {
    this.props.history.push("/ap/all-reguler-invoice/1");
  };

  render() {
    const pageStyle = `
    @page {
      size: A4;
      text-align: center;
      margin: 8em 2em 0 4em ;
    }
    @media all {
      .pagebreak {
        display: none;
      }
    }
    @media print {
      .pagebreak {
        page-break-before: always;
      }
      html, body {
        width: 210mm;
        height: 297mm;
      }
    }
  `;
    return (
      <>
        <Container className="mt-4" fluid>
          {this.state.data.sap === "" ? (
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between mx-5">
                  <div className="align-self-center">
                    <span
                      className="btn btn-sm "
                      style={{
                        backgroundColor: "#71797E",
                        color: "#fff",
                        border: "none",
                        pointerEvents: "none",
                        borderRadius: "45px",
                        fontSize: "0.90em",
                      }}
                    >
                      <i className="fa fa-tags" />{" "}
                      {this.state.data.invoice_type}
                    </span>
                  </div>
                  <div className="text-center">
                    <h4>Invoice Routing slip</h4>
                    <h2>{this.state.data.company_name} </h2>
                  </div>
                  <div className="align-self-center">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() => this.confirmCancel()}
                    >
                      <span aria-hidden="true" style={{ fontSize: "2 rem" }}>
                        &times;
                      </span>
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="px-6 col-lg-6 col-md-6 col-sm-12" xs="12">
                    <FormGroup>
                      <u>
                        <h3>Document</h3>
                      </u>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          Invoice
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          <b>{this.state.data.invoice}</b>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          Purchase order
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          <b>{this.state.data.purchase_order}</b>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          Tax Invoice
                          <i className="text-muted"> Faktur pajak</i>
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          <b>{this.state.data.tax_invoice}</b>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          Good receipt (GR)
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          <b>{this.state.data.good_receipt}</b>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          Minutes of handover
                          <i className="text-muted"> BAST</i>
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          <b>{this.state.data.bast}</b>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          Delivery note
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          <b>{this.state.data.delivery_note}</b>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />

                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto ">
                          SAP document number
                        </Col>
                        {this.state.data.sap === "" ? (
                          <>
                            <Col md={6} className="my-auto ">
                              <Input
                                type="text"
                                name="sap_number"
                                value={this.state.doc.sap_number}
                                onChange={this.handleChangeString}
                                placeholder="SAP document number"
                                required
                                className={
                                  this.state.paramError.sap_number
                                    ? "is-invalid"
                                    : ""
                                }
                              ></Input>
                              <div className="invalid-feedback">
                                {this.state.paramError.sap_number}
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col md={6} className="my-auto ">
                              :&nbsp;<b>{this.state.data.sap}</b>
                            </Col>
                          </>
                        )}
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col className="px-6 col-lg-6 col-md-6 col-sm-12" xs="12">
                    <FormGroup>
                      <u>
                        <h3>TAX</h3>
                      </u>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col md={6} className="my-auto ">
                          <label
                            className="form-control-label"
                            style={{ fontSize: "1.10em" }}
                          >
                            Amount invoice
                          </label>
                        </Col>
                        <Col md={6} className="my-auto ">
                          <Row>
                            <Col className="text-left"></Col>
                            <Col className="text-right">
                              <div style={{ fontSize: "1.10em" }}>
                                {currencyIDR(
                                  this.state.data.invoice_amount
                                ).replace("IDR", "RP")}
                                {/* {this.state.data.invoice_amount.toUpperCase()} */}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup className="mx-2">
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          <Label className="form-control-label my-auto text-muted">
                            {this.state.data.ppn ? (
                              <i
                                className="fa fa-check-circle color-green fa-lg"
                                style={icon_cl}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-times-circle fa-lg"
                                aria-hidden="true"
                              ></i>
                            )}
                            &nbsp; PPN
                          </Label>
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          {this.state.data.ppn ? (
                            <>
                              {currencyIDR(this.state.data.ppn_value).replace(
                                "IDR",
                                "RP"
                              )}
                            </>
                          ) : (
                            0
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="mx-2">
                      <Row>
                        <Col md={6} className="my-auto text-left">
                          <Label className="form-control-label my-auto text-muted">
                            {this.state.data.pph ? (
                              <>
                                <i
                                  className="fa fa-check-circle color-green fa-lg"
                                  style={icon_cl}
                                  aria-hidden="true"
                                />
                              </>
                            ) : (
                              <i
                                className="fa fa-times-circle fa-lg"
                                aria-hidden="true"
                              ></i>
                            )}
                            &nbsp; PPH &nbsp;{this.state.data.tax_pph}&nbsp;%
                          </Label>
                        </Col>
                        <Col md={6} className="my-auto text-right">
                          {this.state.data.pph ? (
                            <>
                              {currencyIDR(this.state.data.pph_value).replace(
                                "IDR",
                                "- RP"
                              )}
                            </>
                          ) : (
                            -0
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Row>
                        <Col md={6}>
                          <label
                            className="form-control-label my-auto"
                            style={{ fontSize: "1.30em" }}
                          >
                            Total invoice
                          </label>
                        </Col>
                        <Col md={6} className="text-right">
                          <div style={{ fontSize: "1.30em" }}>
                            <strong>
                              {currencyIDR(
                                this.state.data.total_invoice
                              ).replace("IDR", "RP")}
                              {/* {currencyIDR(
                                            this.state.data.total_invoice
                                          ).replace(/\.00$/, "")} */}
                            </strong>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row></Row>
              </CardBody>
              {this.state.data.company_name ? (
                <CardFooter>
                  <Row className="justify-content-md-center mb-3 text-center">
                    <Col md={3}>
                      {this.state.data.sap === "" ? (
                        <>
                          <Button
                            block
                            className="text-uppercase"
                            color="success"
                            type="button"
                            onClick={() => this.handleSubmit()}
                          >
                            {this.state.isLoading ? (
                              <>
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Submit
                                </span>
                              </>
                            ) : (
                              <span className="btn-inner--text">Submit</span>
                            )}
                          </Button>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </CardFooter>
              ) : null}
            </Card>
          ) : null}
          {this.state.data.sap === "" ? null : (
            <>
              <ComponentToPrint
                data={this.state.data}
                ref={(el) => (this.componentRef = el)}
              />
              <Container style={{ width: 900 }}>
                <Row className="justify-content-md-center mb-3 text-center">
                  <Col className="ml-5">
                    <Button
                      block
                      className="text-uppercase"
                      color="danger"
                      type="button"
                      onClick={() => this.confirmCancel()}
                    >
                      <span id="request_quote" className="btn-inner--text">
                        Back
                      </span>
                    </Button>
                  </Col>

                  <Col className="mr-5">
                    {this.state.data.sap === "" ? null : (
                      <>
                        <div>
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                color="default"
                                className="text-uppercase"
                                id="print-tooltip"
                                block
                              >
                                Print
                              </Button>
                            )}
                            pageStyle={pageStyle}
                            content={() => this.componentRef}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </>
          )}
          {this.state.alert}
        </Container>
      </>
    );
  }
}
class ComponentToPrint extends React.Component {
  render() {
    return (
      <section>
        <div className="text-center">
          <h5 className="text-muted">INVOICE ROUTING SLIP</h5>
          <h2>{this.props.data.company_name} </h2>
        </div>
        <span
          style={{
            borderStyle: " solid double",
            width: "100%",
            display: "block",
            margin: "2em 0 3em 0",
          }}
        />
        <div className="mx-4 mt-5">
          <FormGroup>
            <table style={{ width: "100%", margin: "3em 0 0 0" }}>
              <tbody>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    SAP document number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.sap}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    Invoice number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.invoice}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    Purchase order number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.purchase_order}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    Tax invoice number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.tax_invoice}
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
          </FormGroup>
          </div>
          <FormGroup>
            {/* <table style={{ width: "100%", margin: "1em 0 0 0" }}>
                <tbody>
                  <tr>
                    <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                      Good receipt (GR)
                    </td>
                    <td
                      style={{ margin: "30em 3em 0 0", width: "5%" }}
                      className="text-center"
                    >
                      :
                    </td>
                    <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                      <h3>
                        {" "}
                        {this.props.data.good_receipt ? (
                          <i
                            className="fa fa-check color-green fa-lg"
                            style={icon_cl}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle fa-lg"
                            aria-hidden="true"
                          ></i>
                        )}
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                      Minutes of handover
                      <small>
                        <i className="text-muted"> BAST</i>
                      </small>
                    </td>
                    <td
                      style={{ margin: "30em 3em 0 0", width: "5%" }}
                      className="text-center"
                    >
                      :
                    </td>
                    <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                      <h3>
                        {" "}
                        {this.props.data.bast ? (
                          <i
                            className="fa fa-check color-green fa-lg"
                            style={icon_cl}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle fa-lg"
                            aria-hidden="true"
                          ></i>
                        )}
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                      Delivery Note
                    </td>
                    <td
                      style={{ margin: "30em 3em 0 0", width: "5%" }}
                      className="text-center"
                    >
                      :
                    </td>
                    <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                      <h3>
                        {" "}
                        {this.props.data.delivery_note ? (
                          <i
                            className="fa fa-check color-green fa-lg"
                            style={icon_cl}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle fa-lg"
                            aria-hidden="true"
                          ></i>
                        )}
                      </h3>
                    </td>
                  </tr>
                  {this.props.data.siuk_file ? (
                    <tr>
                      <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                        Surat izin usaha kontruksi (SIUK)
                      </td>
                      <td
                        style={{ margin: "30em 3em 0 0", width: "5%" }}
                        className="text-center"
                      >
                        :
                      </td>
                      <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                        <h3>
                          {" "}
                          {this.props.data.delivery_note ? (
                            <i
                              className="fa fa-check color-green fa-lg"
                              style={icon_cl}
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-times-circle fa-lg"
                              aria-hidden="true"
                            ></i>
                          )}
                        </h3>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table> */}
            <span
              style={{
                borderStyle: "solid",
                borderWidth: "2px",
                display: "block",
                margin: "3em 0 3em 0",
              }}
            />
          </FormGroup>
          <div className="mx-4 mt-5">
          <table style={{ width: "100%", margin: "3em 0 0 0" }}>
            <tbody>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  Type invoice
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3
                    className="text-right"
                    style={{
                      fontSize: "1.30em",
                    }}
                  >
                    {this.props.data.invoice_type}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  Amount invoice
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3 className="text-right">
                    {currencyIDR(this.props.data.invoice_amount).replace(
                      "IDR",
                      "RP"
                    )}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 30em", width: "35%" }}>PPN</td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3 className="text-right">
                    {this.props.data.ppn === 1 ? (
                      <>
                        {currencyIDR(this.props.data.ppn_value).replace(
                          "IDR",
                          ""
                        )}
                      </>
                    ) : (
                      0
                    )}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  PPH {this.props.data.tax_pph}&nbsp;%
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3 className="text-right">
                    {this.props.data.pph == 1 ? (
                      <>
                        {currencyIDR(this.props.data.pph_value).replace(
                          "IDR",
                          "- "
                        )}
                      </>
                    ) : (
                      -0
                    )}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  Total invoice
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3
                    className="text-right"
                    style={{
                      fontSize: "1.30em",
                    }}
                  >
                    {currencyIDR(this.props.data.total_invoice).replace(
                      "IDR",
                      "RP"
                    )}
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      </section>
    );
  }
}

ComponentToPrint.propTypes = {
  data: PropTypes.array,
};

export default InvoiceRoutingSlip;
