/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import { Card, CardHeader, Alert, Button, Row, Col } from "reactstrap";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
      </label>
    </div>
  ),
});
const ForwarderVendorListData = (props) => {
  const info = props.info;
  var btnPurple = {
    backgroundColor: "#8965e0",
    color: "#fff",
    border: "none",
  };
  function dateFormatter(cell, row) {
    if (!cell) {
      return "";
    }
    return (<b>{moment(cell).format("DD-MM-Y")}</b>)
  }
  function handleStatus(cell, row) {
  
    if (cell === 5 || cell === 2 || cell === 7) {
      return (
        <>
          <div className="text-center">
            <Button
              size="sm"
              style={btnPurple}
              onClick={() => props.actionStatus(row)}
            >
              {/* <i
                className="fa fa-arrow-circle-right fa-lg"
                aria-hidden="true"
              ></i> */}
              {"  "}ON PROGRESS
            </Button>
          </div>
        </>
      );
    }

  
  }
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "created_at",
              text: "invoice date",
              sort: true,
              formatter: dateFormatter,
            },
            {
              dataField: "company_name",
              text: "VENDOR",
              sort: true,
              formatter: (cell,row) =>{
                return (
                  <a
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                  onClick={() => props.details(row)}
                >
                  <u><b >{cell}</b></u>
                </a>)
              }
            },
            {
              dataField: "purchase_order",
              text: "PO",
              sort: true,
              formatter: (cell,row) =>{
                return (<b>{cell}</b>)
              }
            },
            {
              dataField: "good_receipt",
              text: "GR",
              sort: true,
              formatter: (cell,row) =>{
                return (<b>{cell}</b>)
              }
            },
           
            {
              dataField: "invoice",
              text: "Invoice",
              sort: true,
              formatter: (cell, row) => {
                return (
                  <>
                      <b>{cell}</b>
                  </>
                );
              },
            },
            {
              dataField: "status_inv",
              text: "status",
              headerAlign: "center",
              sort: true,
              align: "center",
              formatter: (cell,row) =>{
             
                if (cell == 5 || cell == 2 || cell == 7){
                  return (
                    <>
                    <b className="text-primary">On Process</b>
                    </>)
                }
                if (cell == 12){
                  return (
                    <>
                    <b className="text-danger">Reject</b>
                    </>)
                }
                if (cell == 8 || cell == 9 || cell == 10 || cell == 11){
                  return (
                    <>
                    <b className="text-success">Approve</b>
                    </>)
                }
              }
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                  {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data record</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ForwarderVendorListData;
