import React from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  UncontrolledTooltip,
  Input,
  Label,
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateCompany, validateUser } from "services/validate";
// import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

//services
import config from "services/config";

//packages
import axios from "axios";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// import { getDataCompanies } from "./Redux/companyActions";
// import ListUsers from "./Table/ListVendorUserAdmin";
import { webdatetime } from "services/sysdate";
import { currencyIDR } from "services/currency";

class AdminInvoiceDetail extends React.Component {
  state = {
    thisShow: "",
    preview: undefined,
    listTrackInvoice: [],
    data: {
      company_name: "",
      company_phone: "",
      company_address: "",
    },
    actionListCompanies: {
      page: 1,
      items_per_page: 10,
      order_by_field: "company_id",
      order_by_direction: "DESC",
      company_name: "",
    },
    dataUser: {
      username: "",
      password: "",
      email: "",
      role_id: 6,
      is_active: 1,
    },
    dataUpdateUser: {
      username: "",
      email: "",
      role_id: 6,
      is_active: 0,
      password: "",
      company_id: "",
    },
    file: {
      invoice_number_file: "",
      purchase_order_file: "",
      good_receipt_file: "",
      bast_file: "",
      tax_invoice_file: "",
      delivery_note_file: "",
      siuk_file: "",
      add_file1: "",
      add_file2: "",
      add_file3: "",
    },
    showAdd: "",
    showEdit: "",
    passwordShow: false,
    showEditVendor: false,
    updateUser: false,
    listCompanies: [],
    listUsers: [],
    isLoading: false,
    alert: null,
    paramErrorCompany: {},
    paramErrorUser: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/admin/invoice_detail_adm",
        { id: this.props.match.params.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        data: {
          invoice_id: response.data.data[0].invoice_id,
          user_invoice: response.data.data[0].user_invoice,
          company_name: response.data.data[0].company_name,
          created_on: response.data.data[0].created_at,

          invoice_number: response.data.data[0].invoice,
          purchase_order: response.data.data[0].purchase_order,
          tax_invoice: response.data.data[0].tax_invoice,
          good_receipt: response.data.data[0].good_receipt,
          delivery_note: response.data.data[0].delivery_note,
          bast: response.data.data[0].bast,

          siuk: response.data.data[0].siuk,
          add1: response.data.data[0].add1,
          add2: response.data.data[0].add2,
          add3: response.data.data[0].add3,

          invoice_type: response.data.data[0].invoice_type,
          // invoice_info: 12,
          invoice_info: response.data.data[0].invoice_info,
          invoice_status: response.data.data[0].invoice_status,
          invoice_amount: response.data.data[0].invoice_amount,

          pic: response.data.data[0].username,
          ver: response.data.data[0].tax_verified,

          pic_verified_date: response.data.data[0].tax_date,

          pic_note: response.data.data[0].tax_note,

          is_ppn: response.data.data[0].ppn,
          ppn: response.data.data[0].tax_ppn,
          ppn_value: response.data.data[0].ppn_rate,
          is_pph: response.data.data[0].pph,
          type_pph: response.data.data[0].tax_pph,
          pph_value: response.data.data[0].tax_rate,
          total_invoice: response.data.data[0].total_invoice,

          ap_id: response.data.data[0].ap_id,
          ap_ver: response.data.data[0].ap_ver,
          ap_date: response.data.data[0].ap_date,
          ap_note: response.data.data[0].ap_note,
          sap_number: response.data.data[0].ap_sap,
          sap_number_at: response.data.data[0].ap_sap_at,

          is_retrieved: response.data.data[0].is_retrieved,
          input_at: response.data.data[0].input_at,
        },
        file: {
          invoice_number_file: response.data.data[0].invoice_file,
          siuk_file: response.data.data[0].siuk_file,
          add_file1: response.data.data[0].add_file1,
          add_file2: response.data.data[0].add_file2,
          add_file3: response.data.data[0].add_file3,
        },
        files: {
          fa: response.data.data[0].invoice_file,
          fb: response.data.data[0].siuk_file,
          f1: response.data.data[0].add_file1,
          f2: response.data.data[0].add_file2,
          f3: response.data.data[0].add_file3,
          // response.data.data[0].purchase_order_file,
          // response.data.data[0].tax_invoice_file,
          // response.data.data[0].good_receipt_file,
          // response.data.data[0].bast_file,
          // response.data.data[0].delivery_note_file,
        },
      });
    } catch (err) {
      console.log(err);
    }
    // this.togglePassword = this.togglePassword.bind(this);
    this.getListTrackInvoice();
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  getListTrackInvoice = async () => {
    await axios
      .post(
        config.API_URL + "/admin/invoice_tracking_adm",
        { id: this.props.match.params.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState({
            ...this.state,
            listTrackInvoice: res.data.data,
          });
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/users/admin_list_vendor_user",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  // getDataListCompanies = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/companies",
  //       this.state.actionListCompanies,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               listCompanies: res.data.data,
  //             },
  //             () => {
  //               this.props.dispatch(getDataCompanies(this.state.listCompanies));
  //             }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  handleAdd = () => {
    this.setState({
      showAdd: true,
    });
    // axios
    //   .post(
    //     config.API_URL + "/companies/delete_company",
    //     { company_id: id },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: config.TOKEN,
    //       },
    //     }
    //   )
    //   .then(
    //     () => {
    //       this.confirmedAlert();
    //       this.getDataListCompanies();
    //     },
    //     (err) => {
    //       console.log("error: ", err);
    //     }
    //   );
  };
  // remove = (id) => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/delete_company",
  //       { company_id: id },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       () => {
  //         this.confirmedAlert();
  //         // this.getDataListCompanies();
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };

  putDataToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorCompany = validateCompany(this.state.data);
    this.setState({ paramErrorCompany });
    if (Object.keys(paramErrorCompany).length === 0) {
      axios
        .post(
          config.API_URL + "/companies/admin_update_company",
          this.state.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  // user crud
  // create
  handleSubmitUser = () => {
    this.setState(
      {
        isLoading: true,
        dataUser: {
          ...this.state.dataUser,
          company_id: this.props.match.params.companyId,
        },
      },
      () => {
        const paramErrorUser = validateUser(this.state.dataUser);
        this.setState({ paramErrorUser });
        if (Object.keys(paramErrorUser).length === 0) {
          // console.log(this.state.dataUser)
          axios
            .post(
              config.API_URL + "/users/admin_create_user",
              this.state.dataUser,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataUser: {
                      ...this.state.dataUser,
                      username: "",
                      email: "",
                      password: "",
                      // role_id: "",
                    },
                  });
                  this.getListUsers();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataUser.username);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  // update
  putDataUserToAPI = () => {
    this.setState({ isLoading: true });
    console.log(this.state.dataUpdateUser);
    const paramErrorUser = validateUser(this.state.dataUpdateUser);
    this.setState({ paramErrorUser });
    if (Object.keys(paramErrorUser).length === 0) {
      axios
        .post(
          config.API_URL + "/users/admin_update_user",
          this.state.dataUpdateUser,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getListUsers();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.dataUpdateUser.username);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  // delete
  removeUser = (data) => {
    axios
      .post(
        config.API_URL + "/users/admin_delete_user",
        { user_id: data.user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertUser();
          this.getListUsers();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
        company_id: this.props.match.params.companyId,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleChangeStringUser = (event) => {
    this.setState({
      dataUser: {
        ...this.state.dataUser,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
      },
    });
  };

  handleChangeStringUpdateUser = (event) => {
    this.setState({
      dataUpdateUser: {
        ...this.state.dataUpdateUser,
        [event.target.name]: event.target.value,
        company_id: this.props.match.params.companyId,
        local_datetime: webdatetime(),
        role_id: 6,
      },
    });
  };

  handleSubmit = () => {
    this.putDataToAPI();
  };

  handletoMainPage = () => {
    this.props.history.push("/admin/received-document");
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlertModal()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={2000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  // confirmAlert = (data) => {
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         warning
  //         style={{ display: "block" }}
  //         title="Are you sure?"
  //         onConfirm={() => this.remove(data.company_id)}
  //         onCancel={() => this.hideAlert()}
  //         showCancel
  //         confirmBtnBsStyle="danger"
  //         confirmBtnText="Yes, delete it!"
  //         cancelBtnBsStyle="secondary"
  //         cancelBtnText="Cancel"
  //         btnSize=""
  //       >
  //         Delete {`${data.company_name}`} vendor and all users, You won't be
  //         able to revert this!
  //       </SweetAlert>
  //     ),
  //   });
  // };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.handletoMainPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Vendor has been deleted.
        </SweetAlert>
      ),
    });
  };

  confirmAlertUser = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeUser(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.username}`} user, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlertUser = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={3000}
        >
          User has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleUpdateUser = (data) => {
    this.setState({
      dataUpdateUser: {
        user_id: data.user_id,
        username: data.username,
        email: data.email,
        company_id: data.company_id,
        role_id: data.role_id,
        is_active: data.is_active,
        is_verified: data.is_verified,
      },
      showEdit: true,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  hideAlertModal = () => {
    this.setState({
      showAdd: false,
      showEdit: false,
      showEditVendor: false,
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleSubmitUpdateUser = () => {
    this.putDataUserToAPI();
  };

  hideModal = () => {
    this.setState({
      showAdd: false,
      showEdit: false,
      dataUser: {
        username: "",
        password: "",
        email: "",
        role_id: 6,
        is_active: 1,
      },
      dataUpdateUser: {
        username: "",
        email: "",
        role_id: 6,
        is_active: 0,
      },
    });
  };
  togglePassword = () => {
    this.setState({
      ...this.state,
      passwordShow: !this.state.passwordShow,
    });
  };

  onChangePreview = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="link"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={config.BUCKET_URL + data}
            type="application/pdf"
          >
            {" "}
            <button type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </object>
        </SweetAlert>
      ),
    });
  };

  directRouting = () => {
    this.props.history.push(
      "/admin/invoice-routing-slip-admin/" + this.props.match.params.id
    );
  };
  render() {
    var list = this.state.listTrackInvoice.map((ids, index) => {
        if (ids.status_id == 10) {
          return (
            <>
              <div className="d-flex text-muted" key={index}>
                <div className="mr-auto">
                  {" "}
                  <i className="fa fa-dot-circle-o" />
                  &nbsp;
                  <small className="ml-2 text-muted">
                  <b>{ids.description}</b>
                  </small>
                  <br />
                  <small className="border-left dashed border-muted ml-2">
                    <b className="ml-3">{ids.created_at}</b>
                  </small>
                </div>
                <div>
                  <small>
                    <b>{ids.username}</b>
                  </small>
                </div>
              </div>
              <div className="border-left dashed border-muted d-flex ml-2">
                          <span className="text-muted ml-3 my-3"></span>
                        </div>
            </>
          );
        }
        if (ids.status_id == 9) {
          return (
            <>
              <div className="d-flex text-muted" key={index}>
                <div className="mr-auto">
                  {" "}
                  <i className="fa fa-dot-circle-o" />
                  &nbsp;
                  <small className="ml-2 text-muted">
                  <b>{ids.description}</b>
                  </small>
                  <br />
                  <small className="border-left dashed border-muted ml-2">
                    <b className="ml-3">{ids.created_at}</b>
                  </small>
                </div>
                <div>
                  <small>
                    <b>{ids.username}</b>
                  </small>
                </div>
              </div>
              <div className="border-left dashed border-muted d-flex ml-2">
                          <span className="text-muted ml-3 my-3"></span>
                        </div>
            </>
          );
        }
        if (ids.status_id == 9) {
          return (
            <>
              <div className="d-flex text-muted" key={index}>
                <div className="mr-auto">
                  {" "}
                  <i className="fa fa-dot-circle-o" />
                  &nbsp;
                  <small className="ml-2 text-muted">
                  <b>{ids.description}</b>
                  </small>
                  <br />
                  <small className="border-left dashed border-muted ml-2">
                    <b className="ml-3">{ids.created_at}</b>
                  </small>
                </div>
                <div>
                  <small>
                    <b>{ids.username}</b>
                  </small>
                </div>
              </div>
              <div className="border-left dashed border-muted d-flex ml-2">
                          <span className="text-muted ml-3 my-3"></span>
                        </div>
            </>
          );
        }
        if (ids.status_id == 3) {
          return (
            <>
              <div className="d-flex text-muted" key={index}>
                <div className="mr-auto">
                  {" "}
                  <i className="fa fa-dot-circle-o" />
                  &nbsp;
                  <small className="ml-2 text-muted">
                  <b>{ids.description}</b>
                  </small>
                  <br />
                  <small className="border-left dashed border-muted ml-2">
                    <b className="ml-3">{ids.created_at}</b>
                  </small>
                </div>
                <div>
                  <small>
                    <b>{ids.username}</b>
                  </small>
                </div>
              </div>
              <div className="border-left dashed border-muted d-flex ml-2">
                          <span className="text-muted ml-3 my-3"></span>
                        </div>
            </>
          );
        }
        if (ids.status_id == 6) {
          return (
            <>
              <div className="d-flex text-muted" key={index}>
                <div className="mr-auto">
                  {" "}
                  <i className="fa fa-dot-circle-o" />
                  &nbsp;
                  <small className="ml-2 text-muted">
                  <b>{ids.description}</b>
                  </small>
                  <br />
                  <small className="border-left dashed border-muted ml-2">
                    <b className="ml-3">{ids.created_at}</b>
                  </small>
                </div>
                <div>
                  <small>
                    <b>{ids.username}</b>
                  </small>
                </div>
              </div>
              <div className="border-left dashed border-muted d-flex ml-2">
                          <span className="text-muted ml-3 my-3"></span>
                        </div>
            </>
          );
        }
        if (ids.status_id == 1) {
          return (
            <>
              <div className="d-flex text-muted" key={index}>
                <div className="mr-auto">
                  {" "}
                  <i className="fa fa-dot-circle-o" />
                  &nbsp;
                  <small className="ml-2 text-muted">
                  <b>{ids.description}</b>
                  <br />
                    <b className="ml-4">{ids.created_at}</b>
                  </small>
                </div>
                <div>
                  <small>
                    <b>{ids.username}</b>
                  </small>
                </div>
              </div>
            </>
          );
        }
      // }
      return null;
    });
    return (
      <>
        <StandardHeader
          name="detail invoice"
          parentName="Received Document"
          link="received-document"
        />
        <Container className="mt--6" fluid>
          {/* <Modal
            centered
            fullscreen=""
            // size="lg"
            isOpen={this.state.showAdd}
            // toggle={this.handleDetails}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Add user
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.hideModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Username</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="username"
                      placeholder="Username"
                      value={this.state.dataUser.username}
                      required
                      className={
                        this.state.paramErrorUser.username ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="email"
                      placeholder="Email"
                      value={this.state.dataUser.email}
                      required
                      className={
                        this.state.paramErrorUser.email ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.email}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Password</label>
                    <InputGroup>
                      <Input
                        id="password"
                        type={this.state.passwordShow ? "text" : "password"}
                        onKeyPress={this.keyPressed}
                        name="password"
                        placeholder="Password"
                        value={this.state.dataUser.password}
                        required
                        className={
                          this.state.paramErrorUser.password ? "is-invalid" : ""
                        }
                        onChange={this.handleChangeStringUser}
                        disabled={this.state.updateUser ? true : false}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-outline-primary"
                          onClick={this.togglePassword}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {this.state.passwordShow ? (
                            <i className="fa fa-eye" />
                          ) : (
                            <i className="fa fa-eye-slash" />
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.paramErrorUser.password}
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Is Verified</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="is_active"
                      placeholder="Is Active"
                      value={
                        this.state.updateUser
                          ? this.state.dataUpdateUser.is_active
                          : this.state.dataUser.is_active
                      }
                      required
                      onChange={this.handleChangeStringUser}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.is_active}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                color="link"
                data-dismiss="modal"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="btn-icon"
                type="button"
                disabled={this.state.isLoading ? true : false}
                onClick={() => this.handleSubmitUser()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal> */}
          <Card>
            <CardHeader>
              <Row>
                <Col
                  className="col-lg-11 col-md-11 col-sm-11 text-center"
                  xs="12"
                >
                  <h3 className="mb-0">{this.state.data.company_name}</h3>
                </Col>
                <Col className="col-lg-1 col-md-1 col-sm-1 text-right" xs="12">
                  <Button
                    id="dl"
                    size="sm"
                    type="button"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        config.BUCKET_URL + this.state.file.invoice_number_file,
                        "_blank"
                      )
                    }
                  >
                    <i className="fa fa-download"></i>
                  </Button>
                  <UncontrolledTooltip flip delay={0} target={"dl"}>
                    Donwload File
                  </UncontrolledTooltip>
                  <UncontrolledTooltip flip delay={0} target={"pr"}>
                    Preview File
                  </UncontrolledTooltip>
                  &emsp;
                  <Button
                    size="sm"
                    type="button"
                    id="pr"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.onChangePreview(this.state.file.invoice_number_file)
                    }
                  >
                    <i className="fa fa-file-pdf-o"></i>
                  </Button>{" "}
                  &emsp;
                  <button
                    type="button"
                    className="close mt--5 mr--4"
                    // style={{marginTop: "--100px"}}
                    style={{ fontSize: "0.9em" }}
                    aria-label="Close"
                    onClick={() => this.handletoMainPage()}
                  >
                    <i className="fa fa-times-circle text-default" />
                  </button>
                  {/* <a  id="del" style={{cursor: "pointer",objectFit: "cover"}}onClick={() => this.confirmAlert(this.state.data)}><i className="fa fa-times-circle" aria-hidden="true"/></a> */}
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Card className="shadow">
                      <CardHeader>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h3>Detail</h3>
                          </div>
                          <div className="ml-auto text-right"></div>
                        </div>
                      </CardHeader>
                      <CardBody className="bg-white px-4 pt-4">
                        <FormGroup>
                          <Row>
                            <Col className="col-md-4 col-sm-12 col-12 my-auto">
                              Invoice number
                            </Col>
                            <Col className="col-md-7 col-sm-12 col-12 my-auto">
                              :&emsp;<b>{this.state.data.invoice_number}</b>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col className="col-md-4 col-sm-12 col-12 my-auto">
                              Purchase order (PO)
                            </Col>
                            <Col className="col-md-7 col-sm-12 col-12 my-auto">
                              :&emsp;<b>{this.state.data.purchase_order}</b>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col className="col-md-4 col-sm-12 col-12 my-auto">
                              Tax Invoice
                              <i className="text-muted"> Faktur pajak</i>
                            </Col>
                            <Col className="col-md-7 col-sm-12 col-12 my-auto">
                              :&emsp;<b>{this.state.data.tax_invoice}</b>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col className="col-md-4 col-sm-12 col-12 my-auto">
                              Good receipt (GR)
                            </Col>
                            <Col className="col-md-7 col-sm-12 col-12 my-auto">
                              :&emsp;<b>{this.state.data.good_receipt}</b>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col className="col-md-4 col-sm-12 col-12 my-auto">
                              Delivery note
                            </Col>
                            <Col className="col-md-7 col-sm-12 col-12 my-auto">
                              :&emsp;<b>{this.state.data.delivery_note}</b>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col className="col-md-4 col-sm-12 col-12 my-auto">
                              <label className="form-control-label">
                                Minutes of handover
                                <i className="text-muted"> BAST</i>
                              </label>
                            </Col>
                            <Col
                              xs="12"
                              className="col-md-7 col-sm-12 col-12 my-auto"
                            >
                              :&emsp;<b>{this.state.data.bast}</b>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col className="col-md-4 col-sm-12 col-12 my-auto">
                              SAP number
                            </Col>
                            {this.state.data.sap_number ? (
                              <>
                                <Col className="col-md-7 col-sm-12 col-12 my-auto">
                                  :&emsp;
                                  <Button
                                    size="sm"
                                    type="button"
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                    }}
                                    onClick={() => this.directRouting(this.state.data.invoice_id)}
                                  >
                                    <b id="sap_time">
                                      <i className="fa fa-file-pdf-o" />{" "}
                                      {this.state.data.sap_number}
                                    </b>
                                  </Button>

                                </Col>
                                <UncontrolledTooltip placement="right" target="sap_time">
                                  {this.state.data.sap_number_at}
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              <>
                                <Col className="col-md-7 col-sm-12 col-12 my-auto">
                                  :&emsp;
                                </Col>
                              </>
                            )}
                          </Row>
                        </FormGroup>
                        {this.state.file.siuk_file ||
                        this.state.file.add_file1 ||
                        this.state.file.add_file2 ||
                        this.state.file.add_file3 ? (
                          <>
                            {this.state.add ? (
                              <>
                                <div className="text-right mt--3 mb-3">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.onShow(false)}
                                  >
                                    <i className="fa fa-chevron-circle-up" />
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="text-right mt--3 mb-3">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.onShow(true)}
                                  >
                                    <i
                                      className="fa fa-chevron-circle-down"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </a>
                                </div>
                              </>
                            )}
                          </>
                        ) : null}
                      </CardBody>
                    </Card>
                    {this.state.file.siuk_file ||
                    this.state.file.add_file1 ||
                    this.state.file.add_file2 ||
                    this.state.file.add_file3 ? (
                      <>
                        {this.state.add ? (
                          <>
                            <Card>
                              <CardBody>
                                {this.state.file.siuk_file !== "" ? (
                                  <>
                                    <Row>
                                      <Col md="12" className="my-auto">
                                        Construction business license
                                        <br />
                                        <small>
                                          <i className="text-muted">
                                            Surat izin usaha kontruksi (SIUK)
                                          </i>
                                        </small>
                                      </Col>
                                    </Row>
                                    <FormGroup>
                                      <Row>
                                        <Col md="7" xs="12" className="my-auto">
                                          &emsp;{this.state.data.siuk}
                                        </Col>
                                        <Col
                                          md="5"
                                          xs="12"
                                          className="my-auto text-right"
                                        >
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="dl"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              window.open(
                                                config.BUCKET_URL +
                                                  this.state.file.siuk_file,
                                                "_blank"
                                              )
                                            }
                                          >
                                            <i className="fa fa-download"></i>
                                          </Button>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"dl"}
                                          >
                                            Donwload File
                                          </UncontrolledTooltip>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"pr"}
                                          >
                                            Preview File
                                          </UncontrolledTooltip>
                                          &emsp;
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="pr"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.onChangePreview(
                                                this.state.file.siuk_file
                                              )
                                            }
                                          >
                                            <i className="fa fa-file-pdf-o"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  </>
                                ) : null}
                                {this.state.file.add_file1 === "" ||
                                this.state.file.add_file1 === null ? null : (
                                  <>
                                    <Row>
                                      <Col md="12" className="my-auto">
                                        <label className="form-control-label">
                                          Additional 1
                                        </label>
                                      </Col>
                                    </Row>
                                    <FormGroup>
                                      <Row>
                                        <Col md="7" xs="12" className="my-auto">
                                          &emsp;{this.state.data.add1}
                                        </Col>
                                        <Col
                                          md="5"
                                          xs="12"
                                          className="my-auto text-right"
                                        >
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="dl"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              window.open(
                                                config.BUCKET_URL +
                                                  this.state.file.add_file1,
                                                "_blank"
                                              )
                                            }
                                          >
                                            <i className="fa fa-download"></i>
                                          </Button>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"dl"}
                                          >
                                            Donwload File
                                          </UncontrolledTooltip>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"pr"}
                                          >
                                            Preview File
                                          </UncontrolledTooltip>
                                          &emsp;
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="pr"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.onChangePreview(
                                                this.state.file.add_file1
                                              )
                                            }
                                          >
                                            <i className="fa fa-file-pdf-o"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  </>
                                )}
                                {this.state.file.add_file2 === "" ||
                                this.state.file.add_file2 === null ? null : (
                                  <>
                                    <Row>
                                      <Col md="12" className="my-auto">
                                        <label className="form-control-label">
                                          Additional 2
                                        </label>
                                      </Col>
                                    </Row>
                                    <FormGroup>
                                      <Row>
                                        <Col md="7" xs="12" className="my-auto">
                                          &emsp;{this.state.data.add2}
                                        </Col>
                                        <Col
                                          md="5"
                                          xs="12"
                                          className="my-auto text-right"
                                        >
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="dl"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              window.open(
                                                config.BUCKET_URL +
                                                  this.state.file.add_file2,
                                                "_blank"
                                              )
                                            }
                                          >
                                            <i className="fa fa-download"></i>
                                          </Button>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"dl"}
                                          >
                                            Donwload File
                                          </UncontrolledTooltip>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"pr"}
                                          >
                                            Preview File
                                          </UncontrolledTooltip>
                                          &emsp;
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="pr"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.onChangePreview(
                                                this.state.file.add_file2
                                              )
                                            }
                                          >
                                            <i className="fa fa-file-pdf-o"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  </>
                                )}
                                {this.state.file.add_file3 === "" ||
                                this.state.file.add_file3 === null ? null : (
                                  <>
                                    <Row>
                                      <Col md="12" className="my-auto">
                                        <label className="form-control-label">
                                          Additional 3
                                        </label>
                                      </Col>
                                    </Row>
                                    <FormGroup>
                                      <Row>
                                        <Col md="7" xs="12" className="my-auto">
                                          &emsp;{this.state.data.add3}
                                        </Col>
                                        <Col
                                          md="5"
                                          xs="12"
                                          className="my-auto text-right"
                                        >
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="dl"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              window.open(
                                                config.BUCKET_URL +
                                                  this.state.file.add_file3,
                                                "_blank"
                                              )
                                            }
                                          >
                                            <i className="fa fa-download"></i>
                                          </Button>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"dl"}
                                          >
                                            Donwload File
                                          </UncontrolledTooltip>
                                          <UncontrolledTooltip
                                            flip
                                            delay={0}
                                            target={"pr"}
                                          >
                                            Preview File
                                          </UncontrolledTooltip>
                                          &emsp;
                                          <Button
                                            size="sm"
                                            type="button"
                                            id="pr"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.onChangePreview(
                                                this.state.file.add_file3
                                              )
                                            }
                                          >
                                            <i className="fa fa-file-pdf-o"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  </>
                                )}
                              </CardBody>
                            </Card>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Card>
                      <CardBody className=" mx-4">
                        <div className="text-center">
                          <span
                            className="btn btn-sm btn-secondary"
                            style={{
                              fontSize: "0.90em",
                              pointerEvents: "none",
                              // borderRadius: "45px",
                            }}
                          >
                            <i className="fa fa-tags" />
                            &nbsp;
                            {this.state.data.invoice_type}
                          </span>
                        </div>
                        <hr className="mt-4" />
                        {this.state.data.pic && this.state.data.ver ? (
                          <>
                            <FormGroup>
                              {this.state.data.invoice_info == 7 ? (
                                <>
                                  <Row>
                                    <Col md={12}>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          value={this.state.data.pic_note}
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </>
                              ) : null}
                              {/* <Row>
                                
                                  <Col className="text-right">
                                    
                                  </Col>
                                </Row> */}
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <Col md={4}>
                                  <label
                                    className="form-control-label"
                                    style={{ fontSize: "1.30em" }}
                                  >
                                    <strong>Amount invoice</strong>
                                  </label>
                                </Col>
                                <Col md={8} className="text-right">
                                  <div style={{ fontSize: "1.30em" }}>
                                    <strong>
                                      {currencyIDR(
                                        this.state.data.invoice_amount
                                      ).replace("IDR", "RP")}
                                    </strong>
                                    {/* {this.state.data.invoice_amount.toUpperCase()} */}
                                  </div>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className="ml-4">
                              <Row>
                                <Col md={6}>
                                  <Label className="form-control-label my-auto">
                                    PPN
                                  </Label>
                                </Col>
                                <Col md={6} className="text-right">
                                  {this.state.data.is_ppn ? (
                                    <>
                                      {currencyIDR(
                                        this.state.data.ppn_value
                                      ).replace("IDR", "RP")}
                                    </>
                                  ) : (
                                    0
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className="ml-4">
                              <Row>
                                <Col md={6}>
                                  <Label className="form-control-label my-auto text-muted">
                                    PPH{" "}
                                    {this.state.data.is_pph
                                      ? this.state.data.type_pph + "%"
                                      : null}
                                  </Label>
                                </Col>
                                <Col md={6} className="text-right">
                                  {this.state.data.is_pph ? (
                                    <>
                                      {currencyIDR(
                                        this.state.data.pph_value
                                      ).replace("IDR", "- RP")}
                                    </>
                                  ) : (
                                    0
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                            <hr />
                            <FormGroup>
                              <Row>
                                <Col md={6}>
                                  <label
                                    className="form-control-label my-auto"
                                    style={{ fontSize: "1.30em" }}
                                  >
                                    <strong> Total invoice</strong>
                                  </label>
                                </Col>
                                <Col md={6} className="text-right">
                                  <div style={{ fontSize: "1.30em" }}>
                                    <strong>
                                      {currencyIDR(
                                        this.state.data.total_invoice
                                      ).replace("IDR", "RP")}
                                      {/* {currencyIDR(
                                            this.state.data.total_invoice
                                          ).replace(/\.00$/, "")} */}
                                    </strong>
                                  </div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </>
                        ) : null}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <div className="d-flex justify-content-center">
                          <div className="text-center">
                       
                            <i className="fa fa-history" />
                            &nbsp;
                          Tracking
                            </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="mx-6">{list}</div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default AdminInvoiceDetail;
