import decode from "jwt-decode";
require("dotenv").config();
const BASE_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_LOCAL : process.env.REACT_APP_BASE_URL ;
var config = {
  API_URL: BASE_URL,
  BUCKET_URL: process.env.REACT_APP_AWS_BUCKET_URL
}
try {
  const token_local = localStorage.getItem("token");
  const token_ = decode(token_local);
  var config = {
    ...config,
    TOKEN: token_.token,
    USER_ID: token_.user_id,
    USERNAME: token_.username,
    EMAIL: token_.email,
    COMPANY_ID: token_.company_id,
    COMPANY_NAME: token_.company_name,
    // COMPANY_PHONE: token_.company_phone,
    COMPANY_ADDRESS: token_.company_address,
    COMPANY_SIUK: token_.siuk
  };
} catch (e) {
  console.log(e);
}

export default config;
