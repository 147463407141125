import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import moment from "moment";

// reactstrap components
import { Card, CardHeader, Alert, Row, Col } from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
      </label>
    </div>
  ),
});

const ListSettingPPH = (props) => {
  const info = props.info;
  function dateFormatter(cell, row) {
    if (!cell) {
      return "";
    }
    return moment(cell).format("DD-MM-YYYY HH:mm");
  }
  const handleAction = (cell, row) => {
    return (
      <>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => props.edit(row)}
          className="table-action"
        >
          <i class="fa fa-pencil-square-o fa-1x"></i>
        </a>
        &emsp;&emsp;
        <a
          style={{ cursor: "pointer" }}
          onClick={() => props.delete(row)}
          className="table-action"
        >
          <i class="fa fa-trash fa-1x"></i>
        </a>
      </>
    );
  };

  function getPic(cell, row, formatExtraData) {
    // console.log(cell)
    console.log(formatExtraData);

    return formatExtraData;
    // <>
    // moment(formatExtraData).format("D-MM-YYYY  H:mm")[cell]

    // </>
  }

  // const info = props.info;
  // const numtoString = (cell, row) => {
  //    return cell.toString()
  // };
  // const info = props.info;
  // const actionsIsActive = (cell, row) => {
  //   if (cell === 1) return "Yes";
  //   if (cell === 0) return "No";
  // };
  // const actionsAs = (cell, row) => {
  //   if (cell === 1) return "Verification";
  //   if (cell === 0) return "Not Verification";
  // };
  // const actionsFormatter = (cell, row) => {
  //   return (
  //     <>
  //       <a
  //         className="table-action"
  //         id={"tooltip12312"}
  //         style={{ cursor: "pointer" }}
  //         onClick={() => props.details(row)}
  //       >
  //         <i className="fa fa-chevron-right" />
  //       </a>
  //       <UncontrolledTooltip delay={0} target={"tooltip12312"}>
  //         Details
  //       </UncontrolledTooltip>
  //     </>
  //   );
  // };
  function afterSaveCell(oldValue, newValue) {
    console.log("--after save cell--");
    console.log("New Value was apply as");
    console.log(newValue);
    console.log(`and the type is ${typeof newValue}`);
  }
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="pph_type_id"
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell,
          })}
          columns={[
            {
              dataField: "is_active",
              text: "Use",
              sort: true,
              headerAlign:"center",
              align:"center",
              formatter: (cell, row) => {
                if (row.is_active == 1) {
                  return (
                    <>
                      <i className="fa fa-check-circle text-success" />
                    </>
                  );
                } else {
                  return (
                    <>
                      <i className="fa fa-times-circle" />
                    </>
                  );
                }
              },
            },
            {
              dataField: "pph_type",
              text: "Type PPH",
              sort: true,
            },
            {
              dataField: "pph_rate",
              text: "Rate %",
              type: "number",
              sort: true,
            },
            {
              dataField: "updated_at",
              text: "Updated date",
              sort: true,
              formatter: dateFormatter,
            },
            {
              text: "Action",
              sort: true,
              formatter: handleAction,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col className="text-left">
                    <h3 className="mb-0">List PPH</h3>
                  </Col>
                  {info}
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListSettingPPH;
