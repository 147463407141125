import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  ButtonGroup,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  InputGroup
} from "reactstrap";
import config from "services/config";
import ListInvoice from "./ListInvoiceVendorAll";
import StandardHeader from "components/Headers/HeaderVendor";
import { validateFilterPeriod } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
var moment = require("moment");

class VendorInvoiceAll extends PureComponent {
  state = {
    listInvoiceVendorAll: [],
    listInvoiceStatusActivity: [],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    show_custome: false,
    listPeriod: [],
    listStatus: [],
    select_period: "1",
    min_date: "",
    max_date: "",
    actionFilter: {
      user_id: config.USER_ID,
      company_id: config.COMPANY_ID,
      invoice: "",
      status: "1",
      from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
      to_date: moment().add(1, "days").format("YYYY-MM-DD"),
    },
    activity: {
      select_id: "",
    },
    isLoading: false,
    paramError: {},
    alert: null,
    showActivity: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getDateInvoice();
    if (this.props.match.params.tb){
        this.setState({
          actionFilter:{
            ...this.state.actionFilter,
            status : this.props.match.params.tb
          }
        })
    } 
    if (this.props.match.params.tb ===":tb") {
      this.setState({
        actionFilter:{
          ...this.state.actionFilter,
          status : "1"
        }
      })
    }
    this.setState({
      listPeriod: [
        {
          value: "1",
          label: "All",
        },
        {
          value: "2",
          label: "Month",
        },
        {
          value: "3",
          label: "Annual",
        },
        {
          value: "4",
          label: "Set",
        },
      ],
      listStatus: [
        {
          value: "1",
          label: "All",
        },
        {
          value: "2",
          label: "Approve",
        },
        {
          value: "3",
          label: "Reject",
        },
      ],
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  getDateInvoice = async () => {
    const response = await axios.post(
      config.API_URL + "/vendor/list_early_date_invoice",
      {company_id: 4000001060},
      // {company_id: config.COMPANY_ID},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    this.setState({
      ...this.state,
      actionFilter:{
        ...this.state.actionFilter,
        from_date: response.data.data[0].min_date,
        to_date: response.data.data[0].max_date,
      }
    },()=>this.getListInvoiceVendorAll()
    );
  };
  getListInvoiceVendorAll = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    const paramError = validateFilterPeriod(this.state.actionFilter);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      axios
        .post(
          config.API_URL + "/vendor/list_invoice_vendor_all",
          this.state.actionFilter,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.setState(
                {
                  listInvoiceVendorAll: res.data.data,
                },
                () => {
                  if (this.state.isFilterLoading) {
                    this.setState({
                      isFilterLoading: false,
                      isFilter: true,
                    });
                  }
                  if (this.state.isResetLoading) {
                    this.setState({
                      isResetLoading: false,
                      isFilter: true,
                    });
                  }
                }
              );
            }
          },
          (err) => {
            console.log("error: ", err);
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

 
  handleChangeString = (event) => {
    this.setState({
      actionFilter: {
        ...this.state.actionFilter,
        [event.target.name]: event.target.value,
      },
    }, () => 
      this.getListInvoiceVendorAll());
  };
  handleChangeStatus = (e) => {
    var selectPeriod = e;
    // console.log(selectPeriod ===1 ? true : false)
    if (selectPeriod ===1) {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          status: "1"
        },
      },()=>this.getListInvoiceVendorAll());
    }
    if (selectPeriod ===2) {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          status: "2"
        },
      },()=>this.getListInvoiceVendorAll());
    }
    if (selectPeriod ===3) {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          status: "3"
        },
      },()=>this.getListInvoiceVendorAll());
    }
  };
  handleChangePeriod = (e) => {
    var selectPeriod = e.target.value;
    if (selectPeriod === "1") {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: this.state.min_date,
          to_date: this.state.max_date,
        },
        select_period: "1",
        show_custome: false,
      });
    }
    if (selectPeriod === "2") {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: moment().subtract(1, "months").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
        select_period: "2",
        show_custome: false,
      });
    }
    if (selectPeriod === "3") {
      return this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
          to_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
        select_period: "3",
        show_custome: false,
      });
    }
    if (selectPeriod === "4") {
      this.setState({
        actionFilter: {
          ...this.state.actionFilter,
          from_date: "",
          to_date: this.state.actionFilter.to_date,
        },
        select_period: 4,
        show_custome: !this.state.show_custome,
      });
    }
  };

  handleFilter = () => {
    this.getListInvoiceVendorAll();
  };

  handleReset = () => {
    this.setState(
      {
        ...this.state,
        select_period: "1",
        actionFilter: {
          ...this.state.actionFilter,
          invoice: "",
          status: "1",
          po: "",
          from_date: "",
          to_date: ""
        },
        show_custome: false,
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getListInvoiceVendorAll();
            }
          );
        }
      }
    );
  };
  handleDetails = (e) => {
    var  id = e
    this.setState({
      activity: {
        select_id: id
      }
    }, () => {
      this.getListActivity()
    }
    )
  };
  handleViews = (e) => {
    this.props.history.push("/vendor/review-reguler-invoice/"+this.state.actionFilter.status+'/' + e );
    console.log(this.state.actionFilter.status)
  }
  getListActivity = () => {
      axios
      .post(
        config.API_URL + "/vendor/list_vendor_invoice_reguler_activity",this.state.activity,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listInvoiceStatusActivity: res.data.data,
              showActivity: !this.state.showActivity
            }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
        )
  };
  hideModal = () => {
    this.setState({
      showActivity: false,
      activity:{
        select_id: ""
      }
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  // handleDownload = (e) => {
  //   // this.props.history.push("/admin/Invoice-routing-slip/" + e.id);
  //   console.log(e)
  // };
  // handleRoutingSlip = (e) => {
  //   // this.props.history.push("/admin/Invoice-routing-slip/" + e.id);
  //   console.log(e)
  // };
  render() {
 
    var tableGroup = this.state.listStatus.map((items, index) => {
      
      if (this.state.actionFilter.status===items.value){
        return (
          <>
          <Button 
            className="mb-0" 
            style={{backgroundColor: "#5bc0de", color: "white"}} 
            onClick={()=>this.handleChangeStatus(index+1)} 
            key={index}>
            {items.label}
          </Button>
              </>
        );
      } else {
        return (
          <>
          <Button 
            className="mb-0" 
            onClick={()=>this.handleChangeStatus(index+1)} 
            key={index}>
            {items.label}
          </Button>
              </>
        );
      }
    })
    return (
      <>
        <StandardHeader name="Close" parentName="Invoice" link="close-reguler-invoice" />
        <Container className="mt--6" fluid>
          <div>
            <Modal
              centered
              fullscreen=""
              size="lg"
              isOpen={this.state.showActivity}
              toggle={this.handleDetails}
            >
              <ModalHeader toggle={this.hideModal}>
                <h3 className="text-center">Approval status</h3>
              </ModalHeader>
              <ModalBody>
                <Table responsive size="sm" borderless>
                  {this.state.listInvoiceStatusActivity.map(
                    (listAct, index) => {
                      if (listAct.status_id === 12) {
                      return (
                        <>
                        <div>
                          <tr key={index} >
                            <td className="text-muted text-center"style={{width:"10%"}}>{moment(listAct.created_at).format("hh:mm DD-MM-YYYY")}</td>&emsp;
                            <td style={{backgroundColor:"#EF9F9F",borderRadius:"5px",textAlign:"center",width:"30%"}}><b>Reject</b></td>
                            <td className="text-muted text-center"style={{width:"60%"}}>{listAct.reject}&emsp;{listAct.note} </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>&nbsp;</td>
                          </tr>
                          </div>
                        </>
                      );
                    } else if (listAct.status_id === 3 || listAct.select_id === 8 || listAct.select_id === 9 || listAct.select_id === 11) {
                      return (
                        <>
                        <div>
                          <tr key={index}>
                            <td className="text-muted text-center"style={{width:"10%"}}>{moment(listAct.created_at).format("hh:mm DD-MM-YYYY")}</td>&emsp;
                            <td style={{backgroundColor:"#BAD1C2",borderRadius:"5px",textAlign:"center",width:"30%"}}><b>Approved</b></td>
                            <td className="text-center"style={{width:"60%"}}>&emsp;</td>
                          </tr>
                          <tr>
                            <td colSpan={3}>&nbsp;</td>
                          </tr>
                          </div>
                        </>
                      );
                    } else if(listAct.status_id === 1) {
                      return (
                        <>
                        <div>
                          <tr key={index} >
                            <td className="text-muted text-center" style={{width:"10%"}}>{moment(listAct.created_at).format("hh:mm DD-MM-YYYY")}</td>&emsp;
                            <td style={{backgroundColor:"#F6F6F6",borderRadius:"5px",textAlign:"center",width:"30%"}}><b>Add Invoice</b></td>
                            <td className="text-center" style={{width:"60%"}}>&emsp;</td>
                          </tr>
                          <tr>
                            <td colSpan={3}>&nbsp;</td>
                          </tr>
                          </div>
                        </>
                      );
                    }
                    return null
                  }
                  )}
                </Table>
              </ModalBody>
              <ModalFooter className="text-center">
                <Button
                  color="link"
                  className="mx-6"
                  onClick={this.hideModal}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <Card className="bg-secondary shadow">
            {/* <CardBody>
              <Row>
                <Col className="col-lg-4 col-md-4 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Invoice</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="invoice"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.invoice}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-4 col-md-4 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">PO</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="po"
                      placeholder="search"
                      onKeyPress={this.keyPressed}
                      value={this.state.actionFilter.po}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-4 col-md-4 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Period</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="period"
                      value={this.state.select_period}
                      onChange={this.handleChangePeriod}
                    >
                      {this.state.listPeriod.map((items, index) => {
                        return (
                          <option key={index} value={items.value}>
                            {items.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-end">
                {this.state.show_custome ? (
                  <>
                    <Col className="col-lg-4 col-md-4 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">From date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="from_date"
                          value={this.state.actionFilter.from_date}
                          onChange={this.handleChangeString}
                          require
                          min={this.state.actionFilter.from_date}
                          max={this.state.actionFilter.to_date}
                          className={
                            this.state.paramError.from_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.from_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-4 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">To date</label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="to_date"
                          value={this.state.actionFilter.to_date}
                          min={this.state.actionFilter.from_date}
                          max={this.state.actionFilter.to_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.to_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.to_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row className="justify-content-md-center">
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <div className="text-center">
                    <Button
                      block
                      color="dark"
                      className="btn-icon"
                      type="button"
                      disabled={this.state.isResetLoading ? true : false}
                      onClick={() => this.handleReset()}
                    >
                      {this.state.isResetLoading ? (
                        <>
                          <span className="btn-inner--text">
                            <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                            Reset
                          </span>
                        </>
                      ) : (
                        <span className="btn-inner--text">Reset</span>
                      )}
                    </Button>
                  </div>
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <Button
                    block
                    color="primary"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isFilterLoading ? true : false}
                    onClick={() => this.handleFilter()}
                  >
                    {this.state.isFilterLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Filter
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Filter</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </CardBody> */}
          </Card>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  info={
                    <>
                     <Row>
                  {/* <Col className="text-left">
                    <h3>List all invoice</h3> */}
                    {/* <div className="d-flex row">

                    <div>
                    </div>
                    <div>
                          <Input
                             size="sm"
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="period"
                      value={this.state.select_period}
                      onChange={this.handleChangePeriod}
                    >
                      {this.state.listPeriod.map((items, index) => {
                        return (
                          <option key={index} value={items.value}>
                            {items.label}
                          </option>
                        );
                      })}
                    </Input>
                              <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", }}
                            onClick={() => this.setState({actionFilter:{...this.state.actionFilter,invoice:""}},()=> this.getListInvoiceVendor())}
                          >
                            <i className="fa fa-times-circle text-default" />
                          </button>
                          {this.state.show_custome ? (
                  <>
                        <Input
                          id="example-date-input"
                          type="date"
                          size="sm"
                          onKeyPress={this.keyPressed}
                          name="from_date"
                          value={this.state.actionFilter.from_date}
                          onChange={this.handleChangeString}
                          require
                          min={this.state.actionFilter.from_date}
                          max={this.state.actionFilter.to_date}
                          className={
                            this.state.paramError.from_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.from_date}
                        </div>
                        <Input
                          id="example-date-input"
                          type="date"
                          size="sm"
                          onKeyPress={this.keyPressed}
                          name="to_date"
                          value={this.state.actionFilter.to_date}
                          min={this.state.actionFilter.from_date}
                          max={this.state.actionFilter.to_date}
                          onChange={this.handleChangeString}
                          require
                          className={
                            this.state.paramError.to_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.to_date}
                        </div>
                  </>
                ) : null}
                    </div>
                    </div> */}
                  {/* </Col> */}
                      <Col className="col-lg-4 col-md-4 col-sm-7">
                          <InputGroup>
                            <Input
                              size="sm"
                              type="search"
                              className="search"
                              id="search"
                              placeholder="Search"
                              result
                              onKeyPress={this.keyPressed}
                              name="invoice"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                            />
                              <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            style={{ fontSize: "0.9em", height: "31px",border:"1px solid #D6D9DE",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px", }}
                            onClick={() => this.setState({actionFilter:{...this.state.actionFilter,invoice:""}},()=> this.getListInvoiceVendorAll())}
                          >
                            <i className="fa fa-times-circle text-default" />
                          </button>
                          </InputGroup>
                    </Col>
                      <Col className="col-lg-8 col-md-8 col-sm-5 text-right">
                    <ButtonGroup>
                    {
                      tableGroup
                    }
                    </ButtonGroup>
                      </Col>
                </Row>
                    </>
                  }
                  list={this.state.listInvoiceVendorAll}
                  views={this.handleViews}
                  // printDoc={this.handleDownload}
                  // printSlip={this.handleRoutingSlip}
                  // details={this.handleDetails}
                />
              </div>
            </div>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}
export default VendorInvoiceAll;
