import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
} from "reactstrap";
import StandardHeader from "components/Headers/HeaderForwarder";
import config from "services/config";
import axios from "axios";
// import SweetAlert from "react-bootstrap-sweetalert";
import ListInvoice from "./ForwarderVendorLIstData";

class ForwarderVendorList extends PureComponent {
  state = {
    listInvoiceVendor: [],
    listInvoiceStatusActivity: [],
    isFilter: false,
    isFilterLoading: false,
    actionFilter: {
      user_id: config.USER_ID,
      vendor: "",
      po: "",
      gr: "",
    },
    isLoading: false,
    alert: null,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListInvoiceVendor();
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  getListInvoiceVendor = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/forwarder/get_list_vendor",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listInvoiceVendor: res.data.data,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  
  handleDetails = (e) => {
    // console.log(e)
    this.props.history.push("/forwarder/detail-invoice-forwarder/" + e.id);
    // console.log(this.props.history.push("/forwarder/get_detail_vendor/" + e.id))
  };
 


  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getListInvoiceVendor();
      }
    );
  };
  
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
 
  render() {
    return (
      <>
        <StandardHeader
          name="List"
          parentName="Invoice"
          link="list-invoice-forwarder"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListInvoice
                  list={this.state.listInvoiceVendor}
                  info={
                      <Row>
                        <Col>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              placeholder="Search Vendor"
                              result
                              onKeyPress={this.keyPressed}
                              name="vendor"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              placeholder="Search PO"
                              result
                              onKeyPress={this.keyPressed}
                              name="po"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <InputGroup>
                            <Input
                              bsSize="sm"
                              type="search"
                              className="search"
                              placeholder="Search GR"
                              result
                              onKeyPress={this.keyPressed}
                              name="gr"
                              value={this.state.actionFilter.invoice}
                              onChange={this.handleChangeString}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                  }
                  actionStatus={this.handleStatusDetails}
                  details={this.handleDetails}
                />
              </div>
            </div>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}
export default ForwarderVendorList;
