import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Label,
  Card,
  CardHeader,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardBody,
  FormGroup,
  Input,
  // ButtonGroup,
  Button,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

class SettingAdm extends PureComponent {
  state = {
    national: [],
    isLoading: false,
    isFilterLoading: false,
    isResetLoading: false,
    alert: null,
    listAnnouncement: [],
    listTimeOperational: [],
    daysStart: ["start month",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,"end month"],
    daysEnd: ["start month",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,"end month"],
    opt : [{  
      key: 0,
      value: "CHOOSE",
  },
      {
      key: 1,
      value: "DAY",
  },
      {
      key: 2,
      value: "MONTH",
  }],
    modal: {
      setOperational: false,
      setOperationalDescription: "",
      setOperationalOption: ""
    },
   data: {
      startDate:"",
      startTime:"",
      endDate:"",
      startEnd:"",
      opts:""
   }
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      axios
        .get(
          "https://raw.githubusercontent.com/guangrei/APIHariLibur_V2/main/calendar.json",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (this.mounted) {
            this.setState({
              national: res.data,
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
    this.getAnnouncement();
    this.getListTimeOperational();
  };
  getListTimeOperational = async () => {
    await axios
      .post(
        config.API_URL + "/admin/list_time_operational",
        { id: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then((res) => {
        this.setState({
          ...this.state,
          listTimeOperational: res.data.data,
        });
      });
  };
  getAnnouncement = async () => {
    await axios
      .post(
        config.API_URL + "/admin/announcement",
        { id: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then((res) => {
        this.setState({
          ...this.state,
          listAnnouncement: res.data.data,
        });
      });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  actionTrigger = (data) =>{
    console.log(data)
    this.setState({
      modal: {
        setOperational: true,
        setOperationalDescription: data.opt === 1 ? data.start_description+" "+data.start_detail+" - "+data.end_description+" "+data.end_detail : data.start_description+" - "+data.end_description
      },
      data: {
        startDate: data.start_description,
        startTime: data.start_detail,
        endDate: data.end_description,
        endTime: data.end_detail,
        rpt: data.repeated,
        opt: data.opt
     }
      })
    // this.setState({modal: {setOperational: true,setOperationalDescription: data.start_detail +" -  "+data.end_detail,setOperationalOption: data.opt}})
  }

  handleChangeString = (event) => {
    this.setState({data:{
      ...this.state.data,
      [event.target.name]: event.target.value
    }})

  }

  render() {
   
    var national = this.state.national;
    var found = Object.entries(national).map(([key, value]) => (
      <>
        <tr>
          <td>{key}</td>
          <td>{value.holiday}</td>
          <td>{value.description + " " + value.summary}</td>
        </tr>
      </>
    ));
    var dataAnnouncement = this.state.listAnnouncement.map((data) => {
      return (
        <>
          <tr>
            <td className="text-center">{data.name}</td>
            <td>{data.description.substring(0, 140)}</td>
            <td className="text-center">{data.id}</td>
            <td className="text-center">{data.id}</td>
          </tr>
        </>
      );
    });
    return (
      <>
        <StandardHeader
          name="Setting"
          parentName="Settings"
          link="setting-ap"
        />
          <Modal
          centered
          size="lg"
          isOpen={this.state.modal.setOperational}
          // toggle={this.closeModal}
        >
  <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
                <Col xs="10">
                  <h3 className="mb-0">
                    {this.state.modal.setOperationalDescription}
                  </h3>
                    <small className="text-muted">Set Operational</small>
                </Col>
                <Col xs="2" className="text-right">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={()=>this.setState({
                      modal: {
                        setOperational: false,
                        setOperationalDescription: ""
                      },
                      data: {
                        startDate: "",
                        startTime: "",
                        endDate: "",
                        startEnd: "",
                        rpt: "",
                        opts: ""
                     }
                    })}
                  >
                    <span aria-hidden="true" style={{ fontSize: "1 rem" }}>
                      &times;
                    </span>
                  </button>
                </Col>
              </Row>
              </CardHeader>
              <CardBody>
<form action="">
  <Row>
    {/* <Col>
    
  <Input
                    name="setOperationalOption"
                    type="text"
                    value={this.state.modal.setOperationalOption}
                    // onChange={this.handleChangeString}
                    list="data"
                    style={{ textTransform: "uppercase", marginTop: "1em" }}
                    // className={
                    //   this.state.paramError.type_invoice ? "is-invalid" : ""
                    // }
                  />
                  <datalist id="data">
                    {this.state.opt.map((item, key) => (
                      <option key={key} value={item.key}>{item.value}</option>
                    ))}
                  </datalist>
    </Col> */}
    <Col className="text-center">
      <Button>
    days
      </Button>
    </Col>
    <Col className="text-center">
    <Button>
    month
    </Button>
    </Col>
  </Row>
  <Row>
  <Col className="text-center col-md-3 col-sm-3">
    <Label for="sdt">start date</Label>
  <Input
                    name="startDate"
                    type="text"
                    id="dt"
                    value={this.state.data.startDate}
                    onChange={this.handleChangeString}
                    list="cek"
                    style={{ textTransform: "uppercase", marginTop: "1em" }}
                    // className={
                    //   this.state.paramError.type_invoice ? "is-invalid" : ""
                    // }
                  />
                  <datalist id="cek">
                    {this.state.daysStart.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </datalist>
    </Col>
  <Col className="text-center col-md-3 col-sm-3">
  <Label for="stm">start hour</Label>
  <Input
                    name="startTime"
                    type="time"
                    id="stm"
                    value={this.state.data.startTime}
                    onChange={this.handleChangeString}
                  />
    </Col>
  <Col className="text-center col-md-3 col-sm-3">
    <Label for="edt">end date</Label>
  <Input
                    name="endDate"
                    type="text"
                    id="edt"
                    value={this.state.data.endDate}
                    onChange={this.handleChangeString}
                    list="cek"
                    style={{ textTransform: "uppercase", marginTop: "1em" }}
                    // className={
                    //   this.state.paramError.type_invoice ? "is-invalid" : ""
                    // }
                  />
                  <datalist id="cek">
                    {this.state.daysStart.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </datalist>
    </Col>
    <Col className="text-center col-md-3 col-sm-3">
  <Label for="stm">end hour</Label>
  <Input
                    name="endTime"
                    type="time"
                    id="stm"
                    value={this.state.data.endTime}
                    onChange={this.handleChangeString}
                  />
    </Col>
  </Row>
</form>
              </CardBody>
              </Card>
        </Modal>
        <Container className="mt--6" fluid>
          {/* <Row>
            <Col>
              <Card>
                <CardBody>
                  <Table bordered responsive>
                    <tr>
                      <th className="text-center">announcement</th>
                      <th className="text-center">description</th>
                      <th className="text-center">active</th>
                      <th className="text-center">action</th>
                    </tr>
                    {dataAnnouncement}
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col md={7} sm={7}>
              <Card>
                <CardBody>
                  <Table bordered responsive>
                    <thead dark>
                    <tr>
                      <th className="text-center">Start Day</th>
                      <th className="text-center">Start Hour</th>
                      <th className="text-center">End Day</th>
                      <th className="text-center">End Hour</th>
                      <th className="text-center">Set</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.listTimeOperational.map((item, index) => {
                      if (item.opt==1){
                        // if (!item.start_date){
                          return (
                            <>
                              <tr key={index}>
                                <th className="text-center">
                                  {item.start_description}
                                </th>
                                <th className="text-center">
                                  {item.start_detail}
                                </th>
                                <th className="text-center">
                                  {item.end_description}
                                </th>
                                <th className="text-center">
                                  {item.end_detail}
                                </th>
                                <th className="text-center">
                                  <Button
                                    className=""
                                    style={{
                                      backgroundColor: "#FFC107",
                                      color: "light",
                                      borderRadius: "36px",
                                      padding: "5px 10px",
                                    }}
                                    onClick={()=>this.actionTrigger(item)}
                                  >
                                   {item.start_description+" "+item.start_detail} - {item.end_description+" "+item.end_detail}
                                  </Button>
                                </th>
                              </tr>
                            </>
                          )
                        // } else {
                        //   return (
                        //     <>
                        //       <tr key={index}>
                        //         <th className="text-center">
                        //           {item.start_date}
                        //         </th>
                        //         <th className="text-center">
                        //           {item.start_detail}
                        //         </th>
                        //         <th className="text-center">
                        //           {item.end_date}
                        //         </th>
                        //         <th className="text-center">
                        //           {item.end_detail}
                        //         </th>
                        //         <th className="text-center">
                        //           <Button
                        //             className=""
                        //             style={{
                        //               backgroundColor: "#FFC107",
                        //               color: "light",
                        //               borderRadius: "36px",
                        //               padding: "5px 10px",
                        //             }}
                        //             onClick={()=>this.actionTrigger(item)}
                        //           >
                        //            {item.start_description+" "+item.start_detail} - {item.end_description+" "+item.end_detail}
                        //           </Button>
                        //         </th>
                        //       </tr>
                        //     </>
                        //   )
                        // }
                      } else {
                      if(!item.start_date)  {
                        return (
                          <>
                            <tr key={index}>
                              <th className="text-center">
                                {item.start_description}
                              </th>
                              <th className="text-center">
                              
                              </th>
                              <th className="text-center">
                                {item.end_description}
                              </th>
                              <th className="text-center">
                               
                              </th>
                              <th className="text-center">
                                <Button
                                  className=""
                                  style={{
                                    backgroundColor: "#FFC107",
                                    color: "light",
                                    borderRadius: "36px",
                                    padding: "5px 10px",
                                  }}
                                  onClick={()=>this.actionTrigger(item)}
                                >
                                 {item.start_description} - {item.end_description}
                                </Button>
                              </th>
                            </tr>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <tr key={index}>
                              <th className="text-center">
                                {item.start_date}
                              </th>
                              <th className="text-center">
                              
                              </th>
                              <th className="text-center">
                                {item.end_date}
                              </th>
                              <th className="text-center">
                               
                              </th>
                              <th className="text-center">
                                <Button
                                  className=""
                                  style={{
                                    backgroundColor: "#FFC107",
                                    color: "light",
                                    borderRadius: "36px",
                                    padding: "5px 10px",
                                  }}
                                  onClick={()=>this.actionTrigger(item)}
                                >
                                {item.start_date ? (
                                    <>
                                  {item.start_date} - {item.end_date} 
                                    </>
                                  )
                                  :(
<>
                                    {item.start_description} - {item.end_description}
</>
                                  )
                              }
                                </Button>
                              </th>
                            </tr>
                          </>
                        );
                      }
                      }
                     
                    })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col md={5} sm={5}>
              {/* <Card>
                <CardBody>
                  <Table bordered>{found}</Table>
                </CardBody>
              </Card> */}
                 <Card variant="danger">
              <CardHeader>
                <h2>
              Pemberitahuan
                </h2>
              </CardHeader>
              <CardBody>
              <p className="font-weight-normal">
   Mulai saat ini akan diberlakukan jadwal jam operasional E-INVOICE
  </p>
  <p className="font-weight-normal">
    Jadwal open:
  </p>
  <p className="font-weight-normal">
    Senin - kamis dimulai dari jam 09:00 wib - 15:00 wib pada tanggal 1 sampai 25 setiap bulannya
      </p>
  <p className="font-weight-normal">
    Jadwal close:
  </p>
  <p className="font-weight-normal">
    </p>
    <p className="font-weight-normal">Tanggal 26 sampai akhir bulan tanggal 30/31 setiap bulannya<br />hari jumat dan sabtu<br />setiap tanggal merah atau libur </p>
              </CardBody>
      </Card>
            </Col>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default SettingAdm;
