/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Modal,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
// import moment from "moment";
import { validateUser } from "services/validate";
import ListUsers from "./ListUserAdmin";
//redux
import { styleInputSelect } from "services/styles";
import AsyncSelect from "react-select/async";
import { validateAPCreateNewAccount } from "services/validate";
import { webdatetime } from "services/sysdate";

class UserAdmin extends PureComponent {
  state = {
    listVendor: [],
    selectedOptionCompanies: [],
    actionListUser: {
      username: "",
    },
    dataUser: {
      local_datetime: webdatetime(),
      company_id: '',
      username: "",
      password: "password",
      confirm_password: '',
      email: "",
      role_id: 6,
      is_active: 1,
    },
    dataUpdateUser: {
      local_datetime: webdatetime(),
      user_id:"",
      username: "",
      email: "",
      role_id: 6,
      is_active: 0,
    },
    paramErrorUser: {},
    alert: null,
    listUser: [],
    // total_pages: 0,
    // total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    showModalAdd: false,
    showModalEdit: false,
    showVendor: false,
    passwordShow: false,
    showEditVendor: false,
  };

  fetchDataCompanies = (inputValue) => {
    let searchTerm = inputValue;

    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }

    this.setState({
      dataUser: {
        ...this.state.dataUser,
        company_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/companies/companies_public",
      {id : this.props.match.params.id},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        this.setState({
          company_id: response.data.data[0].company_id ,
          company_name: response.data.data[0].company_name
        })
        const compare = response.data.data.filter((i) =>
          i.company_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.company_name,
          value: data.company_id,
        }));
      });
    }
  };
  onSearchChangeCompanies = (selectedOptionCompanies) => {
    this.setState({
      selectedOptionCompanies: selectedOptionCompanies,
      dataUser: {
        ...this.state.dataUser,
        company_id: selectedOptionCompanies.value,
      },
    });
  };
  componentDidMount = async () => {
    this.mounted = true;
    this.getDataListUser();
 
  };

  

  componentWillUnmount() {
    this.mounted = false; 
  }
  handleChangeStringUpdateUser = (event) => {
    this.setState({
      dataUpdateUser: {
        ...this.state.dataUpdateUser,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
        role_id: 6,
      },
    });
  };
  handleChangeString = (event) => {
    this.setState({
      dataUser: {
        ...this.state.dataUser,
        [event.target.name]: event.target.value,
        local_datetime: webdatetime(),
        role_id: 6,
      },
    });
  };
  handleChangeStringUser = (event) => {
    console.log(event);
    this.setState(
      {
        actionListUser: {
          ...this.state.actionListUser,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getDataListUser();
      }
    );
  };

  handleSubmitUser = () => {
    // var fd = this.state.dataUser.company_id
    // var pd =   fd.split("|")
    // var vn = pd[pd.length - 2]
    // this.setState(
    //   {
    //     isLoading: true,
    //     dataUser: {
    //       ...this.state.dataUser,
    //       company_id: vn,
    //     },
    //   },
    //   () => {
        const paramErrorUser = validateAPCreateNewAccount(this.state.dataUser);
        this.setState({ paramErrorUser });
        if (Object.keys(paramErrorUser).length === 0) {
          axios
            .post(config.API_URL + "/users/admin_create_user", this.state.dataUser, {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.data);
                  this.getDataListUser();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataUser.username);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
    //   }
    // );
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.setState({alert: false},()=> this.hideModal())}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={2000}
        >
          {`${data}`} has been successfully added.
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.setState({ ...this.state, alert: null })}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  handleSubmitUpdateUser = () => {
    this.setState({ isLoading: true });
    const paramErrorUser = validateUser(this.state.dataUpdateUser);
    this.setState({ paramErrorUser });
    if (Object.keys(paramErrorUser).length === 0) {
      axios
        .post(
          config.API_URL + "/users/admin_update_user",
          this.state.dataUpdateUser,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getDataListUser();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.dataUpdateUser.username);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

  getDataListUser = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/users/admin_list_user", this.state.actionListUser, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listUser: res.data.data,
              },
              () => {;
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handlepagination = (numPage) => {
    this.setState(
      {
        actionListUser: {
          ...this.state.actionListUser,
          page: numPage,
        },
      },
      () => {
        this.getDataListUser();
      }
    );
  };



  handleFilter = () => {
    this.setState(
      {
        actionListUser: {
          ...this.state.actionListUser,
          page: 1,
        },
      },
      () => {
        this.getDataListUser();
      }
    );
  };

  

  handleReset = (data) => {
   
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.resetUser(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="default"
          confirmBtnText="Yes, reset!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Password user will be reset with
           <br/>
           <br/>
          <b>{`${data.email} | password`}</b>
        </SweetAlert>
      ),
    });
  };
  // handleResetFilter = () => {
  //   this.setState(
  //     {
  //       actionListUser: {
  //         page: 1,
  //         items_per_page: 10,
  //         order_by_field: "created_at",
  //         order_by_direction: "DESC",
  //         user_name: "",
  //       },
  //     },
  //     () => {
  //       if (this.state.isResetLoading === false) {
  //         this.setState(
  //           {
  //             isResetLoading: true,
  //           },
  //           () => {
  //             this.getDataListUser();
  //           }
  //         );
  //       }
  //     }
  //   );
  // };
 
  handleDelete = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.delUser(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.username}`} user, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };
  resetUser = (data) => {
    // console.log(data)
    axios
      .post(
        config.API_URL + "/users/admin_reset_user",
        { user_id: data.user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.setState({
            actionListUser: {
              username: "",
            },
          },()=>
          this.getDataListUser(),
          this.confirmedResetAlert(data)
          )
        },
        (err) => {
          console.log(err);
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
            }
          }
        }
      );
  };
  delUser = (data) => {
    // console.log(data)
    axios
      .post(
        config.API_URL + "/users/admin_delete_user",
        { user_id: data.user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          this.confirmedAlert(data.username);
          this.getDataListUser();
        },
        (err) => {
          console.log(err);
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
            }
          }
        }
      );
  };
  confirmedResetAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Successfully"
          onConfirm={() => this.setState({alert: null},()=>this.getDataListUser())}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={2000}
        >
        User with email {`${data.email}`} has been reset password.
        <br/>
        </SweetAlert>
      ),
    });
  };
  confirmedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.setState({alert: null},()=>this.getDataListUser())}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={2000}
        >
         {`${data}`} user has been deleted.
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleDetails = (e) => {
    var is_ac
    if(e.is_active == 1 && e.is_verified ==1 ) {
    is_ac = 1
  }else{
    is_ac = 0
  }

    this.setState({
      ...this.state,
      showModalEdit: true,
      dataUpdateUser: {
        user_id: e.user_id,
        username: e.username,
        email: e.email,
        role_id: 6,
        is_active: is_ac,
      },
    })
    // this.props.history.push("/admin/user_sales_details/" + e.user_id);
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handletoAdd = () => {
    try{
      // if (this.props.match.params.id){
        const newRequest = axios.post(
          config.API_URL + "/companies/companies_public",
          {id : this.props.match.params.id},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
            );
            if (newRequest) {
              return newRequest.then((response) => {
                this.setState({
                  data:{
                    company_id    : response.data.data[0].company_id,
                    company_name  : response.data.data[0].company_name 
                  },
                  showModalAdd: true,
                })
              })
            }
      // } else {
      //   this.props.history.push("/aunt/login");
      // }
    } catch (error) {
      // this.props.history.push("/aunt/login");
      console.log(error)
    }
    // this.setState({
    //   ...this.state,
    //   showModalAdd: true,
    // });
  };
  hideModal = () => {
    this.setState({
      showModalAdd: false,
      showModalEdit: false,
      dataUser: {
        username: "",
        password: "",
        email: "",
        role_id: 6,
        is_active: 1,
      },
      dataUpdateUser: {
        username: "",
        email: "",
        role_id: 0,
        is_active: 0,
      },
    });
  };
  
  togglePassword = () => {
    this.setState({
      ...this.state,
      passwordShow: !this.state.passwordShow,
    });
  };
  handleDetailsVendor = (data) => {
    this.props.history.push("/admin/vendor_detail/" + data.company_id);
  };
  render() {
    // if (this.state.dataUser.company_id){
    //   var filterData = this.state.dataUser.company_id
    //   var pickData =   filterData.split("|")
    //   var vendorName = pickData[pickData.length - 1]
    // }


    return (
      <>
        <StandardHeader name="User" parentName="User" link="user" />
        <Container className="mt--6" fluid>
          <Modal
            centered
            fullscreen=""
            // size="lg"
            isOpen={this.state.showModalAdd}
            // toggle={this.handleDetails}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Add user
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.hideModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                {/* <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Role</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="role_id"
                      value={this.state.dataUser.role_id}
                      required
                      onChange={this.handleChangeString}
                    >
                      <option value={6}>Vendor</option>
                      <option value={2}>Adm AP</option>
                      <option value={3}>Adm TAX</option>
                    </Input>
                  </FormGroup>
                </Col> */}
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                <FormGroup>
                   <AsyncSelect
                      cacheOptions
                      defaultOptions
                      styles={styleInputSelect}
                      value={this.state.selectedOptionCompanies}
                      placeholder={<div>Select</div>}
                      loadOptions={this.fetchDataCompanies}
                      onChange={(e) => {
                        this.onSearchChangeCompanies(e);
                      }}
                    />
                </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Username</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="username"
                      placeholder="Username"
                      value={this.state.dataUser.username}
                      required
                      className={
                        this.state.paramErrorUser.username ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeString}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="email"
                      placeholder="Email"
                      value={this.state.dataUser.email}
                      required
                      className={
                        this.state.paramErrorUser.email ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeString}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.email}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Password</label>
                    <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                      <Input
                        id="password"
                        type={this.state.passwordShow ? "text" : "password"}
                        onKeyPress={this.keyPressed}
                        name="password"
                        value={this.state.dataUser.password}
                        required
                        className={
                          this.state.paramErrorUser.password ? "is-invalid" : ""
                        }
                        onChange={this.handleChangeString}
                        // disabled={this.state.updateUser ? true : false}
                      />
                      <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                          {this.state.passwordShow ? (
                            <a onClick={this.togglePassword} style={{cursor: "pointer",objectFit: "cover"}}>

                            <i className="fa fa-eye" />
                            </a>
                            ) : (
                              <a onClick={this.togglePassword} style={{cursor: "pointer",objectFit: "cover"}}>
                              <i className="fa fa-eye-slash" />
                              </a>
                          )}
                      </InputGroupText>
                    </InputGroupAddon>
                    
                      <div className="invalid-feedback">
                        {this.state.paramErrorUser.password}
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Is Active</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="is_active"
                      placeholder="Is Active"
                      value={this.state.dataUser.is_active}
                      required
                      onChange={this.handleChangeString}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.is_active}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                color="link"
                data-dismiss="modal"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="btn-icon"
                type="button"
                disabled={this.state.isLoading ? true : false}
                onClick={() => this.handleSubmitUser()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
          <Modal
            centered
            fullscreen=""
            // size="lg"
            isOpen={this.state.showModalEdit}
            // toggle={this.handleDetails}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Edit user
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.hideModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Username</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="username"
                      placeholder="Username"
                      value={this.state.dataUpdateUser.username}
                      required
                      className={
                        this.state.paramErrorUser.username ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUpdateUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.username}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="email"
                      placeholder="Email"
                      value={this.state.dataUpdateUser.email}
                      required
                      className={
                        this.state.paramErrorUser.email ? "is-invalid" : ""
                      }
                      onChange={this.handleChangeStringUpdateUser}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.email}
                    </div>
                  </FormGroup>
                </Col>
                {/* <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Password</label>
                    <InputGroup>
                      <Input
                        type={this.state.passwordShow ? "text" : "password"}
                        onKeyPress={this.keyPressed}
                        name="password"
                        placeholder="Password"
                        value={this.state.dataUpdateUser.password}
                        required
                        className={
                          this.state.paramErrorUser.password ? "is-invalid" : ""
                        }
                        onChange={this.handleChangeStringUpdateUser}
                        // disabled={this.state.dataUpdateUser ? true : false}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-outline-primary"
                          onClick={this.togglePassword}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {this.state.passwordShow ? (
                            <i className="fa fa-eye" />
                          ) : (
                            <i className="fa fa-eye-slash" />
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {this.state.paramErrorUser.password}
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col> */}
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Is Active</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="is_active"
                      placeholder="Is Active"
                      value={this.state.dataUpdateUser.is_active}
                      onChange={this.handleChangeStringUpdateUser}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramErrorUser.is_active}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                // className="ml-auto"
                color="link"
                data-dismiss="modal"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="btn-icon"
                type="button"
                disabled={this.state.isLoading ? true : false}
                onClick={() => this.handleSubmitUpdateUser()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                  <ListUsers
                   list={this.state.listUser}
                  info={
                    <div className="d-flex justify-content-end">
                    <div className="px-4" style={{ width: "500px" }}>
                      <InputGroup>
                    <Input
                      size="sm"
                      id="example-date-input"
                      type="text"
                      onKeyPress={this.keyPressed}
                      result
                      name="username"
                      placeholder="Search"
                      value={this.state.actionListUser.username}
                      onChange={this.handleChangeStringUser}
                    />
                      <button
                                type="button"
                                className="btn btn-secondary btn-sm"
                                style={{
                                  fontSize: "0.9em",
                                  height: "31px",
                                  border: "1px solid #D6D9DE",
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      actionListUser: {
                                        ...this.state.actionListUser,
                                        username: "",
                                      },
                                    },
                                    () => this.getDataListUser()
                                  )
                                }
                              >
                                <i className="fa fa-times-circle text-default" />
                              </button>
                  </InputGroup>
                  </div>

                  <Button
                    size="sm"
                    color="success"
                    type="button"
                    className="btn-icon"
                    onClick={() => this.handletoAdd()}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add" />
                    </span>{" "}
                    Add
                  </Button>
                
                </div>
                  }
                  vendor={this.handleDetailsVendor}
                  details={this.handleDetails}
                  delete={this.handleDelete}
                  reset={this.handleReset}
                />
              </div>
            </div>
          </Row>
        </Container>

        {this.state.alert}
      </>
    );
  }
}

export default UserAdmin;
