/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

// reactstrap components
import { Card, CardHeader, Alert, Row } from "reactstrap";
// import Button from "reactstrap/lib/Button";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            {/* <option value="10">10</option>
            <option value="25">25</option> */}
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListInvoiceTax = (props) => {
  const info = props.info;
  const uid = props.uid;
  // const budge = props.budge;
  var btnPurple = {
    backgroundColor: "#8965e0",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  };
  var btndetail = {
    backgroundColor: "#6C757D",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  };

  function dateFormatter(cell, row) {
    if (!cell) {
      return "";
    }
    return moment(cell).format("DD-MM-YYYY");
  }

  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "created_at",
              text: "Created date",
              sort: true,
              formatter: dateFormatter,
            },
            {
              dataField: "company_name",
              text: "Vendor",
              sort: true,
               formatter: (cell, row) => {
                return (<>
                  <a
                        // style={btnPurple}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => props.details(row)}
                        className="table-action"
                      >
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        />
                        <b>{" "+cell}</b>
                      </a>
                </>)
              }
              // formatter: (cell, row) => {
              //   if (!row.pic_id) {
              //     return (
              //       <>
              //         <a
              //           style={btnPurple}
              //           onClick={() => props.verification(row)}
              //           className="table-action text-white btn btn-success btn-sm"
              //         >
              //           <i
              //             className="fa fa-arrow-circle-right"
              //             aria-hidden="true"
              //           />
              //           <b>{cell}</b>
              //         </a>
              //       </>
              //     );
              //   } else if (row.pic_id == uid) {
              //     return (
              //       <>
              //         <a
              //           style={btndetail}
              //           onClick={() => props.verification(row)}
              //           className="table-action text-white btn btn-success btn-sm"
              //         >
              //           <i
              //             className="fa fa-arrow-circle-right"
              //             aria-hidden="true"
              //           />
              //           <b>{cell}</b>
              //         </a>
              //       </>
              //     );
              //   } else {
              //     return (
              //       <>
              //         <a
              //           style={{
              //             pointerEvents: "none",
              //             cursor: "not-allowed !important",
              //           }}
              //           className="table-action btn btn-secondary btn-sm"
              //         >
              //           <i
              //             className="fa fa-hourglass-half text-primary"
              //             aria-hidden="true"
              //           />
              //           {"  "}
              //           <b>{cell}</b>
              //         </a>
              //       </>
              //     );
              //   }
              // },
            },

            
            {
              dataField: "invoice",
              text: "Invoice",
              sort: true,
               formatter: (cell, row) => {
                return (
                  <>
                  
                <a
                style={{
                  cursor: "pointer",
                }}
                onClick={() => props.show(row)}
                className="table-action"
                >
                <b>{" "+cell}</b>
              </a>
                </>
              )
            }
            },
            {
              dataField: "purchase_order",
              text: "Purchase order",
              sort: true,
              headerAlign: "center",
              align: "center",
            },
            {
              dataField: "username",
              text: "process",
              sort: true,
              headerAlign: "center",
              align: "center",
              // formatter: (cell, row) => {
              //   if (row.pic_id == uid) {
              //     return <b>You</b>;
              //   } else {
              //     return <b>{cell}</b>;
              //   }
              // },
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  {/* <Col md={6} xl={8} sm={12}>
                <h3 className="mb-0">List active invoice{" "}<Badge bg="default">{budge}</Badge>
                </h3> 
                </Col> */}
                  {info}
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Invoice empty</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListInvoiceTax;
