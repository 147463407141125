import React, { PureComponent } from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import config from "services/config";
import axios from "axios";
import { currencyIDR } from "services/currency";
import SweetAlert from "react-bootstrap-sweetalert";
import { webdatetime } from "services/sysdate";
const icon_cl = {
  color: "#16a085",
};
class InvoiceRoutingSlipAdmin extends PureComponent {
  state = {
    preview: undefined,
    isLoading: false,
    paramError: {},
    data: [],
    doc: {
      pic_id: config.USER_ID,
      invoice_id: "",
      sap_number: "",
    },
    ready: false,
  };
  componentDidMount() {
    this.mounted = true;
    this.getDocument();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getDocument = async () => {
    const response = await axios.post(
      config.API_URL + "/admin/routing_slip_print_admin",
      { invoice: this.props.match.params.conId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );
    this.setState(
      {
        data: {
          id: response.data.data[0].id,
          invoice_type: response.data.data[0].invoice_type,
          company_name: response.data.data[0].company_name,
          invoice: response.data.data[0].invoice,
          invoice_file: response.data.data[0].invoice_file,
          purchase_order: response.data.data[0].purchase_order,
          good_receipt: response.data.data[0].good_receipt,
          tax_invoice: response.data.data[0].tax_invoice,
          bast: response.data.data[0].bast,
          delivery_note: response.data.data[0].delivery_note,
          sap: response.data.data[0].sap_number,
          ppn: response.data.data[0].ppn,
          pph: response.data.data[0].pph,
          tax_ppn: response.data.data[0].tax_ppn,
          ppn_value: response.data.data[0].ppn_rate,
          tax_pph: response.data.data[0].tax_pph,
          pph_value: response.data.data[0].tax_rate,
          invoice_amount: response.data.data[0].invoice_amount,
          total_invoice: response.data.data[0].total_invoice,
        },
      }
    );
  };

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        // THIS IS THE HACK
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  handleChangeString = (event) => {
    this.setState({
      doc: {
        ...this.state.doc,
        [event.target.name]: event.target.value,
        invoice_id: this.state.data.id,
      },
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.setState({ alert: null }, () => this.getDocument())
          }
          // onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          timeout={700}
          // confirmBtnText={<>
          // <i className="fa fa-print" aria-hidden="true"></i>&emsp; Ok
          // {/* <i className="fa fa-print" aria-hidden="true"></i>&emsp; Print routing slip */}
          // </>
          // }
          cancelBtnBsStyle="danger"
          cancelBtnText="Cancel"
          btnSize=""
        >
          <b>Submit SAP number has been successfully</b>
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
          timeout={700}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  confirmCancel = () => {
    this.props.history.push("/admin/vendor_invoice_detail/"+ this.props.match.params.conId );
  };

  render() {
    const pageStyle = `
    @page {
      size: A4;
      text-align: center;
      margin: 8em 2em 0 4em ;
    }
    @media all {
      .pagebreak {
        display: none;
      }
    }
    @media print {
      .pagebreak {
        page-break-before: always;
      }
      html, body {
        width: 210mm;
        height: 297mm;
      }
    }
  `;
    return (
      <>
        <Container className="mt-4" fluid>
          {this.state.data.sap === "" ? null : (
            <>
              <ComponentToPrint
                data={this.state.data}
                ref={(el) => (this.componentRef = el)}
              />
              <Container style={{ width: 900 }}>
                <Row className="justify-content-md-center mb-3 text-center">
                  <Col className="ml-5">
                    <Button
                      block
                      className="text-uppercase"
                      color="danger"
                      type="button"
                      onClick={() => this.confirmCancel()}
                    >
                      <span id="request_quote" className="btn-inner--text">
                        Back
                      </span>
                    </Button>
                  </Col>

                  <Col className="mr-5">
                    {this.state.data.sap === "" ? null : (
                      <>
                        <div>
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                color="default"
                                className="text-uppercase"
                                id="print-tooltip"
                                block
                              >
                                Print
                              </Button>
                            )}
                            pageStyle={pageStyle}
                            content={() => this.componentRef}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </>
          )}
          {this.state.alert}
        </Container>
      </>
    );
  }
}
class ComponentToPrint extends React.Component {
  render() {
    return (
      <section>
        <div className="text-center">
          <h5 className="text-muted">INVOICE ROUTING SLIP</h5>
          <h2>{this.props.data.company_name} </h2>
        </div>
        <span
          style={{
            borderStyle: " solid double",
            width: "100%",
            display: "block",
            margin: "2em 0 3em 0",
          }}
        />
        <div className="mx-4 mt-5">
          <FormGroup>
            <table style={{ width: "100%", margin: "3em 0 0 0" }}>
              <tbody>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    SAP document number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.sap}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    Invoice number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.invoice}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    Purchase order number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.purchase_order}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                    Tax invoice number
                  </td>
                  <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                  <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                    <h3>
                      {this.props.data.tax_invoice}
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
          </FormGroup>
          </div>
          <FormGroup>
            {/* <table style={{ width: "100%", margin: "1em 0 0 0" }}>
                <tbody>
                  <tr>
                    <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                      Good receipt (GR)
                    </td>
                    <td
                      style={{ margin: "30em 3em 0 0", width: "5%" }}
                      className="text-center"
                    >
                      :
                    </td>
                    <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                      <h3>
                        {" "}
                        {this.props.data.good_receipt ? (
                          <i
                            className="fa fa-check color-green fa-lg"
                            style={icon_cl}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle fa-lg"
                            aria-hidden="true"
                          ></i>
                        )}
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                      Minutes of handover
                      <small>
                        <i className="text-muted"> BAST</i>
                      </small>
                    </td>
                    <td
                      style={{ margin: "30em 3em 0 0", width: "5%" }}
                      className="text-center"
                    >
                      :
                    </td>
                    <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                      <h3>
                        {" "}
                        {this.props.data.bast ? (
                          <i
                            className="fa fa-check color-green fa-lg"
                            style={icon_cl}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle fa-lg"
                            aria-hidden="true"
                          ></i>
                        )}
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                      Delivery Note
                    </td>
                    <td
                      style={{ margin: "30em 3em 0 0", width: "5%" }}
                      className="text-center"
                    >
                      :
                    </td>
                    <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                      <h3>
                        {" "}
                        {this.props.data.delivery_note ? (
                          <i
                            className="fa fa-check color-green fa-lg"
                            style={icon_cl}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle fa-lg"
                            aria-hidden="true"
                          ></i>
                        )}
                      </h3>
                    </td>
                  </tr>
                  {this.props.data.siuk_file ? (
                    <tr>
                      <td style={{ margin: "30em 3em 0 0", width: "50%" }}>
                        Surat izin usaha kontruksi (SIUK)
                      </td>
                      <td
                        style={{ margin: "30em 3em 0 0", width: "5%" }}
                        className="text-center"
                      >
                        :
                      </td>
                      <td style={{ margin: "30em 5em 0 0", width: "45%" }}>
                        <h3>
                          {" "}
                          {this.props.data.delivery_note ? (
                            <i
                              className="fa fa-check color-green fa-lg"
                              style={icon_cl}
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-times-circle fa-lg"
                              aria-hidden="true"
                            ></i>
                          )}
                        </h3>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table> */}
            <span
              style={{
                borderStyle: "solid",
                borderWidth: "2px",
                display: "block",
                margin: "3em 0 3em 0",
              }}
            />
          </FormGroup>
          <div className="mx-4 mt-5">
          <table style={{ width: "100%", margin: "3em 0 0 0" }}>
            <tbody>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  Type invoice
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3
                    className="text-right"
                    style={{
                      fontSize: "1.30em",
                    }}
                  >
                    {this.props.data.invoice_type}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  Amount invoice
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3 className="text-right">
                    {currencyIDR(this.props.data.invoice_amount).replace(
                      "IDR",
                      "RP"
                    )}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 30em", width: "35%" }}>PPN</td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3 className="text-right">
                    {this.props.data.ppn === 1 ? (
                      <>
                        {currencyIDR(this.props.data.ppn_value).replace(
                          "IDR",
                          ""
                        )}
                      </>
                    ) : (
                      0
                    )}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  PPH {this.props.data.tax_pph}&nbsp;%
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3 className="text-right">
                    {this.props.data.pph == 1 ? (
                      <>
                        {currencyIDR(this.props.data.pph_value).replace(
                          "IDR",
                          "- "
                        )}
                      </>
                    ) : (
                      -0
                    )}
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ margin: "30em 3em 0 0", width: "35%" }}>
                  Total invoice
                </td>
                <td
                    style={{ margin: "30em 3em 0 0", width: "5%" }}
                    className="text-center"
                  >
                    :
                  </td>
                <td style={{ margin: "30em 5em 0 0", width: "60%" }}>
                  <h3
                    className="text-right"
                    style={{
                      fontSize: "1.30em",
                    }}
                  >
                    {currencyIDR(this.props.data.total_invoice).replace(
                      "IDR",
                      "RP"
                    )}
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      </section>
    );
  }
}

ComponentToPrint.propTypes = {
  data: PropTypes.array,
};

export default InvoiceRoutingSlipAdmin;
