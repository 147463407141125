import React, { PureComponent } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import config from "services/config";
import { validateLogin } from "services/validate";
import axios from "axios";
// import AuthHeader from "components/Headers/AuthHeader";
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
var jwt = require("jwt-simple");
var secret = "HS256 ";
const nD = new Date();
const dt =
  nD.getFullYear() +
  "-" +
  ("0" + (nD.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + nD.getDate()).slice(-2) +
  " " +
  ("0" + nD.getHours()).slice(-2) +
  ":" +
  ("0" + nD.getMinutes()).slice(-2) +
  ":" +
  nD.getSeconds();

class Home extends PureComponent {
  state = {
    data: {
      email: "",
      password: "",
    },
    passwordShow: false,
    authError: false,
    isLoading: false,
    paramError: {},
    token: null,
    alert: null,
  };
  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    const paramError = validateLogin(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(config.API_URL + "/auth/login_admin", this.state.data, headers)
        .then(
          (res) => {
            localStorage.setItem("token", jwt.encode(res.data.data, secret));
            localStorage.setItem("refresh_admin", "true");
            this.setState({ isLoading: false });
            this.props.history.push("/admin");
          },
          (err) => {
            this.setState({ authError: true, isLoading: false });
            this.failedAlert("Invalid Credentials");
          }
        );
    } else {
      this.setState({ isLoading: false });
    }
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyUp = (event) => {
    event.preventDefault();
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      this.handleSubmit();
    }
  };
  togglePassword = () => {
    this.setState({
      ...this.state,
      passwordShow: !this.state.passwordShow,
    });
  };
  render() {
    // const paramError = this.state.paramError;
    return (
      
      <div style={{ height: `1000px` }}>
        {/* <AuthHeader title="ADMIN" lead="ggygg" /> */}
        <div className="header pt-lg-7">
          <Container>
            <div className="header-body text-center mb-4">
              <Row className="justify-content-center">
                <Col className="px-5" lg="8" md="8" xl="8">
                    <h1 className="text-white"><i className="fa fa-lock text-light" aria-hidden="true"/>{" "}Admin</h1>
                    <p style={{textShadow: `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`}} className="h3 text-white">{this.props.lead}</p>
             
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="my-auto pb-5">
          <Row className="justify-content-center">
            <Col lg="4" md="7">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: this.state.focusedEmail,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={this.state.data.email}
                      onChange={this.handleChange}
                      required
                      // className={this.state.paramError.emailAdmin ? "is-invalid" : ""}
                      className={
                        this.state.paramError.email ? "is-invalid" : ""
                      }
                      onFocus={() => this.setState({ focusedEmail: true })}
                      onBlur={() => this.setState({ focusedEmail: false })}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.email}
                      {/* {this.state.paramError.emailAdmin} */}
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: this.state.focusedPassword,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                     type={this.state.passwordShow ? "text" : "password"}
                      onKeyDown={this.handleEnter}
                      name="password"
                      placeholder="Password"
                      value={this.state.data.password}
                      onChange={this.handleChange}
                      required
                      className={
                        this.state.paramError.password ? "is-invalid" : ""
                      }
                      onFocus={() => this.setState({ focusedPassword: true })}
                      onBlur={() => this.setState({ focusedPassword: false })}
                    />
                      <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                          {this.state.passwordShow ? (
                            <a onClick={this.togglePassword} style={{cursor: "pointer",objectFit: "cover"}}>

                            <i className="fa fa-eye" />
                            </a>
                            ) : (
                              <a onClick={this.togglePassword} style={{cursor: "pointer",objectFit: "cover"}}>
                              <i className="fa fa-eye-slash" />
                              </a>
                          )}
                      </InputGroupText>
                    </InputGroupAddon>
                    <div className="invalid-feedback">
                      {this.state.paramError.password}
                    </div>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    block
                    className="text-uppercase my-4"
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={this.state.isLoading ? true : false}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Login
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Login</span>
                    )}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </div>
    );
  }
}

export default Home;
