/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import { Card, CardHeader, Alert, Button, Row, Col } from "reactstrap";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
      </label>
    </div>
  ),
});
const ListInvoiceVendor = (props) => {
  const info = props.info;
  var btnPurple = {
    backgroundColor: "#8965e0",
    color: "#fff",
    border: "none",
    pointerEvents: "none",
    cursor: "not-allowed !important"
  };
  function dateFormatter(cell, row) {
    if (!cell) {
      return "";
    }
    return moment(cell).format("DD-MM-Y");
  }
  function handleStatus(cell, row) {
  
    if (cell === 5 || cell === 2 || cell === 7) {
      return (
        <>
          <div className="text-center">
            <Button
              size="sm"
              style={btnPurple}
              
              // onClick={() => props.actionStatus(row)}
            >
              {/* <i
                className="fa fa-arrow-circle-right fa-lg"
                aria-hidden="true"
              ></i> */}
              {"  "}ON PROGRESS
            </Button>
          </div>
        </>
      );
    }

  
  }
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "created_at",
              text: "Created date",
              sort: true,
              formatter: dateFormatter,
            },
            {
              dataField: "invoice",
              text: "Invoice",
              sort: true,
              formatter: (cell, row) => {
                if (row.revision > 0){
                  return (
                    <>
                      <a
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                        onClick={() => props.details(row)}
                      >
                        <b>{cell+" R"+row.revision}</b>
                      </a>
                    </>
                  );
                } else {
                  return (
                    <>
                      <a
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                        onClick={() => props.details(row)}
                      >
                        <b>{cell}</b>
                      </a>
                    </>
                  );
                }
              },
            },
            {
              dataField: "purchase_order",
              text: "PO",
              sort: true,
            },
            {
              dataField: "good_receipt",
              text: "GR",
              sort: true,
              },
              {
                dataField: "tax_invoice",
                text: "Tax invoice",
                sort: true,
              },
            {
              dataField: "bast",
              text: "BAST",
              sort: true,
            },
            {
              dataField: "delivery_note",
              text: "Delivery Note",
              sort: true,
            },
            {
              dataField: "invoice_status",
              text: "status",
              headerAlign: "center",
              formatter: handleStatus,
              sort: true,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col className="col-md-6 col-sm-12 text-left">
                    <h3 className="mb-0">List active invoice</h3>
                  </Col>
                  {info}
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data record</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListInvoiceVendor;
